import React from 'react';

const ArrowRightIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 40 40"
      width="40px"
      fill="#383D42"
    >
      <path d="M0 0h40v40H0z" fill="none" />
      <path d="M17,14.7c-0.4,0.4-0.4,1,0,1.4l3.9,3.9L17,23.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l4.6-4.6c0.4-0.4,0.4-1,0-1.4l-4.6-4.6C18,14.3,17.4,14.3,17,14.7z" />
    </svg>
  );
};

export default ArrowRightIcon;
