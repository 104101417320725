import styled from 'styled-components';

export const ScrollToTopButtonWrapper = styled.div`
  .scroll-pill {
    position: fixed !important;
    left: 45%;
    bottom: 86px;
    height: 37px;
    width: 103px;
    z-index: 1;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    background-color: #00c400 !important;
    border-radius: 30px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

    // do not show on mobiles
    @media (max-width: 768px) {
      display: none;
    }
  }

  .scroll-button {
    position: fixed !important;
    left: 20px !important;
    bottom: 86px;
    height: 56px;
    width: 56px;
    z-index: 1;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #00c400 !important;
    border-radius: 50%;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

    // do not show on desktop
    @media (min-width: 768px) {
      display: none;
    }
  }
`;
