import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import ProductTile from '../ui/ProductTile';
import { ProductListWrapper } from '../styles/List';
import FilterForm from '../filter/FilterForm/FilterForm';
import {
  resetFiltersAction,
  getProductsAction,
  loadMoreProductsAction,
  loadProductAction,
  resetPaginationAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import {
  updateScrollPositionAction,
  scrollToLastPositionAction,
  updateLastScrollPositionAction,
  setUpdateLastScrollPosition,
  setPreviousPageIsProductAction,
  setPreviousUrlAction,
  scrollDataActionUi,
} from '../../store/actions/uiAction';
import FilterButton from '../ui/FilterButton';
import { useTranslation } from 'react-i18next';

import './products.css';
import SortButton from '../filter/SortButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import ProductTileSkeleton from '../skeletons/elements/products/ProductTileSkeleton';
import { getWindowPosition } from '../../utils/window';
import ScrollButton from '../ui/scrollButton';

function Products() {
  const [selectedSort, setSelectedSort] = useState('featured');
  const location = useLocation();
  const products = useSelector((state) => state.catalogManager.products);
  const hasMore = useSelector((state) => state.catalogManager.hasMore);
  const selectedFIlters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  const page = useSelector((state) => state.catalogManager.page);
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const isFiltersUpdated = useSelector(
    (state) => state.catalogManager.isFiltersUpdated,
  );
  const savedPage = useSelector((state) => state.catalogManager.savedPage);
  const useSavedPage = useSelector(
    (state) => state.catalogManager.useSavedPage,
  );
  // console.log('useSavedPage : ', useSavedPage);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  // console.log('scrollToLastPosition : ', scrollToLastPosition);
  const lastPosition = useSelector(
    (state) => state.uiManager.lastScrollPosition,
  );
  const currentScroll = useSelector((state) => state.uiManager.scrollPosition);
  const updateLastScrollPosition = useSelector(
    (state) => state.uiManager.updateLastScrollPosition,
  );
  const previousPageIsProduct = useSelector(
    (state) => state.uiManager.previousPageIsProduct,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('product');

  // useEffect(() => {
  //   dispatch(setPreviousPageIsProductAction(false));
  // }, [dispatch]);

  useEffect(() => {
    // console.log('updateLastScrollPosition:: ', updateLastScrollPosition);
    window.addEventListener('scroll', handleScroll);
    setTimeout(
      () => {
        if (scrollToLastPosition) {
          window.scrollTo({
            top: lastPosition,
            behavior: 'smooth',
          });
        }
        // dispatch(scrollToLastPositionAction(false));
        // dispatch(setUpdateLastScrollPosition(true));

        // dispatch(setPreviousPageIsProductAction(false));

        // console.log('scrollToLastPosition : ', scrollToLastPosition);
        // console.log('updateLastScrollPosition : ', updateLastScrollPosition);
      },
      300,
      dispatch(scrollDataActionUi('used')),
    );

    return () => {
      // dispatch(setSortByAction('featured'));
      window.removeEventListener('scroll', handleScroll);
      // dispatch(setPreviousPageIsProductAction(false));
    };
  }, [window.scrollY]);

  const handleScroll = () => {
    // console.log('position : out ', window.scrollY);

    dispatch(updateScrollPositionAction(window.scrollY));
  };

  const handleSelectChange = (e) => {
    let selected = e.target.value;
    setSelectedSort(selected);
    dispatch(setSortByAction(selected));
    // dispatch(resetPaginationAction());
    dispatch(loadProductAction());
    // console.log('length of products', products.length);
    if (scrollToLastPosition === false || products.length < 1) {
      dispatch(
        getProductsAction({ ...selectedFIlters, sortBy: selected, page: 0 }),
      );
    }
  };

  const history = useHistory();
  useEffect(() => {
    let prevLocation;
    history.listen((nextLocation) => {
      // console.log("Prev URl",prevLocation);
      // ...
      if (prevLocation) {
        dispatch(setPreviousUrlAction(prevLocation));
      }
      prevLocation = nextLocation;
    });
  }, [history]);

  useEffect(() => {
    // console.log('mounted')
    // console.log('Location Action 2:',history.action);
    // ...
    if (history.action === 'POP') {
      // console.log('POP so we went back');

      if (history.action === 'PUSH') {
        // console.log('PUSH so we went started the page for first time');
      }

      return () => {
        // console.log('unmounted' , currentScroll);
        dispatch(updateLastScrollPositionAction(currentScroll));
        // dispatch(updateScrollPositionAction(lastPosition));
        // ...
      };
    }
  }, []);

  const loadMoreData = () => {
    if (
      (selectedFIlters && !_.isEmpty(selectedFIlters)) ||
      (selectedFIlters && location.pathname === '/products')
    ) {
      dispatch(
        loadMoreProductsAction({
          ...selectedFIlters,
          sortBy: sortBy,
          page: savedPage + 1,
        }),
      );
    }
    // else if (selectedFIlters && location.pathname === '/products') {
    //   dispatch(
    //     loadMoreProductsAction({
    //       ...selectedFIlters,
    //       sortBy: selectedSort,
    //       page: page + 1,
    //     }),
    //   );
    // } else {
    // }
  };
  // };

  const loadMoreDataWithSavedPage = () => {
    if (
      (selectedFIlters && !_.isEmpty(selectedFIlters)) ||
      (selectedFIlters && location.pathname === '/products')
    ) {
      dispatch(
        loadMoreProductsAction({
          ...selectedFIlters,
          sortBy: sortBy,
          page: savedPage + 1,
        }),
      );
    }
    // else if (selectedFIlters && location.pathname === '/products') {
    //   dispatch(
    //     loadMoreProductsAction({
    //       ...selectedFIlters,
    //       sortBy: selectedSort,
    //       page: page + 1,
    //     }),
    //   );
    // } else {
    // }
  };
  // dispatch(scrollToLastPositionAction(false));

  const fetchMoreData = () => {
    // load more data when we reach the end of the page and we are using saved page
    // if (useSavedPage) {
    //   setTimeout(() => {
    //     loadMoreDataWithSavedPage();
    //     // console.log('Loading more with Saved Page');
    //     // dispatch(productsDoneLoading());
    //   }, 1500);
    // }
    // load more data when we reach the end of the page and we are NOT using saved page
    // if (!useSavedPage) {
    setTimeout(() => {
      loadMoreData();
      // console.log('Loading more with Page');
      // dispatch(productsDoneLoading());
    }, 1500);
    // }
  };

  // const fetchMoreData = () => {
  //   setTimeout(() => {
  //     loadMoreData();
  //     // dispatch(productsDoneLoading());
  //   }, 1500);
  // };

  return (
    <>
      <div className="mt-2">
        <div className="row m-0 position-relative">
          <div className="filterForm_wrapper col-lg-3 col-md-4 pl-0">
            <FilterForm />
          </div>
          <div className="col-lg-9 col-md-8 p-0">
            {/* <ProductSlider title="Recently Added" products={products} />
      <hr className="mt-5 mb-3" /> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="pull-right sortBy">
                <select
                  name="sortBy_select"
                  value={sortBy}
                  onChange={handleSelectChange}
                >
                  <option value="featured">Featured</option>
                  <option value="price:1">Price: Low to high</option>
                  <option value="price:-1">Price: High to low</option>
                  <option value="dateCreated:-1">New arrivals</option>
                  <option value="outOfStock:1">Stock Level: In stock</option>
                  {/* <option value="outOfStock:-1">
      Stock Level: Out of stock
    </option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex d-sm-none ml-auto">
                <FilterButton />
                <SortButton
                  value={sortBy}
                  onChange={(value) => setSelectedSort(value)}
                />
              </div>
            </div>
            <ProductListWrapper>
              <ScrollButton />
              {isLoading && (
                <div className="row">
                  {Array(8)
                    .fill()
                    .map((item, index) => (
                      <div className="column col-md-3 mt-2" key={index}>
                        <ProductTileSkeleton key={index} />
                      </div>
                    ))}
                </div>
              )}
              {!isLoading && products && products.length === 0 && (
                <p className="alert alert-info mt-4">{t('no_product')}</p>
              )}
              <InfiniteScroll
                dataLength={products.length}
                next={fetchMoreData}
                hasMore={hasMore && products.length > 0}
                loader={
                  <h4 className="loading_heading_indicator">
                    <CircularProgress />
                    Loading...
                  </h4>
                }
                scrollThreshold="0.7"
                endMessage={
                  (products && products.length !== 0 && products.length > 12) ||
                  (!isLoading && (
                    <h4 className="loading_heading_indicator">
                      Yay! You have seen it all
                    </h4>
                  ))
                }
                // below props only if you need pull down functionality
                refreshFunction={fetchMoreData}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={''}
                releaseToRefreshContent={''}
              >
                <div className="row">
                  {products &&
                    products.length > 0 &&
                    products.map((product, index) => (
                      <div className="column col-md-3 mt-2" key={product._id}>
                        <ProductTile index={index} productListItem={product} />
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </ProductListWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
