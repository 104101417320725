import React, { useState } from 'react';
import {
  ProductReviewHeaderWrapper,
  ProgressBarsWrapper,
} from '../styles/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ProgressBarLine from './ProgressBarLine';
import { Link } from 'react-router-dom';
import RatingStars from '../ui/Reviews/RatingStars';
import ReviewComment from '../../pages/Reviews/ReviewComment';
import AppModal from '../modals/PostReviewModal';
import WriteReview from '../modals/WriteReview';
import { getRatingAverage } from '../../utils/rating';
import { numberWithCommas, roundOffNumber } from '../../utils/format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadMoreReviewsAction } from '../../store/actions/reviewAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollButton from './scrollButton';

const Reviews = ({ postReview = true, reviews, product, reviewsCount }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('product');
  const page = useSelector((state) => state.reviewManager.page);
  const hasMore = useSelector((state) => state.reviewManager.hasMore);

  const loadMoreReviews = () => {
    setTimeout(() => {
      if (product) {
        dispatch(
          loadMoreReviewsAction({
            product: product,
            page: page + 1,
          }),
        );
      } else {
        dispatch(
          loadMoreReviewsAction({
            page: page + 1,
          }),
        );
      }
    }, 1000);
  };
  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  };

  const reviewsTotalCount =
    reviewsCount && reviewsCount.total ? reviewsCount.total : 0;

  const average =
    reviews && reviews.length > 0 ? roundOffNumber(reviewsCount.average, 1) : 0;

  return (
    <>
      <ScrollButton />
      {postReview ? <hr className="mt-3 mb-3" /> : <hr className="mt-0 mb-3" />}
      <div className="row justify-content-center pl-2 pr-2">
        <div className="col-md-8 mb-5 pr-0 pl-0">
          <ProductReviewHeaderWrapper>
            <div className="row">
              <div className="customer-reviews">
                <h5 className="text-left">{t('customers_reviews')}</h5>
              </div>
              <div className="write-a-review">
                {postReview ? (
                  <Link to="#" onClick={openModal}>
                    <h6 className="text-right">{t('write_review')}</h6>
                  </Link>
                ) : null}
              </div>
            </div>
          </ProductReviewHeaderWrapper>
          <div className="row">
            <RatingStars average={average} />
            &nbsp; {average} out of 5
          </div>
          <div className="row mt-2">
            {reviews && reviews.length > 0
              ? numberWithCommas(reviewsTotalCount)
              : 0}{' '}
            reviews
          </div>
          <div className="container">
            <div className="row mt-2">
              <div className="col-md-12 pr-0 pl-0">
                <ProgressBarsWrapper>
                  {reviewsCount &&
                    reviewsCount.count &&
                    reviewsCount.count.length > 0 && (
                      <ul>
                        {reviewsCount.count.map((reviewCount, index) => (
                          <li key={index} className="row">
                            <div className="star">
                              {reviewCount._id + ' '}
                              <FontAwesomeIcon
                                icon={faStar}
                                color="#ffb400"
                                className="ml-2"
                              />
                            </div>
                            <div className="bar mr-2">
                              <ProgressBarLine
                                variant="determinate"
                                value={
                                  reviewsTotalCount > 0
                                    ? getRatingAverage(
                                        reviewCount.count,
                                        reviewsTotalCount,
                                      )
                                    : 0
                                }
                              />
                            </div>
                            {numberWithCommas(reviewCount.count)}
                          </li>
                        ))}
                      </ul>
                    )}
                  {reviewsCount &&
                    reviewsCount.count &&
                    reviewsCount.count.length === 0 && (
                      <ul>
                        <li className="row">
                          <div className="star">
                            5{' '}
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#ffb400"
                              className="ml-2"
                            />
                          </div>
                          <div className="bar mr-2">
                            <ProgressBarLine variant="determinate" value={0} />
                          </div>
                          0
                        </li>
                        <li className="row">
                          <div className="star">
                            4{' '}
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#ffb400"
                              className="ml-2"
                            />
                          </div>
                          <div className="bar mr-2">
                            <ProgressBarLine variant="determinate" value={0} />
                          </div>
                          0
                        </li>
                        <li className="row">
                          <div className="star">
                            3{' '}
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#ffb400"
                              className="ml-2"
                            />
                          </div>
                          <div className="bar mr-2">
                            <ProgressBarLine variant="determinate" value={0} />
                          </div>
                          0
                        </li>
                        <li className="row">
                          <div className="star">
                            2{' '}
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#ffb400"
                              className="ml-2"
                            />
                          </div>
                          <div className="bar mr-2">
                            <ProgressBarLine variant="determinate" value={0} />
                          </div>
                          0
                        </li>
                        <li className="row">
                          <div className="star">
                            1{' '}
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#ffb400"
                              className="ml-2"
                            />
                          </div>
                          <div className="bar mr-2">
                            <ProgressBarLine variant="determinate" value={0} />
                          </div>
                          0
                        </li>
                      </ul>
                    )}
                </ProgressBarsWrapper>
              </div>
            </div>
          </div>
          {reviews && reviews.length > 0 && (
            <>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-12 pl-0 pr-0">
                  <h5>Reviews</h5>
                </div>
                <InfiniteScroll
                  dataLength={reviews.length}
                  next={loadMoreReviews}
                  hasMore={hasMore}
                  loader={
                    <h4 className="loading_heading_indicator">
                      <CircularProgress />
                      Loading...
                    </h4>
                  }
                >
                  {reviews &&
                    reviews.length > 0 &&
                    reviews.map((review, index) => (
                      <ReviewComment key={index} review={review} />
                    ))}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </div>
      {product && (
        <AppModal
          isOpen={modalIsOpen}
          component={<WriteReview product={product} closeModal={closeModal} />}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default Reviews;
