import _ from 'lodash';
import {
  GET_DAILY_DEALS_SUCCESS,
  SEARCH_SUCCESS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_BRANDS_SUCCESS,
  GET_BRAND_PRODUCTS_SUCCESS,
  GET_BRAND_SUCCESS,
  GET_BRAND_FEATURED_PRODUCTS_SUCCESS,
  GET_RELATED_PRODUCTS_SUCCESS,
  GET_CATEGORY_PRODUCTS_SUCCESS,
  GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS,
  UPDATE_SELECTED_FILTERS,
  SET_SELECTED_FILTERS,
  RESET_FILTERS,
  SCROLL_DATA_CAT,
  CLEAR_ALL_FILTERS,
  LOAD_FILTER,
  GET_FILTERS_OPTIONS_SUCCESS,
  RESET_PRODUCTS,
  REMOVE_FILTER_TERM,
  LOADING_PRODUCT,
  PRODUCT_WEB_SHARE_SUCCESS,
  RESET_PRODUCT_SHARING,
  LOAD_MORE_SEARCH_RESULTS,
  RESET_RELATED_PRODUCTS,
  LOAD_MORE_PRODUCTS,
  STOP_LOADING_MORE_PRODUCTS,
  RESET_SELECTED_FILTERS,
  SET_SELECTED_FILTER,
  RESET_SELECTED_PAGE,
  GET_BRAND_FILTERS_OPTIONS_SUCCESS,
  CHANGE_FILTER_STATE,
  RESET_PAGINATION_ACTION,
  SET_SORT_BY_OPTION,
  RESET_SHOP_NOT_FOUND,
  RESET_PRODUCT,
  LOADING_PRODUCTS_WITH_SAVED_PAGE,
  LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE,
  RELATED_PRODUCTS_SECTION_LOADING,
  GET_CURATED_SECTION_SUCCESS,
  REMOVE_CURATED_SECTION,
  RESET_FILTERS_OPTIONS,
} from '../constants/ActionTypes';
import {
  GET_VERTICAL_FEATURED_PRODUCTS_SUCCESS,
  GET_MEGASTORE_VERTICAL_PRODUCTS_SUCCESS,
  GET_VERTICAL_SUCCESS,
} from '../constants/CatalogActionTypes';

const initialState = {
  dailyDeals: [],
  curatedSection: {},
  searchResults: [],
  searchSuggestion: [],
  products: [],
  product: {},
  relatedProducts: [],
  relatedProductsSectionLoading: true,
  brand: {},
  brands: [],
  selectedFIlters: null,
  page: 0,
  canRefetchProducts: false,
  isLoading: false,
  usePreviousPage: false,
  filterOptions: [],
  isProductShared: false,
  message: '',
  hasMore: false,
  vertical: {},
  isFiltersUpdated: false,
  selectedPage: null,
  clearAllFilters: false,
  sortBy: 'featured',
  loadProductsFromLastPage: false,
  savedPage: 0,
  isSavedPageUpdated: false,
  isLoadingProductsWithSavedPage: false,
  useSavedPage: false,
  isLoadingFilters: false,
};

const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DAILY_DEALS_SUCCESS:
      return {
        ...state,
        dailyDeals: payload,
        hasMore: true,
        isLoading: false,
      };
    case GET_CURATED_SECTION_SUCCESS:
      return {
        ...state,
        curatedSection: payload,
        hasMore: true,
        isLoading: false,
      };
    case REMOVE_CURATED_SECTION:
      return {
        ...state,
        curatedSection: {},
      };

    case LOADING_PRODUCTS_WITH_SAVED_PAGE:
      return {
        ...state,
        isLoadingProductsWithSavedPage: true,
      };
    case LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE:
      return {
        ...state,
        useSavedPage: payload,
      };

    case SCROLL_DATA_CAT:
      if (payload === 'reset') {
        // console.log('reset scroll Car')
        return {
          ...state,
          savedPage: 0,
          products: [],
          page: 0,
          products: [],
          dailyDeals: [],
          sortBy: 'featured',
          selectedFilters: [],
          vertical: [],
        };
      }
      if (payload === 'save') {
        // console.log('save scroll Car')
        return {
          ...state,
        };
      }
      if (payload === 'use') {
        // console.log('use scroll Car')
        return {
          ...state,
        };
      }

    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: payload.products,
        searchSuggestion:
          payload.suggestion &&
          payload.suggestion.results &&
          payload.suggestion.results.documents.length > 0
            ? payload.suggestion.results.documents
            : [],
        isLoading: false,
      };
    case LOAD_MORE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [...state.searchResults, ...payload],
        isLoading: false,
        hasMore: true,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload,
        isLoading: false,
        canRefetchProducts: false,
        hasMore: true,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload,
        isLoading: false,
        canRefetchProducts: false,
      };
    case GET_BRAND_SUCCESS:
      return { ...state, brand: payload };

    case GET_BRANDS_SUCCESS:
      return { ...state, brands: payload };
    case GET_BRAND_PRODUCTS_SUCCESS:
      return { ...state, brandProducts: payload };
    case GET_BRAND_FEATURED_PRODUCTS_SUCCESS:
      return { ...state, brandFeaturedProducts: payload };
    case GET_CATEGORY_PRODUCTS_SUCCESS:
      return { ...state, categoryProducts: payload };
    case GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS:
      return { ...state, categoryFeaturedProducts: payload };
    case GET_VERTICAL_SUCCESS:
      return { ...state, vertical: payload };
    case GET_MEGASTORE_VERTICAL_PRODUCTS_SUCCESS:
      return { ...state, products: payload, isLoading: false };
    case GET_VERTICAL_FEATURED_PRODUCTS_SUCCESS:
      return { ...state, verticalFeaturedProducts: payload };
    case GET_RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        relatedProducts: payload,
        relatedProductsSectionLoading: false,
      };
    case RELATED_PRODUCTS_SECTION_LOADING:
      return { ...state, relatedProductsSectionLoading: payload };
    case GET_FILTERS_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptions: payload,
        isFiltersUpdated: false,
        isLoadingFilters: false,
      };
    case GET_BRAND_FILTERS_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptions: payload,
        isFiltersUpdated: false,
        isLoadingFilters: false,
      };
    case LOAD_FILTER:
      return { ...state, isLoading: true };
    case RESET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFIlters: {},
        isFiltersUpdated: true,
        isLoadingFilters: true,
      };
    case SET_SELECTED_FILTERS:
      let newFilters = {
        ...state.selectedFIlters,
        [payload.key]: [
          {
            label: payload.item.label,
            value: payload.item.value,
            id: payload.item.id,
          },
        ],
      };

      return {
        ...state,
        selectedFIlters: newFilters,
        isFiltersUpdated: true,
        isLoadingFilters: false,
      };
    case SET_SELECTED_FILTER:
      let newSelectedFilters = {
        ...state.selectedFIlters,
        [payload.key]: [
          {
            label: payload.item.label,
            value: payload.item.value,
            id: payload.item.id,
          },
        ],
      };

      return {
        ...state,
        selectedFIlters: newSelectedFilters,
      };

    case UPDATE_SELECTED_FILTERS:
      let updatedKeyArr = state.selectedFIlters[payload.key]
        ? state.selectedFIlters[payload.key]
        : [];

      let updatedFilters = {
        ...(payload.key === 'Vertical' || payload.key === 'Category'
          ? _.omit(state.selectedFIlters, [payload.key])
          : state.selectedFIlters),
        [payload.key]:
          payload.key === 'Vertical' || payload.key === 'Category'
            ? [
                {
                  label: payload.item.label,
                  value: payload.item.value,
                  id: payload.item.id,
                },
              ]
            : [
                ...updatedKeyArr,
                {
                  label: payload.item.label,
                  value: payload.item.value,
                  id: payload.item.id,
                },
              ],
      };

      //console.log('updatedFilters', updatedFilters);
      return {
        ...state,
        selectedFIlters: updatedFilters,
        isLoading: true,
        canRefetchProducts: true,
        isFiltersUpdated: true,
        isLoadingFilters: false,
        page: 0,
      };

    case RESET_FILTERS:
      if (payload === 'on reset button') {
        // console.log('reset filters');
        return {
          ...state,
          selectedFIlters: {},
          isLoadingFilters: true,

          isFiltersUpdated: false,
          page: 0,
          savedPage: 0,
        };
      } else {
        return {
          ...state,
          selectedFIlters: {},
          isLoadingFilters: false,

          isFiltersUpdated: false,
          page: 0,
          savedPage: 0,
        };
      }

    case RESET_PAGINATION_ACTION:
    case CHANGE_FILTER_STATE:
      return {
        ...state,
        page: 0,
        savedPage: 0,
      };
    case SET_SORT_BY_OPTION:
      return {
        ...state,
        sortBy: payload,
      };

    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        clearAllFilters: payload,
      };

    case RESET_PRODUCTS:
      return { ...state, products: [] };

    case RESET_FILTERS_OPTIONS:
      return { ...state, filterOptions: [] };

    case RESET_PRODUCT:
      return { ...state, product: {} };

    case REMOVE_FILTER_TERM:
      let NewFilters = state.selectedFIlters[payload.filter].filter(
        (filter) => filter.value !== payload.item.value,
      );

      state.selectedFIlters[payload.filter] = NewFilters;
      let newUpdatedFilters = state.selectedFIlters;

      return {
        ...state,
        selectedFIlters: newUpdatedFilters,
        canRefetchProducts: true,
        page: 0,
        savedPage: 0,
      };
    case LOADING_PRODUCT:
      return {
        ...state,
        isLoading: true,
        // products: [],
        product: {},
        searchResults: [],
        relatedProducts: [],
        // dailyDeals: [],
      };
    case RESET_RELATED_PRODUCTS:
      return { ...state, product: {}, relatedProducts: [] };
    case PRODUCT_WEB_SHARE_SUCCESS:
      return {
        ...state,
        isProductShared: true,
        message: payload,
      };
    case RESET_PRODUCT_SHARING:
      return { ...state, isProductShared: false };
    case LOAD_MORE_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...payload],
        isLoading: false,
        isLoadingProductsWithSavedPage: false,
        hasMore: true,
        page: state.page + 1,
        savedPage: state.page + 1,
      };
    case STOP_LOADING_MORE_PRODUCTS: {
      return {
        ...state,
        hasMore: false,
      };
    }
    default:
      return state;
  }
};

export default stateReducer;
