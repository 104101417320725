import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import ListItem from "@material-ui/core/ListItem";
import {changeLanguageAction} from "../../store/actions/uiAction";
import {useDispatch, useSelector} from "react-redux";

const LanguageSelector= ({type}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('language');
    const language = useSelector((state) => state.uiManager.language);

    const handleLanguageChange = (evt) => {
        let language = evt.target.value
        i18n.changeLanguage(language);
        dispatch(changeLanguageAction(language));
        localStorage.setItem("language", language)
    };

    switch (type) {
        case "radio":
            return(
                <ListItem>
                    <FormControl component="fieldset" className="pl-16">
                        <RadioGroup
                            aria-label="language"
                            name="language"
                            value={language}
                            onChange={handleLanguageChange}
                            row
                        >
                            <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label={t("en")}
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                value="sw"
                                control={<Radio />}
                                label={t("sw")}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </ListItem>
            );
        case "dropdown":
            return(
                <select onChange={handleLanguageChange} value={language} >
                    <option value='en'>{t('en')}</option>
                    <option value='sw'>{t('sw')}</option>
                </select>
            );
        default:
            return true;
    }
};

export default LanguageSelector;