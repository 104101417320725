import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import {
  changeFilterMenuState,
  changeSortMenuState,
} from '../../store/actions/uiAction';
import { FilterButtonWrapper } from '../../components/styles/Filter';
import FilterModal from '../modals/FilterModal';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';

import { scrollToTop } from '../../utils/window';
import FlyoutFilterMenu from '../../components/ui/FlyoutFilterMenu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Capacitor } from '@capacitor/core';

const FilterButton = () => {
  const menuIsOpen = useSelector((state) => state.uiManager.filterState);
  const [hideFloatingFilterButton, setHideFloatingFilterButton] = useState(
    true,
  );
  const [canOpen, setCanOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const toggleFilterMenu = () => {
    dispatch(changeFilterMenuState(!menuIsOpen));
    dispatch(changeSortMenuState(true));
    document.body.style.overflow = 'hidden';
    setCanOpen(true);
  };

  const getPathParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/:pathname`,
    });
    return (matchProfile && matchProfile.params) || {};
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const pathname = location.pathname;
      let showFloatingValue = 0;
      let iosGap = Capacitor.isNative && Capacitor.platform === 'ios' ? 125 : 0;
      const pathParams = getPathParams(pathname);
      let isShow = null;

      switch (pathParams.pathname) {
        case 'products':
          showFloatingValue = 85 - iosGap;
          isShow = currPos.y > showFloatingValue;
          break;
        case 'category':
        case 'brand':
        case 'megastore':
          showFloatingValue = 105;
          isShow = currPos.y > showFloatingValue;
          break;
        default:
          showFloatingValue = -43;
          isShow = currPos.y > showFloatingValue;
          break;
      }

      if (isShow !== hideFloatingFilterButton) {
        setHideFloatingFilterButton(isShow);
      }
    },
    [hideFloatingFilterButton],
    false,
    false,
    300,
  );

  return (
    <>
      <FilterButtonWrapper
        onClick={() => toggleFilterMenu()}
        className={hideFloatingFilterButton || canOpen ? '' : `floating-filter`}
      >
        <div>
          {hideFloatingFilterButton || canOpen ? (
            <>
              <FilterIcon />
              <h3>Filter</h3>
            </>
          ) : (
            <>
              <FilterListIcon style={{ color: 'white' }} />
              <h3 style={{ color: 'white' }}>Filter</h3>
            </>
          )}
        </div>
      </FilterButtonWrapper>

      <FlyoutFilterMenu
        canOpen={canOpen}
        onCloseMenu={(value) => setCanOpen(value)}
      />
    </>
  );
};

export default FilterButton;
