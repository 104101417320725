import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  selectOptionAction,
  selectVariantAction,
} from '../../store/actions/cartAction';
import { ButtonInline } from '../styles/Form';
import { VariantsWrapper } from '../styles/variants';
// import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

const VariantSelector = ({ options, variants, product, index }) => {
  const dispatch = useDispatch();
  // const { t } = useTranslation('product');
  // const [value, setValue] = React.useState(0);
  const [cartItem, setCartItem] = React.useState(null);
  const selectedVariant = useSelector(
    (state) => state.cartManager.selectedVariant,
  );
  const selectedOptions = useSelector(
    (state) => state.cartManager.selectedOptions,
  );

  useEffect(() => {
    if (Array.isArray(options.values)) {
      dispatch(
        selectOptionAction({ key: options.key, value: options.values[0] }),
      );
    }

    setCartItem(product);
  }, []);

  // useEffect(() => {
  //   if (selectedOptions) {
  //     let option = '';
  //     var result = Object.values(selectedOptions).join('.');
  //     let selectedVariant = _.find(variants, ['name', result]);
  //     if (selectedVariant) {
  //       selectedVariant['productId'] = product._id;
  //       dispatch(selectVariantAction(selectedVariant));
  //       console.log('Selected Variant = ', selectedVariant);
  //     }
  //   }
  // }, [selectedOptions]);

  const handleChange = (key, option) => {
    let updatedVariant = { ...selectedOptions, [key]: option };
    var result = Object.values(updatedVariant).join('.');
    // console.log('updated result + variants = ', result, variants);
    let selectedVariant = _.find(variants, ['name', result]);

    if (selectedVariant) {
      selectedVariant['productId'] = product._id;
      dispatch(selectVariantAction(selectedVariant));
      // console.log('Selected Variant = ', selectedVariant);
    }
    // console.log('updatedVariant', updatedVariant);
    dispatch(selectOptionAction({ key, value: option }));
  };
  return (
    <VariantsWrapper>
      {selectedOptions && selectedVariant && !selectedVariant.outOfStock && (
        <>
          <h5>{options.key}</h5>
          <div className="d-flex variant-tile">
            {options.values.map((option, i) => (
              <ButtonInline
                key={i}
                onClick={() => handleChange(options.key, option)}
                className={`p-2 ${
                  selectedOptions[options.key] === option
                    ? 'active position-relative'
                    : ''
                }`}
              >
                <button
                  className={`check-icon position-absolute ${
                    selectedOptions[options.key] === option
                      ? 'd-block'
                      : 'd-none'
                  }`}
                  style={{ top: '-4px', right: 0 }}
                >
                  <FontAwesomeIcon icon={faCheckSquare} />
                </button>
                {option}
              </ButtonInline>
            ))}
          </div>
        </>
      )}
    </VariantsWrapper>
  );
};
export default VariantSelector;
