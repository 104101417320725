import styled from 'styled-components';

export const FilterButtonWrapper = styled.div`
  color: #00c400;
  padding-top: 0;
  float: right;
  cursor: pointer;
  margin: 0 3px;

  h3 {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
    display: inline-block;
  }
  svg {
    display: inline-block;
  }
  .floating-filter svg {
    fill: white;
  }
`;

export const ProductsFilterWrapper = styled.div`
  padding: 20px;
  h3 {
    color: #3c3f42;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
  }
`;

export const FilterWrapperSkeletonWrapper = styled.div`
// box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
filter-form-skeleton-container-inner{
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
  filter-form-skeleton-container {
    padding-top: 10px;
    height: 100px
    border-radius: 4px;
  }
`;

export const FlyoutMenuWrapper = styled.div`
  /* Hide scrollbar for Chrome, Safari and Opera */
  .flyoutMenu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .flyoutMenu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .flyoutMenu {
    width: 86%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    will-change: translateX;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    overflow: auto;
    z-index: 10000;
    max-height: 100%;
  }

  .flyoutFilterMenu {
    width: 82%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    will-change: translateX;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    overflow: auto;
    z-index: 1000000000000000;
    max-height: 100%;
  }

  .flyoutMenu.hide {
    transform: translate3d(100vw, 0, 0);
  }

  .flyoutMenu.show {
    transform: translate3d(0vw, 0, 0);
    overflow: scroll;
    margin-top: 0;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .flyoutFilterMenu.hide {
    transform: translate3d(100vw, 0, 0);
  }

  .flyoutFilterMenu.show {
    transform: translate3d(0vw, 0, 0);
    overflow: scroll;
    margin-top: 0;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .flyoutMenu_desktop {
    width: 100vw;
    will-change: translateX;
    transition: 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flyoutMenu_desktop.show {
    transform: translateX(0px);
    background-color: rgba(0, 0, 0, 1);
  }
  .menu-row {
    display: flex;

    color: rgba(0, 0, 0, 0.5);
  }
  .menuRow_close {
    max-width: 180px;
  }
  .menuRow_reset {
    margin-left: auto;
  }
  .menuRow_reset button {
    cursor: pointer;
  }

  /* Clear floats after the columns */
  .menu-row:after {
    content: '';
    display: table;
    clear: both;
  }
  .column {
    float: left;
    width: 50%;
  }
  button {
    background: none;
    border: none;
    padding-right: 4px;
    color: rgba(0, 0, 0, 0.5);
  }
  h4 {
    display: block;
    font-size: 18px;
    float: right;
    padding-top: 10px;
  }

  @media (min-width: 768px) {
    .flyoutMenu.show {
      margin-top: 0px;
    }
  }

  @media (min-width: 768px) {
    .flyoutMenu_desktop {
      width: 520px;
      left: auto;
    }
    .flyoutMenu_desktop.show {
      transform: translateX(40px);
    }
  }
`;

export const FilterGroupWrapper = styled.div`
  h2 {
    color: #3c3f42;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
  }
  p {
    cursor: pointer;
    padding-left: 15px;
    color: #00c400;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
  }
  .MuiTypography-body2 {
    font-weight: 300;
    font-family: 'DM Sans';
  }
`;
