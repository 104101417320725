import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { HomePageWrapper } from '../../components/ui/HomePage';
import BannerSkeleton from './elements/image/BannerSkeleton';
import SecondaryNavSkeleton from './elements/navs/SecondaryNavSkeleton';
import CategoriesVerticalSliderSkeleton from './elements/products/CategoriesVerticalSliderSkeleton';
import ProductsSliderSkeleton from './elements/products/ProductsSliderSkeleton';
import AppLayout from './layouts/AppLayout';

function HomeSkeleton() {
  return (
    <AppLayout
      children={
        <>
          <SecondaryNavSkeleton />
          <HomePageWrapper>
            <BannerSkeleton />
            <div className="container-full pt-3">
              <CategoriesVerticalSliderSkeleton
                link={true}
                recommended={false}
              />
            </div>
            <div className="container-full">
              <ProductsSliderSkeleton link={true} header={true} />
            </div>
            <div className="container-full mt-4">
              <h2 className="pl-0">
                <Skeleton width={220} height={30} />
              </h2>
              <div className="row">
                <div className="col-md-12 pl-0 pr-0">
                  <BannerSkeleton />
                </div>
              </div>
            </div>
          </HomePageWrapper>
        </>
      }
    />
  );
}

export default HomeSkeleton;
