import {
  GET_NEW_ARRIVALS,
  GET_RECOMMENDED_DEALS_FOR_YOU,
  GET_YOUR_FAVORITE_CATEGORIES,
  GET_RELATED_TO_YOUR_VIEWS,
  GET_RELATED_TO_ITEMS_IN_YOUR_CART,
  SEND_USER_EVENT,
  SEND_PRODUCT_VIEW_EVENT,
  SEND_ADD_TO_CART_EVENT,
  SEND_PURCHASE_COMPLETE_EVENT,
  GET_FREQUENTLY_BOUGHT_TOGETHER,
  GET_CURETTED_SECTIONS,
  GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING,
  CLEAR_CURATED_SECTIONS,
} from '../constants/RecommendationActionTypes.js';
import {
  getNewArrivals,
  getRecommendedDealsForYou,
  getYourFavoriteCategories,
  getRelatedToYourViews,
  getRelatedToItemsInYourCart,
  sendUserEvent,
  sendProductViewEvent,
  sendAddToCartEvent,
  sendPurchaseCompleteEvent,
  getFrequentlyBoughtTogether,
  getCurettedSections,
} from '../../services/RecommendationService.js';
import { handleErrorAction } from './utilsAction';

export const getNewArrivalsAction = (data) => {
  return function (dispatch) {
    return getNewArrivals().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_NEW_ARRIVALS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const setRecommendedSectionsLoadingAction = (data) => {
  let payload = true;
  let type =
    data === 'cart'
      ? 'SET_CART_RECOMMENDED_SECTIONS_LOADING'
      : 'SET_RECOMMENDED_SECTIONS_LOADING';
  if (data === 'cart-empty') {
    type = 'SET_CART_RECOMMENDED_SECTIONS_LOADING';
    payload = false;
  }

  return function (dispatch) {
    return dispatch({
      type: type,
      payload: payload,
    });
  };
};

export const setFrequentlyBoughtTogetherSectionLoadingAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING,
      payload: data,
    });
  };
};

export const clearCuratedSectionsAction = () => {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_CURATED_SECTIONS,
    });
  };
};

export const getCurettedSectionsAction = (data) => {
  return function (dispatch) {
    return getCurettedSections().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_CURETTED_SECTIONS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) =>
        dispatch({
          type: GET_CURETTED_SECTIONS,
          payload: [],
        }),
    );
  };
};

export const getRecommendedDealsForYouAction = (data) => {
  return function (dispatch) {
    return getRecommendedDealsForYou().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_RECOMMENDED_DEALS_FOR_YOU,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) =>
        dispatch({
          type: GET_RECOMMENDED_DEALS_FOR_YOU,
          payload: [],
        }),
    );
  };
};

export const sendUserEventAction = (data) => {
  return function (dispatch) {
    return sendUserEvent(data).then(
      (response) => {
        if (response.status === 1) {
          // console.log('sendUserEventAction', response);
          return dispatch({
            type: SEND_USER_EVENT,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const sendProductViewEventAction = (data) => {
  return function (dispatch) {
    return sendProductViewEvent(data).then(
      (response) => {
        if (response.status === 1) {
          // console.log('sendUserEventAction', response);
          return dispatch({
            type: SEND_PRODUCT_VIEW_EVENT,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const sendAddToCartEventAction = (data) => {
  return function (dispatch) {
    return sendAddToCartEvent(data).then(
      (response) => {
        if (response.status === 1) {
          // console.log('sendUserEventAction', response);
          return dispatch({
            type: SEND_ADD_TO_CART_EVENT,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const sendPurchaseCompleteEventAction = (data) => {
  return function (dispatch) {
    return sendPurchaseCompleteEvent(data).then(
      (response) => {
        if (response.status === 1) {
          // console.log('sendUserEventAction', response);
          return dispatch({
            type: SEND_PURCHASE_COMPLETE_EVENT,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getYourFavoriteCategoriesAction = (data) => {
  return function (dispatch) {
    return getYourFavoriteCategories().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_YOUR_FAVORITE_CATEGORIES,
            payload: response.data.data
              ? response.data.data
              : { products: [], categories: [] },
          });
        }
      },
      (error) => {
        dispatch(handleErrorAction(error));
        dispatch({
          type: GET_YOUR_FAVORITE_CATEGORIES,
          payload: { products: [], categories: [] },
        });
      },
    );
  };
};

export const getRelatedToYourViewsAction = (data) => {
  return function (dispatch) {
    return getRelatedToYourViews(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_RELATED_TO_YOUR_VIEWS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => {
        dispatch(handleErrorAction(error));
        dispatch({
          type: GET_RELATED_TO_YOUR_VIEWS,
          payload: [],
        });
      },
    );
  };
};

export const getRelatedToItemsInYourCartAction = (data) => {
  return function (dispatch) {
    return getRelatedToItemsInYourCart(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_RELATED_TO_ITEMS_IN_YOUR_CART,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) =>
        dispatch({
          type: GET_RELATED_TO_ITEMS_IN_YOUR_CART,
          payload: [],
        }),
    );
  };
};

export const getFrequentlyBoughtTogetherAction = (data) => {
  return function (dispatch) {
    return getFrequentlyBoughtTogether(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_FREQUENTLY_BOUGHT_TOGETHER,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) =>
        dispatch({
          type: GET_FREQUENTLY_BOUGHT_TOGETHER,
          payload: [],
        }),
    );
  };
};
