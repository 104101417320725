import React from 'react';

const ArrowDownIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 40 40"
      width="40px"
      fill="#fff"
    >
      <path
        d="M11.2,15c0.7-0.7,1.7-0.7,2.3,0l6.5,6.5l6.5-6.5c0.7-0.7,1.7-0.7,2.3,0c0.7,0.7,0.7,1.7,0,2.3L21.2,25
	c-0.7,0.7-1.7,0.7-2.3,0l-7.7-7.7C10.5,16.7,10.5,15.7,11.2,15z"
      />
    </svg>
  );
};

export default ArrowDownIcon;
