import { matchPath, useParams } from 'react-router';

export default function useFixedHeaderRoute(routePathname) {
  const params = useParams();

  const getPathParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/:pathname`,
    });
    return (
      (params && params.subDomain && 'subDomain') ||
      (matchProfile && matchProfile.params && matchProfile.params.pathname) ||
      {}
    );
  };

  let isFixedHeaderRoute = null;
  const path = getPathParams(routePathname);

  switch (path) {
    case 'brand':
    case 'category':
    case 'daily-deals':
    case 'megastore':
    case 'products':
    case 'subDomain':
      isFixedHeaderRoute = true;
      break;
    default:
      isFixedHeaderRoute = false;
      break;
  }

  return { route: path, isFixedHeaderRoute: isFixedHeaderRoute };
}
