import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { verifyCardPaymentAction } from '../../store/actions/PaymentAction';
import { truncate } from 'lodash';

function CardPaymentRedirect() {
  const { transactionId } = useParams();
  const [canRedirect, setCanRedirect] = useState(false);
  const location = useLocation();
  const searchQueryObj = qs.parse(location.search);
  const isCardPaymentSuccess = useSelector(
    (state) => state.paymentManager.isCardPaymentSuccess,
  );
  const isCardPaymentFail = useSelector(
    (state) => state.paymentManager.isCardPaymentFail,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const searchQueryObj = qs.parse(location.search);
    dispatch(verifyCardPaymentAction(searchQueryObj, transactionId));
    // console.log(searchQueryObj, transactionId);
  }, []);

  useEffect(() => {
    if (isCardPaymentSuccess) {
      setCanRedirect(true);
    }
    if (isCardPaymentFail) {
      setCanRedirect(true);
    }
  }, [isCardPaymentSuccess, isCardPaymentFail]);

  return (
    <div className="container pt-3">
      <p>processing....</p>
      {canRedirect && <Redirect to="/pay" />}
    </div>
  );
}

export default CardPaymentRedirect;
