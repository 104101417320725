import React from 'react';

const CategoryIcon = ({active}) => {
    return(	
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 24 24"  fill={active ? "#00c400" : "rgba(56,61,66,0.7)"}>
<style type="text/css">
	.st0{'fill:none;'}
	.st1{'fill:rgba(56,61,66,0.7);'}
</style>
<g class="st0">
	{/* <rect class="st1" width="24" height="24"/> */}
</g>
<g>
	<g>
		<g>
			<path class="st2" d="M2.2,6.5L2.2,6.5c0,2.4,2,4.4,4.4,4.4h0c2.4,0,4.4-2,4.4-4.4v0c0-2.4-2-4.4-4.4-4.4h0
				C4.1,2.2,2.2,4.1,2.2,6.5z M6.5,8.7L6.5,8.7c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v0
				C8.7,7.7,7.7,8.7,6.5,8.7z M2.2,17.5L2.2,17.5c0,2.4,2,4.4,4.4,4.4h0c2.4,0,4.4-2,4.4-4.4v0c0-2.4-2-4.4-4.4-4.4h0
				C4.1,13.1,2.2,15.1,2.2,17.5z M6.5,19.7L6.5,19.7c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v0
				C8.7,18.7,7.7,19.7,6.5,19.7z M13.1,6.5L13.1,6.5c0,2.4,2,4.4,4.4,4.4h0c2.4,0,4.4-2,4.4-4.4v0c0-2.4-2-4.4-4.4-4.4h0
				C15.1,2.2,13.1,4.1,13.1,6.5z M17.5,8.7L17.5,8.7c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v0
				C19.7,7.7,18.7,8.7,17.5,8.7z M13.1,17.5L13.1,17.5c0,2.4,2,4.4,4.4,4.4h0c2.4,0,4.4-2,4.4-4.4v0c0-2.4-2-4.4-4.4-4.4h0
				C15.1,13.1,13.1,15.1,13.1,17.5z M17.5,19.7L17.5,19.7c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v0
				C19.7,18.7,18.7,19.7,17.5,19.7z"/>
		</g>
	</g>
</g>
</svg>

// <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" fill={active ? "#00c400" : "#3c3f42"} >
// <style type="text/css" >
// 	.st0{'fill:none;'}
// </style>
// {/* <path class="st0" d="M0,0h24v24H0V0z"/> */}
// <path d="M12,2l-5.5,9h11L12,2z"/>
// <circle cx="17.5" cy="17.5" r="4.5"/>
// <path d="M3,13.5h8v8H3V13.5z"/>
// </svg>
    );
};

export default CategoryIcon;