import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { toggleCallmodalAction } from '../../../store/actions/uiAction';

const customStyles = {
  content: {
    top: '77%',
    left: '87%',
    right: '0',
    bottom: 'auto',
    transform: 'translate(-80%, -63%)',
    maxWidth: '400px',
    width: '100%',
    background: 'none',
    border: 'none',
  },
};

const ContactModal = ({ isOpen, setIsOpen, component }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={() => dispatch(toggleCallmodalAction(false))}
      overlayClassName="bgOverlay"
      closeTimeoutMS={2000}
    >
      {component}
    </Modal>
  );
};

export default ContactModal;
