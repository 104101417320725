import { combineReducers } from 'redux';
import CartReducer from './cartReducer';
import StateReducer from './stateReducer';
import uiReducer from './uiReducer';
import catalogReducer from './catalogReducer';
import shopReducer from './shopReducer';
import categoryReducer from './categoryReducer';
import locationReducer from './locationReducer';
import paymentReducer from './paymentReducer';
import reviewReducer from './reviewReducer';
import searchReducer from './searchReducer';
import recommendationReducer from './recommendationReducer';

const appReducer = combineReducers({
  cartManager: CartReducer,
  stateManager: StateReducer,
  uiManager: uiReducer,
  catalogManager: catalogReducer,
  shopManager: shopReducer,
  categoryManager: categoryReducer,
  locationManager: locationReducer,
  paymentManager: paymentReducer,
  reviewManager: reviewReducer,
  searchManager: searchReducer,
  recommendationManager: recommendationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
    localStorage.removeItem('persist:root');
  }
  return appReducer(state, action);
};

export default rootReducer;
