import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDasH0S_wptPvUlfLZh_13YBgKjwxs6Jxc',
  authDomain: 'inalipa-74b71.firebaseapp.com',
  databaseURL: 'https://inalipa-74b71.firebaseio.com',
  projectId: 'inalipa-74b71',
  storageBucket: 'inalipa-74b71.appspot.com',
  messagingSenderId: '247489376602',
  appId: '1:247489376602:web:525c6e4c59c38f829e4363',
  measurementId: 'G-WZ31117T44',
};

const app = firebase.initializeApp(firebaseConfig);
const remoteConfig = firebase.remoteConfig(app);

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};

export const fetchItemFromRemoteConfig = async (configName) => {
  let itemData = {};
  await remoteConfig
    .fetchAndActivate()
    .then((activated) => {
      console.log(remoteConfig.getValue(configName));
      // if (!activated) console.log('not activated', configName);
      // else {
      let obj = remoteConfig.getValue(configName);
      itemData = JSON.parse(obj._value);
      // }
    })
    .catch((err) => {
      console.log('err', err.message);
      return err.message;
    });
  return itemData;
};
