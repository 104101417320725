import Geocode from 'react-geocode';
import { useDispatch } from 'react-redux';
import {
  updateLocationAction,
  changeLocationPermissionStatusAction,
  setLocationOutOfBoundAction,
} from '../store/actions/locationAction';
import { Plugins, Capacitor } from '@capacitor/core';
import * as turf from '@turf/turf';

const { Geolocation } = Plugins;

Geocode.setApiKey('AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU');
Geocode.enableDebug();

const bounds = [
  [-6.93789, 39.2014],
  [-6.99948, 39.29336],
  [-6.92157, 39.30191],
  [-6.84841, 39.28369],
  [-6.8223, 39.28677],
  [-6.81858, 39.29102],
  [-6.81769, 39.29483],
  [-6.81872, 39.29724],
  [-6.81872, 39.29981],
  [-6.81657, 39.30131],
  [-6.81288, 39.30053],
  [-6.80968, 39.2981],
  [-6.79401, 39.28371],
  [-6.78226, 39.28734],
  [-6.76788, 39.28351],
  [-6.75591, 39.2881],
  [-6.74826, 39.28966],
  [-6.74025, 39.28613],
  [-6.73229, 39.27732],
  [-6.7461, 39.27103],
  [-6.75458, 39.26973],
  [-6.75931, 39.26242],
  [-6.74839, 39.24548],
  [-6.7304, 39.23858],
  [-6.70823, 39.23246],
  [-6.69509, 39.22665],
  [-6.66696, 39.22352],
  [-6.63797, 39.20134],
  [-6.62704, 39.18278],
  [-6.61172, 39.16955],
  [-6.59514, 39.16279],
  [-6.58251, 39.1545],
  [-6.57413, 39.14123],
  [-6.58468, 39.12147],
  [-6.6055, 39.12728],
  [-6.71788, 39.12052],
  [-6.76895, 39.11869],
  [-6.79308, 39.14707],
  [-6.88828, 39.14213],
  [-6.92698, 39.17668],
  [-6.93789, 39.2014],
];

const poly = turf.polygon([bounds]);

export const getArea = (addressArray) => {
  let area = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          'sublocality_level_1' === addressArray[i].types[j] ||
          'locality' === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  }
};

export const getState = (addressArray) => {
  let state = '';
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_1' === addressArray[i].types[0]
      ) {
        state = addressArray[i].long_name;
        return state;
      }
    }
  }
};

export const getCity = (addressArray) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      'administrative_area_level_2' === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const useGetCurrentLocation = async () => {
  const dispatch = useDispatch();
  // if (Capacitor.isNative && Capacitor.platform === 'ios') {
  if (Capacitor.isNative) {
    const coordinates = await Geolocation.getCurrentPosition();
    // console.log(
    //   ' Device is iOS useGetCurrentLocation coordinates = ',
    //   coordinates,
    // );
    if (coordinates && coordinates.coords) {
      let newLat = coordinates.coords.latitude;
      let newLng = coordinates.coords.longitude;
      Geocode.fromLatLng(newLat, newLng).then(
        (response) => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = getCity(addressArray),
            area = getArea(addressArray),
            state = getState(addressArray);

          let coordinates = {
            lat: newLat,
            lng: newLng,
          };

          let isPlaceInBound = turf.booleanPointInPolygon(
            turf.point([newLat, newLng]),
            poly,
          );
          if (isPlaceInBound) {
            let data = {
              address,
              city,
              area,
              state,
              mapPosition: coordinates,
              markerPosition: coordinates,
              zoom: 13,
            };
            document.getElementById('locationAutocomplete').value = address;
            dispatch(updateLocationAction(data));
            dispatch(setLocationOutOfBoundAction(false));
          } else {
            document.getElementById('locationAutocomplete').value = '';
            dispatch(setLocationOutOfBoundAction(true));
          }
        },
        (error) => {
          console.error(error);
        },
      );
    } else {
      dispatch(changeLocationPermissionStatusAction(false));
      dispatch(setLocationOutOfBoundAction(true));
    }
  } else {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(changeLocationPermissionStatusAction(true));
        let newLat = position.coords.latitude;
        let newLng = position.coords.longitude;

        //console.log("navigator.geolocation.getCurrentPosition lat + lng ",newLat,newLng)

        Geocode.fromLatLng(newLat, newLng).then(
          (response) => {
            const address = response.results[0].formatted_address,
              addressArray = response.results[0].address_components,
              city = getCity(addressArray),
              area = getArea(addressArray),
              state = getState(addressArray);

            let coordinates = {
              lat: newLat,
              lng: newLng,
            };

            let isPlaceInBound = turf.booleanPointInPolygon(
              turf.point([newLat, newLng]),
              poly,
            );

            if (isPlaceInBound) {
              let data = {
                address,
                city,
                area,
                state,
                mapPosition: coordinates,
                markerPosition: coordinates,
                zoom: 13,
              };
              document.getElementById('locationAutocomplete').value = address;
              dispatch(updateLocationAction(data));
              dispatch(setLocationOutOfBoundAction(false));
            } else {
              document.getElementById('locationAutocomplete').value = '';
              dispatch(setLocationOutOfBoundAction(true));
            }
          },
          (error) => {
            console.error(error);
          },
        );
      });
    } else {
      dispatch(changeLocationPermissionStatusAction(false));
    }
  }
};

export const getLocationInfoFromCoordinates = (lat, lng) => {
  // console.log('getLocationInfoFromCoordinates lat + lng ', lat, lng);
  Geocode.fromLatLng(lat, lng).then(
    (response) => {
      // console.log(response);
      const address = response.results[0];
      return address;
    },
    (error) => {
      console.error(error);
    },
  );
};
