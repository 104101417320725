import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Capacitor,
  Plugins,
  PushNotificationActionPerformed,
} from '@capacitor/core';
const { App: CapApp, Browser, PushNotifications } = Plugins;

const AppUrlListener = () => {
  let history = useHistory();
  let location = useLocation();
  useEffect(() => {
    if (Capacitor.isNative) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          const slug = notification.notification.data.link
            .split('.co.tz')
            .pop();
          if (slug) {
            // console.log('slug + pathname', slug, location.pathname);
            history.push(slug);
            if (slug === location.pathname) {
              location.reload();
            }
            if (notification.notification.data.receiptLink) {
              Browser.open({ url: notification.notification.data.receiptLink });
            }
          }
        },
      );
    }

    CapApp.addListener('appUrlOpen', (data) => {
      const slug = data.url.split('.co.tz').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
