import {
  SELECT_VARIANT,
  ADD_TO_CART,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_TOTAL,
  REMOVE_ITEM,
  CHANGE_MODAL_STATUS,
  RESET_CART_RETRIEVAL_STATUS,
  CLEAR_CART,
  CHANGE_LOCATION_PERMISSION_STATUS,
  TOKEN_GENERATED,
  LOGOUT,
  CART_CREATED,
  ADD_SHOP_DELIVERY_ITEM,
  UPDATE_CART,
  SAVE_ORDER,
  GET_PAST_ORDERS,
  RE_ORDER,
  CART_ITEMS_RETRIEVED,
  CART_WEB_SHARE_SUCCESS,
  RESET_CART_SHARING,
  SELECT_OPTION,
  RESET_VARIANTS_OPTION,
  SELECT_PRODUCT,
  GO_TO_CHECKOUT,
  DISCOUNT_SUCCESS,
  TOGGLE_FLYOUT_MENU,
  RESET_PAST_ORDERS,
  SET_DELIVERY_DATETIME_SUCCESS,
  RESET_DELIVERY_DATETIME,
  RESET_DELIVERY_DATETIME_BY_SHOP,
} from '../constants/ActionTypes';
import _ from 'lodash';

const initialState = {
  user: {},
  cartItems: [],
  pastOrders: {},
  loadingPastOrders: true,
  pastCartIds: [],
  total: 0,
  vat: 0,
  totalObj: {},
  vatObj: {},
  deliverByShopInfo: {},
  quantityObj: {},
  itemsInCart: 0,
  selectedVariant: null,
  selectedProduct: null,
  isCartCreated: false,
  isCartRetrieved: false,
  modalIsOpen: false,
  isCartShared: false,
  message: null,
  selectedOptions: null,
  canGoToCheckout: false,
  isPastOrderRetrieved: true,
  isSharedItemsIncomplete: false,
  message: '',
  checkoutMessage: '',
  itemsAfterCartModalIsOpen: 0,
  isOrderSaved: false,
  deliveryDatetime: null,
};

function CartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      let isProductInCart = _.find(state.cartItems, [
        'sku_id',
        action.payload.sku_id,
      ]);
      if (!isProductInCart) {
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload],
          total:
            parseFloat(state.total) +
            parseFloat(action.payload.total * action.payload.quantity),
          vat:
            parseFloat(state.vat) +
            parseFloat(action.payload.vat * action.payload.quantity),
          totalObj: {
            ...state.totalObj,
            [action.payload.sku_id]:
              action.payload.total * action.payload.quantity,
          },
          vatObj: {
            ...state.vatObj,
            [action.payload.sku_id]: parseFloat(
              action.payload.vat * action.payload.quantity,
            ),
          },
          quantityObj: {
            ...state.quantityObj,
            [action.payload.sku_id]: action.payload.quantity,
          },
          itemsInCart: state.itemsInCart + action.payload.quantity,
        };
      } else {
        return { ...state };
      }

    case CHANGE_PRODUCT_QUANTITY:
      let updatedTotalObj = {
        ...state.totalObj,
        [action.product.sku_id]: action.quantity * action.product.price,
      };
      let updatedVATObj = {
        ...state.vatObj,
        [action.product.sku_id]: parseFloat(
          action.quantity * action.product.vat,
        ),
      };
      let updatedQuantityObj = {
        ...state.quantityObj,
        [action.product.sku_id]: action.quantity,
      };
      let totalArr = Object.keys(updatedTotalObj).map(
        (key) => updatedTotalObj[key],
      );
      let totalVATarr = Object.keys(updatedVATObj).map(
        (key) => updatedVATObj[key],
      );

      let totalQuantityArr = Object.keys(updatedQuantityObj).map(
        (key) => updatedQuantityObj[key],
      );

      totalArr[action.product.sku_id] = action.quantity * action.product.price;

      totalVATarr[action.product.sku_id] = action.quantity * action.product.vat;
      let updatedTotal = totalArr.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0,
      );
      let updatedVAT = totalVATarr.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0,
      );
      let updatedTotalQuantity = totalQuantityArr.reduce((a, b) => a + b, 0);

      let items = state.cartItems.map((product) => {
        if (product.sku_id === action.product.sku_id) {
          let total =
            parseInt(action.quantity) * parseInt(action.product.price);
          // let vat = parseInt(action.quantity) * parseInt(action.product.vat);
          return {
            ...product,
            quantity: action.quantity,
            qty: action.quantity,
            total,
          };
        }
        return product;
      });

      return {
        ...state,
        cartItems: items,
        total: updatedTotal,
        vat: updatedVAT,
        totalObj: updatedTotalObj,
        vatObj: updatedVATObj,
        quantityObj: {
          ...state.quantityObj,
          [action.product.sku_id]: action.quantity,
        },
        itemsInCart: updatedTotalQuantity,
        isSharedCartIncomplete: false,
      };
    case CHANGE_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case CART_CREATED:
      return {
        ...state,
        cartID: action.payload,
        isCartCreated: true,
      };
    case REMOVE_ITEM:
      let cartItem = action.payload;

      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.sku_id !== action.payload.sku_id,
        ),
        total: state.total - action.payload.total,
        totalObj: _.omit(state.totalObj, [action.payload.sku_id]),
        quantityObj: _.omit(state.quantityObj, [action.payload.sku_id]),
        vatObj: _.omit(state.vatObj, [action.payload.sku_id]),
        itemsInCart:
          state.itemsInCart - state.quantityObj[action.payload.sku_id],
        vat: state.vat - state.vatObj[cartItem.sku_id],
        isCartRetrieved: true,
        isSharedItemsIncomplete: false,
        isSharedCartIncomplete: false,
      };
    case ADD_SHOP_DELIVERY_ITEM:
    case RESET_DELIVERY_DATETIME_BY_SHOP:
      return {
        ...state,
        deliverByShopInfo: {
          ...state.deliverByShopInfo,
          [action.payload.shop]: action.payload.deliveryInfo,
        },
      };
    case UPDATE_CART:
      return {
        ...state,
      };
    case CART_ITEMS_RETRIEVED:
      let sharedTotalObj = {};
      let sharedQuantityObj = {};
      let sharedVATObj = {};
      let sharedItems = action.payload;

      let vat = _.sum(sharedItems.map((item) => item.vat));
      let sharedtotal = _.sum(sharedItems.map((item) => item.total));
      sharedItems.map((item) => (sharedTotalObj[item.sku_id] = item.total));
      sharedItems.map(
        (item) => (sharedQuantityObj[item.sku_id] = item.quantity),
      );
      sharedItems.map((item) => (sharedVATObj[item.sku_id] = item.vat));
      let sharedItemsInCart = _.sum(sharedItems.map((item) => item.quantity));
      return {
        ...state,
        cartItems: action.payload,
        isCartRetrieved: true,
        vat,
        total: sharedtotal,
        totalObj: sharedTotalObj,
        quantityObj: sharedQuantityObj,
        itemsInCart: sharedItemsInCart,
        vatObj: sharedVATObj,
        modalIsOpen: true,
        isSharedCartIncomplete: action.isSharedItemsIncomplete,
        message: action.message,
        checkoutMessage: action.checkoutMessage,
        isCartShared: false,
      };

    case RESET_CART_RETRIEVAL_STATUS:
      return {
        ...state,
        isCartRetrieved: false,
        modalIsOpen: true,
      };
    case SAVE_ORDER:
      return {
        ...state,
        pastCartIds: [...state.pastCartIds, action.payload],
        cartItems: [],
        total: 0,
        totalObj: {},
        quantityObj: {},
        itemsInCart: 0,
        vat: 0,
        vatObj: {},
        isCartCreated: false,
        isOrderSaved: true,
      };

    case GET_PAST_ORDERS:
      return {
        ...state,
        pastOrders: action.payload,
        isPastOrderRetrieved: true,
        loadingPastOrders: false,
      };

    case RESET_PAST_ORDERS:
      return { ...state, pastOrders: {}, loadingPastOrders: true };

    case CHANGE_MODAL_STATUS:
      return { ...state, modalIsOpen: action.payload };

    case CHANGE_LOCATION_PERMISSION_STATUS:
      return {
        ...state,
        isLocationEnabled: action.payload,
      };
    case TOKEN_GENERATED:
      return { ...state, token: action.payload };
    case LOGOUT:
      return { ...state, authenticated: false };
    case GO_TO_CHECKOUT:
      return { ...state, canGoToCheckout: true };
    case SELECT_VARIANT:
      return {
        ...state,
        selectedVariant: action.payload,
        selectedProduct: {
          ...state.selectedProduct,
          price: action.payload.price,
          total: state.selectedProduct.quantity * action.payload.price,
          sku_id: action.payload._id,
          options: {
            variant: action.payload.name,
          },
          image: action.payload.picture
            ? `products/${action.payload.picture}`
            : state.selectedProduct.image,
        },
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case SELECT_OPTION:
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          [action.payload.key]: action.payload.value,
        },
      };
    case RESET_VARIANTS_OPTION:
      return {
        ...state,
        selectedOptions: null,
      };
    case CART_WEB_SHARE_SUCCESS:
      return {
        ...state,
        isCartShared: true,
        message: action.payload,
      };
    case RESET_CART_SHARING:
      return {
        ...state,
        isCartShared: false,
        isCartRetrieved: false,
        mesaage: null,
      };
    case RE_ORDER:
      let cartItems = action.payload;
      // console.log('REDUCER RE_ORDER cartItems', cartItems);

      let reOrderTotalObj = {};
      let reOrderQuantityObj = {};
      let reOrderVatObj = {};

      let total = cartItems.reduce(function (acc, obj) {
        return acc + obj.total;
      }, 0);

      let pastvat = cartItems.reduce(function (acc, obj) {
        return acc + obj.vat;
      }, 0);

      let itemsInCart = cartItems.reduce(function (acc, obj) {
        return acc + obj.quantity;
      }, 0);

      action.payload.map((item) => {
        reOrderTotalObj[item.sku_id] = item.total;
        reOrderQuantityObj[item.sku_id] = item.quantity;
        reOrderVatObj[item.sku_id] = item.vat;
      });

      return {
        ...state,
        cartItems: cartItems,
        total: total,
        totalObj: reOrderTotalObj,
        quantityObj: reOrderQuantityObj,
        vatObj: reOrderVatObj,
        itemsInCart: itemsInCart,
        vat: pastvat,
      };
    case TOGGLE_FLYOUT_MENU:
      return {
        ...state,
        itemsAfterCartModalIsOpen: action.itemsInCart,
      };
    case CLEAR_CART:
      return initialState;

    case SET_DELIVERY_DATETIME_SUCCESS:
      return {
        ...state,
        deliveryDatetime: action.payload,
      };

    case RESET_DELIVERY_DATETIME:
      return {
        ...state,
        deliveryDatetime: null,
      };

    default:
      return state;
  }
}

export default CartReducer;
