import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import _ from 'lodash';

import {
  createCartAction,
  getCartItemsAction,
  resetCartRetrievalStatusAction,
} from '../../store/actions/cartAction';
import { toggleFlyoutMenuAction } from '../../store/actions/uiAction';

function ShareCart() {
  const [canRedirect, setCanRedirect] = useState();

  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const isCartRetrieved = useSelector(
    (state) => state.cartManager.isCartRetrieved,
  );
  let { cartId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCartRetrieved) {
      setCanRedirect(true);
      let createCartPayload = {
        items: cartItems,
        status: 'created',
      };
      dispatch(createCartAction(createCartPayload));
      dispatch(toggleFlyoutMenuAction(true));
    }
    return () => dispatch(resetCartRetrievalStatusAction());
  }, [isCartRetrieved]);

  useEffect(() => {
    dispatch(getCartItemsAction(cartId));
    dispatch(toggleFlyoutMenuAction(true));
  }, []);
  return <div>{canRedirect && <Redirect to="/" />}</div>;
}

export default ShareCart;
