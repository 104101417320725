import moment from 'moment';

export const numberWithCommas = (x) => {
  if (x !== null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format('DD-MM-YYY HH:MM');
  return buildDate;
};

/*
 * Format 24Hrs format to 12hrs format
 * */
export const timeTo12Hrs = (time) => {
  return moment(time, 'hh:mm A').format('hh:mm A');
};

/*
 * Format 12Hrs format to 24hrs format
 * */
export const timeTo24Hrs = (time) => {
  return moment(time, 'hh:mm A').format('HH:mm');
};

/*
 * Rounding of number to decimal places
 * */
export const roundOffNumber = (number, decimalPlaces) => {
  return Number(
    Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces,
  );
};
