import React from 'react';

const ShopIcon = ({active}) => {
    // return(
    //     <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill={active ? "#00c400" : "#3c3f42"}>
    //         <rect fill="none" width="96" height="96"/>
    //         <rect x="79.98" y="27.92" width="0.04" height="8.09"/>
    //         <path
    //             d="M80.05,36H80V13a1,1,0,0,0-1-1h-62a1,1,0,0,0-1,1V35.77a1,1,0,0,1-.1.45L8.07,51.74a1,1,0,0,0-.11.45V59a1,1,0,0,0,1,1h1.95a1,1,0,0,1,1,1V76h0v7.08a1,1,0,0,0,1,1H51a1,1,0,0,0,1-1V61a1,1,0,0,1,1-1H75a1,1,0,0,1,1,1V83a1,1,0,0,0,1,1H83a1,1,0,0,0,1-1V61a1,1,0,0,1,1-1h2a1,1,0,0,0,1-1V52.19a1,1,0,0,0-.11-.45ZM25,20H70.93a1,1,0,0,1,1,1v5.88a1,1,0,0,1-1,1H25a1,1,0,0,1-1-1V21A1,1,0,0,1,25,20ZM42.9,76H21a1,1,0,0,1-1-1V61a1,1,0,0,1,1-1H42.9a1,1,0,0,1,1,1V75A1,1,0,0,1,42.9,76ZM17.25,50.53l6.48-13.94a1,1,0,0,1,.9-.58H71.37a1,1,0,0,1,.9.58l6.48,13.94a1,1,0,0,1-.9,1.42H18.15A1,1,0,0,1,17.25,50.53Z"/>
    //     </svg>
    // );
    
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 24 24" fill={active ? "#00c400" : "rgba(56,61,66,0.7)"}>
   <style type="text/css">
       .st0{'fill:none;'}
       .st1{'fill:rgba(56,61,66,0.7);'}
   </style>
   {/* <path class="st0" d="M0,0h24v24H0V0z"/> */}
   <path class="st1" d="M19.3,8.3l0.7,3.4H4.1l0.7-3.4H19.3 M21.1,2.6H2.9v2.3h18.2V2.6z M21.1,6.1H2.9l-1.1,5.7V14h1.1v6.8h11.4V14
	h4.6v6.8h2.3V14h1.1v-2.3L21.1,6.1z M5.2,18.6V14H12v4.6H5.2z"/>   </svg>
   
    );
};

export default ShopIcon;