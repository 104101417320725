import axios from 'axios';
import { MERCANT_API, API_KEY } from '../constants';
import { inalipaMethodHeaders } from './config';

export const getBanners = async () => {
  const result = await axios
    .get(`${MERCANT_API}/website/banners`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const getDeviceNotifications = async (deviceToken) => {
  return await axios
    .get(
      `${MERCANT_API}/messaging/customer-notifications/${deviceToken}`,
      inalipaMethodHeaders,
    )
    .then((data) => data);
};
