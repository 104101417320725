import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCartAction,
  createCartAction,
} from '../../store/actions/cartAction';
import { ActionButtonRound } from '../styles/Form';
import QuantityToggler from '../ui/QuantityToggler';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Plugins } from '@capacitor/core';
import { getRelatedToItemsInYourCartAction, sendAddToCartEventAction, setRecommendedSectionsLoadingAction } from '../../store/actions/recommendationAction';
import { generateItemsObjectForRelatedToCart } from '../../utils/analytics';

const { Device } = Plugins;

const AddToCartButton = ({ cartItem, hasVariant, canTrackInventory }) => {
  const [showToggler, setShowToggler] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const isCartCreated = useSelector((state) => state.cartManager.isCartCreated);
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const selectedProduct = useSelector(
    (state) => state.cartManager.selectedProduct,
  );
  const selectedOption = useSelector(
    (state) => state.cartManager.selectedOption,
  );

  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('product');

  useEffect(() => {
    checkIfProductIsAddedToCart();
  }, [selectedProduct, selectedOption]);

  useEffect(() => {
    checkIfProductIsAddedToCart();
    return () => setShowToggler(false);
  }, []);

  const checkIfProductIsAddedToCart = () => {
    if (cartItems) {
      setShowToggler(false);
      let isProductInCart = _.find(cartItems, [
        'sku_id',
        hasVariant
          ? selectedProduct && selectedProduct.sku_id
          : cartItem.sku_id,
      ]);

      if (isProductInCart) {
        setIsInCart(true);
      } else {
        setIsInCart(false);
      }
    }
  };
  const showQuantityToggler = async () => {
    await checkIfProductIsAddedToCart();
    setShowToggler(true);
    if (!isCartCreated) {
      let deviceUuid = JSON.parse(localStorage.getItem('appDev')).uuid ?? null;
      if (!deviceUuid) {
        Device.getInfo()
          .then((device) => {
            deviceUuid = device.uuid;
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      let createCartPayload = {
        items: [cartItem],
        status: 'created',
        uuid: deviceUuid,
      };
      dispatch(createCartAction(createCartPayload));
    }

    // console.log('Add to cart . is product in cart ? ', isInCart); 

    if (!isInCart) {
      let addedProduct = hasVariant ? selectedProduct : cartItem;

      let addToCartEvenBody = {userEvent: {
        "eventType": "add-to-cart",
        "visitorId": localStorage.getItem('appNumber'),
        "productDetails": [
            {
                "product": {
                    "id": addedProduct.id
                },
                "quantity": {
                    "items": 1
                }
            }
        ]
      }};
      // if (cartItems.length > 0 ){
      //   let productsInCartDetails = generateItemsObjectForRelatedToCart (cartItems)
      //     dispatch(setRecommendedSectionsLoadingAction('cart'));
      //     dispatch(getRelatedToItemsInYourCartAction({
      //       placementId: "frequently-bought-together",
      //       userEvent: {
      //           eventType: "add-to-cart",
      //           visitorId: localStorage.getItem('appNumber'),
      //           productDetails: 
      //             productsInCartDetails
      //       }
      //   }));
      //     // console.log('productsInCartDetails ',  productsInCartDetails);
      // }

      dispatch(sendAddToCartEventAction(addToCartEvenBody));
      dispatch(addToCartAction(addedProduct));
    }
  };

  return (
    <>
      {!showToggler && (
        <ActionButtonRound onClick={(e) => showQuantityToggler()}>
          {t('add_to_cart')}
        </ActionButtonRound>
      )}
      {showToggler && !isFlyoutMenuOpen && (
        <QuantityToggler
          cartItem={cartItem}
          hasVariant={hasVariant}
          canTrackInventory={canTrackInventory}
        />
      )}
    </>
  );
};

export default AddToCartButton;
