import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Circle, Basket } from '../styles/Form';
import { updateCartAction } from '../../store/actions/cartAction';
import { toggleFlyoutMenuAction } from '../../store/actions/uiAction';
import Cart from '../ui/Cart';
import { ReactComponent as CartIcon } from '../../assets/images/shopping-cart.svg';
import { ReactComponent as CartIconWhite } from '../../assets/images/shopping-cart-white.svg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import FlyoutMenu from '../menu/FlyoutMenu';
import { Capacitor, Plugins } from '@capacitor/core';
import { useHistory, useLocation } from 'react-router-dom';
import { generateItemsListForFirebaseAnalytics } from '../../utils/analytics';

const { Device, FirebaseAnalytics } = Plugins;

const BasketComponent = () => {
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const cartID = useSelector((state) => state.cartManager.cartID);
  const itemsInCart = useSelector((state) => state.cartManager.itemsInCart);
  const savedTotal = useSelector((state) => state.cartManager.total);

  const location = useLocation();
  const history = useHistory();
  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );

  const [hideFloatingCart, setHideFloatingCart] = useState(true);
  const toogleCart = (canOpen) => {
    let pathName = location.pathname;

    if (pathName === '/pay') {
      history.push({
        pathname: '/checkout',
        state: {
          openCart: true,
        },
      });
      setTimeout(dispatch(toggleFlyoutMenuAction(true)), 1000);
      document.body.style.overflow = 'unset';
    } else {
      dispatch(toggleFlyoutMenuAction(canOpen, itemsInCart));
      document.body.style.overflow = 'hidden';
    }

    let deviceUuid = JSON.parse(localStorage.getItem('appDev')).uuid ?? null;
    if (!deviceUuid) {
      Device.getInfo()
        .then((device) => {
          deviceUuid = device.uuid;
        })
        .catch((err) => {
          console.log('err', err);
        });
    }

    let cartItemsForAnalytics = generateItemsListForFirebaseAnalytics(
      cartItems,
    );

    FirebaseAnalytics.logEvent({
      name: 'view_cart',
      params: {
        currency: 'TZS',
        value: savedTotal,
        items: cartItemsForAnalytics,
      },
    });

    dispatch(updateCartAction({ items: cartItems, uuid: deviceUuid }, cartID));
  };
  const dispatch = useDispatch();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.y);
      const isShow = currPos.y > -80;
      if (isShow !== hideFloatingCart && window.innerWidth > 575) {
        setHideFloatingCart(isShow);
      }
    },
    [hideFloatingCart],
    false,
    false,
    300,
  );

  return (
    <>
      <Basket
        onClick={() => toogleCart(true)}
        className={
          hideFloatingCart || isFlyoutMenuOpen
            ? ''
            : `floating-cart ${
                Capacitor.isNative && Capacitor.platform === 'ios'
                  ? 'floating-cart-ios'
                  : ''
              }`
        }
      >
        <div>
          {hideFloatingCart || isFlyoutMenuOpen ? (
            <CartIcon />
          ) : (
            <CartIconWhite />
          )}
          <Circle className="cart-count-icon">
            <span>{itemsInCart ? itemsInCart : 0}</span>
          </Circle>
        </div>
      </Basket>
      <FlyoutMenu component={<Cart />} />
    </>
  );
};
export default BasketComponent;
