import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { INALIPA_S3_URL } from '../../constants';
import { ProductCard, ProductCardAccordion } from './style';
import { numberWithCommas } from '../../utils/format';
import DateTimePicker from '../date/DateTimePicker';
import placeholder from '../../assets/images/placeholders/item.png';
import _ from 'lodash';
import DeliveryOptions from '../../pages/Checkout/DeliveryOptions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color: '#00C400',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '1px',
    lineHeight: '21px',
  },
  time: {
    color: '#000000',
    fontSize: '12px',
    letterSpacing: '1px',
    lineHeight: '16px',
    paddingLeft: '20px',
  },
}));

function DeliveryByShopSelector({ shops, delivery, method }) {
  const [preOrder, setPreOrder] = useState(null);
  const classes = useStyles();
  const deliverByShopInfo = useSelector(
    (state) => state.cartManager.deliverByShopInfo,
  );
  useEffect(() => {
    // console.log('Deliver when available from these shops');
    // console.log(shops, deliverByShopInfo);
  }, []);

  return (
    <div className={classes.root}>
      {Object.keys(shops).map((shop) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {shops[shop] &&
              shops[shop][0] &&
              shops[shop][0] &&
              shops[shop][0].shopName
                ? shops[shop][0].shopName
                : shop}
            </Typography>
          </AccordionSummary>
          <ProductCardAccordion>
            <time className={classes.time}>
              {/* {deliverByShopInfo[shop] && deliverByShopInfo[shop].deliveryDate},
              At{' '}
              {deliverByShopInfo[shop] && deliverByShopInfo[shop].deliveryTime} */}
              {deliverByShopInfo[shop] &&
                deliverByShopInfo[shop].deliveryDatetime &&
                moment(deliverByShopInfo[shop].deliveryDatetime).format('LLLL')}
            </time>
            <AccordionDetails>
              {shops[shop].map((product) => (
                <>
                  <ProductCard>
                    <img
                      src={
                        product && product.image
                          ? `${INALIPA_S3_URL}${product.image}`
                          : placeholder
                      }
                      alt={product.name}
                      className="img-fluid "
                    />

                    <div className="productCard_details">
                      <h3>{product.name}</h3>
                      <h4>
                        {product.quantity} X TZS{' '}
                        {numberWithCommas(product.price)}
                      </h4>
                    </div>
                  </ProductCard>
                </>
              ))}
              {/* <DateTimePicker
                shop={shop}
                shopId={shops[shop][0].shopId}
                openingHours={shops[shop][0].shopOpeningHours}
                preOrder={
                  shops[shop] ? _.maxBy(shops[shop], 'preOrder').preOrder : 0
                }
                shopName={shops[shop][0].shopName ?? shop}
              /> */}
              {delivery &&
                delivery.map((shopDelivery, index) => (
                  <>
                    {shopDelivery.shop === shops[shop][0].shopId && (
                      <DeliveryOptions
                        option={shopDelivery}
                        method={method}
                        shop={shop}
                      />
                    )}
                  </>
                ))}
            </AccordionDetails>
          </ProductCardAccordion>
        </Accordion>
      ))}
    </div>
  );
}

export default DeliveryByShopSelector;
