import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { ProductListWrapper } from '../../components/styles/List';
import ProductTile from '../../components/ui/ProductTile';
import { scrollToTop } from '../../utils/window';
import {
  getDailyDealsAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPreviousPathnameAction } from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';
import {
  scrollToLastPositionAction,
  setPreviousUrlAction,
  setUpdateLastScrollPosition,
  updateLastScrollPositionAction,
  updateScrollPositionAction,
} from '../../store/actions/uiAction';
const { FirebaseAnalytics } = Plugins;
const DailyDeals = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('product');
  const products = [];
  const dailyDeals = useSelector((state) => state.catalogManager.dailyDeals);
  let wentBack = false;
  const lastPosition = useSelector(
    (state) => state.uiManager.lastScrollPosition,
  );
  const updateLastScrollPosition = useSelector(
    (state) => state.uiManager.updateLastScrollPosition,
  );
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );

  if (dailyDeals.length > 0) {
    for (let dailyDeal of dailyDeals) {
      if (dailyDeal.pricePromotion !== undefined) {
        if (dailyDeal.pricePromotion.prices.retail.old) {
          products.push(dailyDeal);
        }
      }
    }
  }
  useEffect(() => {
    // dispatch(getDailyDealsAction());
    if (scrollToLastPosition === false && dailyDeals.length < 1) {
      setTimeout(
        () => dispatch(getDailyDealsAction()),
        dispatch(getDailyDealsAction()),
        1000,
      );
      // console.log('Get All products Success in Product page : ', products)
    }
    let previousRoutes = {
      parent: { name: 'Home', link: '/' },
      coParent: { name: 'Daily Deals', link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'deals',
      },
    });
  }, []);

  const Url = location;
  const history = useHistory();
  useEffect(() => {
    scrollToTop();
    return () => {
      dispatch(setPreviousUrlAction(Url));
    };
  }, [location]);

  useEffect(() => {
    // console.log('mounted')
    // console.log('Location Action 2:',history.action);

    // console.log('Location Url :',Url);
    // ...
    if (history.action === 'POP') {
      // console.log('POP so we went back');
      wentBack = true;

      if (history.action === 'PUSH') {
        // console.log('PUSH so we went started the page for first time');
      }

      return () => {
        // console.log('unmounted')
      };
    }
  }, []);

  // useEffect(() => {
  //   console.log('Ready to scroll to last position : ', lastPosition);
  // if ( scrollToLastPosition ) {
  //   setTimeout(() => {
  //   window.scrollTo({
  //     top: lastPosition,
  //     behavior: 'smooth',
  //   });
  // } ,
  // dispatch(scrollToLastPositionAction(false)),
  // dispatch(setUpdateLastScrollPosition(true)),
  // 2500);

  // }
  // // return () => {
  // //   console.log('returning')
  // //   wentBack = false;
  // // }

  // }, [wentBack]);
  useEffect(() => {
    // console.log('updateLastScrollPosition:: ', updateLastScrollPosition);
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      if (wentBack) {
        window.scrollTo({
          top: lastPosition,
          behavior: 'smooth',
        });
      }

      // dispatch(setPreviousPageIsProductAction(false));
    }, 2500);

    dispatch(setUpdateLastScrollPosition(true));

    return () => {
      dispatch(setSortByAction('featured'));
      window.removeEventListener('scroll', handleScroll);
      // dispatch(updateLastScrollPositionAction(window.scrollY));
      // dispatch(setPreviousPageIsProductAction(false));
      dispatch(scrollToLastPositionAction(false));
    };
  }, []);

  const handleScroll = () => {
    // console.log('position : out ', window.scrollY);

    dispatch(updateScrollPositionAction(window.scrollY));
    // dispatch(updateLastScrollPositionAction(window.scrollY));
  };

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);

  return (
    <>
      <PageTitle title="Inalipa - Daily Deals" />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: '/', name: 'Home' }}
        child={{ name: 'Daily Deals' }}
      />
      <div className="container">
        <h2>Daily Deal</h2>
        {products && products.length === 0 && (
          <p className="alert alert-info">{t('no_product')}</p>
        )}
        {products && products.length > 0 && (
          <ProductListWrapper>
            <div className="row">
              {products &&
                products.length > 0 &&
                products.map((product, index) => (
                  <div className="column mt-2" key={product._id}>
                    <ProductTile index={index} productListItem={product} />
                  </div>
                ))}
            </div>
          </ProductListWrapper>
        )}
      </div>
    </>
  );
};

export default DailyDeals;
