import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getShopBySubDomainAction,
  resetShopProductsAction,
} from '../../store/actions/shopAction';
import PageTitle from '../../components/utils/PageTitle';
import ProductsComponent from '../../components/products/Products';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import OpeningHours from '../../components/ui/Shop/OpeningHours';
import { scrollToTop } from '../../utils/window';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import {
  getFilterOptionsAction,
  getProductsAction,
  loadProductAction,
  setFilterAction,
  setSelectedFilterAction,
} from '../../store/actions/catalogAction';
import {
  resetSelectedPageAction,
  selectPageAction,
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import _ from 'lodash';
import ShopBanner from '../../components/shop/ShopBanner';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const { PUBLIC_URL } = process.env;

const Shop = () => {
  let dispatch = useDispatch();
  const location = useLocation();
  const { subDomain } = useParams();

  const shop = useSelector((state) => state.shopManager.shop);
  const sortBy = useSelector((state) => state.catalogManager.shop);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const products = useSelector((state) => state.catalogManager.products);

  useEffect(() => {
    if (scrollToLastPosition === false) {
      scrollToTop();
    }

    // dispatch(getProductsAction({ subDomain: [subDomain], sortBy: sortBy }));
    if (scrollToLastPosition === false || products.length < 1) {
      setTimeout(
        () => dispatch(loadProductAction()),
        dispatch(getProductsAction({ subDomain: [subDomain], sortBy: sortBy })),
        1000,
      );
      // console.log('Get All products Success in Shop page : ', products)
    }
    dispatch(getShopBySubDomainAction(subDomain));
    dispatch(setPreviousPathnameAction(location.pathname));
    dispatch(selectPageAction({ name: 'subDomain', filter: [subDomain] }));
    return () => {
      dispatch(resetSelectedPageAction());
      dispatch(resetShopProductsAction());
    };
  }, [dispatch, location.pathname, subDomain]);

  useEffect(() => {
    if (!_.isEmpty(shop)) {
      // console.log('Shop retrieved ', shop);
      let selectedVertical = {
        key: 'subDomain',
        item: { value: shop.subDomain, label: shop.name, id: shop._id },
      };
      dispatch(getFilterOptionsAction({ Shop: [shop._id] }));
      dispatch(setSelectedFilterAction(selectedVertical));
    }

    let previousRoutes = {
      parent: { name: 'Stores', link: '/' },
      coParent: { name: shop.name, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: { content_type: 'shop', item_name: shop.name, item_id: shop._id },
    });
  }, [dispatch, location.pathname, shop]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <>
      <PageTitle
        title={shop.name ? 'Inalipa - ' + shop.name : 'Inalipa - Shop'}
        description={shop.description ? shop.description : 'Inalipa - Shop'}
      />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: '/stores', name: 'Stores' }}
        child={{ name: shop.name }}
      />

      <div className="container">
        <div className="row pt-4">
          <div className="col-12 col-sm-3 col-md-4 col-lg-6 col-xl-7 pl-0 mb-3">
            <h2 className="mt-0 mb-0">{shop.name}</h2>
          </div>
          {shop && shop.settings && (
            <OpeningHours shopSettings={shop.settings} />
          )}
        </div>
        {shop &&
          shop.settings &&
          shop.settings.show_header_banner &&
          shop.settings.header_banner && (
            <ShopBanner
              banner={
                shop.settings.header_banner ??
                `${PUBLIC_URL}images/megastore_banner.jpeg`
              }
            />
          )}
        <hr className="mt-2 mb-3" />
        <div className="">
          <ProductsComponent />
        </div>
      </div>
    </>
  );
};

export default Shop;
