import styled from 'styled-components';

export const ProductWrapper = styled.div`
  .product-image-wrapper {
    min-height: 160px;
  }
  .product-image-wrapper-skeleton {
    min-height: 350px !important;
  }
  .product-image-wrapper img {
    max-width: 160px;
  }
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
  }
  h4 {
    color: #757575;
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  strike {
    color: #757575;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  .product-image {
    position: relative;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .product-image-wrapper img {
      max-width: 350px;
    }
    .product-image-wrapper-skeleton {
      max-height: 350px !important;
    }
  }

  .product-image-wrapper .pre-order {
    position: absolute;
    top: 75%;
    left: 20%;
    border: 1px #f15b2a solid;
    border-radius: 20px;
    background-color: white;
    color: #f15b2a;
    font-size: 10px;
    text-align: center;
    padding: 5px 10px;
    margin: 5px 10px;
    cursor: none;

    @media (min-width: 768px) {
      top: 35%;
      left: 25%;
      border-radius: 30px;
      padding: 8px 10px;
      font-size: 12px;
    }
  }
`;

export const BrandProductWrapper = styled.div`
  a {
    color: black;
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  .img.container {
    position: relative;
    text-align: center;
    width: 95px;
    height: 95px;
  }

  .img.container > img {
    object-fit: cover;
    opacity: 0.6;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  ul > li {
    list-style: none;
    margin: 15px 0;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 13.5%;
    max-width: 13.5%;
    margin-left: 15px;
  }

  .brand-group {
    margin: 15px 0 0 20px;
  }

  .brands-letter {
    padding: 0 16px 0;
    background: #fff;
    margin-bottom: 20px;
    font: 14px/18px DM Sans;
    letter-spacing: 0.2px;
    font-weight: 400;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    // z-index: 1000;
  }

  brands-initial-character {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .brands-letter:after {
    border-bottom: 1px solid #cbcbcb;
    margin: 6px 0;
  }
  .brands-letter:after {
    content: '';
    display: block;
  }

  .brands {
    list-style-type: none;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    height: 40px;
  }

  .brands::-webkit-scrollbar {
    display: none;
  }

  .brands-initial {
    margin-right: 35px;
  }
  // .brands>li:not(:first-child) {
  //     min-width: 40px;
  // }

  .brands-link {
    color: #000;
  }

  .brands-link-selected {
    border-bottom: 2px solid #000;
    padding-bottom: 16px;
  }

  @media (min-width: 768px) {
    .list-brands {
      display: grid;
      grid-column-gap: 25px;
      grid-template-columns: repeat(7, 2fr);
    }

    .brand-letter {
      padding: 16px 32px 0;
    }
  }

  @media (max-width: 600px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
`;
export const ProductReviewHeaderWrapper = styled.div`
  .customer-reviews {
    width: 50%;
    text-align: left;
  }
  .write-a-review {
    width: 50%;
    text-align: right;
  }
  .write-a-review > a {
    text-decoration: none;
    color: #00c400;
  }
  h5 {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    h5 {
      font-size: 18px;
    }
  }

  @media (max-width: 600px) {
    h5 {
      font-size: 16px;
    }
  }
`;

export const ProgressBarsWrapper = styled.div`
  ul {
    list-style-type: none;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0 0 10px;
  }
  .star {
    width: 8%;
  }
  .bar {
    width: 80%;
    margin: 5px 0 0;
  }

  @media (max-width: 768px) {
    .star {
      width: 10%;
    }
    .bar {
      width: 75%;
    }
  }

  @media (max-width: 600px) {
    .star {
      width: 15%;
    }
    .bar {
      width: 70%;
    }
  }
`;
