import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { ProductListWrapper } from '../../components/styles/List';
import ProductTile from '../../components/ui/ProductTile';
import { scrollToTop } from '../../utils/window';
import {
  getCuratedSectionAction,
  removeCuratedSectionAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPreviousPathnameAction } from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';
import {
  scrollToLastPositionAction,
  setPreviousUrlAction,
  setUpdateLastScrollPosition,
  updateLastScrollPositionAction,
  updateScrollPositionAction,
} from '../../store/actions/uiAction';
import { INALIPA_S3_URL } from '../../constants';
import { MegaStoreImageWrapper } from '../../components/styles/List';
import { Card } from '../../components/styles/Card';
import { filterOutOfStock } from '../../utils/productFiltering';
import CuratedPageSkeleton from '../../components/skeletons/CuratedPageSkeleton';

const { FirebaseAnalytics } = Plugins;
const CuratedPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const location = useLocation();
  let curatedProducts = [];
  const { t } = useTranslation('product');

  let wentBack = false;
  const lastPosition = useSelector(
    (state) => state.uiManager.lastScrollPosition,
  );
  const updateLastScrollPosition = useSelector(
    (state) => state.uiManager.updateLastScrollPosition,
  );
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );

  const curatedSection = useSelector(
    (state) => state.catalogManager.curatedSection,
  );

  const Url = location;
  const history = useHistory();

  useEffect(() => {
    dispatch(getCuratedSectionAction(id));
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    let previousRoutes = {
      parent: { name: 'Home', link: '/' },
      coParent: { name: `${curatedSection?.name}`, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'deals',
      },
    });
    return () => {
      dispatch(setPreviousUrlAction(Url));
      dispatch(removeCuratedSectionAction());
    };
  }, [location]);

  // console.log('curatedSection : ', curatedSection);
  // curatedProducts = curatedSection.products;

  useEffect(() => {
    // console.log('updateLastScrollPosition:: ', updateLastScrollPosition);
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      if (wentBack) {
        window.scrollTo({
          top: lastPosition,
          behavior: 'smooth',
        });
      }

      // dispatch(setPreviousPageIsProductAction(false));
    }, 300);

    dispatch(setUpdateLastScrollPosition(true));

    return () => {
      dispatch(setSortByAction('featured'));
      window.removeEventListener('scroll', handleScroll);
      // dispatch(updateLastScrollPositionAction(window.scrollY));
      // dispatch(setPreviousPageIsProductAction(false));
      dispatch(scrollToLastPositionAction(false));
    };
  }, []);

  const handleScroll = () => {
    // console.log('position : out ', window.scrollY);

    dispatch(updateScrollPositionAction(window.scrollY));
    // dispatch(updateLastScrollPositionAction(window.scrollY));
  };

  let curatedSectionSize = curatedSection && Object.keys(curatedSection).length;
  return (
    <>
      <PageTitle title={`Inalipa - ${curatedSection?.name}`} />
      <SecondaryNav className="d-none d-sm-block" />
      {curatedSection && curatedSectionSize !== 0 && (
        <Breadcrumb
          parent={{ link: '/', name: 'Home' }}
          child={{ name: curatedSection?.name }}
        />
      )}

      {curatedSectionSize === 0 && <CuratedPageSkeleton />}
      {curatedSection && curatedSection?.banner && (
        <div className="row">
          <div className="container">
            <h2> {curatedSection?.name} </h2>
            <Card>
              <MegaStoreImageWrapper>
                <img
                  src={`${INALIPA_S3_URL}web/banners/${curatedSection.banner}`}
                  className="img-fluid img-list"
                  height="120"
                  width="120"
                  alt="banner"
                />
              </MegaStoreImageWrapper>
            </Card>
          </div>
        </div>
      )}

      <div className="container pt-4">
        {curatedSection &&
          curatedSection?.products &&
          curatedSection?.products?.length === 0 && (
            <p className="alert alert-info">{t('no_product')}</p>
          )}
        {curatedSection && curatedSection?.products?.length > 0 && (
          <ProductListWrapper>
            <div className="row">
              {curatedSection &&
                curatedSection?.products &&
                curatedSection?.products?.length > 0 &&
                filterOutOfStock(curatedSection.products).map(
                  (product, index) => (
                    <div className="column mt-2" key={product._id}>
                      <ProductTile index={index} productListItem={product} />
                    </div>
                  ),
                )}
            </div>
          </ProductListWrapper>
        )}
      </div>
    </>
  );
};

export default CuratedPage;
