// import _ from 'lodash';
import {
  GET_NEW_ARRIVALS,
  GET_RECOMMENDED_DEALS_FOR_YOU,
  GET_YOUR_FAVORITE_CATEGORIES,
  GET_RELATED_TO_YOUR_VIEWS,
  GET_RELATED_TO_ITEMS_IN_YOUR_CART,
  SEND_USER_EVENT,
  SEND_PRODUCT_VIEW_EVENT,
  SEND_ADD_TO_CART_EVENT,
  SEND_PURCHASE_COMPLETE_EVENT,
  GET_FREQUENTLY_BOUGHT_TOGETHER,
  GET_CURETTED_SECTIONS,
  SET_RECOMMENDED_SECTIONS_LOADING,
  SET_CART_RECOMMENDED_SECTIONS_LOADING,
  GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING,
  CLEAR_CURATED_SECTIONS,
} from '../constants/RecommendationActionTypes';

const initialState = {
  newArrivals: [],
  recommendedDealsForYou: [],
  yourFavoriteCategories: [],
  relatedToYourViews: [],
  relatedToItemsInYourCart: [],
  frequentlyBoughtTogether: [],
  frequentlyBoughtTogetherSectionLoading: false,
  curatedSections:[],
  recommendedSectionsLoading: true,
  cartRecommendedSectionsLoading: true,
  recommendedDealsForYouLoading: true,
  yourFavoriteCategoriesLoading: true,
  relatedToYourViewsLoading: true,
  frequentlyBoughtTogetherLoading: true,
  curettedSectionsLoading: true,
  lastUserEventStatus: null,
};

const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NEW_ARRIVALS:
      return {
        ...state,
        newArrivals: payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedSectionsLoading: false,
      };

    case SEND_USER_EVENT:
      return {
        ...state,
        lastUserEventStatus: payload.status === 1 ? 'success' : 'error',
      };

    case SEND_PRODUCT_VIEW_EVENT:
      return {
        ...state,
        lastUserEventStatus: payload.status === 1 ? 'success' : 'error',
      };

    case SEND_ADD_TO_CART_EVENT:
      return {
        ...state,
        lastUserEventStatus: payload.status === 1 ? 'success' : 'error',
      };

    case SEND_PURCHASE_COMPLETE_EVENT:
      return {
        ...state,
        lastUserEventStatus: payload.status === 1 ? 'success' : 'error',
      };

    case GET_RECOMMENDED_DEALS_FOR_YOU:
      return {
        ...state,
        recommendedDealsForYou: payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedDealsForYouLoading: false,
      };

    case GET_CURETTED_SECTIONS:
      return {
        ...state,
        curatedSections: payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedSectionsLoading: false,
      };

    case CLEAR_CURATED_SECTIONS:
      return {
        ...state,
        curatedSections: [],
        recommendedSectionsLoading: true,
      };

    case GET_YOUR_FAVORITE_CATEGORIES:
      return {
        ...state,
        yourFavoriteCategories: payload.categories,
        recommendedDealsForYou: payload.products,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedDealsForYouLoading: false,
        yourFavoriteCategoriesLoading: false,
      };

    case GET_RELATED_TO_YOUR_VIEWS:
      return {
        ...state,
        relatedToYourViews: payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedSectionsLoading: false,
      };

    case GET_FREQUENTLY_BOUGHT_TOGETHER:
      return {
        ...state,
        frequentlyBoughtTogetherSectionLoading : false,
        frequentlyBoughtTogether : payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedSectionsLoading: false,
      };


    case GET_RELATED_TO_ITEMS_IN_YOUR_CART:
      return {
        ...state,
        relatedToItemsInYourCart: payload,
        page: 0,
        hasMore: true,
        isLoading: false,
        recommendedSectionsLoading: false,
        cartRecommendedSectionsLoading: false,
      };

    
    case SET_RECOMMENDED_SECTIONS_LOADING:
      return {
        ...state,
        isLoading: true,
        recommendedSectionsLoading: payload,
        recommendedDealsForYouLoading: payload,
        yourFavoriteCategoriesLoading: payload,
        relatedToYourViewsLoading: payload,
        cartRecommendedSectionsLoading: payload,
        frequentlyBoughtTogetherSectionLoading: payload,
        curettedSectionsLoading: payload,
      }

    case SET_CART_RECOMMENDED_SECTIONS_LOADING:
      return {
        ...state,
        isLoading: true,
        cartRecommendedSectionsLoading: payload,
      }
    
    case GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING:
      return {
        ...state,
        isLoading: true,
        frequentlyBoughtTogetherSectionLoading: payload,
      }
   
    default: 
      return state;
  }
};

 

export default stateReducer;
