import React from 'react';

const ArrowUpIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      viewBox="0 0 40 40"
      width="40px"
      fill="#fff"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M11.2,25c0.7,0.7,1.7,0.7,2.3,0l6.5-6.5l6.5,6.5c0.7,0.7,1.7,0.7,2.3,0s0.7-1.7,0-2.3L21.2,15
	c-0.7-0.7-1.7-0.7-2.3,0l-7.7,7.7C10.5,23.3,10.5,24.3,11.2,25z"
      />
    </svg>
  );
};

export default ArrowUpIcon;
