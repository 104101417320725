export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_CURATED_SECTION_SUCCESS = 'GET_CURATED_SECTION_SUCCESS';
export const REMOVE_CURATED_SECTION = 'REMOVE_CURATED_SECTION';
export const TOGGLE_CALL_MODAL = 'TOGGLE_CALL_MODAL';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const SET_ACTIVE_ACCORDION = 'SET_ACTIVE_ACCORDION';
export const GET_DAILY_DEALS_SUCCESS = 'GET_DAILY_DEALS_SUCCESS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const LOAD_MORE_PRODUCTS = 'LOAD_MORE_PRODUCTS';
export const STOP_LOADING_MORE_PRODUCTS = 'STOP_LOADING_MORE_PRODUCTS';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION';
export const SET_UPDATE_LAST_SCROLL_POSITION =
  'SET_UPDATE_LAST_SCROLL_POSITION';
export const SET_SCROLL_TO_LAST_POSITION = 'SET_SCROLL_TO_LAST_POSITION';
export const SCROLL_TO_LAST_POSITION = 'SCROLL_TO_LAST_POSITION';
export const RESET_LOCATION = 'RESET_LOCATION';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const SCROLL_DATA_UI = 'SCROLL_DATA_UI';
export const SCROLL_DATA_CAT = 'SCROLL_DATA_CAT';
export const SET_PREVIOUS_PAGE_IS_PRODUCT = 'SET_PREVIOUS_PAGE_IS_PRODUCT';
export const LOADING_PRODUCTS_WITH_SAVED_PAGE =
  'LOADING_PRODUCTS_WITH_SAVED_PAGE';
export const LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE =
  'LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE';
export const RESET_SAVED_PAGE = 'RESET_SAVED_PAGE';
export const LOAD_PRODUCTS_WITH_SAVED_PAGE = 'LOAD_PRODUCTS_WITH_SAVED_PAGE';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRAND_PRODUCTS_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_SUCCESS = 'GET_BRAND_BY_ID_SUCCESS';
export const GET_BRAND_FEATURED_PRODUCTS_SUCCESS =
  'GET_BRAND_FEATURED_PRODUCTS_SUCCESS';
export const GET_CATEGORY_PRODUCTS_SUCCESS = 'GET_CATEGORY_PRODUCTS_SUCCESS';
export const GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS =
  'GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS';
export const GET_RELATED_PRODUCTS_SUCCESS = 'GET_RELATED_PRODUCTS_SUCCESS';
export const GET_FEATURED_SHOPS_SUCCESS = 'GET_FEATURED_SHOPS_SUCCESS';
export const RESET_SHOP_PRODUCTS = 'RESET_SHOP_PRODUCTS';
export const LOADING_SHOP_PRODUCTS = 'LOADING_SHOP_PRODUCTS';
export const GET_INALIPA_MEGASTORE_SUCCESS = 'GET_INALIPA_MEGASTORE_SUCCESS';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOP_PRODUCTS_SUCCESS = 'GET_SHOP_PRODUCTS_SUCCESS';
export const GET_SHOP_BY_SUBDOMAIN_SUCCESS = 'GET_SHOP_BY_SUBDOMAIN_SUCCESS';
export const SHOP_NOT_FOUND = 'SHOP_NOT_FOUND';
export const RESET_SHOP_NOT_FOUND = 'RESET_SHOP_NOT_FOUND';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const GO_TO_CHECKOUT = 'GO_TO_CHECKOUT';
export const LOAD_MORE_SEARCH_RESULTS = 'LOAD_MORE_SEARCH_RESULTS';
export const SELECT_VARIANT = 'SELECT_VARIANT';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const SELECT_OPTION = 'SELECT_OPTION';
export const RESET_VARIANTS_OPTION = 'RESET_VARIANTS_OPTION';
export const CHANGE_FILTER_STATE = 'CHANGE_FILTER_STATE';
export const CHANGE_SORT_STATE = 'CHANGE_SORT_STATE';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_FLYOUT_MENU = 'TOGGLE_FLYOUT_MENU';
export const SHOW_MOBILE_BACK_BUTTON = 'SHOW_MOBILE_BACK_BUTTON';
export const SET_PREVIOUS_PATHNAME = 'SET_PREVIOUS_PATHNAME';
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';

export const GET_FILTERS_OPTIONS_SUCCESS = 'GET_FILTERS_OPTIONS_SUCCESS';
export const GET_BRAND_FILTERS_OPTIONS_SUCCESS =
  'GET_BRAND_FILTERS_OPTIONS_SUCCESS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS';
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';
export const REMOVE_FILTER_TERM = 'REMOVE_FILTER_TERM';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_PAGINATION_ACTION = 'RESET_PAGINATION_ACTION';
export const SET_SORT_BY_OPTION = 'SET_SORT_BY_OPTION';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const RESET_SELECTED_PAGE = 'RESET_SELECTED_PAGE';
export const SELECT_RELATED_PRODUCT = 'SELECT_RELATED_PRODUCT';
export const RESET_SELECTED_RELATED_PRODUCT = 'RESET_SELECTED_RELATED_PRODUCT';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const RESET_FILTERS_OPTIONS = 'RESET_FILTERS_OPTIONS';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const LOAD_FILTER = 'LOAD_FILTER';
export const RESET_RELATED_PRODUCTS = 'RESET_RELATED_PRODUCTS';
export const RELATED_PRODUCTS_SECTION_LOADING =
  'RELATED_PRODUCTS_SECTION_LOADING';

export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS';
export const REFETCH_DELIVERY_OPTIONS_SUCCESS =
  'REFETCH_DELIVERY_OPTIONS_SUCCESS';
export const CLEAR_REFETCH__DELIVERY_OPTIONS =
  'CLEAR_REFETCH__DELIVERY_OPTIONS';
export const INITIATE_PAYMENT_PROCESS_SUCCESS =
  'INITIATE_PAYMENT_PROCESS_SUCCESS';
export const ADD_DELIVERY_INFO_SUCCESS = 'ADD_DELIVERY_INFO_SUCCESS';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const UPDATE_LAST_SCROLL_POSITION = 'UPDATE_LAST_SCROLL_POSITION';
export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE';
export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED';
export const NO_PAYMENT_RECEIVED = 'NO_PAYMENT_RECEIVED';
export const DISCOUNT_SUCCESS = 'DISCOUNT_SUCCESS';
export const DISCOUNT_FAIL = 'DISCOUNT_FAIL';
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT';
export const INITIATE_CARD_PAYMENT = 'INITIATE_CARD_PAYMENT';
export const CARD_PAYMENT_SUCCESS = 'CARD_PAYMENT_SUCCESS';
export const CARD_PAYMENT_FAIL = 'CARD_PAYMENT_FAIL';
export const RESET_PAYMENT_OPTIONS = 'RESET_PAYMENT_OPTIONS';
export const TOGGLE_ORDER_SUMMARY_ACCORDION = 'TOGGLE_ORDER_SUMMARY_ACCORDION';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS =
  'UPDATE_SHIPPING_ADDRESS_SUCCESS';

export const ENTER_SITE = 'ENTER_SITE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const RE_ORDER = 'RE_ORDER';
export const RESET_PAST_ORDERS = 'RESET_PAST_ORDERS';
export const SAVE_ORDER = 'SAVE_ORDER';
export const GET_PAST_ORDERS = 'GET_PAST_ORDERS';
export const CART_CREATED = 'CART_CREATED';
export const CART_WEB_SHARE_SUCCESS = 'CART_WEB_SHARE_SUCCESS';
export const PRODUCT_WEB_SHARE_SUCCESS = 'PRODUCT_WEB_SHARE_SUCCESS';
export const CART_ITEMS_RETRIEVED = 'CART_ITEMS_RETRIEVED';
export const RESET_CART_RETRIEVAL_STATUS = 'RESET_CART_RETRIEVAL_STATUS';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const RESET_CART_SHARING = 'RESET_CART_SHARING';
export const RESET_PRODUCT_SHARING = 'RESET_PRODUCT_SHARING';
export const SET_DELIVERY_DATETIME_SUCCESS = 'SET_DELIVERY_DATETIME_SUCCESS';
export const RESET_DELIVERY_DATETIME = 'RESET_DELIVERY_DATETIME';
export const RESET_DELIVERY_DATETIME_BY_SHOP =
  'RESET_DELIVERY_DATETIME_BY_SHOP';

export const ADD_SHOP_DELIVERY_ITEM = 'ADD_SHOP_DELIVERY_ITEM';
export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
export const INCREMENT_QUANTITY = 'INCREMENT_QUANTITY';
export const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';
export const CHANGE_TOTAL = 'CHANGE_TOTAL';
export const ADD_DELIVERY_DETAILS = 'ADD_DELIVERY_DETAILS';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAIL = 'ACTION_FAIL';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CHANGE_MODAL_STATUS = 'CHANGE_MODAL_STATUS';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const LOCATION_OUT_OF_BOUND = 'LOCATION_OUT_OF_BOUND';
export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const RESET_LOADING_LOCATION = 'RESET_LOADING_LOCATION';
export const RESTORE_DEFAULT_CONFIG = 'RESTORE_DEFAULT_CONFIG';
export const SELECT_SKU = 'SELECT_SKU';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LOCATION_PERMISSION_STATUS =
  'CHANGE_LOCATION_PERMISSION_STATUS';
export const SELECT_PAGE = 'SELECT_PAGE';

export const TOKEN_GENERATED = 'TOKEN_GENERATED';
export const LOGOUT = 'LOGOUT';

export const SET_TRACKING_NUMBER = 'SET_TRACKING_NUMBER';
export const RESET_TRACKING_NUMBER = 'RESET_TRACKING_NUMBER';

export const PAYMENT_ACTION_FAIL = 'PAYMENT_ACTION_FAIL';
export const PAYMENT_ACTION_SUCCESS = 'PAYMENT_ACTION_SUCCESS';
export const PAYMENT_RESET_STATE = 'PAYMENT_RESET_STATE';
