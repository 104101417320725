import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import {
  getBrandsAction,
  getProductsAction,
  loadProductAction,
  resetPaginationAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { FlyoutMenuWrapper } from '../styles/Filter';
import CloseIcon from '@material-ui/icons/Close';
import {
  changeFilterMenuState,
  changeSortMenuState,
} from '../../store/actions/uiAction';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const FlyoutSortMenu = (props) => {
  const menuIsOpen = useSelector((state) => state.uiManager.canShowSort);
  const selectedFIlters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );
  const sort = useSelector((state) => state.catalogManager.sortBy);
  const page = useSelector((state) => state.catalogManager.page);
  const [sortBy, setSortBy] = useState(props.value);
  const [canOpen, setCanOpen] = useState(props.canOpen);
  const canShowSort = useSelector((state) => state.uiManager.canShowSort);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    let selected = event.target.value;
    setSortBy(event.target.value);
    dispatch(setSortByAction(event.target.value));
    props.onChange(event.target.value);
    dispatch(resetPaginationAction());
    dispatch(loadProductAction());
    dispatch(
      getProductsAction({ ...selectedFIlters, sortBy: selected, page: 0 }),
    );
    dispatch(changeSortMenuState(false));
  };

  useEffect(() => {
    // dispatch(resetPaginationAction());
    dispatch(getCategoriesAction());
    dispatch(getBrandsAction());
  }, []);

  useEffect(() => {
    if (props.canOpen) {
      setCanOpen(props.canOpen);
    }
  }, [props.canOpen]);

  const closeMenu = () => {
    dispatch(changeSortMenuState(false));
    dispatch(changeFilterMenuState(false));
    setCanOpen(false);
    props.onCloseMenu(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (e.target.className === 'overlay-filter') { 
        closeMenu();
      }
    });
  }, [canShowSort]);


  return (
    <FlyoutMenuWrapper>
      {canOpen && <div class="overlay-filter"></div>}
      <div className={`flyoutFilterMenu ${canOpen ? 'show' : 'hide'}`}>
        <div className="w-100 p-2">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <button onClick={() => closeMenu()}>
                <CloseIcon />
              </button>
              {/* <h4 className="column pt-2">Sort</h4> */}
            </div>
            <div className="menuRow_reset"></div>
          </div>

          <FormControl component="fieldset">
            <FormLabel>Sort By</FormLabel>
            <RadioGroup
              aria-label="sortBy"
              name="sortBy"
              value={sort}
              onChange={(event) => handleChange(event)}
            >
              <FormControlLabel
                value="featured"
                control={<Radio />}
                label="Featured"
              />
              <FormControlLabel
                value="price:1"
                control={<Radio />}
                label="Price: Low to high"
              />

              <FormControlLabel
                value="price:-1"
                control={<Radio />}
                label="Price: High to low"
              />
              <FormControlLabel
                value="dateCreated:-1"
                control={<Radio />}
                label="New arrivals"
              />
              <FormControlLabel
                value="outOfStock:1"
                control={<Radio />}
                label="Stock Level: In stock"
              />
              {/* <FormControlLabel
                value="outOfStock:-1"
                control={<Radio />}
                label="Stock Level: Out of stock"
              /> */}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </FlyoutMenuWrapper>
  );
};

export default FlyoutSortMenu;
