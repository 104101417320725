import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { QuantityTogglerWrapper } from '../styles/slider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  addToCartAction,
  changeQuantityAction,
  removeItemAction,
  changeTotalAction,
} from '../../store/actions/cartAction';
import { Plugins } from '@capacitor/core';
import { sendAddToCartEventAction } from '../../store/actions/recommendationAction';

const { FirebaseAnalytics } = Plugins;

const QuantityToggler = ({ cartItem, hasVariant, canTrackInventory }) => {
  const [counter, setCounter] = useState(1);
  const [isQuantityInputEmpty, setIQuantityInputEmpty] = useState(false);
  const [product, setProduct] = useState(null);
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const totalObj = useSelector((state) => state.cartManager.totalObj);
  const quantityObj = useSelector((state) => state.cartManager.quantityObj);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const selectedProduct = useSelector(
    (state) => state.cartManager.selectedProduct,
  );
  const selectedOption = useSelector(
    (state) => state.cartManager.selectedOption,
  );

  useEffect(() => {
    let currentProduct = hasVariant ? selectedProduct : cartItem;

    setProduct(currentProduct);
  }, []);

  useEffect(() => {
    let currentProduct = hasVariant ? selectedProduct : cartItem;
    setProduct(currentProduct);
  }, [selectedOption]);

  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const handleIncrement = (cartQuantity) => {
    let quantity = cartQuantity;

    if (!cartQuantity) {
      quantity = 0;
    }

    if (isNaN(quantity) || quantity < 0) {
      return false;
    }

    quantity = parseInt(quantity + 1);
    setIQuantityInputEmpty(false);
    changeCartProductQuantity(quantity, 'add');
  };

  const handleDecrement = (cartQuantity) => {
    let quantity = parseInt(cartQuantity - 1);

    if (isNaN(quantity) || quantity < 0) {
      return false;
    }
    if (quantity === 0) {
      dispatch(removeItemAction(cartItem));
      changeCartProductQuantity(0, 'remove');
      setIsError(false);
    } else {
      changeCartProductQuantity(quantity, 'remove');
    }
  };

  const handleChange = (event, cartItem) => {
    let quantity = parseInt(event.target.value);

    if (!quantity) {
      setIQuantityInputEmpty(true);
    } else {
      let selected_sku = hasVariant ? selectedProduct.sku_id : cartItem.sku_id;

      if (quantityObj.hasOwnProperty(selected_sku)) {
        let isProductInCart = _.find(cartItems, ['sku_id', product.sku_id]);

        changeCartProductQuantity(
          quantity,
          quantity > isProductInCart.quantity ? 'add' : 'remove',
        );

        setIQuantityInputEmpty(false);
      } else {
        if (cartItem.stock && cartItem.stock < quantity) {
          setIsError(true);
          setMessage(`Sorry , only ${cartItem.stock} item(s) remains . `);
        } else {
          setIsError(false);
          cartItem['quantity'] = parseInt(quantity);
          cartItem['qty'] = parseInt(quantity);
          dispatch(addToCartAction(cartItem));
        }
      }

      //setIQuantityInputEmpty(false)
    }
  };

  const changeCartProductQuantity = (quantity, actionType) => {
    if (isNaN(quantity) || quantity < 0) {
      return false;
    }

    // console.log(
    //   'changeCartProductQuantity product.stock + quantity + product.track',
    //   product.stock,
    //   quantity,
    //   product.track,
    // );

    if (
      product &&
      (product.stock === 0 || product.stock < quantity) &&
      product.track
    ) {
      setIsError(true);
      setMessage(`Sorry , only ${cartItem.stock} item(s) remains . `);
    } else {
      setIsError(false);
      setCounter(quantity);
      let isProductInCart = _.find(cartItems, ['sku_id', product.sku_id]);
      if (isProductInCart) {
        let totalArr = Object.keys(totalObj).map((key) => totalObj[key]);
        totalArr[cartItem.sku_id] = quantity * cartItem.price;
        let total = totalArr.reduce((a, b) => a + b, 0);
        setTotal(total);
        dispatch(changeTotalAction(total));

        let addToCartEvenBody = {userEvent: {
          "eventType": "add-to-cart",
          "visitorId": localStorage.getItem('appNumber'),
          "productDetails": [
              {
                  "product": {
                      "id": cartItem.id
                  },
                  "quantity": {
                      "items": parseInt(quantity)
                  }
              }
          ]
        }};
        dispatch(sendAddToCartEventAction(addToCartEvenBody));

        dispatch(changeQuantityAction(cartItem, parseInt(quantity)));

        if (actionType === 'add') {
          window.dataLayer.push({
            cloud_retail: {
              eventType: 'add-to-cart',
              productDetails: [
                {
                  product: {
                    id: isProductInCart?.id,
                  },
                  quantity: isProductInCart?.quantity,
                },
              ],
            },
          });

          FirebaseAnalytics.logEvent({
            name: 'add_to_cart',
            params: {
              currency: 'TZS',
              value: isProductInCart.price,
              items: [
                {
                  item_id: isProductInCart?.id,
                  item_name: isProductInCart?.name,
                  affiliation: isProductInCart?.shopName,
                  item_brand: isProductInCart?.brand,
                  item_category: isProductInCart?.category,
                  quantity: quantity,
                },
              ],
            },
          });
        }

        if (actionType === 'remove') {
          FirebaseAnalytics.logEvent({
            name: 'remove_from_cart',
            params: {
              currency: 'TZS',
              value: isProductInCart.price,
              items: [
                {
                  item_id: isProductInCart?.id,
                  item_name: isProductInCart?.name,
                  affiliation: isProductInCart?.shopName,
                  item_brand: isProductInCart?.brand,
                  item_category: isProductInCart?.category,
                  quantity: isProductInCart.quantity - quantity,
                },
              ],
            },
          });
        }
      } else {
        dispatch(addToCartAction(cartItem));
      }
    }
  };

  return (
    <>
      {product && (
        <QuantityTogglerWrapper>
          {/* <code>{JSON.stringify(quantityObj)}</code> */}
          <ButtonGroup size="small" aria-label="small  button group">
            <Button
              onClick={(e) => handleDecrement(quantityObj[product.sku_id])}
              className="circular-button no-border-on-focus"
            >
              {quantityObj[product.sku_id] &&
              quantityObj[product.sku_id] === 1 ? (
                <FontAwesomeIcon icon={faTrash} />
              ) : (
                '-'
              )}
            </Button>

            <input
              type="number"
              name="counter"
              value={
                isQuantityInputEmpty
                  ? ''
                  : product.sku_id in quantityObj
                  ? quantityObj[product.sku_id]
                  : 0
              }
              onChange={(e) => handleChange(e, product)}
              className="border-0 text-center"
            />

            <Button
              onClick={(e) => handleIncrement(quantityObj[product.sku_id])}
              className="circular-button"
            >
              +
            </Button>
          </ButtonGroup>
          {isError && <div className="err">{message}</div>}
        </QuantityTogglerWrapper>
      )}
    </>
  );
};

export default QuantityToggler;
