import React from 'react';
import Fab from '@material-ui/core/Fab';
import CallIcon from '@material-ui/icons/Call';
import './Caller.css';
import ContactModal from '../../modals/ContactModal/ContactModal';
import ContactComponent from '../../modals/ContactModal/ContactComponent';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCallmodalAction } from '../../../store/actions/uiAction';
import { IconButton } from '@material-ui/core';

const Caller = () => {
  const isOpen = useSelector((state) => state.uiManager.isCallModalOpen);
  const handleContactPopUp = () => {
    dispatch(toggleCallmodalAction(true));
  };

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleCallmodalAction(false));
    // console.log('closeModal');
  };

  return (
    <div className="Caller">
      {!isOpen && (
        <Fab color="primary" aria-label="add" onClick={handleContactPopUp}>
          <CallIcon />
        </Fab>
      )}

      <ContactModal
        isOpen={isOpen}
        component={<ContactComponent />}
        setIsOpen={closeModal}
      />
    </div>
  );
};

export default Caller;
