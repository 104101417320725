import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductTile from './ProductTile';
import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import CircleRightArrow from '../../assets/icons/circleRightArrow';
import CircleLeftArrow from '../../assets/icons/circleLeftArrow';
import { VerticalScrollCard } from './VerticalScroll/VerticalScrollCard';
import { INALIPA_S3_URL } from '../../constants';
import placeholder from '../../assets/images/placeholders/item.png';
import useDrag from './VerticalScroll/useDrag';

const { FirebaseAnalytics } = Plugins;

const CategoriesVerticalSlider = ({
  categories,
}) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    scroll: true,
    // speed: 500,
    // slidesToScroll: 4,
    slidesToShow: 7.5,
    slidesToScroll: 5,
    swipe: true,
    nextArrow: (
      <div className="my-slick-arrow" display={'none'}>
         <CircleRightArrow/>
      </div>
    ),
    prevArrow: (
      <div className="my-slick-arrow" display={'none'} >
      <CircleLeftArrow />
   </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7.5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 4,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 3,
          dots: false,
          arrows: false,
        },
      },
    ],
  }
  return (
    <>
      <Slider {...settings}  className= "slick">
        {categories.map((category, index) => (
            <VerticalScrollCard
                title={category.name}
                itemId={category._id} // NOTE: itemId is required for track items
                key={category._id}
                image={category.image ? `${INALIPA_S3_URL}${category.image}` : placeholder}
                shape='circle'
                color='black'
                link={`/category/${category._id}`}
              />
          ))}
      </Slider>
    </>
  );
};

export default CategoriesVerticalSlider;
