import { ACTION_FAIL, CLEAR_CART } from '../constants/ActionTypes';

export const handleErrorAction = (error) => {
  // console.log(error);
  return {
    type: ACTION_FAIL,
    payload:
      error && error.response && error.response.data.message
        ? error.response.data.message
        : "Something wen't wrong , please try again",
  };
};

export const clearLocalStorageAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_CART,
    });
  };
};
