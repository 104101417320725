export const MERCANT_API = 'https://beta-merchantapi.inalipa.co.tz/v1';
export const INALIPA_S3_ALT_URL = 'https://s3.amazonaws.com/inalipa-live/';
export const INALIPA_S3_URL = 'https://prod-assets.inalipa.co.tz/';
export const API_KEY =
  'AKKSCIT6IAJSCIT6KTKSKV7CBLAsAKKSCIT6KELAC6KV7CBEKSCIT6LAIAJT';
export const APP_VERSION = '3.1.7';
export const APP_URL = 'https://inalipa.co.tz';
export const INALIPA_MEGASTORE_ID = '600a55b44b3a2f6f42280590';
export const CALLBACK_TIME = 180; // seconds
export const CHECK_INTERVAL_TIME = 10; // seconds
export const MAX_CHECKS = Math.ceil(CALLBACK_TIME / CHECK_INTERVAL_TIME); // seconds
export const DELIVERY_WAITING_TIME = 1; //hours
export const DELIVERY_BEFORE_CLOSING = 1; //hours
export const INALIPA_WORING_HOURS = { open: 8, close: 4 }; //hours
export const AUTOCOMPLETE_LIMIT = 50;
export const SEARCH_LIMIT = 8;
export const PRODUCTS_LIMIT = 8;
export const REVIEWS_LIMIT = 30;
export const MIN_CHECKOUT_VALUE = 300;
export const DEFAULT_CURRENCY = 'TZS';
