import React, {useState} from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { FormInputWrapper } from "../styles/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Rating from "@material-ui/lab/Rating";
import {PostReviewWrapper} from "../styles/Review";
import {submitProductReview, submitReview} from "../../services/reviewService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import {
    getProductReviewsAction,
    getProductReviewsCountAction,
    loadReviewsCountAction
} from "../../store/actions/reviewAction";

const validateProduct = values => {

    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    } else if (values.name.length > 40) {
        errors.name = 'Must be 40 characters or less';
    }
    if (!values.rating) {
        errors.rating = 'Required';
    } else if (values.rating < 0 && values.rating > 5) {
        errors.rating = 'Invalid rating';
    }
    return errors;

};

const validateOrder = values => {

    const errors = {};

    if (!values.rating) {
        errors.rating = 'Required';
    } else if (values.rating < 0 && values.rating > 5) {
        errors.rating = 'Invalid rating';
    }
    return errors;

};

const WriteReview = ({product, closeModal, order}) => {
    let serverError = false;
    const [isSubmitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: product ?
            {
                product: product._id,
                name: '',
                title: '',
                rating: '',
                comments: '',
            } :
            {
                transaction: order._id,
                rating: '',
                comments: '',
                stayAnonymous: ''
            },
        validate: product ? validateProduct : validateOrder,
        validateOnChange:false,
        validateOnBlur:false,
        onSubmit: (values) => {
            setSubmitting(true);
            let body = JSON.stringify(values, null, 2);
            let submit = product ? submitProductReview(body) : submitReview(body);
                submit.then((data) => {
                        setSubmitting(false)
                        if (product) {
                            dispatch(loadReviewsCountAction());
                            dispatch(getProductReviewsAction(product._id));
                            dispatch(getProductReviewsCountAction(product._id));
                        }
                        closeModal();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: data.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        setSubmitting(false);
                        serverError = true;
                    });
        },

    });

    return (
        <>
            <PostReviewWrapper>
                <div className="container">
                    <div className="row header">
                        <h3>Write a review</h3>
                        <div className="float-right">
                            <button
                                onClick={closeModal}
                                className="close-icon"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <hr/>
                    </div>
                    {serverError && (<div className="alert alert-danger">You have an error on your previous request.</div>)}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <FormInputWrapper>
                                <form className="generic-form" action="#" onSubmit={formik.handleSubmit}>
                                    {product && <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="name">Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter full name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                {formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                                            </div>
                                        </div>
                                    </div>}
                                    {product && <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="review">Review Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter review title"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.title}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="score">Score</label>
                                                <div className="rating">
                                                    <Rating
                                                        id="rating"
                                                        name="rating"
                                                        defaultValue={0}
                                                        fullSymbol="fa fa-star fa-3x"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.rating}
                                                    />
                                                    {formik.errors.rating ? <div className="error">{formik.errors.rating}</div> : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="name">Review (Optional)</label>
                                                <textarea
                                                    className="form-control"
                                                    id="comments"
                                                    name="comments"
                                                    placeholder="Enter message"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.comments}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {order && <div className="row">
                                        <div className="col-md">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="yes"
                                                    name="stayAnonymous"
                                                    id="stayAnonymous"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label className="form-check-label" htmlFor="stayAnonymous">
                                                    I want my review to stay anonymous
                                                </label>
                                                {formik.errors.stayAnonymous ? <div className="error">{formik.errors.stayAnonymous}</div> : null}
                                            </div>
                                        </div>
                                    </div>}
                                     <div className="row mt-2">
                                        <div className="col-md">
                                            {!isSubmitting &&<button className="btn-submit" disabled={isSubmitting}>
                                                Submit
                                            </button>}
                                            {isSubmitting && <div className="text-center"><CircularProgress /></div>}
                                        </div>
                                    </div>

                                </form>
                            </FormInputWrapper>
                        </div>
                    </div>
                </div>
            </PostReviewWrapper>
            </>
    );
}

export default WriteReview;