import React, { useEffect } from 'react';
import { scrollToTop } from '../../utils/window';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';

const NotificationMessage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <PageTitle title="Inalipa - Notication Message" />
      <SecondaryNav className="d-none d-sm-block" />
      <div className="row mt-3">
        <div className="col-xs-1 col-sm-2 col-md-1 mr-3"></div>
        <div className="col-xs-8 col-sm-7 col-md-8">
          <h6>Order Success</h6>
          <p>Your order SDESFGDS is successfully ......</p>
        </div>
      </div>
    </>
  );
};

export default NotificationMessage;
