import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CategoryWrapper } from '../../components/styles/general';
import { Link, useLocation } from 'react-router-dom';
import { INALIPA_S3_URL } from '../../constants';
import { getInalipaMegaStoreAction } from '../../store/actions/shopAction';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import OpeningHours from '../../components/ui/Shop/OpeningHours';
import { scrollToTop } from '../../utils/window';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { Plugins } from '@capacitor/core';
import ScrollButton from '../../components/ui/scrollButton';

const { FirebaseAnalytics } = Plugins;
// import { setPreviousUrlAction } from '../../store/actions/uiAction';

const InalipaMegaStore = () => {
  const inalipaMegastore = useSelector(
    (state) => state.shopManager.inalipaMegastore,
  );
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(getInalipaMegaStoreAction());
    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'inalipa_megastore',
      },
    });
  }, [dispatch]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <>
      <PageTitle title="Inalipa - Inalipa Megastore" />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: 'stores/', name: 'Stores' }}
        child={{ name: 'Inalipa Megastore' }}
      />
      <ScrollButton />
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-sm-3 col-md-4 col-lg-6 col-xl-7 pl-0 mb-3">
            <h2 className="mt-0 mb-0">Inalipa Megastore</h2>
          </div>
          {inalipaMegastore && inalipaMegastore.settings && (
            <OpeningHours shopSettings={inalipaMegastore.settings} />
          )}
        </div>
        <hr className="mt-0 mb-0" />
        <CategoryWrapper>
          <div className="row">
            {!inalipaMegastore.verticals && (
              <div className="alert alert-primary">No categories</div>
            )}
            {inalipaMegastore &&
              inalipaMegastore.verticals &&
              inalipaMegastore.verticals.length > 0 &&
              inalipaMegastore.verticals.map((vertical) => (
                <div className="column mt-3 pr-2 pl-2 text-center text-dark">
                  <Link to={`/megastore/${vertical._id}`}>
                    <div className="cat">
                      <img
                        src={`${INALIPA_S3_URL}categories/shops/${vertical.image}`}
                        className="img-thumb-same-height"
                        alt={vertical.name}
                      />
                      <strong>{vertical.name}</strong>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </CategoryWrapper>
      </div>
      {/*<AppModal*/}
      {/*    isOpen={modalIsOpen}*/}
      {/*    component={<OpeningHoursModal hours={inalipaMegastore.settings.opening_hours} closeModal={closeModal}/>}*/}
      {/*    setIsOpen={setIsOpen}*/}
      {/*/>*/}
    </>
  );
};

export default InalipaMegaStore;
