import { APP_VERSION } from '../constants';
import { useDispatch } from 'react-redux';
import { clearLocalStorageAction } from '../store/actions/utilsAction';
import _ from 'lodash';

export const useCheckVersion = () => {
  let storedAppVersion = localStorage.appVersion;
  let currentAppVersion = APP_VERSION;
  const dispatch = useDispatch();
  if (
    typeof localStorage.appVersion === 'undefined' ||
    localStorage.appVersion === null
  ) {
    // console.log('SET LOCAL STORAGE');
    localStorage.setItem('appVersion', APP_VERSION);
  }
  if (storedAppVersion !== currentAppVersion) {
    console.log('APP VERSION UPDATED ', storedAppVersion, currentAppVersion);
    localStorage.setItem('appVersion', APP_VERSION);
    dispatch(clearLocalStorageAction());
  }
};
