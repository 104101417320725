import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import moment from 'moment';
import '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
import { setMinutes, setHours, isToday } from 'date-fns';
import * as Yup from 'yup';
import 'yup-phone';
import { isValidPhoneNumber } from "tanzanian-phone-validator"; 
import _, { some } from 'lodash';
import {
  CheckoutWrapper,
  FormInputWrapper,
} from '../../components/styles/Form';
import LocationPicker from '../../components/map/LocationPicker';
import Picker from '../../components/map/Picker';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CurrentLocatioRetriever from '../../components/map/CurrentLocatioRetriever';
import {
  initiateTransactionAction,
  addDeliveryInfoAction,
  checkDiscountAction,
  clearDiscountsAction,
  initiateTransactionProcessAction,
  refetchDeliveryOptionsActions,
  clearRefetchDeliveryOptionsAction,
} from '../../store/actions/PaymentAction';
import {
  DEFAULT_CURRENCY,
  DELIVERY_WAITING_TIME,
  INALIPA_S3_ALT_URL,
  MERCANT_API,
  MIN_CHECKOUT_VALUE,
} from '../../constants';

import CustomDatePicker from '../../components/date/CustomDatePicker';
import CustomTimePicker from '../../components/date/CustomTimePicker';
import DeliveryByShopSelector from '../../components/delivery/DeliveryByShopSelector';
import OrderSummary from '../../components/order/OrderSummary/OrderSummary';
import { toggleFlyoutMenuAction } from '../../store/actions/uiAction';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/utils/PageTitle';
import {
  getCartItemsAction,
  resetDeliveryDatetimeAction,
  resetDeliveryDatetimeByShopAction,
  updateCartAction,
} from '../../store/actions/cartAction';
import { scrollToTop } from '../../utils/window';
import { timeTo24Hrs } from '../../utils/format';
import 'react-datepicker/dist/react-datepicker.css';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import {
  resetLoadingLocationAction,
  setLocationOutOfBoundAction,
} from '../../store/actions/locationAction';
import DeliveryOptions from './DeliveryOptions';
import { inalipaMethodHeaders } from '../../services/config';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { generateItemsListForFirebaseAnalytics } from '../../utils/analytics';
const { FirebaseAnalytics, FacebookAnalytics, Device } = Plugins;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color: '#00C400',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '1px',
    lineHeight: '21px',
  },
  time: {
    color: '#000000',
    fontSize: '13px',
    letterSpacing: '1px',
    lineHeight: '16px',
    paddingLeft: '33px',
    paddingBottom: '10px',
  },
}));

const CheckoutForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [discountCodeChanged, setDiscountCodeChanged] = useState(false);
  const [info, setInfo] = useState('');
  const NowHour = parseInt(moment().format('H'));
  const { t } = useTranslation('checkout');
  const [coordinates] = useState({
    lat: -6.7430267,
    lng: 39.2789343,
  });
  const NowMinute = parseInt(moment().format('m'));
  const [deliveryOption, setDeliveryOption] = React.useState('delivery');
  const [canRedirect, setCanRedirect] = useState();
  const savedTotal = useSelector((state) => state.cartManager.total);
  const [showDateTimeSelector, setShowDateTimeSelector] = useState(true);
  const [cartItemsByShop, setCartItemsByShop] = useState(null);
  const [showDeliverWhenAvailable, setShowDeliverWhenAvailable] = useState(
    false,
  );

  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState(
    new moment().add(DELIVERY_WAITING_TIME, 'h').toDate(),
  );
  const [isDiscountSuccess, setIsDiscountSuccess] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new moment().add(9, 'days').toDate());
  const [minTime, setMinTime] = useState({
    hour: parseInt(NowHour) + parseInt(DELIVERY_WAITING_TIME),
    min: NowMinute,
  });
  const [maxTime, setMaxTime] = useState({
    hour: 19,
    min: 0,
  });
  const [excludeTimes, setExcludeTimes] = useState([]);
  const [delivery, setDelivery] = useState(null);
  const [validDeliveryTimesByShops, setValidDeliveryTimesByShops] = useState(
    false,
  );
  const [autoDiscountCode, setAutoDiscountCode] = useState(null);
  const [fullNameInput, setFullNameInput] = useState(null);
  const [phoneNumberInput, setPhoneNumberInput] = useState(null);

  const canGetCurrentLocation = useSelector(
    (state) => state.stateManager.getCurrentLocation,
  );
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const cartID = useSelector((state) => state.cartManager.cartID);
  const selectedAddress = useSelector((state) => state.locationManager.address);
  const selectedEstablishment = useSelector(
    (state) => state.locationManager.establishment,
  );

  const deliverByShopInfo = useSelector(
    (state) => state.cartManager.deliverByShopInfo,
  );
  const markerPosition = useSelector(
    (state) => state.locationManager.markerPosition,
  );

  const deliveryInfo = useSelector(
    (state) => state.paymentManager.deliveryInfo,
  );

  const isDiscounted = useSelector(
    (state) => state.paymentManager.isDiscounted,
  );

  const transaction = useSelector((state) => state.paymentManager.transaction);
  const inalipaMegastore = useSelector(
    (state) => state.shopManager.inalipaMegastore,
  );
  let inalipaOpensAt =
    inalipaMegastore.settings &&
    inalipaMegastore.settings.opening_hours[0].timeFrom.split(':')[0];
  let inalipaclosesAt =
    inalipaMegastore.settings &&
    inalipaMegastore.settings.opening_hours[0].timeTo.split(':')[0];
  const discount = useSelector((state) => state.paymentManager.discount);

  const quantityObj = useSelector((state) => state.cartManager.quantityObj);
  const hasDiscountFailed = useSelector(
    (state) => state.paymentManager.hasDiscountFailed,
  );
  const message = useSelector((state) => state.paymentManager.message);
  const isSharedCartIncomplete = useSelector(
    (state) => state.cartManager.isSharedCartIncomplete,
  );
  const deliveryDatetime = useSelector(
    (state) => state.cartManager.deliveryDatetime,
  );
  const refetchedDeliveryOptions = useSelector(
    (state) => state.cartManager.refetchedDeliveryOptions,
  );
  const isLocationSaved = useSelector(
    (state) => state.locationManager.isLocationSaved,
  );
  const isLocationOutOfBound = useSelector(
    (state) => state.locationManager.isLocationOutOfBound,
  );

  const dispatch = useDispatch();

  const minCheckoutValue = MIN_CHECKOUT_VALUE;

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = /^(?:\+?255|0)[-.\s]?(6[1256789]|7[1345678])\d{7}$/;

  const classes = useStyles();

  useEffect(() => {
    if (discount) {
      setIsDiscountSuccess(true);
    } else {
      setIsDiscountSuccess(false);
    }
  }, [discount]);

  useEffect(() => {
    if (isSharedCartIncomplete) {
      dispatch(updateCartAction({ items: cartItems }, cartID));
    }
  }, [cartID, cartItems, dispatch, isSharedCartIncomplete]);

  useEffect(() => {
    let shopCartItems = _.groupBy(cartItems, (product) => {
      return product.shop;
    });
    setCartItemsByShop(shopCartItems);
    //checkDeliveryTime(deliveryDate);
  }, [cartItems, cartItems.length]);

  useEffect(() => {
    dispatch(initiateTransactionProcessAction(true));
    if (location.state && location.state.openCart) {
      dispatch(toggleFlyoutMenuAction(true));
    } else {
      dispatch(toggleFlyoutMenuAction(false));
    }
    let shopCartItems = _.groupBy(cartItems, (product) => {
      return product.shop;
    });
    dispatch(updateCartAction({ items: cartItems }, cartID));
    setCartItemsByShop(shopCartItems);
    dispatch(clearDiscountsAction());
    dispatch(getCartItemsAction(cartID));
    dispatch(initiateTransactionAction(cartID));
    dispatch(setLocationOutOfBoundAction(false));
    // checkDeliveryTime(deliveryDate);
    // const interval = setInterval(() => {
    //   console.log('interval');
    //   let location = {};
    //   if (markerPosition) {
    //     location = {
    //       location: {
    //         lat: markerPosition.lat,
    //         long: markerPosition.lng,
    //       },
    //     };
    //   }
    //   if (transaction) {
    //     let transactionId = transaction.transactionId;
    //     dispatch(
    //       refetchDeliveryOptionsActions(
    //         location ? { transactionId, location } : { transactionId },
    //       ),
    //     );
    //   }
    // }, 300000);
    scrollToTop();
    return () => {
      // clearInterval(interval);
      // dispatch(clearRefetchDeliveryOptionsAction());
      if (deliverByShopInfo) {
        Object.keys(deliverByShopInfo).map((key) => {
          dispatch(
            resetDeliveryDatetimeByShopAction(key, {
              store: deliverByShopInfo[key].store,
              deliveryDate: null,
              deliveryTime: null,
              deliveryDatetime: null,
            }),
          );
        });
      }
      dispatch(resetDeliveryDatetimeAction());
      dispatch(resetLoadingLocationAction());
    };
  }, []);

  useEffect(() => {
    if (transaction && cartItemsByShop) {
      // console.log('transaction', transaction);
      setDelivery(transaction.delivery);
      let assets = {};
      if (transaction.discount && transaction.discount.image) {
        assets = {
          imageUrl: `${INALIPA_S3_ALT_URL}web/banners/${transaction.discount.image}`,
        };
      } else {
        assets = {
          icon: 'info',
        };
      }

      if (transaction.discount && transaction.discount.available) {
        Swal.fire({
          ...assets,
          title: 'Discount',
          text: transaction.discount.promotionalMessage,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#00c400',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Apply Now',
        }).then((result) => {
          if (result.isConfirmed) {
            formik.setFieldValue('discount', transaction.discount.code);
            setAutoDiscountCode(transaction.discount.code);
            if (transaction && transaction.transactionId) {
              let data = {
                transactionId: transaction.transactionId,
                discountCode: transaction.discount.code,
              };
              dispatch(checkDiscountAction(data));
            }
          }
        });
      }
    }
    const interval = setInterval(() => {
      let location = {};
      if (markerPosition) {
        location = {
          location: {
            lat: markerPosition.lat,
            long: markerPosition.lng,
          },
        };
      }
      if (transaction) {
        let transactionId = transaction.transactionId;
        fetchNewDeliveryOptions(
          location ? { transactionId, location } : { transactionId },
        );
      }
    }, 300000);
    return () => {
      clearInterval(interval);
      dispatch(clearRefetchDeliveryOptionsAction());
    };
  }, [transaction]);

  const fetchNewDeliveryOptions = async (data) => {
    try {
      const deliveryOptions = await axios.post(
        `${MERCANT_API}/payments/delivery-options/${data.transactionId}`,
        data.location ? JSON.stringify(data.location) : null,
        inalipaMethodHeaders,
      );
      if (deliverByShopInfo) {
        Object.keys(deliverByShopInfo).map((key) => {
          dispatch(
            resetDeliveryDatetimeByShopAction(key, {
              store: deliverByShopInfo[key].store,
              deliveryDate: null,
              deliveryTime: null,
              deliveryDatetime: null,
            }),
          );
        });
      }
      dispatch(resetDeliveryDatetimeAction());
      setDelivery(deliveryOptions.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleError = (e) => {
    e.preventDefault();
    // scrollToTop();
  };

  const handleChange = (event) => {
    let selectedDeliveryOption = event.target.value;
    setDeliveryOption(selectedDeliveryOption);
    setDeliveryOption(selectedDeliveryOption);
    if (selectedDeliveryOption === 'delivery') {
      setShowDateTimeSelector(true);
      setShowDeliverWhenAvailable(false);
      if (deliverByShopInfo) {
        Object.keys(deliverByShopInfo).map((key) => {
          dispatch(
            resetDeliveryDatetimeByShopAction(key, {
              store: deliverByShopInfo[key].store,
              deliveryDate: null,
              deliveryTime: null,
              deliveryDatetime: null,
            }),
          );
        });
      }
      setValidDeliveryTimesByShops(false);
    }
    if (selectedDeliveryOption === 'partial') {
      dispatch(resetDeliveryDatetimeAction());
      setShowDeliverWhenAvailable(true);
      setShowDateTimeSelector(false);
    }
  };

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: fullNameInput
        ? fullNameInput
        : deliveryInfo &&
          deliveryInfo.shipping_address &&
          deliveryInfo.shipping_address.recipient_name
        ? deliveryInfo.shipping_address.recipient_name
        : '',
      phoneNumber: phoneNumberInput
        ? phoneNumberInput
        : deliveryInfo &&
          deliveryInfo.shipping_address &&
          deliveryInfo.shipping_address.phone
        ? deliveryInfo.shipping_address.phone
        : '',
      discount: autoDiscountCode ? autoDiscountCode : '',
      deliveryInstructions:
        deliveryInfo &&
        deliveryInfo.shipping_address &&
        deliveryInfo.shipping_address.instructions
          ? deliveryInfo.shipping_address.instructions
          : ' ',
      deliveryDatetime:
        deliveryOption === 'delivery' && deliveryDatetime
          ? deliveryDatetime
          : '',
      deliveryDatetimeByShops:
        // deliveryOption === 'partial' && validDeliveryTimesByShops
        deliveryOption === 'partial' ? deliverByShopInfo : '',
      location: isLocationSaved && !isLocationOutOfBound ? 'location' : '',
    },
    onSubmit: (values) => {
      scrollToTop();
      // console.log('values', values);

      if (discountCodeChanged) {
        if (values.discount && values.discount.length > 2) {
          if (transaction && transaction.transactionId) {
            let data = {
              transactionId: transaction.transactionId,
              discountCode: values.discount,
            };
            dispatch(checkDiscountAction(data));
          }
        }
        setDiscountCodeChanged(false);
      } else {
        if (cartItems.length === 0) {
          setError(true);
          setInfo(
            'You cannot proceed to pay with an empty cart . please continue shopping',
          );
          return false;
        } else {
          setError(false);
        }

        if (
          savedTotal < MIN_CHECKOUT_VALUE ||
          (discount &&
            discount.discount &&
            discount.discount.discounted &&
            discount.discount.discounted > 0 &&
            discount.discount.discounted < minCheckoutValue)
        ) {
          setError(true);
          setInfo(
            `You cannot checkout with order value of less than ${DEFAULT_CURRENCY} ${MIN_CHECKOUT_VALUE} . please continue shopping`,
          );

          return false;
        }
        if (!isDiscountSuccess) {
          document.getElementById('discount').value = '';
          values.discount = '';
        }
        //setCanRedirect(true);
        let deliveryInfo = {
          discountCode: autoDiscountCode ? autoDiscountCode : values.discount,
          shipping_address: {
            recipient_name: values.fullName,
            phone: values.phoneNumber,
            address: `${selectedEstablishment} , ${selectedAddress}`,
            instructions: values.deliveryInstructions,
            deliveryMethod: {
              name: deliveryOption,
              params: {
                location: {
                  latitude: markerPosition.lat,
                  longitude: markerPosition.lng,
                },
              },
            },
          },
        };

        if (deliveryOption === 'delivery') {
          deliveryInfo.shipping_address.deliveryMethod.params.deliveryDate = moment(
            deliveryDate,
          ).format('dddd , MMMM DD YYYY');
          deliveryInfo.shipping_address.deliveryMethod.params.deliveryTime = moment(
            deliveryTime,
          ).format('LT');
          if (deliveryDatetime) {
            deliveryInfo.shipping_address.deliveryMethod.params.deliveryDatetime = deliveryDatetime;
          }
        }
        if (deliveryOption === 'partial') {
          deliveryInfo.shipping_address.deliveryMethod.params.shops = Object.keys(
            deliverByShopInfo,
          ).map((key) => {
            deliverByShopInfo[key]['deliveryDate'] = moment(
              deliverByShopInfo[key]['deliveryDate'],
            ).format('YYYY-MM-DD');
            deliverByShopInfo[key]['deliveryTime'] = timeTo24Hrs(
              deliverByShopInfo[key]['deliveryTime'],
            );
            deliverByShopInfo[key]['deliveryDatetime'] = null;
            return deliverByShopInfo[key];
          });
        }

        dispatch(addDeliveryInfoAction(deliveryInfo));
        // FacebookAnalytics.logEvent({
        //   event: 'payment_initiated',
        //   params: {
        //     category: 'payment_initiated',
        //     action: 'success',
        //     label: 'payment_initiated',
        //     value: 2001,
        //   },
        // });

        let cartItemsForAnalytics = generateItemsListForFirebaseAnalytics(
          cartItems,
        );

        FirebaseAnalytics.logEvent({
          name: 'payment_initiated',
          params: {
            action: 'success',
            currency: 'TZS',
            value: savedTotal,
            items: cartItemsForAnalytics,
          },
        });
        history.push('/pay');
      }
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim('not a valid name')
        .required('Fullname is required '),
        phoneNumber: Yup.string()
        .matches(/^(?:\+?255|0)[-.\s]?(6[1256789]|7[1345678])\d{7}$/, 'Please enter a valid Tanzanian phone number')
        .required('Must enter a Phone number'),

      deliveryDatetime:
        deliveryOption === 'delivery'
          ? Yup.string().required('Delivery time is required')
          : null,
      deliveryDatetimeByShops:
        deliveryOption === 'partial'
          ? Yup.string()
              .test(
                'shopDeliveryTimesValidation',
                'Failed to validate shop times',
                function () {
                  //our logic for validation
                  let n = 0;
                  if (cartItemsByShop) {
                    Object.keys(cartItemsByShop).map((key) => {
                      if (deliverByShopInfo) {
                        if (deliverByShopInfo[key].deliveryDatetime) {
                          n++;
                        }
                      }
                    });
                  }
                  return Object.keys(cartItemsByShop).length === n;
                },
              )
              .required('Delivery time is required by shops')
          : null,
      location: Yup.string().required('Location error'),
    }),
  });
  // if (!_.isEmpty(formik.errors)) {
  //   scrollToTop();
  // }

  // console.log('cartItemsByShop  = ', cartItemsByShop);
  if (formik.errors) {
    // console.log('errors', formik.errors);
  }
  return (
    <>
      <PageTitle title="Inalipa - Checkout" />
      <SecondaryNav className="d-none d-sm-block" />

      {canGetCurrentLocation && <CurrentLocatioRetriever />}

      {/* {<code>{JSON.stringify(formik.errors)}</code>} */}
      <form
        onSubmit={formik.isValid ? formik.handleSubmit : (e) => handleError(e)}
      >
        <div className="container col-md-4 justify-content-center mb-5">
          <div className="row">
            <div
              className="col-md-8 offset-md-2"
              style={{ paddingLeft: '0', paddingRight: '0' }}
            >
              {error && <p className="alert alert-info mt-3">{info}</p>}
              <CheckoutWrapper>
                <FormInputWrapper>
                  <div className="row bg-gray pt-4 pb-4 mt-3">
                    <div className="col-md">
                      <h4>STEP 01 / 04</h4>

                      <OrderSummary />
                      <input
                        id="discount"
                        name="discount"
                        type="text"
                        placeholder="Enter discount code"
                        onChange={(e) => {
                          let code = e.target.value;
                          if (code) {
                            setDiscountCodeChanged(true);
                            setAutoDiscountCode(null);
                          } else {
                            setDiscountCodeChanged(false);
                          }

                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          // call the built-in handleBur
                          // handleBlur(e);
                          // and do something about e
                          let code = e.currentTarget.value;
                          if (code !== '' && code.length > 2) {
                            if (transaction && transaction.transactionId) {
                              let data = {
                                transactionId: transaction.transactionId,
                                discountCode: code,
                              };
                              dispatch(checkDiscountAction(data));
                            }
                            setAutoDiscountCode(code);
                          }
                        }}
                        value={formik.values.discount}
                        className={
                          isDiscountSuccess
                            ? 'success-outline'
                            : hasDiscountFailed
                            ? 'fail-outline'
                            : ''
                        }
                      />
                      {discount && (
                        <p className="alert alert-success mt-3">
                          {discount.description}
                        </p>
                      )}
                      {hasDiscountFailed && (
                        <p className="alert alert-danger mt-3">{message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md mb-3">
                      <h4>STEP 02 / 04</h4>
                      <h2>{t('delivery_information')}</h2>

                      <label htmlFor="fullName">{t('full_name')}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Enter full name"
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setFullNameInput(e.currentTarget.value);
                        }}
                        value={formik.values.fullName}
                      />
                      {formik.touched.fullName && formik.errors.fullName ? (
                        <div className="error ml-2 mt-2">
                          {formik.errors.fullName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2 mb-3">
                    <div className="col-md">
                      <label htmlFor="phoneNumber">{t('phone_number')}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder={t('enter_phone_number')}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setPhoneNumberInput(e.currentTarget.value);
                        }}
                        value={formik.values.phoneNumber}
                      />
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="error ml-2 mt-2">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-2 mb-3">
                    <div className="col-md">
                      {/* <Picker/> */}
                      <LocationPicker
                        center={coordinates}
                        height="300px"
                        zoom={9}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h4>STEP 03 / 04</h4>
                      <h6>{t('delivery_preference')}</h6>
                      {(deliveryOption === 'delivery' &&
                        !deliveryDatetime &&
                        formik.errors.deliveryDatetime) ||
                      (deliveryOption === 'partial' &&
                        formik.errors.deliveryDatetimeByShops) ? (
                        <time className={classes.time}>
                          <div className="error">
                            {formik.errors.deliveryDatetime ??
                              formik.errors.deliveryDatetimeByShops}
                          </div>
                        </time>
                      ) : null}
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="delivery-options"
                          name="delivery-options"
                          value={deliveryOption}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="delivery"
                            control={<Radio />}
                            label={t('deliver_together')}
                          />
                          {showDateTimeSelector && delivery && (
                            <>
                              {deliveryDatetime && (
                                <time className={classes.time}>
                                  {moment(deliveryDatetime).format('LLLL')}
                                </time>
                              )}
                              <DeliveryOptions
                                option={delivery.option_1}
                                method={deliveryOption}
                              />
                            </>
                          )}
                          <FormControlLabel
                            value="partial"
                            control={
                              <Radio
                                disabled={
                                  cartItemsByShop &&
                                  Object.keys(cartItemsByShop).length === 1
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={t('deliver_when_available')}
                          />
                          {delivery && showDeliverWhenAvailable && (
                            <DeliveryByShopSelector
                              shops={cartItemsByShop}
                              delivery={delivery.option_2}
                              method={deliveryOption}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-md">
                      <label htmlFor="discount">
                        {t('delivery_instructions')}
                      </label>
                      <textarea
                        className="form-control"
                        id="deliveryInstructions"
                        name="deliveryInstructions"
                        placeholder={t('enter_delivery_instructions')}
                        onChange={formik.handleChange}
                        value={formik.values.deliveryInstructions}
                      />
                    </div>
                  </div>
                </FormInputWrapper>
                {delivery && (
                  <>
                    <button
                      type="submit"
                      className="inalipa-btn-primary mt-3 mb-2"
                    >
                      {t('continue_to_payment')}
                    </button>
                    <div className="row">
                      <div className="col-md text-center">
                        {Object.keys(formik.errors).length > 0 ? (
                          <div className="error">
                            Please review your details above and submit again.
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
                {!delivery && (
                  <div className="mt-3 text-center">
                    <CircularProgress />
                  </div>
                )}
              </CheckoutWrapper>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
