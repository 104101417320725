import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { scrollToTop } from '../../utils/window';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: '18px 16px 24px 16px',
    color: '#555555',
    fontSize: 14,
  },
}))(MuiAccordionDetails);

const About = () => {
  const { t } = useTranslation('about');
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  scrollToTop();

  return (
    <>
      <PageTitle title="Inalipa - About Inalipa" />
      <SecondaryNav className="d-none d-sm-block" />
      <section id="content">
        <div className="full-page-overlay" />
        <div className="container mt-2">
          <div className="page-header">
            <h2 itemProp="headline">About Inalipa </h2>
          </div>
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{t('what_is_inalipa.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('what_is_inalipa.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>{t('looking_for_experience.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('looking_for_experience.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>{t('how_does_inalipa_work.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('how_does_inalipa_work.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>{t('what_stores_on_inalipa.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('what_stores_on_inalipa.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography>{t('what_payment_methods.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('what_payment_methods.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography>{t('make_international_payment.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('make_international_payment.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography>
                {t('buying_from_inalipa_or_partner_store.qn')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('buying_from_inalipa_or_partner_store.ans')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography>{t('any_charges.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('any_charges.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel9'}
            onChange={handleChange('panel9')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9d-content"
              id="panel9d-header"
            >
              <Typography>{t('track_order.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('track_order.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel10'}
            onChange={handleChange('panel10')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10d-content"
              id="panel10d-header"
            >
              <Typography>{t('order_for_someone_else.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('order_for_someone_else.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel11'}
            onChange={handleChange('panel11')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11d-content"
              id="panel11d-header"
            >
              <Typography>{t('how_delivery_work.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('how_delivery_work.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel12'}
            onChange={handleChange('panel12')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12d-content"
              id="panel12d-header"
            >
              <Typography>{t('contact_for_issues_or_queries.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('contact_for_issues_or_queries.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel13'}
            onChange={handleChange('panel13')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13d-content"
              id="panel13d-header"
            >
              <Typography>{t('leave_review.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('leave_review.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel14'}
            onChange={handleChange('panel14')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel14d-content"
              id="panel14d-header"
            >
              <Typography>{t('request_refund.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('request_refund.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel15'}
            onChange={handleChange('panel15')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15d-content"
              id="panel15d-header"
            >
              <Typography>{t('past_orders_to_re_order.qn')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('past_orders_to_re_order.ans')}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default About;
