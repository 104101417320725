// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  searchCatalog,
  searchAutocomplete,
} from '../../services/catalogService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SearcButton, SearchBox } from '../styles/Form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
// import { getSearchAutocompleteOptionsAction } from '../../store/actions/searchAction';
import { AUTOCOMPLETE_LIMIT } from '../../constants';

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

export default function InalipaAutocomplete() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const page = useSelector((state) => state.catalogManager.page);
  // const o = useSelector((state) => state.searchManager.autocompleteOptions);
  const { t } = useTranslation('search');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const loading = searchTerm && open && options.length === 0;
  // const [canGoBack, setCanGoBack] = useState(true);

  useEffect(() => {
    //console.log('InalipaAutocomplete page = ', page);
    return () => setSearchTerm('');
  }, []);

  useEffect(() => {
    // console.log('location', location.pathname);
    const path = location.pathname;
    const matchRoute = matchPath(path, {
      path: `/product/:id`,
    });
    if (path === `/results` || (matchRoute && path === matchRoute.url)) {
    } else {
      setSearchTerm('');
    }
  }, [location.pathname]);

  useEffect(() => {
    const closeButtonIcon = document.getElementsByClassName(
      'MuiAutocomplete-clearIndicator',
    )[0];

    if (searchTerm && searchTerm.length === 0) {
      closeButtonIcon.style.visibility = 'hidden';
      // dispatch(getSearchAutocompleteOptionsAction([]));
      setSearchTerm('');
      setOpen(false);
      setOptions([]);
    } else if (searchTerm && searchTerm.length > 0) {
      closeButtonIcon.style.visibility = 'visible';
    } else {
      closeButtonIcon.style.visibility = 'hidden';
      setSearchTerm('');
      setOpen(false);
      setOptions([]);
    }
  }, [searchTerm]);

  const onChangeHandle = async (value) => {
    // use the changed value to make request and then use the result. Which
    console.log('onChangeHandle', value);
    setSearchTerm(value);
    // const searchProducts = await searchCatalog(value, page, AUTOCOMPLETE_LIMIT);
    const response = await searchAutocomplete(value, page, AUTOCOMPLETE_LIMIT);

    const autocompleteOptions = [];

    const products =
      response?.data?.data && response?.data?.data?.products
        ? response.data.data.products
        : [];

    const totalProducts =
      response?.data?.data && response?.data?.data?.totalProducts
        ? response.data.data.totalProducts
        : 0;

    if (totalProducts > 0) {
      autocompleteOptions.push({
        title: `See all results (${totalProducts})`,
        value: `results?search_query=${value}`,
        storeName: 'search',
        storePath: 'search',
      });

      products.map((product) =>
        autocompleteOptions.push({
          title: product.name,
          value: product._id,
          storeName:
            product && product.shop && product.shop.name
              ? product.shop.name === 'Inalipa Store' ||
                product.shop.name === 'Inalipa Megastore'
                ? 'Inalipa Megastore'
                : product.shop.name
              : null,
          storePath:
            product && product.shop && product.shop.subDomain
              ? product.shop.name === 'Inalipa Store' ||
                product.shop.name === 'Inalipa Megastore'
                ? '/stores/megastore'
                : `/stores/${product.shop.subDomain}`
              : null,
        }),
      );
    }
    setOptions(autocompleteOptions);
  };

  const checkInputChange = (event, value, reason) => {
    if (!value || value === '') {
      //history.goBack();
    }
    if (reason === 'clear') {
      setSearchTerm('');
      const closeButtonIcon = document.getElementsByClassName(
        'MuiAutocomplete-clearIndicator',
      )[0];
      closeButtonIcon.style.visibility = 'hidden';
    }
  };

  const onProductChange = (event, values, reason) => {
    let isObject = _.isPlainObject(values);
    // let pathname = history.location.pathname.split('/')[1];
    // console.log('onProductChange values + pathname', values, pathname);

    if (isObject) {
      let previousRoutes = {};
      if (values.storeName === 'search') {
        let term = values.value.split('search_query=').pop();
        setSearchTerm(term);
        history.push(`/${values.value}`);
        previousRoutes = {
          parent: { name: 'Stores', link: '/' },
          coParent: { name: values.storeName, link: '/' + values.storePath },
        };
      } else {
        setSearchTerm(values.title);
        history.push(`/product/${values.value}`);
        previousRoutes = {
          parent: { name: 'Stores', link: '/' },
          coParent: { name: values.storeName, link: '/' + values.storePath },
        };
      }
      dispatch(setPreviousPathnameAction(previousRoutes));
      document.activeElement.blur();
    }

    // if (values == null) {
    //   history.push(`/`);
    // } else {
    //   history.push(`/results?search_query=${values}`);
    // }
  };

  const setInputValue = () => {
    return searchTerm.length > 0 ? searchTerm : '';
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    // if (searchTerm.length === 0) {
    //   dispatch(getSearchAutocompleteOptionsAction([]));
    // }
  }, [open]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <SearchBox>
      <div className="search-input-group">
        <Autocomplete
          inputValue={searchTerm}
          style={{ width: 300, fontSize: '16px' }}
          freeSolo
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.title}
          options={searchTerm.length > 0 ? options : []}
          filterOptions={(options) => options}
          loading={loading}
          onChange={onProductChange}
          onInputChange={checkInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('search_placeholder')}
              style={{ fontSize: '16px' }}
              className="form-control"
              InputLabelProps={{ shrink: false }}
              onChange={(ev) => {
                if (ev.target.value !== '' || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && !searchTerm ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              onKeyDown={(e) => {
                let code;
                if (e.key !== undefined) {
                  code = e.key;
                } else if (e.keyIdentifier !== undefined) {
                  code = e.keyIdentifier;
                } else if (e.keyCode !== undefined) {
                  code = e.keyCode;
                }

                if ((code === 13 || code === 'Enter') && e.target.value) {
                  history.push(`/results?search_query=${e.target.value}`);
                }
              }}
            />
          )}
          // debug={process.env.NODE_ENV === 'development'}
        />
        <div className="input-group-append">
          <Link
            to={
              searchTerm && searchTerm.length > 0
                ? `/results?search_query=${searchTerm}`
                : '#'
            }
          >
            <SearcButton disabled={searchTerm && searchTerm.length === 0}>
              <FontAwesomeIcon icon={faSearch} color="white" />
            </SearcButton>
          </Link>
        </div>
      </div>
    </SearchBox>
  );
}
