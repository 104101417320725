import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../ui/NavBar';

import IosStatusBar from '../utils/IosStatusBar/IosStatusBar';

const Header = () => {
  const message = useSelector((state) => state.stateManager.message);

  return (
    <>
      <NavBar />
    </>
  );
};

export default Header;
