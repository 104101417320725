import axios from 'axios';
import { MERCANT_API, API_KEY } from '../constants';
import { inalipaMethodHeaders } from './config';

export const getCartItems = async (cartId) => {
  const result = await axios
    .get(`${MERCANT_API}/shopping-carts/${cartId}/items`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const getPastCartItems = async (cartIds) => {
  const result = await axios
    .get(`${MERCANT_API}/shopping-carts/cart-items?carts=${cartIds}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const getPastOrders = async (body) => {
  return await axios
    .post(
      `${MERCANT_API}/customer-sessions/past-orders`,
      body,
      inalipaMethodHeaders,
    )
    .then((data) => data);
};
