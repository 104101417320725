import React, {useEffect} from "react";
import ProductsSliderSkeleton from "../skeletons/elements/products/ProductsSliderSkeleton";
import {filterOutOfStock} from "../../utils/productFiltering";
import RecommendedProductSlider from "../ui/RecommendedProductSlider";
import RecommendedCategoriesVerticalSlider from "../ui/RecommendedCategoriesVerticalSlider";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    getCurettedSectionsAction,
    getNewArrivalsAction,
    getRelatedToItemsInYourCartAction,
    getRelatedToYourViewsAction,
    getYourFavoriteCategoriesAction,
    sendUserEventAction,
    setRecommendedSectionsLoadingAction
} from "../../store/actions/recommendationAction";
import {getDailyDealsAction} from "../../store/actions/catalogAction";
import {generateItemsObjectForRelatedToCart} from "../../utils/analytics";
import {useLocation} from "react-router-dom";

const CuratedSections = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('home');

    const dailyDeals = useSelector((state) => state.catalogManager.dailyDeals);
    const filteredDailyDeals = [];
    if (dailyDeals.length > 0) {
        for (let i = 0; i < dailyDeals.length; i++) {
            if (dailyDeals[i].pricePromotion !== undefined) {
                if (dailyDeals[i].pricePromotion.prices.retail.old) {
                    filteredDailyDeals.push(dailyDeals[i]);
                }
            }
        }
    }

    const newArrivals = useSelector(
        (state) => state.recommendationManager.newArrivals,
    );
    const recommendedDealsForYou = useSelector(
        (state) => state.recommendationManager.recommendedDealsForYou,
    );
    const yourFavoriteCategories = useSelector(
        (state) => state.recommendationManager.yourFavoriteCategories,
    );
    const relatedToYourViews = useSelector(
        (state) => state.recommendationManager.relatedToYourViews,
    );
    const relatedToItemsInYourCart = useSelector(
        (state) => state.recommendationManager.relatedToItemsInYourCart,
    );
    const recommendedSectionsLoading = useSelector(
        (state) => state.recommendationManager.recommendedSectionsLoading,
    );
    const curatedSections = useSelector(
        (state) => state.recommendationManager.curatedSections,
    );
    const cartItems = useSelector((state) => state.cartManager.cartItems);
    const cartFlyoutOpen = useSelector(
        (state) => state.uiManager.isFlyoutMenuOpen,
    );

    const switchRecommendedSections = (sectionName) => {
        switch (sectionName) {
            case 'New Arrivals':
                return newArrivals?.length > 0 ? newArrivals : [];
            case 'Daily Deals':
                return filteredDailyDeals?.length > 0 ? filteredDailyDeals : [];
            case 'Recommended For You':
                return recommendedDealsForYou?.length > 0 ? recommendedDealsForYou : [];
            case 'Your Recent Views':
                return relatedToYourViews?.length > 0 ? relatedToYourViews : [];
            case 'Recommended Categories For You':
                return yourFavoriteCategories?.length > 0 ? yourFavoriteCategories : [];
            case 'Related To Items In Your Cart':
                return cartItems.length > 0 ? relatedToItemsInYourCart : [];
            default:
                return [];
        }
    };

    useEffect(() => {
        dispatch(getCurettedSectionsAction());
        dispatch(setRecommendedSectionsLoadingAction(true));
        if (!cartItems) {
            dispatch(setRecommendedSectionsLoadingAction('cart-empty'));
        }
        dispatch(getDailyDealsAction());
        dispatch(getNewArrivalsAction());
        dispatch(getYourFavoriteCategoriesAction());
        if (cartItems.length > 0) {
            let productsInCartDetails = generateItemsObjectForRelatedToCart(
                cartItems,
            );
            dispatch(
                getRelatedToItemsInYourCartAction({
                    placementId: 'frequently-bought-together',
                    userEvent: {
                        eventType: 'add-to-cart',
                        visitorId: localStorage.getItem('appNumber'),
                        productDetails: productsInCartDetails,
                    },
                }),
            );
        }
        dispatch(
            sendUserEventAction({
                userEvent: {
                    eventType: 'home-page-view',
                    visitorId: localStorage.getItem('appNumber'),
                },
            }),
        );
        dispatch(
            getRelatedToYourViewsAction({
                placementId: 'recently_viewed_default',
                userEvent: {
                    eventType: 'home-page-view',
                    visitorId: localStorage.getItem('appNumber'),
                },
            }),
        );
    }, [location.pathname, dispatch]);

    return (
        <>
            {recommendedSectionsLoading && (
                <>
                    <ProductsSliderSkeleton link={true} header={true} />
                    <ProductsSliderSkeleton link={true} header={true} />
                    <ProductsSliderSkeleton link={true} header={true} />
                </>
            )}
            {!recommendedSectionsLoading &&
                curatedSections &&
                curatedSections?.length > 0 &&
                curatedSections?.map((curatedSection, index) => (
                    <>
                        {curatedSection.name !== 'Recommended Categories For You' && (
                            <>
                                {/* <div className="horizontal-line hidden-mobile" /> */}
                                {filterOutOfStock(curatedSection.products).length > 0 &&
                                    curatedSection.type !== 'recommendations' && (
                                        <div className="container-full">
                                            <RecommendedProductSlider
                                                title={t(curatedSection.name)}
                                                products={filterOutOfStock(curatedSection.products)}
                                                link={`/curated-products/${curatedSection._id}`}
                                                linkTitle={t('see_all')}
                                            />
                                        </div>
                                    )}
                                {curatedSection.type === 'recommendations' &&
                                    switchRecommendedSections(curatedSection.name).length >
                                    0 && (
                                        <div className="container-full">
                                            {curatedSection.name === 'Daily Deals' && (
                                                <RecommendedProductSlider
                                                    title={t('daily_deals')}
                                                    products={filterOutOfStock(filteredDailyDeals)}
                                                    link="/daily-deals"
                                                    linkTitle={t('see_all')}
                                                />
                                            )}
                                            {curatedSection.name !== 'Daily Deals' &&
                                                curatedSection.name !==
                                                'Related To Items In Your Cart' && (
                                                    <RecommendedProductSlider
                                                        title={t(curatedSection.name)}
                                                        products={
                                                            curatedSection.name === 'Your Recent Views'
                                                                ? switchRecommendedSections(
                                                                    curatedSection.name,
                                                                )
                                                                : filterOutOfStock(
                                                                    switchRecommendedSections(
                                                                        curatedSection.name,
                                                                    ),
                                                                )
                                                        }
                                                        link={`/recommended-page/${curatedSection.name
                                                            .replace(/\s+/g, '-')
                                                            .toLowerCase()}`}
                                                        linkTitle={t('see_all')}
                                                    />
                                                )}
                                            {curatedSection.name !== 'Daily Deals' &&
                                                curatedSection.name ===
                                                'Related To Items In Your Cart' &&
                                                filterOutOfStock(
                                                    switchRecommendedSections(curatedSection.name),
                                                ) &&
                                                !cartFlyoutOpen && (
                                                    <RecommendedProductSlider
                                                        title={t(curatedSection.name)}
                                                        products={filterOutOfStock(
                                                            switchRecommendedSections(curatedSection.name),
                                                        )}
                                                        link={`/recommended-page/${curatedSection.name
                                                            .replace(/\s+/g, '-')
                                                            .toLowerCase()}`}
                                                        linkTitle={t('see_all')}
                                                    />
                                                )}
                                        </div>
                                    )}
                            </>
                        )}
                        {curatedSection.name === 'Recommended Categories For You' &&
                            switchRecommendedSections(curatedSection.name).length > 0 && (
                                <div className="container-full text-white bg-orange">
                                    <h2 className="text-left pl white-text">
                                        {t('Recommended Categories For You')}
                                    </h2>
                                    <RecommendedCategoriesVerticalSlider
                                        categories={yourFavoriteCategories}
                                    />
                                </div>
                            )}
                    </>
                ))}
        </>
    );
};

export default CuratedSections;
