import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useGetCurrentLocation } from '../../utils/location';

const CurrentLocatioRetriever = () => {
  useEffect(() => {
    // console.log('CurrentLocatioRetriever called');
  }, []);
  useGetCurrentLocation();

  return (
    <h4 className="loading_location">
      <CircularProgress /> Getting current location...
    </h4>
  );
};

export default CurrentLocatioRetriever;
