import { GET_CATEGORIES_SUCCESS } from '../constants/ActionTypes';
import { getCategories, getCategory } from '../../services/categoryService';
import { handleErrorAction } from './utilsAction';

export const getCategoriesAction = (data) => {
  return function (dispatch) {
    return getCategories().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_CATEGORIES_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getCategoryAction = (id) => {
  return function (dispatch) {
    return getCategory(id).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_CATEGORIES_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};
