import axios from 'axios';
import { API_KEY, MERCANT_API, REVIEWS_LIMIT } from '../constants';
let initialPagination = `page=0&limit=${REVIEWS_LIMIT}`;

export const getReviews = async () => {
  return await axios
    .get(`${MERCANT_API}/reviews?${initialPagination}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getProductReviews = async (productId) => {
  return await axios
    .get(
      `${MERCANT_API}/product-reviews/product/${productId}?${initialPagination}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => data);
};

export const getMoreReviews = async (params) => {
  let pagination = `page=${params.page}&limit=${REVIEWS_LIMIT}`;
  let url = params.product
    ? `${MERCANT_API}/product-reviews/product/${params.product._id}`
    : `${MERCANT_API}/reviews`;
  return await axios
    .get(`${url}?${pagination}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const submitProductReview = async (body) => {
  return await axios
    .post(`${MERCANT_API}/product-reviews`, body, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const submitReview = async (body) => {
  return await axios
    .post(`${MERCANT_API}/reviews`, body, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getReviewsCount = async () => {
  return await axios
    .get(`${MERCANT_API}/reviews/count`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getProductReviewsCount = async (productId) => {
  return await axios
    .get(`${MERCANT_API}/product-reviews/count/${productId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};
