import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { DateTimeWrapper } from './date';
import {
  addShopToDeliverAction,
  resetDeliveryDatetimeAction,
  resetDeliveryDatetimeByShopAction,
  setDeliveryDatetimeAction,
} from '../../store/actions/cartAction';
import { set } from 'react-ga';
import { isNull } from 'lodash';
import moment from 'moment';

const CustomDeliveryOptionsTimePicker2 = ({
  onClick,
  options,
  shop,
  shopId,
}) => {
  const dispatch = useDispatch();
  const [day, setDay] = useState(null);
  const [times, setTimes] = useState([]);
  const [time, setTime] = useState(null);
  const [disableDatetimeSelection, setDisableDatetimeSelection] = useState(
    true,
  );

  useEffect(() => {
    // setDay(options[0].times[0].label);
    setTimes(options[0].times);
    // setTime(options[0].times[0].value);
    // if (shop) {
    //   let deliveryDateFormatted = moment(options[0].times[0].value).format(
    //     'dddd , MMMM DD YYYY',
    //   );
    //   let deliveryTimeFormatted = moment(options[0].times[0].value).format(
    //     'LT',
    //   );
    //   dispatch(
    //     addShopToDeliverAction(shop, {
    //       store: shopId,
    //       deliveryDate: deliveryDateFormatted,
    //       deliveryTime: deliveryTimeFormatted,
    //       deliveryDatetime: options[0].times[0].value,
    //     }),
    //   );
    // } else {
    // dispatch(setDeliveryDatetimeAction(options[0].times[0].value));
    // }
    if (shop) {
      dispatch(
        resetDeliveryDatetimeByShopAction(shop, {
          store: shopId,
          deliveryDate: null,
          deliveryTime: null,
          deliveryDatetime: null,
        }),
      );
    } else {
      dispatch(resetDeliveryDatetimeAction());
    }

    return () => {};
  }, []);

  const handleDayOptionChange = (event) => {
    if (shop) {
      dispatch(
        resetDeliveryDatetimeByShopAction(shop, {
          store: shopId,
          deliveryDate: null,
          deliveryTime: null,
          deliveryDatetime: null,
        }),
      );
    } else {
      dispatch(resetDeliveryDatetimeAction());
    }
    setDay(event.target.value);
    if (event.target.value) {
      setDisableDatetimeSelection(false);
    } else {
      setDisableDatetimeSelection(true);
      setTime('');
    }
    if (options) {
      options.map((dayOption) => {
        if (event.target.value === dayOption.label) {
          setTimes(dayOption.times);
          setTime('');
          // if (shop) {
          //   let deliveryDateFormatted = moment(
          //     options[0].times[0].value,
          //   ).format('dddd , MMMM DD YYYY');
          //   let deliveryTimeFormatted = moment(
          //     options[0].times[0].value,
          //   ).format('LT');
          //   dispatch(
          //     addShopToDeliverAction(shop, {
          //       store: shopId,
          //       deliveryDate: deliveryDateFormatted,
          //       deliveryTime: deliveryTimeFormatted,
          //       deliveryDatetime: options[0].times[0].value,
          //     }),
          //   );
          // } else {
          //    dispatch(setDeliveryDatetimeAction(dayOption.times[0].value));
          // }
        }
      });
    }
  };

  const handleTimeOptionChange = (event) => {
    setTime(event.target.value);
    if (shop) {
      let deliveryDateFormatted = moment(event.target.value).format(
        'dddd , MMMM DD YYYY',
      );
      let deliveryTimeFormatted = moment(event.target.value).format('LT');
      dispatch(
        addShopToDeliverAction(shop, {
          store: shopId,
          deliveryDate: deliveryDateFormatted,
          deliveryTime: deliveryTimeFormatted,
          deliveryDatetime: event.target.value,
        }),
      );
    } else {
      dispatch(setDeliveryDatetimeAction(event.target.value));
    }
  };

  return (
    <DateTimeWrapper>
      <select
        onChange={handleDayOptionChange}
        className="dateInput mr-3"
        value={day}
        type="text"
        placeholder="Choose Date"
        // disabled={onClick}
      >
        <option value="">Choose Date</option>
        {options &&
          options.map((dayOption, index) => (
            <>
              {dayOption && (
                <option value={dayOption.label}>{dayOption.label}</option>
              )}
            </>
          ))}
      </select>
      <select
        onChange={handleTimeOptionChange}
        className="dateInput"
        value={time}
        type="text"
        placeholder="Choose Time"
        disabled={disableDatetimeSelection}
      >
        <option value="">Choose Time</option>
        {times &&
          times.map((timeOption, index) => (
            <option value={timeOption.value}>{timeOption.label}</option>
          ))}
      </select>
    </DateTimeWrapper>
  );
};

export default CustomDeliveryOptionsTimePicker2;
