import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SearchBox } from '../../../../components/styles/Form';

function SearchBoxSkeleton() {
  return (
    <SearchBox>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 col-sm-12">
          <Skeleton className="searchBox_Skeleton" />
        </div>
      </div>
    </SearchBox>
  );
}

export default SearchBoxSkeleton;
