import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { BannerWrapper } from '../../../../components/styles/Banner';

function BannerSkeleton() {
  return (
    <BannerWrapper>
      <Skeleton className="banner__Skeleton" />
    </BannerWrapper>
  );
}

export default BannerSkeleton;
