import styled from 'styled-components';

export const NavBarWrapper = styled.div`
  .main-nav {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .brand-container {
    display: flex;
    align-items: center;
    order: 1;
  }

  .nav-toggler {
    display: flex;
    order: 0;
    margin-right: 19px;
  }
  .inalipa-nav-right {
    display: flex;
    justify-content: space-evenly;
    order: 1;
    align-items: center;
    min-width: 100px;
  }
  .header_search {
    display: flex;
    flex: 1 1;
    width: 100%;
    order: 4;
    min-width: 300px;
  }
  li.nav-item a {
    color: #212121;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .navFooter {
    background: #3c3f42;
  }
  svg {
    font-size: 26px;
  }
  .navbar-toggler {
    border: none;
  }
  button: focus {
    outline: none;
    border: none;
  }

  .inalipa-nav-link {
    color: #423f3f;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 21px;
    padding: 0px 25px;
  }

  .back-button {
    float: left;
    padding: 4px 10px 4px 0;
    display: none;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .header {
      padding: 0 40px;
    }
  }

  @media (min-width: 768px) {
    .header {
      width: 88%;
      margin: auto 70px;
      padding: 1rem 0 !important;
    }
    .header_search {
      order: 1;
    }
    .brand-container {
      width: inherit;
    }
    .inalipa-nav-right {
      width: inherit;
    }
    .header {
      // border-bottom: 1px solid #0000001a;
    }
  }

  @media (max-width: 575px) {
    .fixed-header {
      position: fixed;
      top: 0;
      background-color: #ffffff;
      z-index: 10000;
      padding-top: env(safe-area-inset-top);
    }
    .nav-toggler {
      order: 0;
    }
    .brand-container {
      order: 2;
    }
    .inalipa-nav-right {
      order: 3;
      min-width: 50px;
    }
  }

  @media (max-width: 1024px) {
    .back-button {
      display: inline-block;
    }
  }
`;

export const SecondaryNavWrapper = styled.div`
    border-bottom: 1px solid #DEE2E6;
    .container {
        margin-bottom: 1px solid #00C400;
    }
    
   a {
       color: #423F3F;
      font-family: "DM Sans";
      font-size: 16px;
      letter-spacing: 1px
      line-height: 21px;
      margin:0px 10px;
      padding-bottom: 1rem;
   }
   .active{
      color: #00C400;
      border-bottom: 2px solid #00C400;
      margin-bottom: 0;
   }
   .nav-item span {
      display:block;
      padding:6px 12px;
   }
   .nav-item select {
     border: none;
     padding-top: 8px;
     color: #423F3F;
   }
   .container-fluid {
     padding: 0 120px 0 120px;
   }
   @media(max-width: 575px){
    border-bottom: 1px solid #ffffff;
   }
   @media (min-width: 576px){
      .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: .1rem;
          padding-left: .1rem;
          font-size:14px;
          margin: 0px 3px;
      }
    }
   @media (min-width: 767px) {  
        .navbar-nav {
            //padding-left: 5px;
        }
        .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: .2rem;
          padding-left: .2rem;
          font-size:15px;
          margin: 0px 4px;
      }
        
      

   
   }
   @media (min-width: 992px) {  
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      font-size:16px;
      margin: 0px 10px;
  }
   }
`;

export const SideBarNavWrapper = styled.div`
  .MuiTypography-body1,
  p {
    font-family: 'DM Sans', sans-serif !important;
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
  }
  .side-bar-mobile-img-fluid {
    max-width: 50%;
    height: auto;
    padding-left: 16px;
  }
  p {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 300;
  }
  a {
    text-decoration: 'none';
  }
  span.MuiTypography-body1 {
    padding: 8px 16px;
  }
  .side-bar-mobile-language-header{
    height: 33px;
    color: #383D42;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    padding-top: 10px;
    }  
    .side-bar-mobile-section-container{
      flex-direction: column;
      padding-left: 17px;
      padding-top: 25px;
    }
    .side-bar-mobile-menu-container{
      flex-direction: column;
      padding-left: 0px;
      padding-top: 25px;
    }
    .side-bar-mobile-menu-container-item{
      padding-left: 17px;
      display: flex;
      height: 44px;
      align-items: center;
    }
    .MuiListItem-gutters {
      padding-left: 0px;
      padding-right: 0px;
    }
    
    .MuiTypography-body1 {
      color: rgba(56,61,66,0.7);
      font-family: 'DM Sans',sans-serif !important;
      font-size: 16px;
      font-weight: 100;
      letter-spacing: 0px;
      line-height: 18px;
    }

    a:hover {
      color: rgba(56,61,66,0.7);
      text-decoration: underline;
    }

    .active{
      color: #00C400;
      background: rgba(223, 247, 227);
 
      width: 100%;
      left: 0;
    }

    .inactive{
      color: rgba(56,61,66,0.7);
      width: 100%;
      left: 0;
    }

    .side-bar-mobile-page-content{
      margin-bottom: 10px;
      opacity: 100%
      padding-top: 10px;
      height: 100%;
    }
    .side-bar-mobile-page-icon{
      padding-right: 0.5em;
      padding-left: 0;
      position: absolute;
    }




    .horizontal-line{
      border-bottom: 1px solid #e5e5e5;
    }
`;

// export const BreadcrumbsWrapper = styled.div`
//   margin: 10px 0;
//   background-color: #c6eac6;
//   .MuiTypography-body1 {
//     font-size: 15px;
//     color: #767676;
//   }

//   .MuiSvgIcon-root {
//     color: #00c400;
//   }

//   a {
//     color: #767676;
//   }

//   @media (max-width: 575px) {
//     .fixed-breadcrumb {
//       position: fixed;
//       // top: 141px;
//       background-color: #ffffff;
//       z-index: 1000;
//       margin-top: -20px;
//     }
//   }
// `;

export const BreadcrumbsWrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
  background-color: #c6eac6 !important;

  /* Start hide scrolling bar*/
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  /* End hide scrolling bar*/

  .MuiTypography-body1 {
    color: #767676;
    font-size: 15px;
  }

  .MuiSvgIcon-root {
    color: #00c400;
  }

  .MuiBreadcrumbs-ol {
    height: 32px;
    flex-wrap: nowrap;
    padding: 8px 0 7px;
    // scroll-snap-align: start;
  }

  .MuiSvgIcon-root {
  }

  a {
    color: #767676;
  }

  @media (max-width: 575px) {
    .fixed-breadcrumb {
      position: fixed;
      // top: 141px;
      background-color: #c6eac6;
      z-index: 1000;
      margin-top: -10px;
    }
  }
`;
