import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { INALIPA_S3_URL } from '../../constants';
import { ActionButtonDangerRound, ActionButtonRound } from '../styles/Form';
import VariantsModal from '../modals/VariantsModal';
import ProductVariants from './ProductVariants';
import { ItemWrapper } from '../styles/slider';
import AddToCartButton from '../cart/AddToCartButton';
import placeholder from '../../assets/images/placeholders/item.png';
import logo from '../../assets/images/placeholders/new_logo.jpg';
import { numberWithCommas, roundOffNumber } from '../../utils/format';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  resetOptionAction,
  selectProductAction,
  // selectVariantAction,
} from '../../store/actions/cartAction';
// import LazyImage from './LazyLoadingImage';
// import LazyLoadImage from './Image/LazyLoadImage';
import useLazyLoadImage from '../../hooks/useLazyLoadImage';
import {
  selectedRelatedProductAction,
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const ProductTile = ({ productListItem, isRelatedProduct, index }) => {
  const history = useHistory();
  // const [mouseMoved, setMouseMoved] = useState(false);
  // const handleClick = () => {
  //     if (!mouseMoved) {
  //     history.push(`/product/${productListItem._id}`);
  //     }
  // };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [variations, setVariations] = useState(null);
  const location = useLocation();
  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );
  //const [selectedVariant, setSelectedVariant] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('product');

  useEffect(() => {
    // console.log('UseEffect ProductTile  = ', productListItem);
    let selectedProductPrice = productListItem.price;
    let selectedVariant = null;
    let oldPrice = null;
    let canTrackInventory = false;
    let isOutOfStock = productListItem.outOfStock;

    if (
      !productListItem.variations &&
      productListItem.pricePromotion &&
      productListItem.pricePromotion.prices &&
      productListItem.pricePromotion.prices.retail &&
      productListItem.pricePromotion.prices.retail.old
    ) {
      oldPrice = productListItem.pricePromotion.prices.retail.old;
    }
    if (
      productListItem.variations &&
      productListItem.variations.length === 0 &&
      productListItem.pricePromotion &&
      productListItem.pricePromotion.prices &&
      productListItem.pricePromotion.prices.retail &&
      productListItem.pricePromotion.prices.retail.old
    ) {
      oldPrice = productListItem.pricePromotion.prices.retail.old;
    }
    if (
      productListItem.variations &&
      productListItem.variations.variants &&
      productListItem.variations.variants.length === 0 &&
      productListItem.pricePromotion &&
      productListItem.pricePromotion.prices &&
      productListItem.pricePromotion.prices.retail &&
      productListItem.pricePromotion.prices.retail.old
    ) {
      oldPrice = productListItem.pricePromotion.prices.retail.old;
    }
    if (
      productListItem.variations &&
      productListItem.variations.variants &&
      productListItem.variations.variants.length > 0
    ) {
      let variantPrices = productListItem.variations.variants.map((variant) => {
        return { name: variant.name, price: variant.price };
      });
      let minProduct = _.minBy(variantPrices, 'price');
      let variants = productListItem.variations.variants;
      selectedVariant = _.find(variants, ['name', minProduct.name]);
      selectedProductPrice = minProduct.price;
      if (
        productListItem.pricePromotion &&
        productListItem.pricePromotion.variants &&
        productListItem.pricePromotion.variants.length > 0
      ) {
        let promotionPrices = productListItem.pricePromotion.variants.map(
          (variant) => {
            return {
              name: variant.name,
              old: variant.retail.old,
              new: variant.retail.new,
            };
          },
        );
        let minPromotionProduct = _.minBy(promotionPrices, 'new');
        if (minPromotionProduct.new === minProduct.price) {
          oldPrice = minPromotionProduct.old;
        }
      }

      //dispatch(selectVariantAction(selectedVariant));
    }
    if (
      productListItem.options &&
      productListItem.options.inventory &&
      productListItem.options.inventory.policy &&
      productListItem.options.inventory.policy === 'inalipa'
    ) {
      canTrackInventory = true;
      isOutOfStock = !(
        productListItem.options &&
        productListItem.options.inventory &&
        productListItem.options.inventory.quantity &&
        productListItem.options.inventory.quantity > 0
      );
    }

    const selectedProductItem = {
      name: productListItem.name,
      quantity: 1,
      qty: 1,
      id: productListItem._id,
      price: parseInt(selectedProductPrice),
      total: parseInt(selectedProductPrice),
      image: productListItem.images
        ? `products/${productListItem.images.main}`
        : selectedVariant && selectedVariant.picture
        ? `products/${selectedVariant.picture}`
        : placeholder,
      shop: productListItem.shop && productListItem.shop.subDomain,
      shopId: productListItem.shop && productListItem.shop._id,
      shopName: productListItem.shop && productListItem.shop.name,
      shopImage:
        productListItem.shop &&
        productListItem.shop.settings &&
        productListItem.shop.settings.logo &&
        productListItem.shop.settings.logo,
      preOrder:
        productListItem.options &&
        productListItem.options.preOrder &&
        productListItem.options.preOrder &&
        parseInt(productListItem.options.preOrder) > 0
          ? parseInt(productListItem.options.preOrder)
          : 0,
      shopOpeningHours:
        productListItem?.shop?.settings?.opening_hours,
      vat:
        productListItem.options && productListItem.options.vatExempted
          ? 0
          : parseFloat(selectedProductPrice - selectedProductPrice / 1.18),
      options: selectedVariant
        ? {
            variant: selectedVariant.name,
          }
        : '',
      stock:
        productListItem.options &&
        productListItem.options.inventory &&
        productListItem.options.inventory.quantity
          ? productListItem.options.inventory.quantity
          : null,
      sku_id: productListItem._id,
      oldPrice: oldPrice,
      track: canTrackInventory,
      isOutOfStock: isOutOfStock,
      brand: productListItem?.brand?.name,
      category: productListItem?.category?.name,
    };

    setProduct(selectedProductItem);
  }, [productListItem]);

  useEffect(() => {
    if (isFlyoutMenuOpen) {
      setIsOpen(false);
    }
  }, [isFlyoutMenuOpen]);

  const showOptions = (product, modifiedProduct) => {
    dispatch(selectProductAction(modifiedProduct));
    dispatch(resetOptionAction());
    setIsOpen(true);
    setVariations(product.variations);
  };

  const selectedRelatedProduct = (isRelatedProduct) => {
    if (isRelatedProduct) {
      dispatch(selectedRelatedProductAction());
      let previousRoutes = {
        parent: { name: 'Home', link: '/' },
        coParent: {
          name:
            productListItem.shop && productListItem.shop.name
              ? productListItem.shop.name === 'Inalipa Store' ||
                productListItem.shop.name === 'Inalipa Megastore'
                ? 'Inalipa Megastore'
                : productListItem.shop.name
              : null,
          link:
            productListItem.shop && productListItem.shop.subDomain
              ? productListItem.shop.name === 'Inalipa Store' ||
                productListItem.shop.name === 'Inalipa Megastore'
                ? '/stores/megastore'
                : '/stores/' + productListItem.shop.subDomain
              : null,
        },
        child: { name: product.name },
      };
      dispatch(setPreviousPathnameAction(previousRoutes));
      console.log('clicked');
      FirebaseAnalytics.logEvent({
        name: 'view_item_list',
        params: {
          item_list_name: isRelatedProduct ? 'Related products' : 'Daily deals',
          items: [
            {
              item_id: productListItem?.id,
              item_name: productListItem?.name,
              affiliation: productListItem?.shop?.name,
              item_brand: productListItem?.brand?.name,
              item_category: productListItem?.category?.name,
              currency: 'TZS',
              price: productListItem?.price,
            },
          ],
        },
      });
    }
  };
  let productImage =
    productListItem && productListItem.images && productListItem.images.main
      ? productListItem.images.main
      : null;
  let productPrice = null;
  if (
    productListItem.variations &&
    productListItem.variations.variants &&
    productListItem.variations.variants.length > 0
  ) {
    let variant = _.minBy(productListItem.variations.variants, 'price');
    productImage = variant.picture ?? productListItem.images.main;
    productPrice = variant.price;
  }
  let source = null;
  if (product && product.image) {
    source = `${INALIPA_S3_URL}${product.image}`;
  }
  const loaded = useLazyLoadImage(source);

  const addDefaultSrc = (ev) => {
    ev.target.src = logo;
  };
  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);

//   function displayPackagingUnitName(data) {
//     for (let i = 0; i < data.length; i++) {
//         if (data[i].serviceId === 'b2c') {
//             return data[i].packagingUnit.name;
//         }
//     }
//     return '';
// }

  return (
    <>
      <ItemWrapper>
        <Link
          // onMouseMove={() => setMouseMoved(true)}
          // onMouseDown={() => setMouseMoved(false)}
          // onMouseUp={() => handleClick()}
          to={`/product/${productListItem._id}`}
          onClick={(e) => {
            selectedRelatedProduct(isRelatedProduct);
          }}
        >
          <div className="item-image-wrapper">
            <img
              src={product && product?.image ? `${loaded || logo}` : logo}
              className="center-block img-responsive img-fluid"
              alt={productListItem?.name}
              height="120"
              width="120"
              onError={addDefaultSrc}
            />

            {productListItem.shop &&
              productListItem.shop.settings &&
              productListItem.shop.settings.logo && (
                <img
                  src={`${INALIPA_S3_URL}shops/${productListItem.shop.settings.logo}`}
                  alt={productListItem.shop.name}
                  className="img-fluid shopLogo"
                  height="120"
                  width="120"
                />
              )}
            {productListItem.options &&
              productListItem.options.preOrder &&
              productListItem.options.preOrder > 0 && (
                <button className="pre-order">Pre Order</button>
              )}

            {productListItem.shop &&
              productListItem.shop.settings &&
              productListItem.shop.settings.logo && (
                <img
                  src={`${INALIPA_S3_URL}shops/${productListItem.shop.settings.logo}`}
                  alt={productListItem.shop.name}
                  className="img-fluid shopLogo"
                />
              )}
            {productListItem.options &&
              productListItem.options.preOrder &&
              productListItem.options.preOrder > 0 && (
                <button className="pre-order">Pre Order</button>
              )}
          </div>

          <h3 className="line-2">{productListItem.name}</h3>
          {/* {productListItem.packaging && <p className="line-2">{displayPackagingUnitName(productListItem.packaging)}</p>} */}
          <h4 style={{ minHeight: '18px' }}>
            {product && product.oldPrice && (
              <strike className="discounted-price">
                TZS {numberWithCommas(product.oldPrice)}
              </strike>
            )}
          </h4>
          <div className="active-price mb-2">
            {product && product.price && (
              <div
                style={{
                  whiteSpace: 'nowrap',
                  fontSize:
                    productListItem &&
                    productListItem.variations &&
                    productListItem.variations.variants &&
                    productListItem.variations.variants.length > 0 &&
                    productListItem.pricePrepend
                      ? window.innerWidth > 575
                        ? '12px'
                        : '10px'
                      : '',
                }}
              >
                {productListItem &&
                  productListItem.variations &&
                  productListItem.variations.variants &&
                  productListItem.variations.variants.length > 0 &&
                  productListItem.pricePrepend && (
                    <h6
                      style={{
                        fontSize: window.innerWidth > 575 ? '12px' : '10px',
                        color: '#757575',
                        display: 'inline',
                      }}
                    >
                      {productListItem.pricePrepend}{' '}
                    </h6>
                  )}
                TZS {numberWithCommas(roundOffNumber(product.price, 0))}
              </div>
            )}
          </div>
        </Link>
        {product &&
          (product.track
            ? product.track && !product.isOutOfStock
            : !product.isOutOfStock) &&
          productListItem.variations &&
          productListItem.variations.variants &&
          productListItem.variations.variants.length > 0 && (
            <ActionButtonRound
              className="btn-brown"
              onClick={(e) => showOptions(productListItem, product)}
            >
              {t('see_options')}
            </ActionButtonRound>
          )}
        {/* {productListItem.variations &&
          productListItem.variations.variants &&
          productListItem.variations.variants.length === 0 && (
            <AddToCartButton cartItem={product} />
          )} */}

        {product &&
          (product.track
            ? product.track && !product.isOutOfStock
            : !product.isOutOfStock) &&
          !productListItem.variations && (
            <AddToCartButton
              cartItem={product}
              hasVariant={false}
              canTrackInventory={product.track}
            />
          )}
        {product &&
          (product.track
            ? product.track && !product.isOutOfStock
            : !product.isOutOfStock) &&
          productListItem.variations &&
          productListItem.variations.length === 0 && (
            <AddToCartButton
              cartItem={product}
              hasVariant={false}
              canTrackInventory={product.track}
            />
          )}
        {product &&
          (product.track
            ? product.track && !product.isOutOfStock
            : !product.isOutOfStock) &&
          productListItem.variations &&
          productListItem.variations.variants &&
          productListItem.variations.variants.length === 0 && (
            <AddToCartButton
              cartItem={product}
              hasVariant={false}
              canTrackInventory={product.track}
            />
          )}
        {product &&
          (product.track
            ? product.track && product.isOutOfStock
            : product.isOutOfStock) && (
            <ActionButtonDangerRound>
              {t('out_of_stock')}
            </ActionButtonDangerRound>
          )}
        {/*{product && !product.track &&*/}
        {/*  productListItem.options.inventory.quantity === 0 && (*/}
        {/*    <ActionButtonDangerRound>*/}
        {/*      {t('out_of_stock')}*/}
        {/*    </ActionButtonDangerRound>*/}
        {/*  )}*/}
        {/*{product && !product.track &&*/}
        {/*  productListItem.options.inventory.quantity === null && (*/}
        {/*    <ActionButtonDangerRound>*/}
        {/*      {t('out_of_stock')}*/}
        {/*    </ActionButtonDangerRound>*/}
        {/*  )}*/}
      </ItemWrapper>

      {product && (
        <VariantsModal
          isOpen={modalIsOpen}
          component={
            <ProductVariants
              product={product}
              variations={variations}
              closeModal={() => setIsOpen(false)}
              productListItem={productListItem}
            />
          }
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default ProductTile;
