import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import Header from './Header';
import Footer from './Footer';
import './AppLayout.css';
import { locationChangeAction } from '../../store/actions/uiAction';
const Layout = ({ children, ...rest }) => {
  let appHome_ios =
    Capacitor.isNative && Capacitor.platform === 'ios'
      ? 'appLayout__home__ios'
      : '';
  const targetRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // useLayoutEffect(() => {
  //   if (targetRef.current) {
  //     console.log(
  //       'targetRef.current.offsetHeight',
  //       targetRef.current.offsetHeight,
  //     );
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight,
  //     });
  //   }
  // }, []);

  const location = useLocation();
  const dispatch = useDispatch();
  
  useLayoutEffect(() => {
  //   if (targetRef.current) {
  //     console.log(
  //       'targetRef.current.offsetHeight',
  //       targetRef.current.offsetHeight,
  //     );
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight,
  //     });
  //   }
  // console.log("location from AppLayout :", location);
  dispatch(locationChangeAction(location))
  // console.log("selected filters to be set", location);
  // dispatch(setSelectedFilterAction(location));
  // console.log("selected filters set", location.state.selectedFilters);
  }, [location]);

  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );
  const menuIsOpen = useSelector((state) => state.uiManager.isMobileMenuOpen);
  const getMarginTop = () => {
    return window.innerWidth < 576 ? '130px' : '';
  };
  const getPaddingTop = () => {
    return window.innerWidth < 576 ? 'env(safe-area-inset-top)' : '';
  };
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <div
          className={
            isFlyoutMenuOpen || menuIsOpen
              ? 'bodyFade appLayout'
              : 'overflowUnset appLayout'
          }
        >
          <div id="header" ref={targetRef}>
            <Header />
          </div>

          <div
            className={
              location.pathname === '/'
                ? `appLayout__home ${appHome_ios}`
                : 'children'
            }
          >
            {children}
          </div>

          <div className="mb-5" />
          <Footer />
        </div>
      )}
    />
  );
};

export default Layout;
