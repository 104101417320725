import React from 'react';
import HeaderSkeleton from '../navigation/HeaderSkeleton';

function AppLayout({ children }) {
  return (
    <div>
      <HeaderSkeleton />
      <div>{children}</div>
    </div>
  );
}

export default AppLayout;
