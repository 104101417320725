export const generateItemsListForFirebaseAnalytics = (items) => {
  let newItemsArray = [];
  items.forEach((item) => {
    newItemsArray.push({
      item_id: item?.id,
      item_name: item?.name,
      affiliation: item?.shopName,
      item_brand: item?.brand,
      item_category: item?.category,
      price: item?.price,
      quantity: item?.quantity,
    });
  });
  return newItemsArray;
};

export const generateItemsListForCloudRetail = (items) => {
  let newItemsArray = [];
  items.forEach((item) => {
    newItemsArray.push({
      product: { id: item?.id },
      quantity: item?.quantity,
    });
  });
  return newItemsArray;
};

export const generateItemsObjectForRelatedToCart = (items) => {
  let productsInCartDetails = [];
  items.forEach((item) => {
    productsInCartDetails.push({
      "product": { id: item?.id },
      "quantity": {items: item?.quantity},
    });
  });
  return productsInCartDetails;
};
