import React, { useState } from 'react';
import { FilterGroupWrapper } from '../styles/Filter';
import { List } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterGroupListItem from './FilterGroupListItem';
import FilterGroupListItemWithRadioInput from './FilterGroupListItemWithRadioInput';

const FilterGroup = ({ options, name, title }) => {
  const [limit, setLimit] = useState(10);

  const filterInputType = () => {
    let inputType = '';
    switch (name) {
      case 'Vertical':
      case 'Category':
        inputType = 'Radio';
        break;
      case 'SubCategory':
      case 'Brand':
        inputType = 'Checkbox';
        break;
      default:
        inputType = 'None';
    }
    return inputType;
  };

  let currentIndex = 0;

  return (
    <FilterGroupWrapper>
      {/* <h2 className="ml-3">{title}</h2> */}

      <List>
        {options && filterInputType() === 'Radio' && (
          <FilterGroupListItemWithRadioInput options={options} name={name} />
        )}
        {options &&
          options.map((option, index) => {
            if (option) {
              const labelId = `checkbox-list-label-${option.value}`;
              currentIndex = currentIndex + 1;
              // if (filterInputType() === 'Radio') {
              //   return (
              //     <FilterGroupListItemWithRadioInput
              //       option={option}
              //       options={options}
              //       name={name}
              //       labelId={labelId}
              //       index={index}
              //       limit={limit}
              //     />
              //   );
              // }
              if (filterInputType() === 'Checkbox') {
                if (index < limit) {
                  return (
                    <FilterGroupListItem
                      option={option}
                      options={options}
                      name={name}
                      labelId={labelId}
                    />
                  );
                }
              }
            }
          })}
        {filterInputType() === 'Checkbox' && options && options.length > 10 && (
          <>
            {currentIndex > limit && (
              <p onClick={() => setLimit(options.length)}>
                <KeyboardArrowDownIcon /> Show More
              </p>
            )}
            {currentIndex === limit && (
              <p onClick={() => setLimit(10)}>
                <KeyboardArrowUpIcon />
                Show Less
              </p>
            )}
          </>
        )}
      </List>
    </FilterGroupWrapper>
  );
};

export default FilterGroup;
