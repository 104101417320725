import {
    GET_PRODUCT_REVIEWS,
    GET_PRODUCT_REVIEWS_COUNT,
    GET_REVIEWS,
    GET_REVIEWS_COUNT, LOAD_MORE_PRODUCT_REVIEWS, LOAD_MORE_REVIEWS,
    LOADING_REVIEWS, LOADING_REVIEWS_COUNT, RESET_REVIEWS_LOADING, STOP_LOADING_MORE_REVIEWS
} from "../constants/ReviewsActionTypes";
import {
    getMoreReviews,
    getProductReviews,
    getProductReviewsCount,
    getReviews,
    getReviewsCount
} from "../../services/reviewService";
import {handleErrorAction} from "./utilsAction";
import {getProducts} from "../../services/catalogService";
import {LOAD_MORE_PRODUCTS, STOP_LOADING_MORE_PRODUCTS} from "../constants/ActionTypes";

export const getReviewsAction = () => {
    return function(dispatch) {
        return getReviews().then(
            (response) => {
                if (response.status === 200) {
                    return dispatch({
                        type: GET_REVIEWS,
                        payload: response.data.data ? response.data.data : [],
                    });
                }
            },
            (error) => dispatch(handleErrorAction(error))
        );
    };
};

export const getProductReviewsAction = (productId) => {
    return function(dispatch) {
        return getProductReviews(productId).then(
            (response) => {
                if (response.status === 200) {

                    return dispatch({
                        type: GET_PRODUCT_REVIEWS,
                        payload: response.data.data ? response.data.data : [],
                    });
                }
            },
            (error) => dispatch(handleErrorAction(error))
        );
    };
};

export const getReviewsCountAction = () => {
    return function(dispatch) {
        return getReviewsCount().then(
            (response) => {
                if (response.status === 200) {

                    return dispatch({
                        type: GET_REVIEWS_COUNT,
                        payload: response.data.data ? response.data.data : [],
                    });
                }
            },
            (error) => dispatch(handleErrorAction(error))
        );
    };
};

export const getProductReviewsCountAction = (productId) => {
    return function(dispatch) {
        return getProductReviewsCount(productId).then(
            (response) => {
                if (response.status === 200) {

                    return dispatch({
                        type: GET_PRODUCT_REVIEWS_COUNT,
                        payload: response.data.data ? response.data.data : [],
                    });
                }
            },
            (error) => dispatch(handleErrorAction(error))
        );
    };
};

export const loadReviewsAction = (data) => {
    return function (dispatch) {
        return dispatch({
            type: LOADING_REVIEWS,
        });
    };
};

export const loadReviewsCountAction = (data) => {
    return function (dispatch) {
        return dispatch({
            type: LOADING_REVIEWS_COUNT,
        });
    };
};

export const resetReviewsAction = () => {
    return function (dispatch) {
        return dispatch({
            type: RESET_REVIEWS_LOADING,
        });
    };
};

export const loadMoreReviewsAction = (params) => {
    return function (dispatch) {
        return getMoreReviews(params).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.data && response.data.data.length > 0) {
                        return dispatch({
                            type: params.product ? LOAD_MORE_PRODUCT_REVIEWS : LOAD_MORE_REVIEWS,
                            payload: response.data.data ? response.data.data : [],
                        });
                    }
                    if (response.data.data && response.data.data.length === 0) {
                        return dispatch({
                            type: STOP_LOADING_MORE_REVIEWS,
                        });
                    }
                }
            },
            (error) => dispatch(handleErrorAction(error)),
        );
    };
};