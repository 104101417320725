import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ShareIcon from '@material-ui/icons/Share';
import RatingStars from '../ui/Reviews/RatingStars';
import { ProductWrapper } from '../styles/product';

function ProductDetailsSkeleton() {
  return (
    <ProductWrapper>
      <div
        className="container pl-0 pr-0 pt-0 pb-o"
        style={{ color: '#c6eac6', minWidth: '100%' }}
      >
        <SkeletonTheme color="#c6eac6">
          <Skeleton
            width={'100%'}
            height={30}
            style={{ borderRadius: '0px' }}
          />
        </SkeletonTheme>
      </div>
      <div className="row mt-3 pl-2">
        <div className=" col-md-6 text-center">
          <SkeletonTheme color="#f3f3f3">
            <Skeleton
              className="product-image-wrapper-skeleton"
              style={{ width: '90%' }}
            />
          </SkeletonTheme>
        </div>
        <div className="col-md-6 pl-2 pr-0 pt-4 text-left">
          <div className="row">
            <div className="col-3 pl-0 pr-0 text-left">
              <Skeleton style={{ height: '80%' }} />
            </div>
            <div className="col-9 pl-0 pr-0 text-right">
              <div className="row mt-2">
                <div className="col-8 text-right pl-0">
                  <RatingStars average={0} />
                </div>
                <div className="col-4 text-left pr-5 pl-0">
                  <Skeleton style={{ height: '80%' }} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 pr-0 pl-0">
              <Skeleton style={{ width: '60%', height: '90%' }} />
            </div>
            <div className="col-md-12 ">
              <div className="text-left">
                <div className="col-md-12 px-0">
                  <Skeleton style={{ width: '20%', height: '70%' }} />
                </div>
                <div className="col-md-12 px-0">
                  <Skeleton style={{ width: '20%', height: '90%' }} />
                </div>
                <SkeletonTheme color="#9dc49d">
                  <div
                    className="col-md-12 px-0"
                    style={{ textAlign: 'center' }}
                  >
                    <Skeleton
                      style={{
                        width: '60%',
                        height: '90%',
                        paddingBottom: '20px',
                        borderRadius: '20px',
                      }}
                    />
                  </div>
                </SkeletonTheme>
                <br />
              </div>
            </div>
          </div>
          <div className="row">
            <hr className="mt-3 mb-3" />
            <div className="col-md-12">
              <Skeleton style={{ width: '60%', height: '20%' }} />
              <Skeleton count={5} />
            </div>
          </div>
        </div>
      </div>
    </ProductWrapper>
  );
}

export default ProductDetailsSkeleton;
