import {
  GET_FEATURED_SHOPS_SUCCESS,
  GET_INALIPA_MEGASTORE_SUCCESS,
  GET_SHOP_BY_SUBDOMAIN_SUCCESS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOPS_SUCCESS,
  LOADING_SHOP_PRODUCTS,
  RESET_SHOP_PRODUCTS,
  SHOP_NOT_FOUND,
  RESET_SHOP_NOT_FOUND,
} from '../constants/ActionTypes';

const initialState = {
  featuredShops: [],
  shops: [],
  inalipaMegastore: {},
  shopProducts: [],
  shop: {},
  isLoading: false,
  isShopFound: true,
};

const shopReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FEATURED_SHOPS_SUCCESS:
      return { ...state, featuredShops: payload, isShopFound: true };
    case GET_SHOPS_SUCCESS:
      return { ...state, shops: payload };

    case GET_INALIPA_MEGASTORE_SUCCESS:
      return { ...state, inalipaMegastore: payload };
    case GET_SHOP_PRODUCTS_SUCCESS:
      return { ...state, shopProducts: payload, isLoading: false };
    case GET_SHOP_BY_SUBDOMAIN_SUCCESS:
      return { ...state, shop: payload, isShopFound: true };
    case RESET_SHOP_PRODUCTS:
      return { ...state, shopProducts: [], shop: {} };
    case LOADING_SHOP_PRODUCTS:
      return { ...state, isLoading: true };
    case SHOP_NOT_FOUND:
      return { ...state, isShopFound: false };
    case RESET_SHOP_NOT_FOUND:
      return { ...state, isShopFound: true };
    default:
      return state;
  }
};

export default shopReducer;
