import styled from 'styled-components';

export const VariantsWrapper = styled.div`
  // display: flex;
  // flex-direction: column;

  background: white;
  padding: 10px;
  width: 100%;

  h2 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 29px;
    text-align: center;
  }

  h5 {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .active {
    border: 1px solid #00c400 !important;
  }
  .variant-tile {
    flex-wrap: wrap;
  }
  .variant-tile > div {
    margin-right: 15px;
  }
  .variant-product-image-wrapper {
    position: relative;
  }
  .variant-product-image-wrapper img {
    display: block;
    margin: 0 auto;
    padding: 18px;
  }
  img.shopLogo {
    position: absolute;
    right: 0;
    top: 0;
    width: 65px;
    height: auto;
    z-index: 10;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .variant-product-image-wrapper img {
      max-width: 260px;
    }
    img.shopLogo {
      width: 85px;
    }
  }
`;
