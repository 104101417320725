import React from 'react';

const CircleRightArrow = ({active}) => {
    return(
<div className="arrow-container" style={{ borderRadius: '50%'}}>		
	<svg  xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"  >
	<path class="arrow-arrow"  d="M9,6.7c-0.4,0.4-0.4,1,0,1.4l3.9,3.9L9,15.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l4.6-4.6c0.4-0.4,0.4-1,0-1.4
		l-4.6-4.6C10,6.3,9.4,6.3,9,6.7z"/>
	</svg>
</div>
  );
};

export default CircleRightArrow;