import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInalipaMegaStoreAction,
} from '../../store/actions/shopAction';
import {
  resetFiltersAction,
  resetSavedPageAction,
  scrollDataActionCat,
} from '../../store/actions/catalogAction';
import Banner from '../../components/ui/Banner';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import PageTitle from '../../components/utils/PageTitle';
import { INALIPA_S3_URL } from '../../constants';
import BusinessCard from '../../components/ui/BusinessCard';
import InalipaLogo from '../../assets/images/inalipa-logo.png';
import { HomePageWrapper } from '../../components/ui/HomePage';
import { scrollToTop } from '../../utils/window';
import { Link, useLocation } from 'react-router-dom';
import {
  scrollDataActionUi,
  scrollToLastPositionAction,
  setPreviousPathnameAction,
} from '../../store/actions/uiAction';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import { resetOptionAction } from '../../store/actions/cartAction';
import CategoriesVerticalSlider from '../../components/ui/CategoriesVerticalSlider';
import CategoriesVerticalSliderSkeleton from '../../components/skeletons/elements/products/CategoriesVerticalSliderSkeleton';
import { fetchItemFromRemoteConfig } from '../../utils/firebase';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/errors/ErrorFallback';
import CuratedSections from "../../components/curatedSections/CuratedSections";

const { PUBLIC_URL } = process.env;

const HomePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const inalipaMegastore = useSelector(
    (state) => state.shopManager.inalipaMegastore,
  );
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const isLoading = useSelector((state) => state.catalogManager.isLoading);

  const [megastoreInfo, setMegastoreInfo] = useState(null);

  useEffect(() => {
    dispatch(scrollDataActionUi('reset'));
    dispatch(scrollDataActionCat('reset'));
    dispatch(getCategoriesAction());
    dispatch(getInalipaMegaStoreAction());
    dispatch(resetOptionAction());
    dispatch(resetFiltersAction());
    dispatch(scrollToLastPositionAction(false));

    let previousRoutes = {
      parent: { name: 'Home', link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));
  }, [cartItems, dispatch, location.pathname]);

  useEffect(() => {
    scrollToTop();
    dispatch(resetSavedPageAction());
  }, [dispatch]);

  const categories = useSelector((state) => state.categoryManager.categories);

  useEffect(() => {
    if (!megastoreInfo) {
      fetchInalipaMegastoreInfo();
    }
  }, [megastoreInfo]);

  const fetchInalipaMegastoreInfo = () => {
    fetchItemFromRemoteConfig('megastore')
      .then((data) => {
        setMegastoreInfo(data);
        // console.log('Megastore data', data);
      })
      .catch((err) => console.log('err', err.message));
  };

  return (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
    >
      <PageTitle title="Inalipa - all your shopping needs at your fingertips" />
      <SecondaryNav className="d-none d-md-block pl-0" />
      <Banner />
      <HomePageWrapper>
        {isLoading && (
          <div className="pt-3 pb-0">
            <CategoriesVerticalSliderSkeleton link={true} recommended={false} />{' '}
          </div>
        )}
        {!isLoading && categories && categories.length > 0 && (
          <>
            <div className="container-full pt-0 pb-0">
              <CategoriesVerticalSlider categories={categories} />
            </div>
          </>
        )}
        <CuratedSections />
        {megastoreInfo && megastoreInfo.show && (
          <div className="container mt-4">
            <h2>{megastoreInfo?.name}</h2>
            <div className="row">
              <div className="col-md-12 pl-0 pr-0">
                <Link to="/stores/megastore" style={{ textDecoration: 'none' }}>
                  <BusinessCard
                    settings={inalipaMegastore}
                    image={
                      inalipaMegastore &&
                      inalipaMegastore.settings &&
                      inalipaMegastore.settings.banner
                        ? `${megastoreInfo?.image}`
                        : `${PUBLIC_URL}images/megastore_banner.jpeg`
                    }
                    logo={
                      inalipaMegastore &&
                      inalipaMegastore.settings &&
                      inalipaMegastore.settings.logo
                        ? `${INALIPA_S3_URL}shops/${inalipaMegastore.settings.logo}`
                        : InalipaLogo
                    }
                    title={megastoreInfo?.title}
                    description={megastoreInfo?.description}
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </HomePageWrapper>
    </ErrorBoundary>
  );
};

export default HomePage;
