import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
import CurrencyFormat from 'react-currency-format';
import {
  removeItemAction,
  shareCartAction,
  resetCartSharingAction,
  createCartAction,
  resetCartRetrievalStatusAction,
  updateCartAction,
  createCartAndTransactionAction,
} from '../../store/actions/cartAction';
import { useLocation, useHistory } from 'react-router-dom';
import { CartWrapper } from '../styles/cart';
import { INALIPA_S3_URL } from '../../constants';
import QuantityToggler from '../ui/QuantityToggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCart } from '@fortawesome/free-solid-svg-icons';
import placeholder from '../../assets/images/placeholders/new_logo.jpg';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import { numberWithCommas } from '../../utils/format';
import { useTranslation } from 'react-i18next';
import { toggleFlyoutMenuAction } from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';
import { CircularProgress } from '@material-ui/core';
import ReactPixel from 'react-facebook-pixel';
import { generateItemsListForFirebaseAnalytics, generateItemsObjectForRelatedToCart } from '../../utils/analytics';
import { getRelatedToItemsInYourCartAction, setRecommendedSectionsLoadingAction } from '../../store/actions/recommendationAction';
const { FirebaseAnalytics, FacebookAnalytics, Device } = Plugins;

const Cart = () => {
  const [isCartCopied, setIsCartCopied] = useState(false);
  const [isCheckoutWithEmptyCart, setIsCheckoutWithEmptyCart] = useState(false);
  const cartMessageRef = useRef(null);
  const history = useHistory();

  const [isCartCopedFail, setIsCartCopedFail] = useState(false);
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const cartId = useSelector((state) => state.cartManager.cartID);
  const savedTotal = useSelector((state) => state.cartManager.total);
  const savedVAT = useSelector((state) => state.cartManager.vat);
  const isCartShared = useSelector((state) => state.cartManager.isCartShared);
  const itemsInCart = useSelector((state) => state.cartManager.itemsInCart);
  const itemsAfterCartModalIsOpen = useSelector(
    (state) => state.cartManager.itemsAfterCartModalIsOpen,
  );
  const message = useSelector((state) => state.cartManager.message);
  const quantityObj = useSelector((state) => state.cartManager.quantityObj);
  const isSharedCartIncomplete = useSelector(
    (state) => state.cartManager.isSharedCartIncomplete,
  );
  const cartMessage = useSelector((state) => state.cartManager.message);
  const checkoutMessage = useSelector(
    (state) => state.cartManager.checkoutMessage,
  );
  const isCartRetrieved = useSelector(
    (state) => state.cartManager.isCartRetrieved,
  );
  const canGoToCheckout = useSelector(
    (state) => state.cartManager.canGoToCheckout,
  );
  const cartID = useSelector((state) => state.cartManager.cartID);

  const location = useLocation();

  const [canCheckout, setCanCheckout] = useState(true);
  const [info, setInfo] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('cart');

  const removeProduct = (product) => {
    dispatch(removeItemAction(product));
  };

  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );

  useEffect(() => {
    // Click away from cart to close
    document.addEventListener('click', (e) => {
      if (e.target.className === 'overlay') {
        closeCart();
      }
    });
  }, [isFlyoutMenuOpen]);

  useEffect(() => {
    cartMessageRef.current.scrollIntoView();
  }, [isSharedCartIncomplete]);

  const goToCheckout = () => {
    if (cartItems.length > 0) {
      let pathname = location.pathname;
      if (pathname === '/checkout') {
        // console.log(
        //   'closeCart itemsAfterCartModalIsOpen  + itemsInCart',
        //   itemsAfterCartModalIsOpen,
        //   itemsInCart,
        // );
        if (itemsAfterCartModalIsOpen !== itemsInCart) {
          dispatch(createCartAndTransactionAction(cartItems));
        }
      }
      dispatch(updateCartAction({ items: cartItems }, cartID));
      history.push('/checkout');
      dispatch(resetCartSharingAction());
      dispatch(toggleFlyoutMenuAction(false));
      setIsCheckoutWithEmptyCart(false);
      document.body.style.overflow = 'unset';

      // FacebookAnalytics.logEvent({
      //   event: 'begin_checkout',
      //   value: savedTotal,
      //   params: {
      //     category: 'begin_checkout',
      //     action: 'success',
      //     label: 'begin_checkout',
      //     value: savedTotal,
      //   },
      // });
      let cartItemsForAnalytics = generateItemsListForFirebaseAnalytics(
        cartItems,
      );
      FirebaseAnalytics.logEvent({
        name: 'begin_checkout',
        params: {
          currency: 'TZS',
          value: savedTotal,
          items: cartItemsForAnalytics,
          // platform: Capacitor.platform,
        },
      });
      let data = {
        number_of_products: cartItems.length,
        payment_info_available: 0,
        content_id: cartId,
        content_type: 'cart_products',
        value: savedTotal,
        currency: 'TZS',
      };
      if (Capacitor.isNative) {
        // FacebookAnalytics.logInitiatedCheckout({
        //   amount: savedTotal,
        //   data,
        // });
      } else {
        // ReactPixel.track('InitiateCheckout', data);
      }
    } else {
      setIsCheckoutWithEmptyCart(true);
      setInfo(
        'You cannot checkout with empty cart . Please choose some products to proceed',
      );
    }
  };
  const closeCart = () => {
    let pathname = location.pathname;
    if (pathname === '/checkout') {
      // console.log(
      //   'closeCart itemsAfterCartModalIsOpen  + itemsInCart',
      //   itemsAfterCartModalIsOpen,
      //   itemsInCart,
      // );
      if (itemsAfterCartModalIsOpen !== itemsInCart) {
        dispatch(createCartAndTransactionAction(cartItems));
      }
    }

    if (cartItems.length > 0) {
      let productsInCartDetails = generateItemsObjectForRelatedToCart (cartItems)
      dispatch(setRecommendedSectionsLoadingAction('cart'));
      dispatch(getRelatedToItemsInYourCartAction({
        placementId: "frequently-bought-together",
        userEvent: {
            eventType: "add-to-cart",
            visitorId: localStorage.getItem('appNumber'),
            productDetails: 
              productsInCartDetails
        }
    }));
      // console.log('productsInCartDetails ',  productsInCartDetails);
    }

    dispatch(resetCartSharingAction());
    dispatch(toggleFlyoutMenuAction(false));
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    if (cartItems.length === 1) {
      setIsCheckoutWithEmptyCart(false);
    }
  }, [cartItems.length]);

  useEffect(() => {
    if (isCartRetrieved) {
      return () => dispatch(resetCartRetrievalStatusAction());
    }
  }, [isCartRetrieved]);

  useEffect(() => {
    var QuantityObj = {};
    var totalObj = {};
    cartItems.map((item, index) => {
      totalObj[index] = item.total;
      QuantityObj[index] = item.quantity;
      return item;
    });
    setIsCartCopedFail(false);
    return () => dispatch(resetCartSharingAction());
  }, []);

  const triggerCartShare = () => {
    if (cartItems.length > 0) {
      setIsCartCopied(false);
      let deviceUuid = JSON.parse(localStorage.getItem('appDev')).uuid ?? null;
      if (!deviceUuid) {
        Device.getInfo()
          .then((device) => {
            deviceUuid = device.uuid;
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      let createCartPayload = {
        items: cartItems,
        uuid: deviceUuid,
      };
      dispatch(updateCartAction({ items: cartItems }, cartId));
      setTimeout(() => dispatch(createCartAction(createCartPayload)), 10000);
      dispatch(shareCartAction(cartId));
    } else {
      setIsCheckoutWithEmptyCart(true);
      setInfo(
        'You cannot share  empty cart . Please choose some products to proceed',
      );
    }
  };

  const copyCartLink = () => {
    navigator.clipboard.writeText(message).then(
      function () {
        setIsCartCopied(true);
      },
      function () {
        setIsCartCopedFail(false);
      },
    );
  };

  return (
    <CartWrapper>
      <div className="cart-container">
        <div className="cart-header">
          <h3 className="cart-title">{t('your_cart')}</h3>
          <div className="top-actions">
            <button
              onClick={() => triggerCartShare()}
              className="cart-sharelink cart-link"
            >
              <ShareIcon />
            </button>
            <button onClick={closeCart} className="cart-closelink">
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className="cart-content-wrapper">
          <div ref={cartMessageRef} className="mt-1"></div>
          {isSharedCartIncomplete && location.pathname === '/' && (
            <p className="alert alert-info mt-3">{cartMessage}</p>
          )}

          {isSharedCartIncomplete &&
            (location.pathname === '/checkout' ||
              location.pathname === '/pay') && (
              <p className="alert alert-info mt-3">{checkoutMessage}</p>
            )}

          {!canCheckout && isCartRetrieved && (
            <p className="alert alert-danger mt-3">{info}</p>
          )}
          {isCheckoutWithEmptyCart && (
            <p className="alert alert-info mt-3">{info}</p>
          )}
          {isCartShared && document.queryCommandSupported('copy') && (
            <>
              <div>
                <button onClick={copyCartLink} className="btn btn-copy">
                  Click to copy link
                </button>
              </div>
              {isCartCopied && <p className="alert-copy">Copied!</p>}
            </>
          )}
          {cartItems && cartItems.length > 0 && (
            <h5 className="pt-2 mt-3">
              {t('item')} : {itemsInCart}
            </h5>
          )}
          {cartItems &&
            cartItems.length > 0 &&
            cartItems.map((product, index) => (
              <div key={product.sku_id}>
                <div className="cart-list-container">
                  <div className="cart-item">
                    <img
                      src={
                        product && product.image
                          ? `${INALIPA_S3_URL}${product.image}`
                          : placeholder
                      }
                      alt={product.name}
                      className="img-fluid cart-image"
                    ></img>

                    <div className="cart-item-content">
                      <h2>{product.name}</h2>
                      <small>
                        {product.options &&
                          product.options.variant &&
                          product.options.variant}
                      </small>
                      <div className="cartItem__content__row">
                        <div className="cartItem__contentRow_toggler">
                          <QuantityToggler
                            cartItem={product}
                            isAdded={false}
                            canTrackInventory={product.track}
                          />
                        </div>
                        <div className="cartItem__contentRow_price">
                          <h3>TZS {numberWithCommas(product.total)}</h3>
                        </div>
                      </div>
                      <div className="cartItem_remove_btn">
                        <button
                          className="btn-remove"
                          onClick={() => removeProduct(product)}
                        >
                          <div className="cartItem_remove_btn_text">
                            <FontAwesomeIcon icon={faTrash} />
                            &nbsp; {t('remove')}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart-item-separator"></div>
              </div>
            ))}

          {cartItems && cartItems.length === 0 && (
            <div className="empty-cart">
              <div>{t('no_items')}</div>
            </div>
          )}

          {!cartItems && (
            <div className="empty-cart">
              <div>
                <CircularProgress />
              </div>
            </div>
          )}
        </div>

        <div className="cart-footer mt-3">
          <div className="row mb-2">
            <div className=" cart-footer-title ">{t('sub_total')}</div>
            <div className="text-right cart-footer-value">
              TZS{' '}
              <CurrencyFormat
                value={Math.abs(parseFloat(savedTotal - savedVAT).toFixed(2))}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="cart-footer-title">VAT</div>
            <div className="text-right cart-footer-value">
              TZS&nbsp;
              <CurrencyFormat
                value={Math.abs(parseFloat(savedVAT).toFixed(2))}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </div>

          <div className="cart-item-separator mt-3 mb-3"></div>
          <div className="row mb-3">
            <div className=" cart-footer-title">{t('total')}</div>
            <div className="text-right cart-footer-value">
              TZS&nbsp;{numberWithCommas(savedTotal)}&nbsp;
            </div>
          </div>
          <div>
            <button className="btn-checkout" onClick={goToCheckout}>
              {t('checkout')}
            </button>
          </div>
        </div>
      </div>
    </CartWrapper>
  );
};

export default Cart;
