import React, { useState } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Link, useHistory } from 'react-router-dom';
// import '.././VerticalCards.css';
import './../../../ui/VerticalScroll/VerticalCards.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
export function VerticalScrollCardSkeleton({ type }) {
  return (
    <div>
      <div className={'skeleton-section'}>
        <SkeletonTheme color="#9dc49d">
          {type === 'square' && (
            <Skeleton
              className={'skeleton-category-image'}
              enableAnimation={true}
              circle={false}
              style={{ borderRadius: '10%' }}
            />
          )}
          {type === 'circle' && (
            <Skeleton
              className={'skeleton-category-image'}
              enableAnimation={true}
              circle={true}
              style={{ borderRadius: '60%' }}
            />
          )}
        </SkeletonTheme>

        <div className={'skeleton-category-title'}>
          <SkeletonTheme color="#e5e5e5">
            <Skeleton width={50} height={20} />
          </SkeletonTheme>
        </div>
      </div>
    </div>
  );
}
