import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import {
  getBrandsAction,
  scrollDataActionCat,
} from '../../store/actions/catalogAction';
import { BrandProductWrapper } from '../../components/styles/product';
import { Link, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import {
  scrollDataActionUi,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';
import ScrollButton from '../../components/ui/scrollButton';

const Brands = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.catalogManager.brands);
  const { t } = useTranslation('product');
  const location = useLocation();

  const groupBrands = () => {
    if (brands) {
      let brandGroups = brands.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0].toUpperCase() ?? e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, brands: [e] };
        // if there is push current element to brands array for that letter
        else r[group].brands.push(e);
        // return accumulator
        return r;
      }, {});
      return Object.values(brandGroups);
    }
  };
  const allBrandsInGroups = groupBrands();

  const Url = location;
  useEffect(() => {
    scrollToTop();
    dispatch(getBrandsAction());
    dispatch(scrollDataActionUi('reset'));
    dispatch(scrollDataActionCat('reset'));
    return () => {
      dispatch(setPreviousUrlAction(Url));
    };
  }, [location]);

  return (
    <>
      <PageTitle title="Inalipa - Brands" />
      <SecondaryNav className="d-none d-sm-block" />
      <ScrollButton />
      <div className="container mb-5">
        <h2 className="mt-2 mb-2 ml-2">{t('all_brands')}</h2>
        <BrandProductWrapper>
          {!brands && (
            <div className="row">
              <div className="container mt-2 text-center">
                <CircularProgress />
              </div>
            </div>
          )}
          <div className="brands-letter pl-0 ml-1">
            <ul className="brands">
              {brands &&
                allBrandsInGroups &&
                allBrandsInGroups.map((group, index) => (
                  <li className="brands-initial">
                    <a
                      href={'#' + group.group}
                      className={
                        window.location.pathname === '/brands#' + group.group
                          ? 'brands-link brands-link-selected'
                          : 'brands-link'
                      }
                    >
                      {group.group}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          {brands &&
            allBrandsInGroups &&
            allBrandsInGroups.map((group, index) => (
              <div className="row brand-group ml-1">
                <>
                  <div className="mb-2" style={{ width: '100%' }}>
                    <h3 className="pl-0" id={group.group}>
                      {group.group}
                    </h3>
                  </div>
                  <ul className="list-brands">
                    {group.brands.map((brand, index) => (
                      <li style={{ listStyle: 'none' }} className="text-left">
                        <Link to={`/brand/${brand._id}`}>
                          <h6>{brand.name}</h6>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              </div>
            ))}
        </BrandProductWrapper>
      </div>
    </>
  );
};

export default Brands;
