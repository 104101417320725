import axios from 'axios';
import { API_KEY, INALIPA_MEGASTORE_ID, MERCANT_API } from '../constants';

const config = {
  headers: {
    'x-inalipa-shop-api-key': API_KEY,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getFeaturedShops = async () => {
  return await axios
    .get(`${MERCANT_API}/website/shops/featured`, config)
    .then((data) => data);
};

export const getShops = async () => {
  return await axios
    .get(`${MERCANT_API}/website/shops`, config)
    .then((data) => data);
};

export const getShopBySubDomain = async (shopSubDomain) => {
  return await axios
    .get(`${MERCANT_API}/shops/subdomain/${shopSubDomain}`, config)
    .then((data) => data);
};

export const getShopProducts = async (shopName) => {
  return await axios
    .get(`${MERCANT_API}/website/products?subDomain=${shopName}`, config)
    .then((data) => data);
};

export const getInalipaMegaStore = async () => {
  return await axios
    .get(`${MERCANT_API}/shops/${INALIPA_MEGASTORE_ID}`, config)
    .then((data) => data);
};
