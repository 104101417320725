import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Card } from '../../components/styles/Card';
import { MegaStoreImageWrapper } from '../styles/List';
import ProductTileSkeleton from './elements/products/ProductTileSkeleton';

function CuratedPageSkeleton() {
   
    return(
        <div className="row">
            <div className="container">
                <div className="pt-5 pb-3">
                    <SkeletonTheme color="#f3f3f3" >
                        <Skeleton height={25}  style={{width:'20%'}} />
                    </SkeletonTheme> 
                </div>
            
            <div>  
                <Card>
                    <MegaStoreImageWrapper>
                    <SkeletonTheme color="#9dc49d">
                            <Skeleton height={350}   style={{height:'1000%px', width:'200%px'}} />
                        </SkeletonTheme> 
                    </MegaStoreImageWrapper>
                </Card>
            </div>

            <div className="row">
                {Array(8)
                  .fill()
                  .map((item, index) => (
                    <div className="column col-md-3 mt-2" key={index}>
                      <ProductTileSkeleton key={index} />
                    </div>
                  ))}
              </div>
           
               
                
            </div>
      </div>
        
    )
    







}

export default CuratedPageSkeleton;