import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  loadProductAction,
  searchCatalogAction,
  updateCatalogSearchAction,
} from '../../store/actions/catalogAction';
import { SearchResultsWrapper } from '../../components/styles/general';
import ProductTile from '../../components/ui/ProductTile';
import qs from 'query-string';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppModal from '../../components/modals/AppModal';
import {
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { AUTOCOMPLETE_LIMIT } from '../../constants';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const Results = () => {
  const results = useSelector((state) => state.catalogManager.searchResults);
  const suggestion = useSelector(
    (state) => state.catalogManager.searchSuggestion,
  );
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const page = useSelector((state) => state.catalogManager.page);
  const hasMore = useSelector((state) => state.catalogManager.hasMore);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();
  const { t } = useTranslation('search');
  const searchQueryObj = qs.parse(location.search);
  useEffect(() => {
    dispatch(loadProductAction());
    let previousRoutes = {
      parent: {
        name: 'Search Results',
        link:
          location.pathname + '?search_query=' + searchQueryObj.search_query,
      },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));
    setSearchTerm(searchQueryObj.search_query);

    FirebaseAnalytics.logEvent({
      name: 'search',
      params: {
        search_term: searchQueryObj.search_term,
      },
    });

    dispatch(
      searchCatalogAction(
        searchQueryObj.search_query,
        page,
        AUTOCOMPLETE_LIMIT,
      ),
    );
  }, [searchQueryObj.search_query, page]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  const fetchMoreData = () => {
    dispatch(
      updateCatalogSearchAction(
        searchQueryObj.search_query,
        page + 1,
        AUTOCOMPLETE_LIMIT,
      ),
    );
  };

  return (
    <>
      <PageTitle title="Inalipa - Search results" />
      <SecondaryNav className="d-none d-md-block" />
      <div className="container page mt-2">
        <SearchResultsWrapper>
          {!isLoading && results && results.length === 0 && (
            <p className="alert alert-info">
              {t('no_results')} {searchQueryObj.search_query}
            </p>
          )}

          <InfiniteScroll
            dataLength={results.length}
            next={fetchMoreData}
            hasMore={hasMore}
            // loader={
            //   <h4 className="loading_heading_indicator">
            //     <CircularProgress />
            //     Loading...
            //   </h4>
            // }
          >
            <div className="row mt-3">
              {results &&
                results.length > 0 &&
                results.map((product) => (
                  <div
                    className="col-6 col-sm-6 col-md-4 col-lg-3 result-tile"
                    key={product._id}
                  >
                    <ProductTile productListItem={product} />
                  </div>
                ))}
            </div>
          </InfiniteScroll>

          <AppModal isOpen={isLoading} component={<CircularProgress />} />
        </SearchResultsWrapper>
      </div>
    </>
  );
};

export default Results;
