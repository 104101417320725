import React, { Component } from 'react';
import { withNamespaces, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polygon,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import {
  updateLocationAction,
  restoreDefaultMapConfigAction,
  getCurrentLocationAction,
  setLocationOutOfBoundAction,
} from '../../store/actions/locationAction';

import * as turf from '@turf/turf';
import { CircularProgress } from '@material-ui/core';

Geocode.setApiKey('AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU');
Geocode.enableDebug();

var bounds = [
  [-6.93789, 39.2014],
  [-6.99948, 39.29336],
  [-6.92157, 39.30191],
  [-6.84841, 39.28369],
  [-6.8223, 39.28677],
  [-6.81858, 39.29102],
  [-6.81769, 39.29483],
  [-6.81872, 39.29724],
  [-6.81872, 39.29981],
  [-6.81657, 39.30131],
  [-6.81288, 39.30053],
  [-6.80968, 39.2981],
  [-6.79401, 39.28371],
  [-6.78226, 39.28734],
  [-6.76788, 39.28351],
  [-6.75591, 39.2881],
  [-6.74826, 39.28966],
  [-6.74025, 39.28613],
  [-6.73229, 39.27732],
  [-6.7461, 39.27103],
  [-6.75458, 39.26973],
  [-6.75931, 39.26242],
  [-6.74839, 39.24548],
  [-6.7304, 39.23858],
  [-6.70823, 39.23246],
  [-6.69509, 39.22665],
  [-6.66696, 39.22352],
  [-6.63797, 39.20134],
  [-6.62704, 39.18278],
  [-6.61172, 39.16955],
  [-6.59514, 39.16279],
  [-6.58251, 39.1545],
  [-6.57413, 39.14123],
  [-6.58468, 39.12147],
  [-6.6055, 39.12728],
  [-6.71788, 39.12052],
  [-6.76895, 39.11869],
  [-6.79308, 39.14707],
  [-6.88828, 39.14213],
  [-6.92698, 39.17668],
  [-6.93789, 39.2014],
];
var poly = turf.polygon([bounds]);

var boundsObjArr = bounds.map((bound) => {
  return {
    lat: bound[0],
    lng: bound[1],
  };
});

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      city: '',
      area: '',
      state: '',
      establishment: '',
      canPickLocation: false,
      canShowMarker: false,
      mapPosition: {
        lat: this.props.mapPosition.lat,
        lng: this.props.mapPosition.lng,
      },
      zoom: null,
      showPolygon: true,
      markerPosition: this.props.markerPosition
        ? {
            lat: this.props.markerPosition.lat,
            lng: this.props.markerPosition.lng,
          }
        : { lat: this.props.mapPosition.lat, lng: this.props.mapPosition.lng },
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log(' handleInputChange name + value ', name, value);
    if (value) {
      this.props.getCurrentLocationAction();
    }
    this.setState({
      [name]: value,
    });
  };

  changeAddress = (e) => {
    // console.log('Change address');
    this.setState({ address: e.target.value });
  };

  componentWillReceiveProps(nextProps) {
    const { markerPosition, address } = nextProps;
    this.setState({
      address: address,
      zoom: 16,
      showPolygon: true,
      markerPosition: markerPosition,
      mapPosition: markerPosition,
    });
    // console.log('componentWillReceiveProps', markerPosition, address);
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng,
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
        });

        if (this.props.isLocationSaved) {
          if (document.getElementById('locationAutocomplete')) {
            document.getElementById('locationAutocomplete').value = address;
          }
        }
      },
      (error) => {
        console.error(error);
      },
    );
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {
    // console.log("Window Closed");
  };

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event, google) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        let coordinates = {
          lat: newLat,
          lng: newLng,
        };

        let isPlaceInBound = turf.booleanPointInPolygon(
          turf.point([newLat, newLng]),
          poly,
        );

        if (isPlaceInBound) {
          document.getElementById('locationAutocomplete').value = address;
          let data = {
            address,
            city,
            area,
            state,
            mapPosition: coordinates,
            markerPosition: coordinates,

            zoom: 15,
          };
          this.props.updateLocationAction(data);
          this.setState({
            address: address ? address : '',
            area: area ? area : '',
            city: city ? city : '',
            state: state ? state : '',
            zoom: 16,
            showPolygon: true,
            markerPosition: {
              lat: newLat,
              lng: newLng,
            },
            mapPosition: {
              lat: newLat,
              lng: newLng,
            },
          });
        } else {
          document.getElementById('locationAutocomplete').value = '';
          this.props.setLocationOutOfBoundAction(true);

          setTimeout(
            this.setState({
              address: '',
              showPolygon: true,
              markerPosition: coordinates,
              zoom: 8,
            }),
            1000,
          );
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    // console.log('onPlaceSelected place =  ', place);

    //console.log("plc", place);
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    let coordinates = {
      lat: latValue,
      lng: lngValue,
    };
    let isPlaceInBound = turf.booleanPointInPolygon(
      turf.point([latValue, lngValue]),
      poly,
    );
    if (isPlaceInBound) {
      let data = {
        address,
        establishment: document.getElementById('locationAutocomplete').value,
        city,
        area,
        state,
        mapPosition: coordinates,
        markerPosition: coordinates,
        zoom: 15,
        showPolygon: true,
      };
      // console.log(data);
      this.props.updateLocationAction(data);
      // Set these values in the state.
      this.setState({
        address: address ? address : '',
        area: area ? area : '',
        city: city ? city : '',
        state: state ? state : '',
        zoom: 16,
        markerPosition: {
          lat: latValue,
          lng: lngValue,
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
        showPolygon: true,
      });
    } else {
      document.getElementById('locationAutocomplete').value = '';
      this.props.setLocationOutOfBoundAction(true);
      setTimeout(
        this.setState({
          address: '',
          showPolygon: true,
          markerPosition: coordinates,
          zoom: 8,
        }),
        1000,
      );
    }
  };

  valueChanged = (value) => {
    this.setState({ establishment: value.target.value });
  };

  render() {
    const { t } = this.props;
    const { address } = this.state;
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <>
          <GoogleMap
            google={this.props.google}
            defaultZoom={this.state.zoom ? this.state.zoom : this.props.zoom}
            defaultCenter={{
              lat:
                this.state.mapPosition && this.state.mapPosition.lat
                  ? this.state.mapPosition.lat
                  : null,
              lng:
                this.state.mapPosition && this.state.mapPosition.lng
                  ? this.state.mapPosition.lng
                  : null,
            }}
            style={{ marginBottom: '120px' }}
            ref={(map) => (this._map = map)}
          >
            {this.state.showPolygon && (
              <Polygon
                path={boundsObjArr}
                options={{
                  strokeColor: '#ca6c6c',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: '#ff8585',
                  fillOpacity: 0.3,
                }}
              />
            )}

            {this.state.markerPosition && this.props.isLocationSaved && (
              <InfoWindow
                onCloseClick={this.onInfoWindowClose}
                position={{
                  lat: this.state.markerPosition.lat + 0.0018,
                  lng: this.state.markerPosition.lng,
                }}
                className="Name"
              >
                <div className="addressTitle">{this.state.address}</div>
              </InfoWindow>
            )}
            {this.state.markerPosition && this.props.isLocationSaved && (
              <>
                <Marker
                  google={this.props.google}
                  draggable={true}
                  onDragEnd={(e) => this.onMarkerDragEnd(e, this.props.google)}
                  position={{
                    lat: this.state.markerPosition.lat,
                    lng: this.state.markerPosition.lng,
                  }}
                />
                <Marker />
              </>
            )}

            {/* For Auto complete Search Box */}
          </GoogleMap>
        </>
      )),
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <label style={{ marginTop: '20px' }}>{t('address')}</label>
          <Autocomplete
            id="locationAutocomplete"
            style={{
              width: '100%',
              height: '50px',
              marginBottom: '30px',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: 'rgba(157, 117, 68, 0.12)',
            }}
            onPlaceSelected={this.onPlaceSelected}
            onChange={this.valueChanged}
            types={['establishment', 'geocode']}
            className="w-input"
            componentRestrictions={{ country: 'tz' }}
            placeholder={t('type_address')}
            required //={this.props.isLocationSaved ? false : true}
            bounds={boundsObjArr}
          />
          {this.props.isLocationOutOfBound && (
            <p className="alert alert-warning">
              Sorry , we only do deliveries in dar es salaam only.
            </p>
          )}
          <h5>OR</h5>

          <div className="current-location-picker">
            <input
              type="checkbox"
              id="pickCurrentLocation"
              name="canPickLocation"
              onChange={this.handleInputChange}
            />
            <label htmlFor="pickCurrentLocation">{t('location')}</label>
          </div>
          {this.props.isLoading && (
            <h4 className="loading_location">
              <CircularProgress /> Loading...
            </h4>
          )}
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU&v=3.exp&libraries=geometry,drawing,places "
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{ height: this.props.height, marginBottom: '20px' }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }

    return map;
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.locationManager.address,
    mapPosition: state.locationManager.mapPosition,
    markerPosition: state.locationManager.markerPosition,
    isLoading: state.locationManager.isLoading,
    isLocationSaved: state.locationManager.isLocationSaved,
    isLocationOutOfBound: state.locationManager.isLocationOutOfBound,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLocationAction: (data) => dispatch(updateLocationAction(data)),
  getCurrentLocationAction: () => dispatch(getCurrentLocationAction()),
  setLocationOutOfBoundAction: (data) =>
    dispatch(setLocationOutOfBoundAction(data)),
  restoreDefaultMapConfigAction: () =>
    dispatch(restoreDefaultMapConfigAction()),
});

export default compose(
  withTranslation('checkout'),
  connect(mapStateToProps, mapDispatchToProps),
)(Map);
