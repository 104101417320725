import styled from 'styled-components';

export const ProductCard = styled.div`
  padding: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  img {
    max-width: 100px;
  }
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
  }
  h4 {
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
  }
  .react-datepicker__input-container {
    padding-bottom: 10px !important;
  }
`;

export const ProductCardAccordion = styled.div`
  .MuiAccordionDetails-root {
    display: block !important;
  }
  .productCard_details {
    margin-left: 10px;
  }
`;
