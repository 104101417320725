import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = ({ title, description }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description ? (
          <meta name="description" content={description} />
        ) : (
          <meta
            name="description"
            content="Order drinks, groceries, cosmetics, electronics, household goods and more from Inalipa™. Open 7-days a week, servicing deliveries 20 hours a day (from 8am to 4am). 24/7 Customer support."
          />
        )}
        <meta
          name="keywords"
          content="Inalipa, Shops, Instagram store, Instagram shop, Ecommerce Tanzania"
        />
      </Helmet>
    </>
  );
};

export default PageTitle;
