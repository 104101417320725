import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/rootReducer';
import thunk from 'redux-thunk';
import { createHashHistory as createHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { whichBrowser } from '../utils/browser';

export const history = createHistory();

const { NODE_ENV } = process.env;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['stateManager'],
};

// console.log('we are in the NODE_ENV', NODE_ENV);

// let middleware = [thunk, routerMiddleware(history), scrollingMiddleware];
let middleware = [thunk];
let enhancers = [applyMiddleware(...middleware)];

if (NODE_ENV === 'development' && whichBrowser() !== 'Safari') {
  enhancers.push(
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, compose(...enhancers));

export const persistor = persistStore(store);
