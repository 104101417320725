import { GET_CATEGORIES_SUCCESS, GET_CATEGORY_SUCCESS } from "../constants/ActionTypes";

const initialState = {
  categories: [],
  category: {}
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CATEGORIES_SUCCESS:
      return { ...state, categories: payload };
    case GET_CATEGORY_SUCCESS:
      return { ...state, categories: payload };
    default:
      return state;
  }
};

export default categoryReducer;
