import axios from "axios";
import {API_KEY, MERCANT_API} from "../constants";

export const getCategories = async () => {
  const result = await axios
    .get(`${MERCANT_API}/website/categories`, {
      headers: {
        "x-inalipa-shop-api-key": API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const getCategory = async (id) => {
    return await axios
      .get(`${MERCANT_API}/website/categories/${id}`, {
          headers: {
              "x-inalipa-shop-api-key": API_KEY,
          },
      })
      .then((data) => data);
};
