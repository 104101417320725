import React from 'react';
import { useSelector } from 'react-redux';
import { FlyoutMenuWrapper } from '../styles/Filter';
import TopMenu from '../ui/TopMenu';
import SidebarNav from '../ui/SidebarNav';

const FlyoutMobileMenu = ({ component }) => {
  const isFlyoutMenuOpen = useSelector(
    (state) => state.uiManager.isFlyoutMenuOpen,
  );
  return (
    <FlyoutMenuWrapper>
      <div className={isFlyoutMenuOpen ? 'overlay' : ''}></div>
      <div
        className={
          isFlyoutMenuOpen
            ? 'flyoutMenu flyoutMenu_desktop show'
            : 'flyoutMenu flyoutMenu_desktop hide'
        }
      >
        {component}
      </div>
    </FlyoutMenuWrapper>
  );
};

export default FlyoutMobileMenu;
