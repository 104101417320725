import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import backgroundImage from '../../assets/images/placeholders/new_logo.jpg';
import { INALIPA_S3_URL } from '../../constants';

const MutlipeImages = ({ name, mainImage, otherImages }) => {
  const addDefaultSrc = (ev) => {
    ev.target.src = backgroundImage;
  };

  return (
    <Carousel>
      <div>
        <img
          src={`${INALIPA_S3_URL}products/${mainImage}`}
          alt={name}
          className="img-fluid"
          product={true}
          onError={addDefaultSrc}
        />
      </div>
      {otherImages &&
        otherImages.length > 0 &&
        otherImages.map((image) => (
          <div>
            <img
              src={`${INALIPA_S3_URL}products/${image}`}
              alt={name}
              className="img-fluid"
              product={true}
              onError={addDefaultSrc}
            />
          </div>
        ))}
    </Carousel>
  );
};

export default MutlipeImages;
