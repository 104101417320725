import {
  INITIATE_PAYMENT_SUCCESS,
  ADD_DELIVERY_INFO_SUCCESS,
  PAYMENT_SUCCESS,
  DISCOUNT_SUCCESS,
  DISCOUNT_FAIL,
  CLEAR_DISCOUNT,
  INITIATE_CARD_PAYMENT,
  CARD_PAYMENT_SUCCESS,
  CARD_PAYMENT_FAIL,
  NO_PAYMENT_RECEIVED,
  RESET_PAYMENT_OPTIONS,
  PAYMENT_COMPLETE,
  PAYMENT_CANCELLED,
  TOGGLE_ORDER_SUMMARY_ACCORDION,
  INITIATE_PAYMENT_PROCESS_SUCCESS,
  REFETCH_DELIVERY_OPTIONS_SUCCESS,
  CLEAR_REFETCH__DELIVERY_OPTIONS,
  RESET_TRACKING_NUMBER,
  SET_TRACKING_NUMBER,
  PAYMENT_ACTION_FAIL,
  PAYMENT_ACTION_SUCCESS,
  PAYMENT_RESET_STATE,
} from '../constants/ActionTypes';

const initialState = {
  transaction: null,
  isTransactionInitiated: false,
  deliveryInfo: null,
  discount: null,
  hasDiscountFailed: false,
  message: '',
  isCardPaymentInitiated: false,
  isCardPaymentSuccess: false,
  isCardPaymentFail: false,
  cardParams: null,
  paymentSuccess: null,
  isPaymentCancelled: false,
  failToReceivePayment: false,
  isDiscounted: false,
  canOrderSummaryExpand: false,
  isProcessing: false,
  refetchedDeliveryOptions: null,
  trackingNumber: null,
  error: false,
  success: false,
  isLoading: false,
  actionMessage: '',
};

const paymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        transaction: payload,
        isTransactionInitiated: true,
        isCardPaymentInitiated: false,
        cardParams: null,
        isDiscounted: false,
        canOrderSummaryExpand: false,
        isProcessing: false,
      };

    case ADD_DELIVERY_INFO_SUCCESS:
      return { ...state, deliveryInfo: payload };
    case DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: payload,
        hasDiscountFailed: false,
        isDiscounted: true,
        canOrderSummaryExpand: true,
        isDiscuntApplied: true,
      };
    case DISCOUNT_FAIL:
      return {
        ...state,
        discount: null,
        hasDiscountFailed: true,
        message: payload,
      };
    case CARD_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: payload,
        isCardPaymentSuccess: true,
        isCardPaymentInitiated: false,
        cardParams: null,
      };
    case CARD_PAYMENT_FAIL:
      return {
        ...state,
        isCardPaymentSuccess: false,
        isCardPaymentInitiated: false,
        isCardPaymentFail: true,
        message: payload,
      };
    case CLEAR_DISCOUNT:
      return {
        ...state,
        discount: null,
        hasDiscountFailed: false,
        message: payload,
      };
    case INITIATE_CARD_PAYMENT:
      return {
        ...state,
        cardParams: payload,
        isCardPaymentInitiated: true,
      };
    case NO_PAYMENT_RECEIVED:
      return { ...state, failToReceivePayment: true, message: payload };
    case PAYMENT_CANCELLED:
      return {
        ...state,
        isPaymentCancelled: true,
        message: payload,
      };
    case RESET_PAYMENT_OPTIONS:
      return {
        ...state,
        failToReceivePayment: false,
        isPaymentCancelled: false,
        isCardPaymentSuccess: false,
        isCardPaymentFail: false,
      };
    case TOGGLE_ORDER_SUMMARY_ACCORDION:
      return { ...state, canOrderSummaryExpand: payload };
    case INITIATE_PAYMENT_PROCESS_SUCCESS:
      return { ...state, isProcessing: payload, transaction: null };
    case REFETCH_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        refetchedDeliveryOptions: payload,
      };
    case CLEAR_REFETCH__DELIVERY_OPTIONS:
      return {
        ...state,
        refetchedDeliveryOptions: null,
      };
    case RESET_TRACKING_NUMBER:
      return {
        ...state,
        trackingNumber: null,
      };
    case SET_TRACKING_NUMBER:
      return {
        ...state,
        trackingNumber: payload,
      };
    case PAYMENT_ACTION_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        isLoading: false,
        actionMessage: payload,
      };
    case PAYMENT_ACTION_FAIL:
      return {
        ...state,
        error: true,
        success: false,
        isLoading: false,
        actionMessage: payload,
      };
    case PAYMENT_RESET_STATE:
      return {
        ...state,
        error: false,
        success: false,
        isLoading: false,
        actionMessage: '',
      };
    default:
      return state;
  }
};

export default paymentReducer;
