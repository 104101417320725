import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import { INALIPA_S3_URL } from '../../constants';
import {
  CategoryWrapper,
  CenteredTextImageWrapper,
} from '../../components/styles/general';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { Link, useLocation } from 'react-router-dom';
import placeholder from '../../assets/images/placeholders/item.png';
import InalipaLogo from '../../assets/images/inalipa-logo.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { resetFiltersAction } from '../../store/actions/catalogAction';
import { StorePageWrapper } from '../../components/ui/StorePage';
import { ShopListWrapper } from '../../components/styles/List';
import StoreCard from '../../components/ui/StoreCard';
import { getShopsAction } from '../../store/actions/shopAction';
import { scrollToTop } from '../../utils/window';
import BusinessCard from '../../components/ui/BusinessCard';
import CategoriesVerticalScroll from '../../components/ui/VerticalScroll/CategoriesVerticalScroll';
import CategoriesVerticalSlider from '../../components/ui/CategoriesVerticalSlider';
import { fetchItemFromRemoteConfig } from '../../utils/firebase';
import ScrollButton from '../../components/ui/scrollButton';

const { PUBLIC_URL } = process.env;

const Stores = () => {
  const [megastoreInfo, setMegastoreInfo] = useState(null);
  const categories = useSelector((state) => state.categoryManager.categories);
  const shops = useSelector((state) => state.shopManager.shops);
  const inalipaMegastore = useSelector(
    (state) => state.shopManager.inalipaMegastore,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('stores');
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const location = useLocation();
  useEffect(() => {
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    dispatch(getShopsAction());
    dispatch(resetFiltersAction());
  }, [location]);

  useEffect(() => {
    if (!megastoreInfo) {
      fetchInalipaMegastoreInfo();
    }
  }, []);

  const fetchInalipaMegastoreInfo = () => {
    fetchItemFromRemoteConfig('megastore')
      .then((data) => {
        setMegastoreInfo(data);
        console.log('Megastore data', data);
      })
      .catch((err) => console.log('err', err.message));
  };

  return (
    <>
      <PageTitle title="Inalipa - Stores" />
      <SecondaryNav className="d-none d-sm-block" />
      <div>
        <ScrollButton />
        {/* {categories && categories.length > 0 && (
          <div className="container-full pt-0 pb-0">
            <CategoriesVerticalSlider
              categories={categories} />
          </div>
      )} */}
        <div className="horizontal-line hidden-mobile" />
        {megastoreInfo && (
          <div className="container mt-4">
            <h2>{megastoreInfo?.name}</h2>
            <div className="row pt-4">
              <div className="col-md-12 pl-0 pr-0">
                <Link to="/stores/megastore" style={{ textDecoration: 'none' }}>
                  <BusinessCard
                    settings={inalipaMegastore}
                    image={
                      inalipaMegastore &&
                      inalipaMegastore.settings &&
                      inalipaMegastore.settings.banner
                        ? `${megastoreInfo?.image}`
                        : `${PUBLIC_URL}images/megastore_banner.jpeg`
                    }
                    logo={
                      inalipaMegastore &&
                      inalipaMegastore.settings &&
                      inalipaMegastore.settings.logo
                        ? `${INALIPA_S3_URL}shops/${inalipaMegastore.settings.logo}`
                        : InalipaLogo
                    }
                    title={megastoreInfo?.title}
                    description={megastoreInfo?.description}
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="container pr-0">
          <h2>{t('explore_partner_stores')}</h2>
          {/* <StorePageWrapper> */}
          <ShopListWrapper>
            <div className="row mb-80">
              {shops &&
                shops.length > 0 &&
                shops.map((shop, index) => (
                  <div
                    className="col-md-3 col-sm-12 p-md-3 p-1 column"
                    key={index}
                  >
                    <Link to={`/stores/${shop.subDomain}`} key={index}>
                      <StoreCard
                        image={
                          shop && shop.banner
                            ? `${INALIPA_S3_URL}${shop.banner}`
                            : placeholder
                        }
                        logo={
                          shop && shop.image
                            ? `${INALIPA_S3_URL}${shop.image}`
                            : placeholder
                        }
                        title={shop.name}
                        category={
                          shop.category && shop.category.name
                            ? shop.category.name
                            : null
                        }
                        username={shop.subDomain}
                        settings={shop.settings}
                        key={index}
                      />
                    </Link>
                  </div>
                ))}
            </div>
          </ShopListWrapper>
        </div>
        {/* </StorePageWrapper> */}
      </div>
    </>
  );
};

export default Stores;
