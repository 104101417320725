import styled from "styled-components";

export const ListImageWrapper = styled.div`
  position: relative;
  text-align: center;
`;
export const Card = styled.div`
  h3 {
    text-align: left;
    color: #3c3f42;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.83px;
    // line-height: 18px;
    // line-height: 1.5;
    margin: 0px 0 5px;
    margin-bottom: 0;
    padding-left: 0;
    // min-height: 35px;
  }
  h4 {
    color: #3c3f42;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    letter-spacing: 1.3px;
    // line-height: 16px;
    min-height: 30px;
    margin-bottom: 0;
  }
  p {
    color: #3c3f42;
    font-size: 12px;
    text-align: left;
  }
  a {
    color: #00c400;
    font-size: 12px;
    text-align: left;
    display: block;
    text-decoration: none;
  }
  
  .open {
    color: #00c400;
    font-weight: bold;
}

.closed {
    color: #ff0000;
    font-weight: bold;
}
  .pre-order {
    color: #F15B2A;
}
.font-defaults {
    font-size: 12px;
    letter-spacing: 1.3px;
    line-height: 21px;
    font-weight: 300 !important;
}

@media (min-width: 767px) {
  h3 {
    font-size: 20px !important;
    line-height: 26px;
  }
  h4 {
    font-size: 16px !important;
    line-height: 21px;
    min-height: 45px;
  }
  .font-defaults {
    font-size: 12px;
  }
}

`;
