import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { trackOrder } from '../../services/orderService';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { FormInputWrapper } from '../../components/styles/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import TrackOrderResults from '../../components/order/TrackOrderResults';
import { toggleFlyoutMenuAction } from '../../store/actions/uiAction';
import { useDispatch, useSelector } from 'react-redux';
import { resetTrackingNumberAction } from '../../store/actions/PaymentAction';

const validate = (values) => {
  const errors = {};

  if (!values.trackingNumber) {
    errors.trackingNumber = 'This field is required';
  }
  return errors;
};

const TrackOrders = () => {
  let serverError = false;
  const [isSubmitting, setSubmitting] = useState(false);
  const savedTrackingNumber = useSelector(
    (state) => state.paymentManager.trackingNumber,
  );
  const [order, setOrder] = useState({});
  const { t } = useTranslation('menu');
  let { trackingNumber } = useParams();
  const dispatch = useDispatch();

  const getOrder = (values) => {
    setSubmitting(true);
    setOrder({});
    trackOrder(JSON.stringify(values, null, 2))
      .then((response) => {
        setSubmitting(false);
        if (response.data.status) {
          dispatch(resetTrackingNumberAction());
          setOrder(response.data.data);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Order not found',
            confirmButtonColor: '#00c400',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        serverError = true;
      });
  };

  useEffect(() => {
    dispatch(toggleFlyoutMenuAction(false));
  }, []);

  useEffect(() => {
    let values = {
      trackingNumber: trackingNumber ?? savedTrackingNumber,
    };
    if (trackingNumber || savedTrackingNumber) {
      getOrder(values);
    }
  }, [trackingNumber, savedTrackingNumber]);

  const formik = useFormik({
    initialValues: {
      trackingNumber: '',
    },
    validate,
    onSubmit: (values) => {
      getOrder(values);
    },
  });
  return (
    <div>
      <PageTitle title="Inalipa - Track Order" />
      <SecondaryNav className="d-none d-md-block" />
      <div className="container mb-5">
        <h2 className="mt-2 mb-2 ml-2">{t('track_order')}</h2>
        <hr className="m-0" />
        <div className="row justify-content-center">
          <div className="col-md-8 mb-4 pr-0 pl-0">
            <div className="row mt-2">
              <div className="col-md">
                <FormInputWrapper>
                  <form
                    className="generic-form"
                    action="#"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md pr-0 pl-0">
                        <div className="form-group">
                          <label htmlFor="trackingNumber">
                            Tracking Number
                          </label>
                          <input
                            type="text"
                            name="trackingNumber"
                            className="form-control"
                            id="trackingNumber"
                            placeholder={
                              trackingNumber ??
                              savedTrackingNumber ??
                              order.trackingNumber ??
                              'Enter Your Order Tracking Number'
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.trackingNumber}
                          />
                          {formik.errors.trackingNumber ? (
                            <div className="error">
                              {formik.errors.trackingNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 p-0">
                      <div className="col-md">
                        {!isSubmitting && (
                          <button
                            className="btn-submit"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        )}
                        {isSubmitting && (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </FormInputWrapper>
              </div>
            </div>
            {Object.keys(order).length > 0 && (
              <>
                <TrackOrderResults order={order} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrders;
