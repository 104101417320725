import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductTile from './ProductTile';
import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import CircleRightArrow from '../../assets/icons/circleRightArrow';
import CircleLeftArrow from '../../assets/icons/circleLeftArrow';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';

const { FirebaseAnalytics } = Plugins;

const RecommendedProductSlider = ({
  title,
  products,
  link,
  linkTitle,
  isRelatedProducts,
}) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    scroll: true,
    speed: 500,
    slidesToShow: products.length > 3 ? 4.75 : products.length,
    slidesToScroll: 3,
    swipe: true,
    nextArrow: (
      <div className="my-slick-arrow" display={'none'}>
        <CircleRightArrow />
      </div>
    ),
    prevArrow: (
      <div className="my-slick-arrow" display={'none'}>
        <CircleLeftArrow />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length > 3 ? 4.75 : products.length,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: products.length > 2 ? 2.75 : products.length,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: products.length > 1 ? 1.75 : products.length,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {products && products.length > 0 && (
        <>
          <div className="row pl-0 pt-1 section-header hidden-mobile ">
            <div className="col-8 pl-0 text-left">
              <Link to={link}>
                <h2>{title}</h2>
              </Link>
            </div>
            {link && linkTitle && (
              <>
                <div className=" pl-0 text-right ">
                  <h2>
                    <Link
                      to={link}
                      style={{ color: '#383D42', fontSize: '16px' }}
                    >
                      {linkTitle}
                    </Link>
                  </h2>
                </div>
              </>
            )}
          </div>
          <div className="row pl-0 pt-1 section-header hidden-desktop ">
            <div
              className="pl-0 text-left"
              style={{ marginRight: '0px', justifyContent: 'flex-start' }}
            >
              <Link to={link}>
                <h2>{title} </h2>
              </Link>
            </div>
            {link && linkTitle && (
              <>
                <div
                  className="text-right"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '-5px',
                  }}
                >
                  <Link to={link}>
                    <ArrowRightIcon />
                  </Link>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <Slider {...settings} className="slick">
        {products &&
          products.length > 0 &&
          products.map((product, index) => (
            <ProductTile
              index={index}
              key={product._id}
              productListItem={product}
              isRelatedProduct={isRelatedProducts ? true : false}
            />
          ))}
      </Slider>
    </>
  );
};

export default RecommendedProductSlider;
