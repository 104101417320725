import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    maxHeight: '540px',
  },
};

const AppModal = ({ isOpen, setIsOpen, component }) => {
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={closeModal}
      overlayClassName="bgOverlay"
    >
      {component}
    </Modal>
  );
};

export default AppModal;
