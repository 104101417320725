import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import { useDispatch, useSelector } from 'react-redux';
import './ContactModal.css';
import { Fab, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  setInalipaContactInformationAction,
  toggleCallmodalAction,
} from '../../../store/actions/uiAction';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { fetchItemFromRemoteConfig } from '../../../utils/firebase';

const ContactComponent = () => {
  const savedTotal = useSelector((state) => state.cartManager.total);
  const contactInfo = useSelector((state) => state.uiManager.contactInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('customerService');

  useEffect(() => {
    if (contactInfo && Object.keys(contactInfo).length === 0) {
      fetchInalipaContactInfo();
    }
  }, []);

  const fetchInalipaContactInfo = () => {
    fetchItemFromRemoteConfig('contacts')
      .then((data) => dispatch(setInalipaContactInformationAction(data)))
      .catch((err) => console.log('err', err.message));
  };

  const chatWithWhatsapp = () => {
    // console.log('chatWithWhatsapp');
    CloseModal();
    window.open(
      `https://api.whatsapp.com/send?phone=+${
        contactInfo?.whatsapp ?? '255745550814'
      }&text=` + encodeURIComponent(t('whatsapp_message')),
      '_blank',
    );
    // window.open('tel:0800710700');
    //history.push('whatsapp://send?text=hello&phone=+255712291552');
    // window.location.href('whatsapp://send?text=hello&phone=+255712291552');
  };

  const launchCall = () => {
    // console.log('launchCall');
    CloseModal();
    window.open(`tel:${contactInfo?.toll_free ?? '0800710700'}`);
  };

  const CloseModal = () => {
    dispatch(toggleCallmodalAction(false));
  };

  return (
    <div className="ContactComponent">
      <div className="ContactComponent__item">
        <div
          className="ContactComponent__itemLeft"
          onClick={() => {
            chatWithWhatsapp();
          }}
        >
          <h3>Chat with Whatsapp</h3>
        </div>
        <div className="ContactComponent__itemRight">
          <IconButton
            onClick={() => {
              chatWithWhatsapp();
            }}
          >
            <WhatsAppIcon />
          </IconButton>
        </div>
      </div>

      <div className="ContactComponent__item">
        <div
          className="ContactComponent__itemLeft"
          onClick={() => {
            launchCall();
          }}
        >
          <h3>Free Call</h3>
        </div>
        <div className="ContactComponent__itemRight">
          <IconButton
            onClick={() => {
              launchCall();
            }}
          >
            <CallIcon />
          </IconButton>
        </div>
      </div>

      <IconButton
        onClick={() => {
          CloseModal();
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default ContactComponent;
