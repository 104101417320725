import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  VERIFICATION_SUCCESS,
  ORDER_SUCCESS,
  CLEAR_ALERTS,
  GET_CURRENT_LOCATION,
  PAYMENT_COMPLETE,
  NO_PAYMENT_RECEIVED,
  PAYMENT_CANCELLED,
} from '../constants/ActionTypes';

const initialState = {
  error: false,
  success: false,
  message: '',
  verified: false,
  isOrderSubmitted: false,
  canGetCurrentLocation: false,
  isPaymentComplete: false,
};

const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_SUCCESS:
      return { ...state, error: false, success: true, message: payload };
    case ACTION_FAIL:
      return { ...state, error: true, success: false, message: payload };
    case ORDER_SUCCESS:
      return { ...state, isOrderSubmitted: true };
    case PAYMENT_COMPLETE:
      return { ...state, isPaymentComplete: true, message: payload };
    case GET_CURRENT_LOCATION:
      return { ...state, getCurrentLocation: true };
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verified: true,
        error: false,
        success: false,
        message: 'Order yako imetumwa',
      };
    case CLEAR_ALERTS:
      return { ...state, error: false, success: false, message: '' };

    default:
      return initialState;
  }
};

export default stateReducer;
