import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  updateFilterAction,
  loadProductAction,
  removeFilterAction,
} from '../../store/actions/catalogAction';
import {
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { usePrevious } from '../../utils/state';
import {
  changeFilterMenuState,
  setActiveAccordionAction,
} from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedChild: {
    paddingLeft: theme.spacing(6),
  },
}));

const FilterGroupListItemWithRadioInput = ({ options, name }) => {
  //   console.log('options', options);
  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [closed, setClosed] = useState(false);
  const [limit, setLimit] = useState(10);
  const [value, setValue] = useState(null);
  const [selectedOptionsArr, setSelectedOptionsArr] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const prevOptions = usePrevious({ selectedOptions, setSelectedOptions });
  const brand = useSelector((state) => state.catalogManager.brand);
  const params = useSelector((state) => state.catalogManager.selectedFIlters);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const location = useLocation();
  let { id } = useParams();

  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const isFiltersUpdated = useSelector(
    (state) => state.catalogManager.isFiltersUpdated,
  );

  const rememberedSelectedFilters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isFiltersUpdated) {
      setSelectedFilters(params);
    }
  }, [isFiltersUpdated]);

  useEffect(() => {
    let clonedOptionsArr = [...selectedOptionsArr];
    if (brand) {
      clonedOptionsArr.push(brand._id);
      setSelectedOptionsArr(clonedOptionsArr);
    }
    if (params[name]) {
      setSelectedFilters(params[name]);
    }
  }, []);

  const handleToggle = (option, filterType) => () => {
    scrollToTop();
    setClosed(!closed);
    setValue(option.value);
    dispatch(changeFilterMenuState(false));
    let filterName = filterType ? filterType : name;
    let filterOptions = params[filterName] ? params[filterName] : [];
    dispatch(loadProductAction());

    if (_.findIndex(filterOptions, ['value', option.value]) === -1) {
      dispatch(updateFilterAction({ key: filterName, item: option }));

      if (filterName === 'Category') {
        if (params['SubCategory'] && params['SubCategory'].length > 0) {
          params['SubCategory'].map((subCategory) =>
            dispatch(removeFilterAction(subCategory, 'SubCategory')),
          );
        }
      }

      if (location.pathname !== `/brand/${id}` && filterName === 'Category') {
        dispatch(setActiveAccordionAction('panel2'));
        if (params['Brand'] && params['Brand'].length > 0) {
          params['Brand'].map((brand) =>
            dispatch(removeFilterAction(brand, 'Brand')),
          );
        }
      }

      if (location.pathname === `/products` && filterName === 'Vertical') {
        dispatch(setActiveAccordionAction('panel2'));
        if (params['Brand'] && params['Brand'].length > 0) {
          params['Brand'].map((brand) =>
            dispatch(removeFilterAction(brand, 'Brand')),
          );
        }
      }

      if (location.pathname === `/products` && filterName === 'Category') {
        dispatch(setActiveAccordionAction('panel3'));
      }
    } else {
      dispatch(removeFilterAction(option, filterName));

      if (location.pathname === `/products` && filterName === 'Vertical') {
        if (params['Category'] && params['Category'].length > 0) {
          params['Category'].map((category) =>
            dispatch(removeFilterAction(category, 'Category')),
          );
        }

        if (params['Brand'] && params['Brand'].length > 0) {
          params['Brand'].map((brand) =>
            dispatch(removeFilterAction(brand, 'Brand')),
          );
        }
      }

      if (filterName === 'Category' && location.pathname !== `/brand/${id}`) {
        if (params['Brand'] && params['Brand'].length > 0) {
          params['Brand'].map((brand) =>
            dispatch(removeFilterAction(brand, 'Brand')),
          );
        }
      }
    }
  };

  let currentIndex = 0;

  // if (options) {
  //   console.log('options', options);
  // }

  return (
    <RadioGroup
      aria-label={name}
      name={name}
      value={params && params[name] && params[name].length > 0 ? (value ??  name === 'Vertical' ? rememberedSelectedFilters && rememberedSelectedFilters['Vertical'] && rememberedSelectedFilters['Vertical'].length > 0 && rememberedSelectedFilters['Vertical'][0]?.value : rememberedSelectedFilters['Category'][0]?.value) : ''}
      // onChange={handleToggle(option)}
    >
      {options &&
        options.length > 0 &&
        options.map((option, index) => {
          if (option) {
            const labelId = `radio-list-label-${option.value}`;
            currentIndex = currentIndex + 1;
            if (index < limit) {
              return (
                <ListItem
                  key={option.value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(option)}
                >
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                  />
                  <ListItemText
                    key={option.value}
                    id={labelId}
                    primary={option.label}
                    style={{
                      fontFamily: 'DM Sans !important',
                      fontWeight: '300 !important',
                    }}
                  />
                </ListItem>
              );
            }
          }
        })}
      {options && options.length > 10 && (
        <>
          {currentIndex > limit && (
            <p onClick={() => setLimit(options.length)}>
              <KeyboardArrowDownIcon /> Show More
            </p>
          )}
          {currentIndex === limit && (
            <p onClick={() => setLimit(10)}>
              <KeyboardArrowUpIcon />
              Show Less
            </p>
          )}
        </>
      )}
    </RadioGroup>
  );
};

export default FilterGroupListItemWithRadioInput;
