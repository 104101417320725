import React, { useEffect, useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RatingStars = ({ average }) => {
  const [approximatedAverage, setApproximatedAverage] = useState(null);

  useEffect(() => {
    setApproximatedAverage(Math.round(average));
  }, [average]);

  const StarIcon = (props) => {
    return (
      <SvgIcon {...props}>
        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
      </SvgIcon>
    );
  };

  return (
    <>
      {approximatedAverage > 0 && (
        <>
          {Array(approximatedAverage)
            .fill()
            .map((_, i) => (
              <StarIcon style={{ color: '#ffb400' }} />
            ))}
          {approximatedAverage !== 5 &&
            Array(5 - approximatedAverage)
              .fill()
              .map((_, i) => <StarIcon color="disabled" />)}
        </>
      )}
      {approximatedAverage === 0 &&
        Array(5)
          .fill()
          .map((_, i) => <StarIcon color="disabled" />)}
    </>
  );
};

export default RatingStars;
