import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getBannersAction,
  setLoadingState,
} from '../../store/actions/uiAction';
import Slider from 'react-slick';
import { INALIPA_S3_URL } from '../../constants';
import { SliderWrapper } from '../../components/styles/slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BannerWrapper } from '../styles/Banner';
import BannerSkeleton from '../skeletons/elements/image/BannerSkeleton';
import { Capacitor } from '@capacitor/core';

const Banner = () => {
  // const [loaded, setLoaded] = useState(null);
  const [screenWidth, setScreenWidth] = useState(0);
  const isLoading = useSelector((state) => state.uiManager.isLoading);
  const banners = useSelector((state) => state.uiManager.banners);
  let bannerIos = 'bannerIos';
  const dispatch = useDispatch();
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    dispatch(setLoadingState());
    dispatch(getBannersAction());

    setScreenWidth(window.innerWidth);
  }, [dispatch]);
  return (
    <BannerWrapper>
      {isLoading && <BannerSkeleton />}
      {!isLoading && (
        <Slider {...settings} className={`banner ${bannerIos}`}>
          {banners &&
            banners.length > 0 &&
            banners.map((banner, index) => (
              <SliderWrapper key={index}>
                <Link
                  className="color-primary border-0 "
                  to={`${banner.link ?? '#'}`}
                >
                  <img
                    src={
                      screenWidth < 481 && banner.mobileImage
                        ? `${INALIPA_S3_URL}web/banners/${banner.mobileImage}`
                        : `${INALIPA_S3_URL}web/${banner.image}`
                    }
                    alt={banner.title}
                    className="img-fluid"
                    height="120"
                    width="120"
                  />
                  {banner.title && (
                    <div className="container">
                      <div className="caption">
                        <h2>{banner.title}</h2>
                      </div>
                    </div>
                  )}
                </Link>
              </SliderWrapper>
            ))}
        </Slider>
      )}
    </BannerWrapper>
  );
};

export default Banner;
