import axios from 'axios';
import {
  MERCANT_API,
  API_KEY,
} from '../constants';
// import { Plugins } from '@capacitor/core';

// const { Device } = Plugins;

const recommendedDealsForYouBody = {
  "placementId": "recommended-for-you",
  "userEvent": {
      "eventType": "home-page-view",
      "visitorId": localStorage.getItem('appNumber')
  }
};


// const relatedToItemsInYourCartBody = {
//     "placementId": "recommended-for-you",
//     "userEvent": {
//         "eventType": "shopping-cart-page-view",
//         "visitorId": localStorage.getItem('appNumber')
//     },
//     "productDetails": [
//         {
//             "product": {
//                 "id": "60506e6e5a78b94a3757e285"
//             }
//         }
//     ]
//   }

const yourFavoriteCategoriesBody = {
  "placementId": "recommended-for-you",
  "userEvent": {
      "eventType": "home-page-view",
      "visitorId": localStorage.getItem('appNumber')
  },
  "predictCategories": true
}

const relatedToYourViewsBody = {
  "placementId": "recently_viewed_default",
  "userEvent": {
      "eventType": "home-page-view",
      "visitorId": localStorage.getItem('appNumber')
  }
}

const contentTypeApplicationJson = {
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Inalipa-Shop-API-KEY': API_KEY,
  },
};

export const sendUserEvent = async (data) => {
    // console.log("body", data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/user-event-collect`,JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const sendProductViewEvent = async (data) => {
    // console.log("body", data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/user-event-collect`,JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const sendAddToCartEvent = async (data) => {
    // console.log("body", data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/user-event-collect`,JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const sendPurchaseCompleteEvent = async (data) => {
    // console.log("body", data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/user-event-collect`,JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getNewArrivals = async () => {
  const result = await axios
    .get(`${MERCANT_API}/recommendations/new-arrivals`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getCurettedSections = async (data) => {
  const result = await axios
    .get(`${MERCANT_API}/curated-products/sections?active=true`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getRecommendedDealsForYou = async () => {
  // console.log('recommendedDealsForYouBody', recommendedDealsForYouBody);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, JSON.stringify(recommendedDealsForYouBody), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getYourFavoriteCategories = async () => {
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, JSON.stringify(yourFavoriteCategoriesBody), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getRelatedToYourViews = async (data) => {
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getBestSellersInYourArea = async () => {
  const result = await axios
    .get(`${MERCANT_API}/recommendations/new-arrivals`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getComfortableStylesForThem = async () => {
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, JSON.stringify(yourFavoriteCategoriesBody), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getRelatedToItemsInYourCart = async (data) => {
  // console.log('relatedToItemsInYourCartBody', data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, JSON.stringify(data), contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getFrequentlyBoughtTogether = async (data) => {
  // console.log('getFrequentlyBoughtTogetherBody', data);
  const result = await axios
    .post(`${MERCANT_API}/recommendations/predict`, data, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getRelatedToYourRecentInterests = async () => {
  const result = await axios
    .get(`${MERCANT_API}/recommendations/new-arrivals`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getTrendingInGamesAndAccessories = async () => {
  const result = await axios
    .get(`${MERCANT_API}/recommendations/new-arrivals`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const getHolidayMovieNight = async () => {
  const result = await axios
    .get(`${MERCANT_API}/recommendations/new-arrivals`, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};