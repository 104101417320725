import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import {
  getBrandsAction,
  resetFiltersAction,
  getProductsAction,
} from '../../store/actions/catalogAction';
import { FlyoutMenuWrapper } from '../styles/Filter';
import CloseIcon from '@material-ui/icons/Close';
import { changeFilterMenuState } from '../../store/actions/uiAction';
import FilterForm from '../filter/FilterForm/FilterForm';
import { Capacitor } from '@capacitor/core';

const FlyoutFilterMenu = (props) => {
  const menuIsOpen = useSelector((state) => state.uiManager.filterState);
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  const filterState = useSelector((state) => state.uiManager.filterState);

  const [canOpen, setCanOpen] = useState(props.canOpen);
  const dispatch = useDispatch();

  const resetFilter = () => {
    //console.log('resetFilter');
    dispatch(resetFiltersAction('on reset button'));
    dispatch(getProductsAction({ sortBy: sortBy }));
  };

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, []);

  const checkIOS = () => {
    return Capacitor.isNative && Capacitor.platform === 'ios' ? '65px' : '0';
  };

  useEffect(() => {
    if (props.canOpen) {
      setCanOpen(props.canOpen);
    } else {
      setCanOpen(false);
    }
  }, [props.canOpen]);

  const closeMenu = () => {
    dispatch(changeFilterMenuState(false));
    setCanOpen(false);
    props.onCloseMenu(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (e.target.className === 'overlay-filter') { 
        closeMenu();
      }
    });
  }, [filterState]);

  return (
    <FlyoutMenuWrapper>
      {canOpen && <div class="overlay-filter"></div>}
      <div
        className={`flyoutFilterMenu ${canOpen ? 'show' : 'hide'}`}
        // style={{ marginTop: checkIOS() }}
      >
        <div className="w-100 p-2">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <button onClick={() => closeMenu()}>
                <CloseIcon />
              </button>
              {/* <h4 className="column pt-2">Filter</h4> */}
            </div>
            {/* <div className="menuRow_reset">
              <button onClick={resetFilter}>Reset</button>
            </div> */}
          </div>
          <FilterForm />
        </div>
      </div>
    </FlyoutMenuWrapper>
  );
};

export default FlyoutFilterMenu;
