import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  // position: absolute;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
   min-height: 200px;
  animation: ${loadingAnimation} 1s infinite;
  @media(min-width: 768px) {
    min-height: 170px;
`;

const ProductPlaceholder = styled.div`
  // position: absolute;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
  margin: 0 10px;
   min-height: 160px;
  animation: ${loadingAnimation} 1s infinite;
  @media(min-width: 768px) {
     margin: 0 5px;
      width: 160px;
  }
`;

const BannerPlaceholder = styled.div`
  // position: absolute;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
  min-height: 130px;
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
  // position: absolute;
  // left: 0;
  //width: 142px;
  // height: 100%;
  // object-fit: cover;
`;

const LazyImage = ({ src, alt, className, product, banner }) => {
    const refPlaceholder = React.useRef();

    const removePlaceholder = () => {
        refPlaceholder.current.remove();
    };

    return (
        <ImageWrapper>
            {!product && !banner && <Placeholder ref={refPlaceholder}/>}
            {banner && <BannerPlaceholder ref={refPlaceholder}/>}
            {product && <ProductPlaceholder ref={refPlaceholder}/>}
            <LazyLoad>
                <img
                    onLoad={removePlaceholder}
                    onError={removePlaceholder}
                    src={src}
                    alt={alt}
                    className={className ?? "img-fluid"}
                />
            </LazyLoad>
        </ImageWrapper>
    );
};

LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

export default LazyImage;
