import React from 'react';
import { MobileFooterWrapper } from '../styles/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../../assets/images/logo-white.png';
import { useTranslation } from 'react-i18next';
import { toggleMobileMenuAction } from '../../store/actions/uiAction';
import { useDispatch } from 'react-redux';
import { useCheckVersion } from '../../utils/versioning';

const MobileFooter = () => {
  let now = moment();
  useCheckVersion();
  const { t } = useTranslation('footer');
  const dispatch = useDispatch();
  const closeSidebar = () => {
    dispatch(toggleMobileMenuAction(false));
  };
  return (
    <MobileFooterWrapper>
      <img src={Logo} alt="Logo" className="img-fluid footer-logo" />
      <div className="d-flex pt-3">
        <ul className="list-inline mx-auto justify-content-center">
          <li className="list-inline-item">
            <Link to="/about-inalipa" onClick={closeSidebar}>
              {t('about')}
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/privacy-policy" onClick={closeSidebar}>
              {t('privacy')}
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms-and-conditions" onClick={closeSidebar}>
              {t('terms_and_conditions')}
            </Link>
          </li>
        </ul>
      </div>

      {/*<div className="d-flex justify-content-center">*/}
      <div className="footer text-center pb-2 logos my-3 d-flex d-flex-column justify-content-center align-items-center">
        <div className="py-2">
          <a
            href="https://www.instagram.com/inalipa_tz/"
            target="__blank"
            rel="noopener noreferrer"
            className="mr-1"
            onClick={closeSidebar}
          >
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x fa-svg-round"
              />
              <FontAwesomeIcon
                icon={faInstagram}
                className="fa-stack-1x fa-inverse fa-logo p-1"
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
          <a
            href="https://www.facebook.com/inalipatz/"
            rel="noopener noreferrer"
            target="__blank"
            onClick={closeSidebar}
          >
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon
                icon={faCircle}
                className=" fa-stack-2x fa-svg-round"
              />
              <FontAwesomeIcon
                icon={faFacebookF}
                className="fa-stack-1x fa-inverse fa-logo p-2"
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
          <a
            href="https://twitter.com/Inalipa_tz"
            target="__blank"
            rel="noopener noreferrer"
            onClick={closeSidebar}
          >
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x fa-svg-round"
              />
              <FontAwesomeIcon
                icon={faTwitter}
                className=" fa-stack-1x fa-inverse fa-logo p-1"
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
        </div>
        <div className="py-2">
          <Link
            to="/become-supplier"
            className="inalipa-btn-primary ml-auto"
            onClick={closeSidebar}
          >
            {' '}
            {t('become_supplier')}
          </Link>
        </div>
      </div>
      {/*</div>*/}
      <div>
        <p>
          &copy; {now.year()}. {t('trademark')}
        </p>
      </div>
      <span className="appVersion">
        App version {localStorage.getItem('appVersion')}
      </span>
    </MobileFooterWrapper>
  );
};

export default MobileFooter;
