import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { BreadcrumbsWrapper } from '../styles/Navigation';
import useFixedHeaderRoute from '../../hooks/useFixedHeaderRoute';

const Breadcrumb = ({ parent, coParent, coParent2, child }) => {
  const location = useLocation();
  const isFixedHeaderRoute = useFixedHeaderRoute(location.pathname);

  return (
    <BreadcrumbsWrapper>
      <div
        className={
          isFixedHeaderRoute.isFixedHeaderRoute
            ? `container fixed-breadcrumb`
            : `container`
        }
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" color="#00C400" />}
          aria-label="breadcrumb"
        >
          {parent && <Link to={parent.link}>{parent.name}</Link>}
          {coParent && <Link to={coParent.link}>{coParent.name}</Link>}
          {coParent2 && <Link to={coParent2.link}>{coParent2.name}</Link>}
          {child && (
            <Typography style={{ color: '#00C400', fontWeight: 'bold' }}>
              {child.name}
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumb;
