import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ItemWrapper } from '../../../styles/slider';

function ProductTileSkeleton() {
  return (
    <>
      <ItemWrapper>
        <h4 className="item-image-wrapper">
          <Skeleton
            className="text-center productImage__Skeleton"
            width={`80%`}
            height={150}
          />
        </h4>
        <h4 className="line-2">
          <Skeleton width={`65%`} height={18} />
        </h4>
        <h4>
          <Skeleton width={`40%`} height={18} />
        </h4>
        <h4>
          <Skeleton width={`40%`} height={18} />
        </h4>
        <h4>
          <SkeletonTheme color="#9dc49d">
            <Skeleton width={`75%`} height={50} />
          </SkeletonTheme>
        </h4>
      </ItemWrapper>
    </>
  );
}

export default ProductTileSkeleton;
