import { GET_AUTOCOMPLETE_OPTIONS } from '../constants/SearchActionTypes';

const initialState = {
  autocompleteOptions: [],
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_AUTOCOMPLETE_OPTIONS:
      return {
        ...state,
        autocompleteOptions: payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
