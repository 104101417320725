import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductsComponent from '../../components/products/Products';
import {
  getFilterOptionsAction,
  getVerticalAction,
  getVerticalProductsAction,
  loadProductAction,
  getProductsAction,
  updateFilterAction,
  setSelectedFilterAction,
  resetSelectedFiltersAction,
} from '../../store/actions/catalogAction';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { scrollToTop } from '../../utils/window';
import { useTranslation } from 'react-i18next';
import {
  setPreviousPathnameAction,
  selectPageAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { INALIPA_MEGASTORE_ID } from '../../constants';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const MegastoreVertical = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('product');
  let { id } = useParams();
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const vertical = useSelector((state) => state.catalogManager.vertical);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const products = useSelector(
    (state) => state.catalogManager.verticalProducts,
  );
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  // const featuredProducts = useSelector(state => (state.catalogManager.verticalFeaturedProducts));

  useEffect(() => {
    // dispatch(getVerticalProductsAction(id));
    if (scrollToLastPosition === false) {
      scrollToTop();
      dispatch(loadProductAction({ Vertical: [id] }));
      dispatch(getVerticalAction(id));
      dispatch(
        getProductsAction({
          Shop: [INALIPA_MEGASTORE_ID],
          Vertical: [id],
          sortBy: sortBy,
        }),
      );
    }
    dispatch(
      getFilterOptionsAction({ Vertical: [id], Shop: [INALIPA_MEGASTORE_ID] }),
    );
    let selectedVertical = {
      key: 'Vertical',
      item: {
        value: id,
        label: 'Vertical',
        id: id,
      },
    };
    let selectedShop = {
      key: 'Shop',
      item: {
        value: INALIPA_MEGASTORE_ID,
        label: 'Shop',
        id: INALIPA_MEGASTORE_ID,
      },
    };
    dispatch(setSelectedFilterAction(selectedVertical));
    dispatch(setSelectedFilterAction(selectedShop));

    dispatch(selectPageAction({ name: 'Vertical', filter: [id] }));
    // dispatch(
    //   updateFilterAction({ key: 'Vertical', item: INALIPA_MEGASTORE_ID }),
    // );
    // dispatch(getVerticalFeaturedProductsAction(id));
    // return () => dispatch(resetSelectedFiltersAction());
  }, [id]);
  useEffect(() => {
    let previousRoutes = {
      parent: { name: 'Stores', link: 'stores/' },
      coParent: { name: 'Inalipa Megastore', link: '/megastore' },
      coParent2: { name: vertical.name, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));
    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'inalipa_megastore_vertical',
        item_name: vertical.name,
        item_id: id,
      },
    });
  }, [vertical]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <>
      <PageTitle title={'Inalipa - Megastore - ' + vertical.name} />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: 'stores/', name: 'Stores' }}
        coParent={{ link: '/stores/megastore', name: 'Inalipa Megastore' }}
        child={{ name: vertical.name }}
      />
      <div className="container pt-4">
        <ProductsComponent />
      </div>
    </>
  );
};

export default MegastoreVertical;
