import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { VerticalScrollCardSkeleton } from './VerticalScrollCardSkeleton';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CategoriesVerticalSliderSkeleton = ({ recommended }) => {
  let categoriesCount = 1;
  if (recommended === true) {
    categoriesCount = 5;
  }
  if (recommended === false) {
    categoriesCount = 9;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    scroll: true,
    slidesToShow: 7.2,
    slidesToScroll: 4,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7.5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 4,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 3,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-full">
        {recommended && (
          <>
            <div className="row pl-0 pt-1 section-header">
              <div className="col-8 pl-0 text-left">
                <h2 className="pl-0">
                  <div className="skeleton-section-title">
                    <Skeleton height={40} />
                  </div>
                </h2>
              </div>
            </div>
            <Slider {...settings}>
              {Array(categoriesCount)
                .fill()
                .map((item, index) => (
                  <VerticalScrollCardSkeleton
                    key={index}
                    categoriesCount={categoriesCount}
                    type={'square'}
                  />
                ))}
            </Slider>
          </>
        )}
        {!recommended && (
          <Slider {...settings}>
            {Array(categoriesCount)
              .fill()
              .map((item, index) => (
                <VerticalScrollCardSkeleton
                  key={index}
                  categoriesCount={categoriesCount}
                  type={'circle'}
                />
              ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default CategoriesVerticalSliderSkeleton;
