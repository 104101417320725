import styled from 'styled-components';

export const HomePageWrapper = styled.div`
  @media (min-width: 767px) {
    h2 {
      font-size: 26px;
    }
  }

  // @media (max-width: 600px) {
  //   h2 {
  //     font-size: 16px;
  //   }
  // }
`;
