import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEye } from '@fortawesome/free-solid-svg-icons';
import Link from '@material-ui/core/Link';
import AppModal from '../../modals/PostReviewModal';
import OpeningHoursModal from '../../modals/OpeningHoursModal';
import { timeTo12Hrs } from '../../../utils/format';

const OpeningHours = ({ shopSettings }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  };
  const getOpenClosedStatus = (settings) => {
    let isOpen = {};
    let hours = settings.opening_hours;
    let now = moment();
    let today = now.format('ddd');
    let currentDay = today.toLowerCase();
    let currentHourMinute = now.format('HH:mm:ss');
    if (hours) {
      hours.forEach(function (day) {
        if (day.day === currentDay) {
          //set timeFrom
          let timeFrom = day.timeFrom + ':00';
          //set timeTO
          let timeTo = day.timeTo + ':00';
          let openHours = day.timeFrom;
          let closeHours = day.timeTo;
          if (timeFrom < currentHourMinute && timeTo > currentHourMinute) {
            isOpen = {
              open: openHours,
              close: closeHours,
            };
          }
          if (day.nextDay) {
            isOpen = {
              open: openHours,
              close: closeHours,
            };
          }
        }
      });
    }
    return isOpen;
  };

  const hours = getOpenClosedStatus(shopSettings);

  return (
    <>
      {Object.keys(hours).length > 0 ? (
        <div
          className={`col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 pl-0 ${
            window.innerWidth > 575 ? ' pr-0 text-right mb-1' : 'mb-3'
          }`}
        >
          <strong style={{ color: '#00c400' }} className="">
            {' '}
            <FontAwesomeIcon icon={faClock} /> &nbsp; Open:{' '}
            {timeTo12Hrs(hours.open)} - {timeTo12Hrs(hours.close)}
          </strong>
        </div>
      ) : (
        <div
          className={`col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 pl-0 ${
            window.innerWidth > 575 ? ' pr-0 text-right mb-1' : 'mb-3'
          }`}
        >
          <strong className="text-danger">
            {' '}
            <FontAwesomeIcon icon={faClock} /> Closed
          </strong>
        </div>
      )}
      <div
        className={`col-6 col-sm-3 col-md-3 col-lg-2 col-xl-2 ${
          window.innerWidth > 575 ? ' pr-0 text-right mb-1' : 'pl-0 mb-3'
        }`}
      >
        <Link
          to="#"
          onClick={openModal}
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          className="btn btn-outline-success btn-sm"
        >
          <span style={{ color: '#00c400' }}>
            <FontAwesomeIcon icon={faEye} /> Opening hours
          </span>
        </Link>
      </div>
      <AppModal
        isOpen={modalIsOpen}
        component={
          <OpeningHoursModal
            hours={shopSettings.opening_hours}
            closeModal={closeModal}
          />
        }
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default OpeningHours;
