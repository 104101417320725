import styled from "styled-components";

export const PostReviewWrapper = styled.div`
    .header {
        padding: 16px 56px 0px 24px;
        min-height: 70px;
        border-bottom: 1px solid #ccc;
    }
    
    .header > h3 {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .float-right {
        position: absolute;
        top: 16px;
        right: 24px;
        padding: 0;
        margin: 0;
        line-height: 20px;
    }
    
button:disabled .btn-submit,
button[disabled] .btn-submit{
background-color: #a2a8a2;
color: #000;
}
    .MuiRating-root {
        color: #ffb400;
        cursor: pointer;
        display: inline-flex;
        position: relative;
        font-size: 1.5rem;
        text-align: left;
        -webkit-tap-highlight-color: transparent;
    }
`;