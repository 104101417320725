import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import FilterGroup from '../FilterGroup';
import './FilterForm.css';
import {
  getProductsAction,
  loadProductAction,
  removeFilterAction,
  resetFiltersAction,
  getFilterOptionsAction,
  setSelectedFilterAction,
  setFilterAction,
  resetProductsAction,
  getBrandFilterOptionsAction,
} from '../../../store/actions/catalogAction';
import { INALIPA_MEGASTORE_ID } from '../../../constants';
import {
  changeFilterMenuState,
  setActiveAccordionAction,
} from '../../../store/actions/uiAction';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiltersSectionSkeleton from '../../skeletons/elements/filters/FiltersSectionSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexShrink: 0,
    width: '60%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    width: '40%',
  },
}));

export const FilterForm = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(null);
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const isLoadingFilters = useSelector(
    (state) => state.catalogManager.isLoadingFilters,
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [selectedFiltersx, setSelectedFiltersx] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(setActiveAccordionAction(''));
  };

  const selectedFilters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );
  const filterOptions = useSelector(
    (state) => state.catalogManager.filterOptions,
  );
  const selectedPage = useSelector((state) => state.uiManager.selectedPage);
  const activeAccordionPanel = useSelector(
    (state) => state.uiManager.activeAccordionPanel,
  );
  const shop = useSelector((state) => state.shopManager.shop);
  const brand = useSelector((state) => state.catalogManager.brand);
  const params = useSelector((state) => state.catalogManager.selectedFIlters);
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  const canRefetchProducts = useSelector(
    (state) => state.catalogManager.canRefetchProducts,
  );

  const removeItem = (item, filter) => {
    dispatch(loadProductAction());
    dispatch(removeFilterAction(item, filter));
  };

  const location = useLocation();
  let { id } = useParams();

  const resetFilters = () => {
    dispatch(setActiveAccordionAction('panel1'));
    dispatch(resetFiltersAction('on reset button'));
    // dispatch(clearAllFiltersAction(true));
    dispatch(resetProductsAction());
    console.log('we reset the filters');
    if (location.pathname === `/megastore/${id}`) {
      dispatch(
        getProductsAction({
          Shop: [INALIPA_MEGASTORE_ID],
          Vertical: [id],
          sortBy: sortBy,
        }),
      );
    } else {
      dispatch(
        getProductsAction(
          selectedPage
            ? { [selectedPage.name]: selectedPage.filter, sortBy: sortBy }
            : { sortBy: sortBy },
        ),
      );
    }
    // console.log('Location pathname + id', location.pathname, id);
    switch (location.pathname) {
      case `/stores/${shop.subDomain}`:
        if (!_.isEmpty(shop)) {
          let selectedVertical = {
            key: 'subDomain',
            item: { value: shop.subDomain, label: shop.name, id: shop._id },
          };
          dispatch(getFilterOptionsAction({ Shop: [shop._id] }));
          dispatch(setSelectedFilterAction(selectedVertical));
        }
        break;
      case `/category/${id}`:
        dispatch(getFilterOptionsAction({ Vertical: [id] }));
        if (filterOptions.verticals) {
          let vertical =
            filterOptions.verticals &&
            Array.isArray(filterOptions.verticals) &&
            filterOptions.verticals[0];
          if (vertical) {
            let selectedVertical = {
              key: 'Vertical',
              item: { value: vertical._id, label: vertical.name },
            };
            // dispatch(setFilterAction(selectedVertical));
            dispatch(setSelectedFilterAction(selectedVertical));
          }
        }
        break;
      case `/brand/${id}`:
        let selectedBrand = {
          key: 'Brand',
          item: { value: brand._id, label: brand.name },
        };
        dispatch(setFilterAction(selectedBrand));
        dispatch(getBrandFilterOptionsAction({ Brand: id }));
        break;
      case `/megastore/${id}`:
        dispatch(
          getFilterOptionsAction({
            Vertical: [id],
            Shop: [INALIPA_MEGASTORE_ID],
          }),
        );
        let selectedVertical = {
          key: 'Vertical',
          item: {
            value: id,
            label: 'Vertical',
            id: id,
          },
        };
        let selectedShop = {
          key: 'Shop',
          item: {
            value: INALIPA_MEGASTORE_ID,
            label: 'Shop',
            id: INALIPA_MEGASTORE_ID,
          },
        };
        dispatch(setSelectedFilterAction(selectedVertical));
        dispatch(setSelectedFilterAction(selectedShop));
        break;
      case `/products`:
        dispatch(getFilterOptionsAction({}));
        break;
      default:
        return true;
    }
    dispatch(changeFilterMenuState(false));
    dispatch(loadProductAction());
    // dispatch(clearAllFiltersAction(false));
  };

  useEffect(() => {
    setPage(location.pathname.split('/')[1]);
    setExpanded('panel1');
    return () => {
      dispatch(setActiveAccordionAction(''));
    };
  }, []);

  useEffect(() => {
    if (activeAccordionPanel && activeAccordionPanel.length > 0) {
      setExpanded(activeAccordionPanel);
    }
  }, [activeAccordionPanel]);

  useEffect(() => {
    if (canRefetchProducts) {
      //   console.log('canRefetchProducts params + name ', params, name);
      dispatch(getProductsAction({ ...params, sortBy: sortBy }));
      // setTimeout(() => setSelectedFiltersx(params), 1000);
      if (location.pathname === `/brand/${id}`) {
        // console.log(params);
        dispatch(getBrandFilterOptionsAction(params));
      } else {
        dispatch(getFilterOptionsAction(params));
      }
    }
  }, [canRefetchProducts]);

  return (
    <>
      {(isLoadingFilters || _.isEmpty(filterOptions)) && (
        <FiltersSectionSkeleton />
      )}
      {(!_.isEmpty(selectedFilters) ||
        (selectedFilters &&
          location.pathname === '/products' &&
          selectedFilters['Vertical'] &&
          selectedFilters['Vertical'].length > 0)) && (
        <div className="appliedFilters">
          <div className="appliedFilters_row">
            <button onClick={() => resetFilters()}>Clear All</button>
          </div>
        </div>
      )}
      {!isLoadingFilters && (
        <div className="filters-div">
          {filterOptions &&
            filterOptions.verticals &&
            filterOptions.verticals.length > 0 &&
            location.pathname === '/products' && (
              <>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      style={{ display: 'inline-block' }}
                      className={classes.heading}
                    >
                      Verticals
                    </Typography>
                    {selectedFilters &&
                      selectedFilters['Vertical'] &&
                      selectedFilters['Vertical'][0] &&
                      selectedFilters['Vertical'][0].label && (
                        <>
                          <br />
                          <Typography
                            style={{ display: 'inline-block' }}
                            className={classes.secondaryHeading}
                          >
                            {selectedFilters['Vertical'][0].label}
                          </Typography>
                        </>
                      )}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      <FilterGroup
                        name="Vertical"
                        title="Category"
                        options={
                          filterOptions.verticals &&
                          filterOptions.verticals.map((vertical) => {
                            return {
                              label: vertical.name,
                              value: vertical._id,
                            };
                          })
                        }
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {selectedFilters &&
                  selectedFilters['Vertical'] &&
                  selectedFilters['Vertical'].length > 0 &&
                  filterOptions &&
                  filterOptions['verticals'].length > 0 &&
                  _.find(filterOptions['verticals'], {
                    _id: selectedFilters['Vertical'][0].value,
                  }) &&
                  _.find(filterOptions['verticals'], {
                    _id: selectedFilters['Vertical'][0].value,
                  }).categories &&
                  _.find(filterOptions['verticals'], {
                    _id: selectedFilters['Vertical'][0].value,
                  }).categories.length > 0 && (
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          style={{ display: 'inline-block' }}
                          className={classes.heading}
                        >
                          Categories
                        </Typography>
                        {selectedFilters &&
                          selectedFilters['Category'] &&
                          selectedFilters['Category'][0] &&
                          selectedFilters['Category'][0].label && (
                            <>
                              <br />
                              <Typography
                                style={{ display: 'inline-block' }}
                                className={classes.secondaryHeading}
                              >
                                {selectedFilters['Category'][0].label}
                              </Typography>
                            </>
                          )}
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>
                          <FilterGroup
                            name="Category"
                            title="Category"
                            options={
                              selectedFilters &&
                              selectedFilters['Vertical'] &&
                              _.find(filterOptions['verticals'], {
                                _id: selectedFilters['Vertical'][0].value,
                              }) &&
                              _.find(filterOptions['verticals'], {
                                _id: selectedFilters['Vertical'][0].value,
                              }).categories &&
                              _.find(filterOptions['verticals'], {
                                _id: selectedFilters['Vertical'][0].value,
                              }).categories.length > 0 &&
                              _.find(filterOptions['verticals'], {
                                _id: selectedFilters['Vertical'][0].value,
                              }).categories.map((category) => {
                                return {
                                  label: category.name,
                                  value: category._id,
                                };
                              })
                            }
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
                {selectedFilters &&
                  selectedFilters['Category'] &&
                  selectedFilters['Category'].length > 0 &&
                  filterOptions &&
                  filterOptions['categories'].length > 0 &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }) &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories.length > 0 && (
                    <Accordion
                      expanded={expanded === 'panel3'}
                      onChange={handleChange('panel3')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          Sub Categories
                        </Typography>
                        {selectedFilters &&
                          selectedFilters['SubCategory'] &&
                          selectedFilters['SubCategory'].length > 0 && (
                            <>
                              <br />
                              <Typography className={classes.secondaryHeading}>
                                {selectedFilters['SubCategory'].length === 1
                                  ? selectedFilters['SubCategory'][0].label
                                  : `${selectedFilters['SubCategory'].length} selected`}
                              </Typography>
                            </>
                          )}
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>
                          <FilterGroup
                            name="SubCategory"
                            title="SubCategory"
                            options={
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }) &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.length > 0 &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.map((category) => {
                                return {
                                  label: category.name,
                                  value: category._id,
                                };
                              })
                            }
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
              </>
            )}

          {filterOptions &&
            filterOptions.verticals &&
            filterOptions.verticals.length > 0 &&
            location.pathname !== '/products' &&
            location.pathname !== `/brand/${id}` && (
              <>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      Categories
                    </Typography>
                    {selectedFilters &&
                      selectedFilters['Category'] &&
                      selectedFilters['Category'][0] &&
                      selectedFilters['Category'][0].label && (
                        <>
                          <br />
                          <Typography className={classes.secondaryHeading}>
                            {selectedFilters['Category'][0].label}
                          </Typography>
                        </>
                      )}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      <FilterGroup
                        name="Category"
                        title="Category"
                        options={_.flatten(
                          filterOptions.verticals &&
                            filterOptions.verticals.map(
                              (vertical) =>
                                vertical.categories &&
                                vertical.categories.map((category) => {
                                  return {
                                    label: category.name,
                                    value: category._id,
                                  };
                                }),
                            ),
                        )}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {selectedFilters &&
                  selectedFilters['Category'] &&
                  selectedFilters['Category'].length > 0 &&
                  filterOptions &&
                  filterOptions['categories'].length > 0 &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }) &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories.length > 0 && (
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          Sub Categories
                        </Typography>
                        {selectedFilters &&
                          selectedFilters['SubCategory'] &&
                          selectedFilters['SubCategory'].length > 0 && (
                            <>
                              <br />
                              <Typography className={classes.secondaryHeading}>
                                {selectedFilters['SubCategory'].length === 1
                                  ? selectedFilters['SubCategory'][0].label
                                  : `${selectedFilters['SubCategory'].length} selected`}
                              </Typography>
                            </>
                          )}
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>
                          <FilterGroup
                            name="SubCategory"
                            title="SubCategory"
                            options={
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }) &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.length > 0 &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.map((category) => {
                                return {
                                  label: category.name,
                                  value: category._id,
                                };
                              })
                            }
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
              </>
            )}

          {filterOptions &&
            filterOptions.categories &&
            filterOptions.categories.length > 0 &&
            location.pathname === `/brand/${id}` && (
              <>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      style={{ display: 'inline-block' }}
                      className={classes.heading}
                    >
                      Categories
                    </Typography>
                    {selectedFilters &&
                      selectedFilters['Category'] &&
                      selectedFilters['Category'][0] &&
                      selectedFilters['Category'][0].label && (
                        <>
                          <br />
                          <Typography
                            style={{ display: 'inline-block' }}
                            className={classes.secondaryHeading}
                          >
                            {selectedFilters['Category'][0].label}
                          </Typography>
                        </>
                      )}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      <FilterGroup
                        name="Category"
                        title="Category"
                        options={
                          filterOptions.categories &&
                          filterOptions.categories.map((category) => {
                            return {
                              label: category.name,
                              value: category._id,
                            };
                          })
                        }
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {selectedFilters &&
                  selectedFilters['Category'] &&
                  selectedFilters['Category'].length > 0 &&
                  filterOptions &&
                  filterOptions['categories'].length > 0 &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }) &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories &&
                  _.find(filterOptions['categories'], {
                    _id: selectedFilters['Category'][0].value,
                  }).subCategories.length > 0 && (
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          Sub Categories
                        </Typography>
                        {selectedFilters &&
                          selectedFilters['SubCategory'] &&
                          selectedFilters['SubCategory'].length > 0 && (
                            <>
                              <br />
                              <Typography className={classes.secondaryHeading}>
                                {selectedFilters['SubCategory'].length === 1
                                  ? selectedFilters['SubCategory'][0].label
                                  : `${selectedFilters['SubCategory'].length} selected`}
                              </Typography>
                            </>
                          )}
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>
                          <FilterGroup
                            name="SubCategory"
                            title="SubCategory"
                            options={
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }) &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.length > 0 &&
                              _.find(filterOptions['categories'], {
                                _id: selectedFilters['Category'][0].value,
                              }).subCategories.map((category) => {
                                return {
                                  label: category.name,
                                  value: category._id,
                                };
                              })
                            }
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
              </>
            )}

          {filterOptions.brands &&
            page !== 'brand' &&
            filterOptions.brands &&
            filterOptions.brands.length > 0 && (
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>Brands</Typography>
                  {selectedFilters &&
                    selectedFilters['Brand'] &&
                    selectedFilters['Brand'].length > 0 && (
                      <>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          {selectedFilters['Brand'].length === 1
                            ? selectedFilters['Brand'][0].label
                            : `${selectedFilters['Brand'].length} selected`}
                        </Typography>
                      </>
                    )}
                </AccordionSummary>

                <AccordionDetails>
                  <Typography>
                    <FilterGroup
                      name="Brand"
                      title="Brand"
                      options={filterOptions.brands.map((brand) => ({
                        label: brand.name,
                        value: brand._id,
                      }))}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
        </div>
      )}
    </>
  );
};
export default FilterForm;
