import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFOundArt } from '../../assets/images/notFound_inalipa_art.svg';
import PageTitle from '../../components/utils/PageTitle';
import { resetShopFoundStatusAction } from '../../store/actions/shopAction';
import './NotFound.css';
const NotFound = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log('Not found added');
    return () => dispatch(resetShopFoundStatusAction());
  }, []);

  return (
    <>
      <PageTitle title={'Inalipa - Page Not Found'} />
      <div className="NotFound">
        <div className="NotFound__image">
          <NotFOundArt />
        </div>

        <h1>404</h1>
        <p>Sorry , there's nothing here :-(</p>
        <Link to="/">Back to homepage</Link>
      </div>
    </>
  );
};

export default NotFound;
