import styled from "styled-components";

export const TrackOrderResultsWrapper = styled.div`
.order-timeline{
    position: relative;
}

.order-timeline hr{
    position: absolute;
    width: 100%;
    top: 20%;
    z-index: 0;
}

.timeline-step .check{
    background: #969696;
    border-radius: 50%;
    padding: 5px;
}

.timeline-step .check.active{
    background: #00C400;
}

.timeline-step .check.active img{
    opacity: 1;
}

.timeline-step img{
    width: 20px;
    opacity: .1;
}

.timeline-step .state{
    margin-top: 10px;
    color: #969696;
}

// .timeline-step .check span{
//     float: left;
//     width: 100%;
// }
`;