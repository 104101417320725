import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  updateFilterAction,
  loadProductAction,
  removeFilterAction,
} from '../../store/actions/catalogAction';
import { ListItem, Checkbox, ListItemText } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { changeFilterMenuState } from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';

const FilterGroupListItem = ({ option, options, name, labelId }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [closed, setClosed] = useState(false);
  const [selectedOptionsArr, setSelectedOptionsArr] = useState([]);
  const brand = useSelector((state) => state.catalogManager.brand);
  const params = useSelector((state) => state.catalogManager.selectedFIlters);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const rememberedSelectedFilters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );

  const location = useLocation();
  let { id } = useParams();

  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const isFiltersUpdated = useSelector(
    (state) => state.catalogManager.isFiltersUpdated,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isFiltersUpdated) {
      setSelectedFilters(params);
    }
  }, [isFiltersUpdated]);

  useEffect(() => {
    let clonedOptionsArr = [...selectedOptionsArr];
    if (brand) {
      clonedOptionsArr.push(brand._id);
      setSelectedOptionsArr(clonedOptionsArr);
    }
    if (params[name]) {
      setSelectedFilters(params[name]);
    }
  }, []);

  const handleToggle = (option, filterType) => () => {
    scrollToTop();
    setClosed(!closed);
    dispatch(changeFilterMenuState(false));
    let filterName = filterType ? filterType : name;
    let filterOptions = params[filterName] ? params[filterName] : [];
    setSelectedFilters(params[filterName]);
    if (location.pathname !== `/brand/${id}` && filterName === 'SubCategory') {
      if (params['Brand'] && params['Brand'].length > 0) {
        params['Brand'].map((brand) =>
          dispatch(removeFilterAction(brand, 'Brand')),
        );
      }
    }
    dispatch(loadProductAction());
    if (_.findIndex(filterOptions, ['value', option.value]) === -1) {
      dispatch(updateFilterAction({ key: filterName, item: option }));
    } else {
      dispatch(removeFilterAction(option, filterName));
    }
  };
  return (
    <>
      <ListItem
        key={option.value}
        role={undefined}
        dense
        button
        onClick={handleToggle(option, name)}
      >
        <ListItemIcon>
          <Checkbox
            key={option.value}
            edge="start"
            checked={
            (  selectedFilters &&
              _.findIndex(selectedFilters[name], ['value', option.value]) !== -1)
              ||
            (rememberedSelectedFilters &&
              _.findIndex(rememberedSelectedFilters[name], ['value', option.value]) !== -1)
            }
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          key={option.value}
          id={labelId}
          primary={option.label}
          style={{
            fontFamily: 'DM Sans !important',
            fontWeight: '300 !important',
          }}
        />
      </ListItem>
    </>
  );
};

export default FilterGroupListItem;
