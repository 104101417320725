export const GET_NEW_ARRIVALS = "GET_NEW_ARRIVALS";
export const GET_RECOMMENDED_DEALS_FOR_YOU = "GET_RECOMMENDED_DEALS_FOR_YOU";
export const GET_YOUR_FAVORITE_CATEGORIES = "GET_YOUR_FAVORITE_CATEGORIES";
export const GET_RELATED_TO_YOUR_VIEWS = "GET_RELATED_TO_YOUR_VIEWS";
export const GET_RELATED_TO_YOUR_RECENT_INTERESTS = "GET_RELATED_TO_YOUR_RECENT_INTERESTS";
export const GET_RELATED_TO_ITEMS_IN_YOUR_CART = "GET_RELATED_TO_ITEMS_IN_YOUR_CART";
export const SEND_USER_EVENT = "SEND_USER_EVENT";
export const SEND_PRODUCT_VIEW_EVENT = "SEND_PRODUCT_VIEW_EVENT";
export const SEND_ADD_TO_CART_EVENT = "SEND_ADD_TO_CART_EVENT";
export const SEND_PURCHASE_COMPLETE_EVENT = "SEND_PURCHASE_COMPLETE_EVENT";
export const GET_FREQUENTLY_BOUGHT_TOGETHER = "GET_FREQUENTLY_BOUGHT_TOGETHER";
export const GET_CURETTED_SECTIONS = "GET_CURETTED_SECTIONS";
export const CLEAR_CURATED_SECTIONS = "CLEAR_CURATED_SECTIONS";
export const SET_RECOMMENDED_SECTIONS_LOADING = "SET_RECOMMENDED_SECTIONS_LOADING";
export const SET_CART_RECOMMENDED_SECTIONS_LOADING = "SET_CART_RECOMMENDED_SECTIONS_LOADING";
export const GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING = "GET_FREQUENTLY_BOUGHT_TOGETHER_SECTION_LOADING";