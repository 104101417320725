import React, { useMemo, forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MobileFooter from '../ui/MobileFooter';
import { SideBarNavWrapper } from '../styles/Navigation';
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import {useDispatch} from "react-redux";
import {toggleMobileMenuAction} from "../../store/actions/uiAction";
import SideBarMobileFooter from './SideBarMobileFooter';
import HomeIcon from '../../assets/icons/homeIcon';
import OrderHistoryIcon from '../../assets/icons/orderHistoryIcon';
import MotorcycleIcon from '../../assets/icons/motorcycleIcon';
import ReviewsIcon from '../../assets/icons/reviewsIcon';
import ContactUsIcon from '../../assets/icons/contactUsIcon';
import Logo from '../../assets/images/logo.png'

const SidebarNav = () => {
    const { t } = useTranslation("menu");
    const dispatch = useDispatch();
    const location = useLocation();
    const closeSidebar = () => {
        dispatch(toggleMobileMenuAction(false));
        document.body.style.overflow = 'unset';
    };




    return (
        <SideBarNavWrapper>

        <div className="side-bar-mobile-menu-container">
        <div className="brand-container">
              <div className="navbar-brand text-info font-weight-bolder" to="/">
                <img src={Logo} alt="Logo" className="side-bar-mobile-img-fluid" />
              </div>
            </div>
        </div>
        <div className="side-bar-mobile-menu-container">

            <Link to="/" onClick={closeSidebar}>     
            <div className={location.pathname==="/"?"side-bar-mobile-page-content active":"side-bar-mobile-page-content inactive"}>
                    <div className='side-bar-mobile-menu-container-item'> 
                    <HomeIcon active={location.pathname === '/'} />
                       <span style={{ paddingLeft: '16px' , fontSize: '18px' }} >{t('home')}</span> 
                    </div>
            </div>
            </Link>
            
            <Link to="/past-orders" onClick={closeSidebar}>     
            <div className={location.pathname==="/past-orders"?"side-bar-mobile-page-content active":"side-bar-mobile-page-content inactive"}>
                    <div className='side-bar-mobile-menu-container-item'> 
                    <OrderHistoryIcon active={location.pathname === '/past-orders'} />
                       <span style={{ paddingLeft: '16px' , fontSize: '18px' }} > {t('past_orders')}</span> 
                    </div>
            </div>
            </Link>
            <Link to="/track-orders" onClick={closeSidebar}>     
            <div className={location.pathname==="/track-orders"?"side-bar-mobile-page-content active":"side-bar-mobile-page-content inactive"}>
                    <div className='side-bar-mobile-menu-container-item'> 
                    <MotorcycleIcon active={location.pathname === '/track-orders'} />
                       <span style={{ paddingLeft: '16px' , fontSize: '18px' }} >{t('track_order')}</span> 
                    </div>
            </div>
            </Link>
            <Link to="/reviews" onClick={closeSidebar}>     
            <div className={location.pathname==="/reviews"?"side-bar-mobile-page-content active":"side-bar-mobile-page-content inactive"}>
                    <div className='side-bar-mobile-menu-container-item'> 
                    <ReviewsIcon active={location.pathname === '/reviews'} />
                       <span style={{ paddingLeft: '16px' , fontSize: '18px' }} >{t('reviews')}</span> 
                    </div>
            </div>
            </Link>
            <Link to="/need-support" onClick={closeSidebar}>     
            <div className={location.pathname==="/need-support"?"side-bar-mobile-page-content active":"side-bar-mobile-page-content inactive"}>
                    <div className='side-bar-mobile-menu-container-item'> 
                    <ContactUsIcon active={location.pathname === '/need-support'} />
                       <span style={{ paddingLeft: '16px' , fontSize: '18px' }} >{t('contact_inalipa')}</span> 
                    </div>
            </div>
            </Link>
        </div>
         
 
        <div className="side-bar-mobile-section-container" >
                <div className="side-bar-mobile-language-header">
                    {t('language')}
                </div>
                <div style={{ MarginRight: '16px' }}>
                    <LanguageSelector type="radio" />
                </div>
            </div><div className="horizontal-line" />
            <SideBarMobileFooter />
        </SideBarNavWrapper>
    );
};

export default SidebarNav;
