import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from 'react-google-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCity,
  getArea,
  getState,
  getLocationInfoFromCoordinates,
} from '../../utils/location';
import './LocationPicker.css';
import { setRegion } from 'react-geocode';
import { updateLocationAction } from '../../store/actions/locationAction';

function Picker() {
  const { t } = useTranslation('checkout');
  const isLocationSaved = useSelector(
    (state) => state.locationManager.isLocationSaved,
  );
  const storedAddress = useSelector((state) => state.locationManager.address);
  const [canPickLocation, setCanPickLocation] = useState(false);
  const dispatch = useDispatch();

  const onPlaceSelected = (place) => {
    const address = place.formatted_address;
    const locationInfoArr = place.address_components;
    const district = getCity(locationInfoArr);
    const area = getArea(locationInfoArr);
    const region = getState(locationInfoArr);
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    let coordinates = { lat, lng };
    let data = {
      address,
      district,
      area,
      region,
      mapPosition: coordinates,
      markerPosition: coordinates,
      zoom: 15,
      showPolygon: false,
    };
    dispatch(updateLocationAction(data));
    // console.log('area + district + region + coordinates + data=  ', area , district,region,coordinates,data);
  };

  const handlePickCurrentLocation = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (value) {
      navigator.geolocation.getCurrentPosition((position) => {
        let locationInfo = getLocationInfoFromCoordinates(
          position.coords.latitude,
          position.coords.longitude,
        );
        // console.log("locationInfo = " , locationInfo)
      });

      //dispatch(getCurrentLocationAction())
    }

    setCanPickLocation(value);
  };

  return (
    <div>
      <label>{t('address')}</label>
      <Autocomplete
        types={['establishment', 'geocode']}
        onPlaceSelected={onPlaceSelected}
        className="w-input autocomplete-input"
        componentRestrictions={{ country: 'tz' }}
        placeholder={isLocationSaved ? storedAddress : t('type_address')}
      />
      <h5>OR</h5>
      <div className="current-location-picker">
        <input
          type="checkbox"
          name="canPickLocation"
          onChange={handlePickCurrentLocation}
          checked={canPickLocation}
        />
        <label htmlFor="discount">{t('location')}</label>
      </div>
    </div>
  );
}

export default Picker;
