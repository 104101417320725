import React from 'react';
import ImageWithLogo from './ImageWithLogo';
import { Card } from '../styles/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { MegaStoreImageWrapper, MegaStoreLogoHalfCircle } from '../styles/List';
import LazyImage from './LazyLoadingImage';
import useLazyLoadImage from '../../hooks/useLazyLoadImage';
import placeholder from '../../assets/images/placeholders/item.png';
import moment from 'moment';

const BusinessCard = ({ image, logo, title, settings, description }) => {
  const loaded = useLazyLoadImage(image);
  const { t } = useTranslation('stores');
  const getOpenClosedStatus = () => {
    let isOpen = false;
    let hours = settings.opening_hours;
    let now = moment();
    let today = now.format('ddd');
    let currentDay = today.toLowerCase();
    let currentHourMinute = now.format('HH:mm:ss');
    if (hours) {
      hours.forEach(function (day) {
        if (day.day === currentDay) {
          //set timeFrom
          let timeFrom = day.timeFrom + ':00';
          //set timeTO
          let timeTo = day.timeTo + ':00';
          if (timeFrom < currentHourMinute && timeTo > currentHourMinute) {
            isOpen = true;
          }
          if (day.nextDay) {
            isOpen = true;
          }
        }
      });
    }
    return isOpen;
  };
  return (
    <Card>
      <MegaStoreImageWrapper>
        <img
          src={image}
          className="img-fluid img-list"
          height="120"
          width="120"
          alt="banner"
        />
        <MegaStoreLogoHalfCircle>
          <img
            src={logo}
            className="img-fluid"
            height="120px"
            width="120px"
            alt="logo"
          />
        </MegaStoreLogoHalfCircle>
      </MegaStoreImageWrapper>
      <h3>{title}</h3>
      {/* <p>
        <FontAwesomeIcon icon={faClock} /> &nbsp; {t('inalipa_hours')}
      </p> */}
      <p>
        <div className="">{description}</div>
        {/* {getOpenClosedStatus(settings) ? <div className="open ">{t('open')}</div> : <div className="closed ">{t('closed')}</div> */}
        {<div className="open">{t('open')}</div>}
      </p>
    </Card>
  );
};

export default BusinessCard;
