import React from "react";
import ImageWithLogo from "./ImageWithLogo";
import { Card } from "../styles/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";

const StoreCard = ({ image, logo, title, category, username, settings }) => {
    const { t } = useTranslation("home");
    const getOpenClosedStatus = (settings) => {
        let isOpen = false;
        let hours = settings.opening_hours;
        let now = moment();
        let today = now.format("ddd");
        let currentDay = today.toLowerCase();
        let currentHourMinute = now.format("HH:mm:ss");
        if(hours) {
            hours.forEach(function (day) {
                if(day.day === currentDay) {

                    //set timeFrom
                    let timeFrom = day.timeFrom + ':00';
                    //set timeTO
                    let timeTo = day.timeTo + ':00';
                    if (timeFrom < currentHourMinute && timeTo > currentHourMinute) {
                        isOpen = true;
                    }
                    if(day.nextDay) {
                        isOpen = true;
                    }
                }
            });
        }
        return isOpen;
    }
  return (
    <Card>
        <div className="img-store">
            <ImageWithLogo image={image} logo={logo} />
        </div>
      <h3>{title}</h3>
      <h4>{category ?? ""}</h4>
        {getOpenClosedStatus(settings) ? <div className="open font-defaults">{t('open')}</div> : <div className="pre-order font-defaults">{t('pre_order')}</div>}
    </Card>
  );
};

export default StoreCard;
