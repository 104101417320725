import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FlyoutMenuWrapper } from '../styles/Filter';
import TopMenu from '../ui/TopMenu';
import SidebarNav from '../ui/SidebarNav';
import {useDispatch} from "react-redux";
import { toggleMobileMenuAction } from '../../store/actions/uiAction';

const FlyoutMobileMenu = () => {
  const menuIsOpen = useSelector((state) => state.uiManager.isMobileMenuOpen);
  const dispatch = useDispatch();
  const closeSidebar = () => {
    dispatch(toggleMobileMenuAction(false));
    document.body.style.overflow = 'unset';
};


useEffect(() => {
  // Click away on overlay to close
  document.addEventListener('click', (e) => {
    if (e.target.className === 'overlay') {
      closeSidebar();
    }
  });
}, []);

  return (
    <FlyoutMenuWrapper>
      
      <div className={menuIsOpen ? 'overlay' : ''}></div>
      <div  className={menuIsOpen ? 'flyoutMenu show' : 'flyoutMenu hide'}>
        {/* <TopMenu /> */}
        <SidebarNav />
      </div>
    </FlyoutMenuWrapper>
  );
};

export default FlyoutMobileMenu;
