import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import moment from 'moment';

const AppInstallBannerWrapper = styled.div`
  display: block;

  .app-install-banner {
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
  }

  .app-install-banner-container {
    margin: 0 auto;
  }

  .app-install-banner-close {
    position: absolute;
    left: 6px;
    top: 18px;
    display: block;
    font-family: 'ArialRoundedMTBold', Arial;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
    border: 0;
    width: 22px;
    height: 22px;
    line-height: 17px;
    color: #b1b1b3;
    background: #efefef;
  }

  .app-install-banner-close:active,
  .app-install-banner-close:hover {
    color: #333;
  }

  .app-install-banner-icon {
    position: absolute;
    left: 30px;
    top: 2px;
    display: block;
    width: 57px;
    height: 57px;
    background-color: #fff;
    background-size: cover;
    background-image: url('/images/app_logo.png');
  }

  .app-install-banner-info {
    position: absolute;
    left: 98px;
    top: 15px;
    width: 44%;
    font-size: 12px;
    line-height: 1.2em;
    font-weight: bold;
    color: #999;
  }

  .app-install-banner-title {
    font-size: 16px;
    line-height: 17px;
    color: #000;
    font-weight: bold;
  }

  .app-install-banner-button {
    position: absolute;
    right: 6px;
    top: 16px;
    padding: 0 10px;
    min-width: 15%;
    height: 28px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    background-color: #00c400;
    text-decoration: none;
    border-radius: 20px;
  }

  .app-install-banner-button:hover {
    background-color: #c1d739;
    border-bottom: 3px solid #8c9c29;
  }

  .app-install-banner-button-text {
    text-align: center;
    display: block;
    padding: 2px 5px;
  }

  @media (min-width: 576px) {
    display: none;
  }
`;

const AppInstallBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  let today = moment();
  useEffect(() => {
    if (!localStorage.getItem('appConv')) {
      setShowBanner(true);
    }
    if (
      localStorage.getItem('appConv') &&
      !JSON.parse(localStorage.getItem('appConv')).installed
    ) {
      let installCancelDate = JSON.parse(localStorage.getItem('appConv'))
        .timeStamp;

      if (today.diff(installCancelDate, 'days') > 0) {
        setShowBanner(true);
      }
    }
  }, []);

  const installApp = () => {
    setShowBanner(false);
    // set app conversion attributes
    if (localStorage.getItem('appConv')) {
      localStorage.removeItem('appConv');
    }
    const appConv = { installed: true };
    localStorage.setItem('appConv', JSON.stringify(appConv));
  };

  const cancelAppInstall = () => {
    setShowBanner(false);
    // set app conversion attributes
    if (localStorage.getItem('appConv')) {
      localStorage.removeItem('appConv');
    }
    const appConv = { installed: false, timeStamp: today };
    localStorage.setItem('appConv', JSON.stringify(appConv));
  };
  return (
    <CSSTransition
      in={showBanner}
      timeout={300}
      classNames="alert"
      unmountOnExit
    >
      <AppInstallBannerWrapper>
        <div className="app-install-banner" id="smartabanner">
          <div className="app-install-banner-container">
            <button
              onClick={cancelAppInstall}
              id="smb-close"
              className="app-install-banner-close"
              style={{ textAlign: 'center' }}
            >
              &times;
            </button>
            <span className="app-install-banner-icon"></span>
            <div className="app-install-banner-info">
              <div className="app-install-banner-title">Inalipa App</div>
              <div>Available now for free</div>
            </div>
            <a
              href="https://inalipa21.app.link/vmet6yqiOdb"
              target="_blank"
              className="app-install-banner-button"
              rel="noreferrer"
            >
              <span
                className="app-install-banner-button-text"
                onClick={installApp}
              >
                Install Now
              </span>
            </a>
          </div>
        </div>
      </AppInstallBannerWrapper>
    </CSSTransition>
  );
};

export default AppInstallBanner;
