import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import ProductsComponent from '../../components/products/Products';
import { getWindowPosition } from '../../utils/window';
import {
  getProductsAction,
  getFilterOptionsAction,
  loadProductAction,
  setFilterAction,
  getVerticalAction,
  setSelectedFilterAction,
} from '../../store/actions/catalogAction';
import { scrollToTop } from '../../utils/window';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import {
  resetSelectedPageAction,
  selectPageAction,
  setPreviousPathnameAction,
  updateScrollPositionAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const Category = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let { id } = useParams();
  const category = useSelector((state) => state.catalogManager.vertical);
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const lastPosition = useSelector(
    (state) => state.uiManager.lastScrollPosition,
  );
  const products = useSelector((state) => state.catalogManager.products);
  const filterOptions = useSelector(
    (state) => state.catalogManager.filterOptions,
  );
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  useEffect(() => {
    // console.log('useEffect getProductsAction() Vertical ID = ', id);
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    dispatch(getVerticalAction(id));
    // dispatch(loadProductAction({ Vertical: [id] }));
    // dispatch(getProductsAction({ Vertical: [id], sortBy: sortBy }));
    if (scrollToLastPosition === false || products.length < 1) {
      dispatch(getProductsAction({ Vertical: [id], sortBy: sortBy }));
      dispatch(loadProductAction({ Vertical: [id] }));
    }
    dispatch(getFilterOptionsAction({ Vertical: [id] }));
    dispatch(selectPageAction({ name: 'Vertical', filter: [id] }));

    return () => dispatch(resetSelectedPageAction());
    //  } else{
    //     window.scrollTo(0, lastPosition);
    //   }
    // dispatch(setPreviousPathnameAction(location.pathname));
  }, [dispatch, id]);

  useEffect(() => {
    let previousRoutes = {
      parent: { name: 'All Categories', link: '/categories' },
      coParent2: { name: category.name, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'category',
        item_name: category.name,
        item_id: category._id,
      },
    });
  }, [dispatch, category, location.pathname]);

  useEffect(() => {
    // console.log('!isLoading and filterOptions = ', filterOptions);
    if (filterOptions.verticals) {
      let vertical =
        filterOptions.verticals &&
        Array.isArray(filterOptions.verticals) &&
        filterOptions.verticals[0];
      if (vertical) {
        let selectedVertical = {
          key: 'Vertical',
          item: { value: vertical._id, label: vertical.name },
        };
        //dispatch(setFilterAction(selectedVertical));
        dispatch(setSelectedFilterAction(selectedVertical));
      }
    }
    window.scrollTo(0, lastPosition);
  }, [dispatch, isLoading, filterOptions.verticals]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <>
      <PageTitle
        title={
          category.name ? 'Inalipa - ' + category.name : 'Inalipa - Category'
        }
        description={
          category.name ? 'Inalipa - ' + category.name : 'Inalipa - Category'
        }
      />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: '/categories', name: 'All Categories' }}
        child={{ name: category.name }}
      />
      <div className="container pt-4">
        <ProductsComponent />
      </div>
    </>
  );
};

export default Category;
