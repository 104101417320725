import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '../../assets/icons/homeIcon';
import TagIcon from '../../assets/icons/tagIcon';
import CategoryICon from '../../assets/icons/categoryIcon';
import ShopIcon from '../../assets/icons/shopIcon';
import { ButtomNavigationWrapper } from '../styles/Layout';
import { useTranslation } from 'react-i18next';
import Caller from '../menu/caller/Caller';
import StoresIcon from '../../assets/icons/stores';
import { useDispatch } from 'react-redux';
import {
  loadProductAction,
  resetFiltersAction,
  resetFiltersOptionsAction,
  resetProductsAction,
  resetSelectedFiltersAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { scrollToLastPositionAction } from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: '0',
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation('menu');

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setValue(0);
        break;
      case '/stores':
        setValue(1);
        break;
      case '/categories':
        setValue(2);
        break;
      case '/brands':
        setValue(3);
        break;
      case '/products':
        setValue(4);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return (
    <>
      <ButtomNavigationWrapper>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction
            label={t('home')}
            icon={<HomeIcon active={location.pathname === '/'} />}
            component={Link}
            to="/"
          />
          <BottomNavigationAction
            label={t('stores')}
            icon={<StoresIcon active={location.pathname === '/stores'} />}
            component={Link}
            to="/stores"
          />
          <BottomNavigationAction
            label={t('categories')}
            icon={<CategoryICon active={location.pathname === '/categories'} />}
            component={Link}
            to="/categories"
          />
          <BottomNavigationAction
            label={t('brands')}
            icon={<ShopIcon active={location.pathname === '/brands'} />}
            component={Link}
            to="/brands"
          />
          <BottomNavigationAction
            label={t('products')}
            icon={<TagIcon active={location.pathname === '/products'} />}
            component={Link}
            to="/products"
            onClick={() => {
              console.log('clear me');
              scrollToTop();
              dispatch(resetFiltersAction());
              dispatch(resetSelectedFiltersAction());
              dispatch(scrollToLastPositionAction(false));
              dispatch(resetProductsAction());
              dispatch(resetFiltersOptionsAction());
              dispatch(setSortByAction('featured'));
              dispatch(loadProductAction());
            }}
          />
        </BottomNavigation>
      </ButtomNavigationWrapper>
      <Caller />
    </>
  );
}
