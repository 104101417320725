import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 !important',
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    maxHeight: '540px',
    border: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
  },
};

const AppModal = ({ isOpen, setIsOpen, component }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      overlayClassName="appBgOverlay"
    >
      {component}
    </Modal>
  );
};

export default AppModal;
