import styled from 'styled-components';

export const MegaStoreImageWrapper = styled.div`
  position: relative;
  .img-fluid {
    width: 100%;
    border-radius: 10px;
  }
  .img-list img {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 767px) {
    .img-list img {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 600px) {
    .img-list img {
      width: 90px;
      height: 90px;
    }
  }
`;

export const MegaStoreLogoHalfCircle = styled.div`
  width: 75px;
  height: 75px;
  background-color: white;
  border-radius: 50%;
  border-bottom: 0;
  color: white;
  position: absolute;
  bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
  img {
    position: relative;
    // top: 18px;
    width: 75px;
  }
  @media (max-width: 767px) {
    width: 65px;
    height: 65px;
    bottom: 35px;
    img {
      // top: 15px;
      width: 65px;
    }
  }

  @media (max-width: 600px) {
    width: 50px;
    height: 50px;
    bottom: 30px;
    img {
      // top:10px;
      width: 50px;
    }
  }
`;

export const ListImageWrapper = styled.div`
  position: relative;
  .img-list img {
    width: 200px;
    height: 200px !important;
  }
  @media (max-width: 767px) {
    .img-list img {
      width: 120px;
      height: 120px !important;
    }
  }

  @media (max-width: 600px) {
    .img-list img {
      width: 90px;
      height: 90px !important;
    }
  }
`;
export const HalfCircle = styled.div`
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  border-bottom: 0;
  color: white;
  position: absolute;
  bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;

  img {
    position: relative;
    // top: 5px;
    width: 100px;
  }
  @media (max-width: 767px) {
    bottom: 80px;
  }

  @media (max-width: 600px) {
    width: 90px;
    height: 90px;
    bottom: 40px;
  }
`;

export const ProductListWrapper = styled.div`
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 25%;
    max-width: 25%;
  }

  @media (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }

  @media (max-width: 600px) {
    .row {
      margin-bottom: 65px;
    }
    .column {
      flex: 100%;
      max-width: 50%;
      padding: 0;
    }
  }
`;

export const ShopListWrapper = styled.div`
  a {
    text-decoration: none;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 25%;
    max-width: 25%;
    padding: 1rem;
    padding: 0;
    object-fit: cover;
  }
  .container {
    width: 200;
    height: 120px;
  }

  /* resize images */
  .column .img-list {
    border-radius: 5px;
    // max-width: 250px;
    height: 250px;
    object-fit: cover;
    width: 100%;
  }

  .p-1 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  @media (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
      object-fit: cover;
    }

    .column .img-list {
      width: 100%;
      min-height: 180px;
      object-fit: cover;
    }
  }

  @media (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 50%;
    }

    .column .img-list {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    .p-md-3 {
      padding: 0 20px 2rem 0 !important;
    }
  }
`;
