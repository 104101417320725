import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import ProductsComponent from '../../components/products/Products';
import {
  getProductsAction,
  getFilterOptionsAction,
  loadProductAction,
  resetFiltersAction,
  resetSelectedFiltersAction,
  loadMoreProductsWithSavedPageAction,
} from '../../store/actions/catalogAction';
import { updateScrollPositionAction } from '../../store/actions/uiAction';
import { getWindowPosition } from '../../utils/window';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { Plugins } from '@capacitor/core';

import {
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
const { FirebaseAnalytics } = Plugins;

const Products = () => {
  const [locationKeys, setLocationKeys] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const products = useSelector((state) => state.catalogManager.products);
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  let position = getWindowPosition();
  let lastPosition = useSelector((state) => state.uiManager.lastScrollPosition);
  let updateLastScrollPosition = useSelector(
    (state) => state.uiManager.updateLastScrollPosition,
  );
  const history = useHistory();
  const useSavedPage = useSelector(
    (state) => state.catalogManager.useSavedPage,
  );
  // console.log('useSavedPage : ', useSavedPage);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const selectedFilters = useSelector(
    (state) => state.catalogManager.selectedFIlters,
  );

  let previousRoutes = {
    parent: { name: 'Products', link: location.pathname },
  };

  useEffect(() => {
    // dispatch(loadProductAction());
    // dispatch(getProductsAction({ sortBy: sortBy }))
    if (scrollToLastPosition === false && products.length < 8) {
      setTimeout(
        () => dispatch(loadProductAction()),
        dispatch(getProductsAction({ sortBy: sortBy })),
        200,
      );
      // console.log('Get All products Success in Product page : ', products)
    }
    // if (filterOptions.vertical < 0 || !scrollToLastPosition) {dispatch(getFilterOptionsAction({}))}
    if (selectedFilters) {
      dispatch(getFilterOptionsAction(selectedFilters));
    } else {
      dispatch(getFilterOptionsAction({}));
    }
    // dispatch(resetFiltersAction());
    // dispatch(resetSelectedFiltersAction());
    dispatch(setPreviousPathnameAction(previousRoutes));
    // if (products.length > 7) {dispatch(loadMoreProductsWithSavedPageAction(false));}

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'products',
      },
    });
  }, [location]);

  return (
    <>
      <PageTitle title="Inalipa - Products" />

      <SecondaryNav className="d-none d-sm-block" />
      <div className="container">
        <ProductsComponent />
      </div>
    </>
  );
};

export default Products;
