import React, { useState } from 'react';
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon';
import { ScrollToTopButtonWrapper } from '../styles/ScrollToTop';
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <ScrollToTopButtonWrapper>
      <span
        onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }}
      >
        <div className={`scroll-pill`}>Back to Top</div>
      </span>
      <span
        onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }}
      >
        <div className="scroll-button">
          <ArrowUpIcon />
        </div>
      </span>
    </ScrollToTopButtonWrapper>
  );
};

export default ScrollButton;
