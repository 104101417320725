import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SecondaryNavWrapper } from '../styles/Navigation';
import LanguageSelector from '../ui/LanguageSelector';
import { useDispatch } from 'react-redux';
import {
  loadProductAction,
  resetFiltersAction,
  resetFiltersOptionsAction,
  resetProductsAction,
  resetSelectedFiltersAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { scrollToLastPositionAction } from '../../store/actions/uiAction';

const SecondaryNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('menu');

  return (
    <SecondaryNavWrapper>
      <div className="container d-none d-sm-block">
        <nav className="navbar navbar-expand-sm pb-0">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/' ? 'active' : ''
                }`}
                to="/"
              >
                {t('home')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/stores' ? 'active' : ''
                }`}
                to="/stores"
              >
                {t('stores')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/categories' ? 'active' : ''
                }`}
                to="/categories"
              >
                {t('categories')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/brands' ? 'active' : ''
                }`}
                to="/brands"
              >
                {t('brands')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={() => {
                  console.log('clear me');
                  dispatch(resetFiltersAction());
                  dispatch(resetSelectedFiltersAction());
                  dispatch(scrollToLastPositionAction(false));
                  dispatch(resetProductsAction());
                  dispatch(resetFiltersOptionsAction());
                  dispatch(setSortByAction('featured'));
                  dispatch(loadProductAction());
                }}
                className={`nav-link ${
                  location.pathname === '/products' ? 'active' : ''
                }`}
                to="/products"
              >
                {t('products')}
              </Link>
            </li>
            <li className="nav-item">
              <span>|</span>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/reviews' ? 'active' : ''
                }`}
                to="/reviews"
              >
                {t('reviews')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/track-orders' ? 'active' : ''
                }`}
                to="/track-orders"
              >
                {t('track_order')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === '/past-orders' ? 'active' : ''
                }`}
                to="/past-orders"
              >
                {t('past_orders')}
              </Link>
            </li>
            <li className="nav-item">
              <span>|</span>
            </li>
            <li className="nav-item">
              <LanguageSelector type="dropdown" />
            </li>
          </ul>
        </nav>
      </div>
    </SecondaryNavWrapper>
  );
};

export default SecondaryNav;
