import React from "react";
import Rating from "@material-ui/lab/Rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";

const ReviewComment = ({review}) => {
    return(
        <>
            {review && (
                <div className="col-md-12 pl-0 pr-0">
                    <hr className="mt-3 mb-3"/>
                    <div className="row">
                        <div className="col-md-12 pl-0 pr-0">
                            <Rating
                                defaultValue={review.rating}
                                precision={0.5}
                                readOnly
                                fullSymbol="fa fa-star"
                            />
                        </div>
                        {review && review.title && (
                            <div className="col-md-12 pl-0 pr-0">
                                <strong>{review.title}</strong>
                            </div>
                        )}
                        {review && review.comments &&
                            <div className="col-md-12 pl-0 pr-0">
                                <p>{review.comments}</p>
                            </div>
                        }
                        <div className="col-12 pl-0 pr-0">
                            <div className="row">
                                <div className="col-12 pl-0 pr-0 text-left" style={{color: "#757575"}}>
                                {review && !review.stayAnonymous && review.transaction && review.transaction.shippingAddress && review.transaction.shippingAddress.recipient_name &&
                                        <>
                                            - {review.transaction.shippingAddress.recipient_name}
                                        </>
                                }
                                {review && !review.stayAnonymous && review.paymentOrder && review.paymentOrder.shippingAddress && review.paymentOrder.shippingAddress.recipient_name &&
                                        <>
                                            - {review.paymentOrder.shippingAddress.recipient_name}
                                        </>
                                }
                                {review && review.name &&
                                        <>
                                            - {review.name}
                                        </>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReviewComment;