import React from 'react';
import tigopesa from '../../assets/images/payment/tigopesa.png';
import mpesa from '../../assets/images/payment/mpesa.jpeg';
import airtelmoney from '../../assets/images/payment/airtel.png';
import visa from '../../assets/images/payment/visa.png';
import mastercard from '../../assets/images/payment/mastercard.png';
import cash from '../../assets/images/payment/cash.png';
import {PaymentFooterWrapper} from '../styles/Layout';

const PaymentsFooter = () => {
    return (
        <>
            <PaymentFooterWrapper>
                <div className="payment-footer py-1">
                    <div className="row">
                        <div className="col-md-12" align="center">
                            <div className="payment-method-images">
                                <img src={mpesa} className="img-fluid" alt="mpesa"></img>
                                <img src={tigopesa} className="img-fluid" alt="tigopesa"></img>
                                <img
                                    src={airtelmoney}
                                    className="img-fluid"
                                    alt="airtelmoney"
                                ></img>
                                <img src={visa} className="img-fluid" alt="visa"></img>
                                <img
                                    src={mastercard}
                                    className="img-fluid"
                                    alt="mastercard"
                                ></img>
                                <img src={cash} className="img-fluid" alt="cash"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </PaymentFooterWrapper>
        </>
    );
};

export default PaymentsFooter;
