import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeFilterMenuState,
  changeSortMenuState,
} from '../../store/actions/uiAction';
import { FilterButtonWrapper } from '../../components/styles/Filter';
import FilterModal from '../modals/FilterModal';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import SortIcon from '@material-ui/icons/Sort';
import FlyoutSortMenu from '../../components/filter/FlyoutSortMenu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Capacitor } from '@capacitor/core';
import { matchPath, useLocation } from 'react-router';

const SortButton = (props) => {
  const menuIsOpen = useSelector((state) => state.uiManager.canShowSort);
  const dispatch = useDispatch();
  const location = useLocation();
  const [sortBy, setSortBy] = useState(props.value);
  const [canOpen, setCanOpen] = useState(false);
  const toggleFilterMenu = () => {
    dispatch(changeSortMenuState(!menuIsOpen));
    dispatch(changeFilterMenuState(true));
    setCanOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const [hideFloatingSortButton, setHideFloatingSortButton] = useState(true);

  const handleChange = (value) => {
    setSortBy(value);
    props.onChange(value);
  };

  const getPathParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
      path: `/:pathname`,
    });
    return (matchProfile && matchProfile.params) || {};
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const pathname = location.pathname;
      let showFloatingValue = 0;
      let iosGap = Capacitor.isNative && Capacitor.platform === 'ios' ? 125 : 0;
      const pathParams = getPathParams(pathname);
      let isShow = null;

      switch (pathParams.pathname) {
        case 'products':
          showFloatingValue = 85 - iosGap;
          isShow = currPos.y > showFloatingValue;
          break;
        case 'category':
        case 'brand':
        case 'megastore':
          showFloatingValue = 105;
          isShow = currPos.y > showFloatingValue;
          break;
        default:
          showFloatingValue = -43;
          isShow = currPos.y > showFloatingValue;
          break;
      }

      if (isShow !== hideFloatingSortButton) {
        setHideFloatingSortButton(isShow);
      }
    },
    [hideFloatingSortButton],
    false,
    false,
    300,
  );

  return (
    <>
      <FilterButtonWrapper
        onClick={() => toggleFilterMenu()}
        className={hideFloatingSortButton || canOpen ? '' : `floating-sort `}
      >
        <div>
          {hideFloatingSortButton || canOpen ? (
            <>
              <SortIcon />
              <h3>Sort</h3>
            </>
          ) : (
            <>
              <SortIcon style={{ color: 'white' }} />
              <h3 style={{ color: 'white' }}>Sort</h3>
            </>
          )}
        </div>
      </FilterButtonWrapper>

      <FlyoutSortMenu
        value={sortBy}
        canOpen={canOpen}
        onChange={(value) => handleChange(value)}
        onCloseMenu={(value) => setCanOpen(value)}
      />
    </>
  );
};

export default SortButton;
