import {
  UPDATE_LOCATION,
  RESTORE_DEFAULT_CONFIG,
  CHANGE_LOCATION_PERMISSION_STATUS,
  ADD_DELIVERY_DETAILS,
  GET_CURRENT_LOCATION,
  LOCATION_OUT_OF_BOUND,
  RESET_LOADING_LOCATION,
} from '../constants/ActionTypes';

export const getCurrentLocationAction = (data) => (dispatch) => {
  return dispatch({ type: GET_CURRENT_LOCATION });
};

export const updateLocationAction = (data) => (dispatch) => {
  return dispatch({ type: UPDATE_LOCATION, payload: data });
};

export const setLocationOutOfBoundAction = (data) => (dispatch) => {
  return dispatch({ type: LOCATION_OUT_OF_BOUND, payload: data });
};

export const restoreDefaultMapConfigAction = () => (dispatch) => {
  return dispatch({ type: RESTORE_DEFAULT_CONFIG });
};

export const changeLocationPermissionStatusAction = (data) => (dispatch) => {
  return dispatch({ type: CHANGE_LOCATION_PERMISSION_STATUS, payload: data });
};

export const addDeliveryDetailsAction = (data) => (dispatch) => {
  return dispatch({ type: ADD_DELIVERY_DETAILS, payload: data });
};

export const resetLoadingLocationAction = () => (dispatch) => {
  return dispatch({ type: RESET_LOADING_LOCATION });
};
