import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppRoute from './components/layout/AppLayout';
import HomePage from './pages/HomePage/HomePage';
import Categories from './pages/Categories/Categories';
import Brands from './pages/Brands/Brands';
import Products from './pages/Products/Products';
import Results from './pages/SearchResults/Results';
import Product from './pages/Products/Product';
import Brand from './pages/Brands/Brand';
import WriteReview from './components/modals/WriteReview';
import Checkout from './pages/Checkout/Checkout';
import PaymentPage from './pages/Payment/Payment';
import InalipaMegaStore from './pages/InalipaMegaStore/InalipaMegaStore';
import GeneralReviews from './pages/Reviews/GeneralReviews';
import Shop from './pages/Shop/Shop';
import TrackOrders from './pages/TrackOrders/TrackOrders';
import PastOrders from './pages/PastOrders/PastOrders';
import BecomeSupplier from './pages/Supplier/BecomeSupplier';
import NeedSupport from './pages/Support/NeedSupportPage';
import About from './pages/About/AboutPage';
import TermsAndConditions from './pages/Terms/TermsAndConditionPage';
import PrivacyPolicy from './pages/Policy/PrivacyPolicyPage';
import ShareCart from './pages/ShareCart/ShareCart';
import DailyDeals from './pages/Products/DailyDeals';
import Category from './pages/Categories/Category';
import CardPaymentRedirect from './pages/CardPaymentRedirect/CardPaymentRedirect';
import MegastoreVertical from './pages/InalipaMegaStore/MegastoreVertical';
import AppUrlListener from './pages/AppUrlListener';
import UnderMaintenance from './pages/under-maintenance/UnderMaintenance';
import NotFound from './pages/NotFound/NotFound';
import NotifiucationList from './pages/Notifications/NotifiucationList';
import NotificationMessage from './pages/Notifications/NotificationMessage';
import Stores from './pages/Stores/Stores';
import CuratedPage from './pages/CuratedPage/CuratedPage';
import RecommendedPage from './pages/RecommendedPage/RecommendedPage';

const AppRouter = () => {
  const reload = () => window.location.reload();
  return (
    <Router>
      <AppUrlListener />
      <Switch>
        <Route path="/apple-app-site-association" onEnter={reload} />
        <AppRoute path="/notifications/message">
          <NotificationMessage />
        </AppRoute>
        <AppRoute path="/notifications">
          <NotifiucationList />
        </AppRoute>
        <AppRoute path="/privacy-policy">
          <PrivacyPolicy />
        </AppRoute>
        <AppRoute path="/terms-and-conditions">
          <TermsAndConditions />
        </AppRoute>
        <AppRoute path="/about-inalipa">
          <About />
        </AppRoute>
        <AppRoute path="/need-support">
          <NeedSupport />
        </AppRoute>
        <AppRoute path="/become-supplier">
          <BecomeSupplier />
        </AppRoute>
        <AppRoute path="/share-cart/:cartId">
          <ShareCart />
        </AppRoute>
        <AppRoute path="/pay">
          <PaymentPage />
        </AppRoute>
        <AppRoute path="/payments/:transactionId/card-payment-redirect">
          <CardPaymentRedirect />
        </AppRoute>
        <AppRoute path="/reviews">
          <GeneralReviews />
        </AppRoute>
        <AppRoute path="/track-orders/:trackingNumber?">
          <TrackOrders />
        </AppRoute>
        <AppRoute path="/past-orders">
          <PastOrders />
        </AppRoute>
        <AppRoute path="/checkout">
          <Checkout />
        </AppRoute>
        <AppRoute path="/write-a-review">
          <WriteReview />
        </AppRoute>
        <AppRoute path="/brand/:id">
          <Brand />
        </AppRoute>
        <AppRoute exact path="/product/:id">
          <Product />
        </AppRoute>
        <AppRoute path="/results">
          <Results />
        </AppRoute>
        <AppRoute exact path="/category/:id">
          <Category />
        </AppRoute>
        <AppRoute exact path="/curated-products/:id">
          <CuratedPage />
        </AppRoute>
        <AppRoute exact path="/recommended-page/:id">
          <RecommendedPage />
        </AppRoute>
        <AppRoute path="/categories">
          <Categories />
        </AppRoute>
        <AppRoute exact path="/brands">
          <Brands />
        </AppRoute>
        <AppRoute exact path="/stores">
          <Stores />
        </AppRoute>
        <AppRoute exact path="/megastore/:id">
          <MegastoreVertical />
        </AppRoute>
        <AppRoute path="/stores/megastore">
          <InalipaMegaStore />
        </AppRoute>
        <AppRoute path="/daily-deals">
          <DailyDeals />
        </AppRoute>
        <AppRoute path="/404">
          <NotFound />
        </AppRoute>
        <AppRoute exact path="/products">
          <Products />
        </AppRoute>
        <AppRoute exact path="/stores/:subDomain">
          <Shop />
        </AppRoute>
        <AppRoute exact path="/">
          <HomePage />
        </AppRoute>
        <AppRoute>
          <NotFound />
        </AppRoute>
      </Switch>
    </Router>
  );
};

export default AppRouter;
