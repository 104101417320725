import styled from 'styled-components';

export const MobileFooterWrapper = styled.div`
  background: #3c3f42;
  text-align: center;
  min-height: 240px;
  // max-height: 260px;
  height: 100%;
  a {
    color: #ffffff;
  }
  .fa-logo {
    color: #3c3f42;
  }
  .inalipa-btn-primary {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  p {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 12px;
    color: white;
  }
  .footer-logo {
    max-width: 150px;
    padding-top: 15px;
    display: none;
  }
  .fa-svg-round {
    left: -3px;
  }
  span.appVersion {
    font-family: Avenirltstd, sans-serif;
    color: #fff;
    font-size: 9px;
    font-weight: lighter;
    text-align: center;
    text-transform: lowercase;
  }

  .footer.d-flex-column {
    flex-direction: column;
  }
  .svg-inline--fa.fa-stack-2x {
    height: 1em;
    width: 2.5em;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 1.5rem;
   }
   
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 10px;
    // max-height: 320px;
    .list-inline-item a {
      font-size: 18px;
      margin: 0 2.5em;
    }
    .inalipa-btn-primary {
      padding: 20px 80px;
    }
    p {
      font-size: 14px;
    }
    .footer-logo {
      max-width: 150px;
      text-align: center;
      padding-top: 15px;
      display: inline-block;
    }
    .fa-svg-round {
      left: 0;
    }

    .footer.d-flex-column {
      flex-direction: row;
    }
  }
  
  @media (min-width: 375px) {
     .list-inline-item {
        font-size: 14px;
     }
  }
`;

export const NavBarMobileFooterWrapper = styled.div`
  background: #ffffff;
  text-align: left;
  min-height: 240px;
  // max-height: 260px;
  height: 100%;
  a {
    color: rgba(56,61,66,0.7);
  }
  .fa-logo {
    color: #ffffff;
  }
  .nav-bar-mobile-inalipa-btn-primary {
    height: 18px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    border-radius: 28px;
    background-color: #00c400;
    color: white;
    text-align: center;
    padding: 20px 65px;
    border: none;
    width: 100%;
  }

  .inalipa-btn-container{
    margin-top: 10px;
    height: 44px;
    width: 90%;
    border-radius: 28px;
    letter-spacing: 0;
    border: none;
    background-color: #00c400;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    color: white;
    line-height: 44px;
  }

  p {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 12px;
    color: white;
  }
  .footer-logo {
    max-width: 150px;
    padding-top: 15px;
    display: none;
  }
  .fa-svg-round {
    left: -3px;
  }
  span.appVersion {
    font-family: Avenirltstd, sans-serif;
    color: #3c3f42;
    font-size: 9px;
    font-weight: lighter;
    text-align: center;
    text-transform: lowercase;
    padding-left: 17px;
  }
  span.tradeMark {
    height: 14px;
    width: 265px;
    color: #383D42;
    font-family: "DM Sans";
    font-size: 11px;
    letter-spacing: 0;
    line-height: 14px;
    padding-left: 17px;
  }

  .footer.d-flex-column {
    flex-direction: column;
  }
  .svg-inline--fa.fa-stack-2x {
    height: 1em;
    width: 2.5em; 
  }
  
  .fa-stack{
    margin-right: 35px;
  }
  .list-container{
    flex-direction: column;
    padding-left: 17px;
  }

  .nav-bar-mobile-section-container{
    flex-direction: column;
    padding-left: 17px;
    padding-top: 25px;
    padding-bottom: 5px;
  }

  .nav-bar-mobile-section-header {
    height: 33px;
    color: #383D42;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    padding-top: 10px;
    // height: 44px;
  }

  .horizontal-line{
    border-bottom: 1px solid #e5e5e5;
  }

  .about-list-item {
    padding-top: 10px;
    height: 44px;
    color: rgba(56,61,66,0.7);
      font-family: 'DM Sans',sans-serif !important;
      font-size: 16px;
      font-weight: 100;
      letter-spacing: 0px;
  }

  // /????
  .about-list-item:not(:last-child) {
    margin-right: 1.5rem;
   }
   
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 10px;
    // max-height: 320px;
    .about-list-item a {
      font-size: 18px;
      line-height: 18px;
      margin: 0 2.5em;
    }
    .inalipa-btn-primary {
      padding: 20px 80px;
    }
    p {
      font-size: 14px;
    }
    .footer-logo {
      max-width: 150px;
      text-align: center;
      padding-top: 15px;
      display: inline-block;
    }
    .fa-svg-round {
      left: 0;
    }

    .footer.d-flex-column {
      flex-direction: row;
    }
  }
  
  @media (min-width: 375px) {
     .about-list-item {
        font-size: 18px;
        line-height: 18px;
     }
  }
`;

export const FooterWrapper = styled.div`
  .desktop-footer {
    display: none;
  }
  svg {
    max-width: 24px;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .desktop-footer {
      display: block;
    }
  }
`;
export const ButtomNavigationWrapper = styled.div`
  .MuiBottomNavigationAction-label {
    margin-top: 5px;
  }
`;

export const PaymentFooterWrapper = styled.div`
  .payment-footer {
    border-top: 1px solid #0000001a;
  }
  .payment-method-images {
    padding: 15px 0;
  }

  .payment-method-images img {
    height: 30px;
    max-height: 30px;
    padding: 0 30px;
  }
`;
