import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { scrollToTop } from '../../utils/window';
import Swal from 'sweetalert2';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInputWrapper } from '../../components/styles/Form';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { contactInalipa } from '../../services/websiteService';
import { Plugins } from '@capacitor/core';
import { fetchItemFromRemoteConfig } from '../../utils/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setInalipaContactInformationAction } from '../../store/actions/uiAction';

const { FirebaseAnalytics } = Plugins;

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string()
    .min(5, 'Too Short!')
    .max(250, 'Too Long!')
    .required('Required'),
});

const NeedSupport = () => {
  const dispatch = useDispatch();
  const contactInfo = useSelector((state) => state.uiManager.contactInfo);

  useEffect(() => {
    scrollToTop();
    if (contactInfo && Object.keys(contactInfo).length === 0) {
      fetchInalipaContactInfo();
    }
    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'contact',
      },
    });
  }, []);

  const fetchInalipaContactInfo = () => {
    fetchItemFromRemoteConfig('contacts')
      .then((data) => dispatch(setInalipaContactInformationAction(data)))
      .catch((err) => console.log('err', err.message));
  };

  const history = useHistory();
  const { t } = useTranslation('menu');
  const [isSubmitting, setSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      recipient: 'info@inalipa.co.tz',
      name: '',
      email: '',
      message: '',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSubmitting(true);
      contactInalipa(JSON.stringify(values, null, 2))
        .then((data) => {
          setSubmitting(false);
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Successfully sent',
            showConfirmButton: false,
            timer: 2000,
          });
          history.push('/');
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Oops!',
            text: 'Error occurred, please try again',
            confirmButtonColor: '#00c400',
          });
        });
    },
  });
  return (
    <>
      <PageTitle title="Inalipa - Need Support" />
      <SecondaryNav className="d-none d-md-block" />
      <section className="container">
        <h2 className="mt-2 mb-2 ml-2">{t('contact_inalipa')}</h2>
        <div className="row justify-content-center g-pt-60">
          <div className="col-md-12 col-sm-12 mb-4 m-auto p-0">
            <div className="row p-md-3 p-0">
              <div className="col-md-7 col-sm-12 p-0">
                {/*<h2 className="ml-3">Contact Us</h2>*/}
                <FormInputWrapper>
                  <form
                    className="generic-form"
                    action="#"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter your name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter your email address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="message">Message</label>
                          <textarea
                            className="form-control"
                            name="message"
                            id="message"
                            rows="3"
                            placeholder="Enter your message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                          />
                          {formik.errors.message ? (
                            <div className="error">{formik.errors.message}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 mb-2">
                      <div className="col-md">
                        {!isSubmitting && (
                          <button
                            className="btn-submit"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Send
                          </button>
                        )}
                        {isSubmitting && (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md text-center">
                        {Object.keys(formik.errors).length > 0 ? (
                          <div className="error">
                            Error! Please review inputs above and submit again.
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </FormInputWrapper>
              </div>
              <div className="col-md-4 col-sm-12 p-0 ml-3">
                <h2>Contact Information</h2>
                <p>
                  <span>
                    {' '}
                    <FontAwesomeIcon icon={faPhone} />
                    &nbsp;
                  </span>
                  <span>
                    <a
                      className="text-black-50"
                      href={`tel:${contactInfo?.toll_free ?? '0800710700'}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {contactInfo?.toll_free ?? '0800710700'}
                    </a>
                  </span>{' '}
                  ( toll free )
                </p>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;
                  </span>
                  <span>
                    <a
                      className="text-black-50"
                      href={`mailto:${
                        contactInfo?.email ?? 'info@inalipa.co.tz'
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {contactInfo?.email ?? 'info@inalipa.co.tz'}
                    </a>
                  </span>
                </p>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faInstagram} />
                    &nbsp;
                  </span>
                  <span>
                    <a
                      className="text-black-50"
                      href={`https://instagram.com/${
                        contactInfo?.instagram ?? 'inalipa_tz'
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @{contactInfo?.instagram ?? 'inalipa_tz'}
                    </a>
                  </span>
                </p>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faWhatsapp} />
                    &nbsp;
                  </span>
                  <span>
                    <a
                      className="text-black-50"
                      href={`https://wa.me/+${
                        contactInfo?.whatsapp ?? '255745550814'
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{contactInfo?.whatsapp ?? '255745550814'}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeedSupport;
