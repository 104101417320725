import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { INALIPA_S3_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

import placeholder from '../../../assets/images/placeholders/item.png';
import './OrderSummary.css';
import CurrencyFormat from 'react-currency-format';
import { toggleOrderSummaryAccordion } from '../../../store/actions/PaymentAction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function OrderSummary() {
  const classes = useStyles();
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const savedTotal = useSelector((state) => state.cartManager.total);
  const savedVAT = useSelector((state) => state.cartManager.vat);
  const isDiscounted = useSelector(
    (state) => state.paymentManager.isDiscounted,
  );
  const discount = useSelector((state) => state.paymentManager.discount);
  const canOrderSummaryExpand = useSelector(
    (state) => state.paymentManager.canOrderSummaryExpand,
  );
  const dispatch = useDispatch();

  const { t } = useTranslation('cart');

  const toggleAccordion = () => {
    // console.log('Toggle Accordion');
    dispatch(toggleOrderSummaryAccordion(!canOrderSummaryExpand));
  };

  return (
    <div className="OrderSummary">
      <div className="OrderSummary_cartItems">
        <Accordion expanded={canOrderSummaryExpand} onClick={toggleAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>{t('order_summary')}</h2>
          </AccordionSummary>
          <AccordionDetails>
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((product) => (
                <>
                  <div className="OrderSummary_cartItems_item">
                    <img
                      src={
                        product && product.image
                          ? `${INALIPA_S3_URL}${product.image}`
                          : placeholder
                      }
                      alt={product.name}
                      className="img-fluid cart-image"
                    ></img>
                    <div className="OrderSummary_cartItems_item_description">
                      <div className="OrderSummary_cartItems_item_description_name">
                        <h2>{product.name}</h2>
                        <small>
                          {product.options &&
                            product.options.variant &&
                            product.options.variant}
                        </small>
                      </div>
                      <div className="OrderSummary_cartItems_item_description_price">
                        <div className="OrderSummary_cartItems_item_description_price_quantity">
                          <h4>
                            {product.quantity} piece(s) X Tsh{' '}
                            <CurrencyFormat
                              value={product.price}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          </h4>
                        </div>
                        <div className="OrderSummary_cartItems_item_description_price_total">
                          <h3>
                            TSh{' '}
                            <CurrencyFormat
                              value={product.total}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            {cartItems && cartItems.length > 0 && (
              <div className="OrderSummary_footer">
                <div className="row mb-2">
                  <div className="col-sm-6 OrderSummary_footer_title">
                    {t('sub_total')}
                  </div>
                  <div className="col-sm-6 text-right OrderSummary_footer_value">
                    TSh{' '}
                    <CurrencyFormat
                      value={
                        isDiscounted &&
                        discount &&
                        discount.discount &&
                        discount.discount.vat
                          ? parseFloat(
                              discount.discount.discounted -
                                discount.discount.vat,
                            ).toFixed(2)
                          : parseFloat(savedTotal - savedVAT).toFixed(2)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 OrderSummary_footer_title">VAT</div>
                  <div className="col-sm-6 text-right OrderSummary_footer_value">
                    TSh&nbsp;
                    <CurrencyFormat
                      value={
                        isDiscounted &&
                        discount &&
                        discount.discount &&
                        discount.discount.vat
                          ? parseFloat(discount.discount.vat).toFixed(2)
                          : parseFloat(savedVAT).toFixed(2)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  </div>
                </div>
                {discount && discount.discount && discount.discount.amount && (
                  <>
                    <div className="OrderSummary_separator mt-3 mb-3"></div>
                    <div className="row">
                      <div className="col-sm-6 OrderSummary_footer_title">
                        Original Total
                      </div>
                      <div className="col-sm-6 text-right OrderSummary_footer_value">
                        TSh&nbsp;
                        <CurrencyFormat
                          value={parseFloat(
                            discount.discount.originalAmount,
                          ).toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-6 OrderSummary_footer_title">
                        Discount
                      </div>
                      <div className="col-sm-6 text-right OrderSummary_footer_value">
                        -&nbsp;TSh&nbsp;
                        <CurrencyFormat
                          value={parseFloat(discount.discount.amount).toFixed(
                            2,
                          )}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="OrderSummary_separator mt-3 mb-3"></div>
                <div className="row mb-3">
                  <div className="col-sm-6 OrderSummary_footer_title">
                    {t('total')}
                  </div>
                  <div className="col-sm-6 text-right OrderSummary_footer_value">
                    TSh&nbsp;{' '}
                    <CurrencyFormat
                      value={
                        isDiscounted &&
                        discount &&
                        discount.discount &&
                        discount.discount.discounted
                          ? parseFloat(discount.discount.discounted).toFixed(2)
                          : parseFloat(savedTotal).toFixed(2)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  </div>
                </div>
              </div>
            )}
            {cartItems && cartItems.length === 0 && (
              <p className="alert alert-info m-3">
                There are no items in your basket.
              </p>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default OrderSummary;
