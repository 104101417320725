import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductTile from './ProductTile';
import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const ProductSlider = ({
  title,
  products,
  link,
  linkTitle,
  isRelatedProducts,
}) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: products.length > 3 ? 4 : products.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length > 3 ? 4 : products.length,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: products.length > 2 ? 3 : products.length,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: products.length > 1 ? 2 : products.length,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: products.length > 1 ? 2 : products.length,
          slidesToScroll: 2,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      {products && products.length > 0 && (
        <div className="row pl-0 pt-1">
          <div className="col-8 pl-0 text-left">
            <h2 >{title}</h2>
          </div>
          {link && linkTitle && (
            // <div className="col-4 pr-0 text-right">
            <div className=" pl-0 text-right">
              <h2>
                <Link to={link} style={{ color: '#383D42', fontSize: '16px' }}>
                  {linkTitle}
                </Link>
              </h2>
            </div>
          )}
        </div>
      )}
      <Slider {...settings}>
        {products &&
          products.length > 0 &&
          products.map((product, index) => (
            <ProductTile
              index={index}
              key={product._id}
              productListItem={product}
              isRelatedProduct={isRelatedProducts ? true : false}
            />
          ))}
      </Slider>
    </>
  );
};

export default ProductSlider;
