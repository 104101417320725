import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Capacitor, Plugins } from '@capacitor/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import Swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { isValidPhoneNumber } from "tanzanian-phone-validator"; 
import { validateTanzanianPhoneNumber } from "tanzanian-phone-validator"; 
// import { CircularProgress } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import OrderSummary from '../../components/order/OrderSummary/OrderSummary';
import {
  CheckoutWrapper,
  FormInputWrapper,
} from '../../components/styles/Form';
import {
  initiateTransactionAction,
  processPaymentAction,
  resetPaymentOptionsAction,
  resetPaymentStateAction,
  updateShippingDetailsAction,
} from '../../store/actions/PaymentAction';
import {
  getCartItemsAction,
  saveCompletedOrderAction,
  updateCartAction,
} from '../../store/actions/cartAction';
import { scrollToTop } from '../../utils/window';
import tigopesa from '../../assets/images/payment/tigopesa.png';
import mpesa from '../../assets/images/payment/mpesa.jpeg';
import airtelmoney from '../../assets/images/payment/airtel.png';
import visa from '../../assets/images/payment/visa.png';
import mastercard from '../../assets/images/payment/mastercard.png';
import cash from '../../assets/images/payment/cash.png';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';

const { Device } = Plugins;

const PaymentPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('checkout');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [info, setInfo] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const formRef = useRef(null);
  const paymentMethodFormRef = useRef();
  const [paymentProvider, setPaymentProvider] = React.useState(null);
  const savedDeliveryInfo = useSelector(
    (state) => state.paymentManager.deliveryInfo,
  );
  const transaction = useSelector((state) => state.paymentManager.transaction);
  const cardParams = useSelector((state) => state.paymentManager.cardParams);
  const isCardPaymentInitiated = useSelector(
    (state) => state.paymentManager.isCardPaymentInitiated,
  );
  // const discount = useSelector((state) => state.paymentManager.discount);
  const success = useSelector((state) => state.paymentManager.success);
  const error = useSelector((state) => state.paymentManager.error);
  const message = useSelector((state) => state.paymentManager.actionMessage);
  const paymentMessage = useSelector((state) => state.paymentManager.message);
  const savedTotal = useSelector((state) => state.cartManager.total);
  const isPaymentComplete = useSelector(
    (state) => state.stateManager.isPaymentComplete,
  );

  const isPaymentCancelled = useSelector(
    (state) => state.paymentManager.isPaymentCancelled,
  );

  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [canRedirect, setCanRedirect] = useState(false);
  const cartID = useSelector((state) => state.cartManager.cartID);
  const cartItems = useSelector((state) => state.cartManager.cartItems);
  const isCardPaymentSuccess = useSelector(
    (state) => state.paymentManager.isCardPaymentSuccess,
  );
  const isCardPaymentFail = useSelector(
    (state) => state.paymentManager.isCardPaymentFail,
  );

  const failToReceivePayment = useSelector(
    (state) => state.paymentManager.failToReceivePayment,
  );

  const retryPayment = () => {
    // console.log('retryPayment');
    if (paymentMethodFormRef.current) {
      paymentMethodFormRef.current.handleSubmit();
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (isCardPaymentInitiated) {
      if (Capacitor.isNative) {
        openInAppBrowser();
      } else {
        formRef.current.submit();
      }
    }
  }, [isCardPaymentInitiated]);

  var openInAppBrowser = function () {
    if (Capacitor.isNative) {
      var inputFields = '';
      var pageContent =
        '<html><head></head><body><form id="theForm" action="' +
        cardParams.gatewayUrl +
        '" method="POST">';
      for (var key in cardParams.parameters) {
        var element = cardParams.parameters[key];
        inputFields =
          inputFields +
          '<input type="hidden" name="' +
          key +
          '" value="' +
          element +
          '">';
      }
      inputFields =
        inputFields +
        '<input type="hidden" name="vpc_SecureHashType" value="' +
        cardParams.hashType +
        '">';
      inputFields =
        inputFields +
        '<input type="hidden" name="vpc_SecureHash" value="' +
        cardParams.hash +
        '">';
      inputFields =
        inputFields +
        '</form> <script type="text/javascript">document.getElementById("theForm").submit();</script></body></html>';
      pageContent = pageContent + inputFields;

      var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

      var inAppBrowserRef = window.cordova.InAppBrowser.open(
        pageContentUrl,
        '_blank',
        'hidden=no,location=no,clearsessioncache=yes,clearcache=yes',
      );

      //inAppBrowserRef.addEventListener('exit', function(){

      //});
    }
  };

  useEffect(() => {
    if (failToReceivePayment || isPaymentCancelled) {
      dispatch(initiateTransactionAction(cartID));
      setIsButtonDisabled(false);
    }
  }, [failToReceivePayment, isPaymentCancelled]);

  useEffect(() => {
    if (success) {
      setIsProcessing(false);
    }
    if (error) {
      setIsProcessing(false);
      setIsButtonDisabled(false);
      dispatch(initiateTransactionAction(cartID));
    }
    return () => {
      dispatch(resetPaymentStateAction());
    };
  }, [success, error]);

  useEffect(() => {
    if (isPaymentComplete || isCardPaymentSuccess) {
      scrollToTop();
      setIsProcessing(false);
      dispatch(saveCompletedOrderAction({ cartID, orderDate: moment() }));
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        text:
          paymentProvider === 'cash'
            ? localStorage.getItem('language') === 'sw'
              ? `
Oda yako inafanyiwa kazi`
              : `Your order is being processed`
            : localStorage.getItem('language') === 'sw'
            ? `Umefanikiwa kukamilisha muamala huu`
            : `You have successfully complete this transaction`,
        showConfirmButton: true,
        confirmButtonText:
          localStorage.getItem('language') === 'sw'
            ? `
Fuatilia Oda`
            : `Track Order`,
        confirmButtonColor: '#00c400',
        timer: 20000,
      }).then((result) => {
        // console.log(result);
        if (result) {
          setCanRedirect(true);
        } else {
          dispatch(resetPaymentOptionsAction());
          setCanRedirect(false);
        }
      });
    }
  }, [isPaymentComplete, isCardPaymentSuccess]);

  useEffect(() => {
    if (isCardPaymentFail) {
      scrollToTop();
      setIsProcessing(false);
      dispatch(saveCompletedOrderAction({ cartID, orderDate: moment() }));
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Oops...',
        text: paymentMessage,
        showConfirmButton: true,

        timer: 20000,
      }).then((result) => {
        // console.log(result);
        if (result) {
        } else {
          dispatch(resetPaymentOptionsAction());
        }
      });
    }
  }, [isCardPaymentFail]);

  useEffect(() => {
    let clonedDeliveryInfo = _.clone(savedDeliveryInfo);

    if (clonedDeliveryInfo) {
      clonedDeliveryInfo['transactionId'] =
        transaction && transaction.transactionId;
      setDeliveryInfo(clonedDeliveryInfo);
    }
    dispatch(resetPaymentOptionsAction());

    let shippingPayload = {
      transactionId: transaction && transaction.transactionId,
      name:
        savedDeliveryInfo && savedDeliveryInfo.shipping_address.recipient_name,
      phone: savedDeliveryInfo && savedDeliveryInfo.shipping_address.phone,
      address: savedDeliveryInfo && savedDeliveryInfo.shipping_address.address,
    };
    dispatch(getCartItemsAction(cartID));
    dispatch(updateShippingDetailsAction(shippingPayload));
    dispatch(updateCartAction({ items: cartItems }, cartID));
    scrollToTop();
    return () => dispatch(resetPaymentOptionsAction());
  }, []);

  useEffect(() => {
    let clonedDeliveryInfo = _.clone(savedDeliveryInfo);
    if (clonedDeliveryInfo) {
      clonedDeliveryInfo['transactionId'] =
        transaction && transaction.transactionId;
      setDeliveryInfo(clonedDeliveryInfo);
    }
    setIsButtonDisabled(false);
  }, [transaction && transaction.transactionId]);

  const handleChange = (paymentType) => {
    setPaymentProvider(paymentType);
  };

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      canVerifyPhoneNumber: false,
      phoneNumber: '',
    },
    onSubmit: (values) => {
      scrollToTop();
      // console.log(paymentProvider);
      if (paymentProvider) {
        setIsButtonDisabled(true);
        setIsError(false);
        setIsProcessing(true);

        let payment = {};
        if (
          paymentProvider === 'tz-mpesa' ||
          paymentProvider === 'tz-tigopesa' ||
          paymentProvider === 'tz-airtel'
        ) {
          payment['type'] = 'mobile_money';
          payment['gateway'] = paymentProvider;
          payment['params'] = {
            phone: values.phoneNumber,
          };

          let combinedObj = _.clone(deliveryInfo);
          combinedObj['payment'] = payment;
          //console.log('Mobile ', combinedObj);
          dispatch(processPaymentAction(combinedObj, cartID, savedTotal));
        }
        if (paymentProvider === 'visa' || paymentProvider === 'mastercard') {
          payment['type'] = paymentProvider;
          payment['gateway'] = 'card';

          let combinedObj = _.clone(deliveryInfo);
          combinedObj['payment'] = payment;
          //combinedObj['transactionId'] = transaction.transactionId;
          //console.log('Card Payment', combinedObj);
          dispatch(processPaymentAction(combinedObj, cartID));
        }

        if (paymentProvider === 'cash') {
          payment['type'] = 'cash';
          payment['gateway'] = paymentProvider;
          payment['params'] = {
            phone: deliveryInfo.shipping_address.phone,
          };

          let combinedObj = _.clone(deliveryInfo);

          combinedObj['payment'] = payment;
          let platform = {};
          if (Capacitor.isNative) {
            let fcmToken = localStorage.getItem('fcmToken');
            platform['name'] = Capacitor.platform;
            platform['device_token'] = fcmToken;
          } else {
            platform['name'] = 'web';
          }
          combinedObj['platform'] = platform;
          Device.getInfo().then((device) => {
            combinedObj['uuid'] = device.uuid;
          });
          // console.log('Cash Payment', combinedObj);
          dispatch(processPaymentAction(combinedObj, cartID, savedTotal));
        }
      } else {
        setIsError(true);
        setInfo('Please select payment method to proceed');
      }
    },
    validationSchema: Yup.object({
      canVerifyPhoneNumber: Yup.boolean(),
      phoneNumber: Yup.string().when('canVerifyPhoneNumber', {
        is: true,
        then: Yup.string()
        .matches(/^(?:\+?255|0)[-.\s]?(6[1256789]|7[1345678])\d{7}$/, 'Please enter a valid Tanzanian phone number')
        .required('Must enter a Phone number'),
      }),
    }),
  });
  return (
    <>
      {/* <code>{JSON.stringify(formik.errors)}</code> */}
      <PageTitle title="Inalipa - Payment" />
      <SecondaryNav className="d-none d-sm-block" />
      <form onSubmit={formik.handleSubmit} innerRef={paymentMethodFormRef}>
        <div className="container col-md-4 justify-content-center mb-5 payment-wrapper">
          <div className="row">
            <div className="col-md-12">
              <CheckoutWrapper>
                <FormInputWrapper>
                  {failToReceivePayment && !isProcessing && (
                    <p className=" alert alert-info mt-4">
                      {paymentMessage}{' '}
                      <button onClick={retryPayment} className="btn-retry">
                        Here
                      </button>
                    </p>
                  )}
                  {isPaymentCancelled && !isProcessing && (
                    <p className=" alert alert-info mt-4">{paymentMessage} </p>
                  )}
                  {isError && <p className="alert alert-danger mt-4">{info}</p>}
                  {error && (
                    <p className="alert alert-danger mt-4">{message}</p>
                  )}
                  {success &&
                    !isPaymentComplete &&
                    !failToReceivePayment &&
                    !isPaymentCancelled && (
                      <div className="mt-4">
                        <LinearProgress />
                        <p className="alert alert-success  alert-wait">
                          {message}
                        </p>
                      </div>
                    )}
                  {isProcessing && !success && (
                    <div className="mt-4">
                      <LinearProgress />
                      <p className="alert alert-success  alert-wait">
                        Processing. Please wait...
                      </p>
                    </div>
                  )}
                  <div className="row bg-gray pt-4 pb-4 mt-4">
                    <div className="col-md">
                      <h4>STEP 04 / 04</h4>
                      <OrderSummary />
                    </div>
                  </div>

                  {isCardPaymentInitiated && cardParams && (
                    <form
                      name="order"
                      action={cardParams.gatewayUrl}
                      method="post"
                      ref={formRef}
                    >
                      <div class="payment-progress m-b-md text-center">
                        <p class="response"></p>
                        {Object.keys(cardParams.parameters).map((key) => (
                          <input
                            type="hidden"
                            name={key}
                            value={cardParams.parameters[key]}
                          />
                        ))}
                        <input
                          type="hidden"
                          name="vpc_SecureHash"
                          value={cardParams.hash}
                        />
                        <input
                          type="hidden"
                          name="vpc_SecureHashType"
                          value={cardParams.hashType}
                        ></input>
                      </div>
                    </form>
                  )}
                  <div className="row mt-3">
                    <div className="col-md mb-3">
                      <h2>{t('choose_payment_method')}</h2>

                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="paymentMethod"
                          name="paymentMethod"
                          value={paymentProvider}
                          onChange={(event) => {
                            dispatch(
                              updateCartAction({ items: cartItems }, cartID),
                            );
                            let paymentType = event.target.value;
                            setIsError(false);
                            handleChange(paymentType);

                            if (
                              paymentType === 'tz-mpesa' ||
                              paymentType === 'tz-tigopesa' ||
                              paymentType === 'tz-airtel'
                            ) {
                              formik.setFieldValue(
                                'canVerifyPhoneNumber',
                                true,
                              );
                            } else {
                              formik.setFieldValue(
                                'canVerifyPhoneNumber',
                                false,
                              );
                            }
                          }}
                        >
                          <div className="payment-method">
                            <FormControlLabel
                              value="tz-mpesa"
                              control={<Radio />}
                              label="M-Pesa"
                            />
                            <img
                              src={mpesa}
                              className="img-fluid float-right"
                              alt="mpesa"
                            ></img>
                          </div>
                          {paymentProvider === 'tz-mpesa' && (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Please enter M-pesa number to pay"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phoneNumber}
                              />
                              {formik.touched.phoneNumber &&
                              formik.errors.phoneNumber ? (
                                <div className="error ml-2 mt-2">
                                  {formik.errors.phoneNumber}
                                </div>
                              ) : null}
                            </>
                          )}
                          <div className="payment-method">
                            <FormControlLabel
                              value="tz-tigopesa"
                              control={<Radio />}
                              label="Tigo Pesa"
                            />
                            <img
                              src={tigopesa}
                              className="img-fluid float-right"
                              alt="tigopesa"
                            ></img>

                            {paymentProvider === 'tz-tigopesa' && (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  placeholder="Please enter Tigopesa number to pay"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneNumber}
                                />
                                {formik.touched.phoneNumber &&
                                formik.errors.phoneNumber ? (
                                  <div className="error ml-2 mt-2">
                                    {formik.errors.phoneNumber}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className="payment-method">
                            <FormControlLabel
                              value="tz-airtel"
                              control={<Radio />}
                              label="Airtel Money"
                            />
                            <img
                              src={airtelmoney}
                              className="img-fluid float-right"
                              alt="airtel"
                            ></img>

                            {paymentProvider === 'tz-airtel' && (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  placeholder="Please enter Airtel Money number to pay"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneNumber}
                                />
                                {formik.touched.phoneNumber &&
                                formik.errors.phoneNumber ? (
                                  <div className="error ml-2 mt-2">
                                    {formik.errors.phoneNumber}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className="payment-method">
                            <FormControlLabel
                              value="visa"
                              control={<Radio />}
                              label="Visa"
                            />
                            <img
                              src={visa}
                              className="img-fluid float-right"
                              alt="visa"
                            ></img>
                          </div>
                          <div className="payment-method">
                            <FormControlLabel
                              value="mastercard"
                              control={<Radio />}
                              label="Mastercard"
                            />
                            <img
                              src={mastercard}
                              className="img-fluid float-right"
                              alt="Mastercard"
                            ></img>
                          </div>
                          <div className="payment-method">
                            <FormControlLabel
                              value="cash"
                              control={<Radio />}
                              label="Cash"
                            />
                            <img
                              src={cash}
                              className="img-fluid float-right"
                              alt="airtel"
                            ></img>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="TnC">
                    {t('by_checking_out')}, {t('accept_inalipa')}{' '}
                    <Link to="terms-and-conditions" target="_blank">
                      {t('terms_and_conditions')}
                    </Link>
                    &nbsp;{t('and')}&nbsp;
                    <Link to="privacy-policy" target="_blank">
                      {t('privacy')}
                    </Link>
                  </div>
                </FormInputWrapper>
                <div className="checkout-submit-btn">
                  <button
                    type="submit"
                    className={
                      isButtonDisabled
                        ? 'inalipa-btn-primary inalipa-btn-disabled'
                        : 'inalipa-btn-primary'
                    }
                    disabled={isButtonDisabled}
                  >
                    {t('pay_now')}
                  </button>
                </div>
              </CheckoutWrapper>
            </div>
          </div>
        </div>
      </form>
      {canRedirect && <Redirect to="track-orders" />}
    </>
  );
};

export default PaymentPage;
