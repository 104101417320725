import styled from 'styled-components';

export const VariantWrapper = styled.div`
  .active {
    border: 1px solid red !important;
  }
`;
export const CartWrapper = styled.div`
  .cart-container {
    display: flex;
    width: 100%;
    max-width: 480px;
    min-width: 320px;
    flex-direction: column;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
    transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    transform: translateX(0px);
    height: 100vh;
    padding: 15px;
  }
  .cart-header {
    position: relative;
    display: flex;
    padding-left: 24px;
    padding-right: 0px;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e6e6e6;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .cart-title {
    color: #3f2100;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 18px;
    line-height: 24px;
  }
  .cart-closelink,
  .cart-link {
    width: 40px;
    height: 40px;
    max-width: 100%;
    display: inline-block;
    border: 0;
    overflow: visible;
    background: none;
    position: relative;
    top: -8px;
  }
  .cart-sharelink {
    padding-right: 10px;
    margin-right: 0;
  }
  .cart-closelink svg {
    color: #3f2100;
    vertical-align: middle;
  }
  .cart-content-wrapper {
    display: block;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }
  .cart-list-container {
    display: block;
    // padding-top: 12px;
    // padding-bottom: 12px;
    // padding-left: 24px;
    // padding-right: 24px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    -webkit-overflow-scrolling: touch;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
    padding-left: 24px;
  }
  .cart-item {
    display: flex;
    align-items: flex-start;
    // padding-top: 16px;
    // padding-bottom: 16px;
    padding: 16px 0;
  }
  .cartItem__content__row {
    display: flex;
    align-items: center;
  }
  .cartItem__content__row > div {
    flex: 0.5;
  }

  .cartItem__contentRow_price h3 {
    font-size: 13px !important;
  }

  .cart-image {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    width: 100px;
    height: 0%;
    border: 0;
  }
  .cart-item-content {
    display: flex;
    // margin-right: 16px;
    margin-left: 16px;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    // padding-right: 10px;
    // padding-left: 10px;
  }
  h2 {
    color: #000000;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
  }
  h3 {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
    margin: 13px 0 !important;
    padding: 0 !important;
  }
  .btn-remove {
    max-width: 100%;
    display: inline;
    cursor: pointer;
    border: 0;
    text-align: left;
    letter-spacing: 1px;
    line-height: 16px;
    width: 50%;
    color: #757575;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    background: transparent;
  }
  .cart-item-separator {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #e6e6e6;
    opacity: 0.6;
  }
  .empty-cart svg {
    display: block;
  }
  .empty-cart {
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    opacity: 0.6;
  }
  .cart-footer-value {
    padding-right: 0px;
    margin-left: auto;
  }
  .cart-item-content svg {
    font-size: 14px !important;
  }
  .cartItem_remove_btn {
    display: flex;
    justify-content: flex-end;
  }
  .cartItem_remove_btn_text {
    display: inline-block;
    float: right;
  }
  .OrderSummary_footer {
    background-color: #f5f5f5;
    padding: 20px;
  }
  .OrderSummary_title {
    color: #757575;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 21px;
  }
  .OrderSummary_value {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 21px;
    text-align: right;
  }
  .btn-checkout {
    display: block;
    border-radius: 30px;
    background-color: #00c400;
    width: 100%;
    padding: 20px 100px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 21px;
    text-align: center;
    border: none;
    text-decoration: none;
  }

  .cart-footer {
    padding: 4px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    h3 {
      font-size: 16px;
      margin-top: 13px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .cart-footer {
      padding: 44px 0;
    }
  }
`;
