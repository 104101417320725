import styled from 'styled-components';

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    color: #757575;
    margin-left: -30px;
  }
`;
