import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Reviews from "../../components/ui/Reviews";
import PageTitle from "../../components/utils/PageTitle";
import SecondaryNav from "../../components/Navigation/SecondaryNav";
import {
    getReviewsAction,
    getReviewsCountAction,
    loadReviewsAction,
    resetReviewsAction
} from "../../store/actions/reviewAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";

const GeneralReviews = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('menu');
    const isLoading = useSelector((state) => state.reviewManager.isLoading);
    const reviews = useSelector((state) => state.reviewManager.reviews);
    const reviewsCount = useSelector((state) => state.reviewManager.reviewsCount);
    useEffect(() => {
        dispatch(resetReviewsAction());
        dispatch(loadReviewsAction());
        dispatch(getReviewsAction());
        dispatch(getReviewsCountAction());
    }, []);
    return (
            <>
                <PageTitle title="Inalipa - Reviews" />
                <SecondaryNav className="d-none d-md-block" />
                {isLoading && <div className="container mt-3 text-center"><CircularProgress /></div>}
                {!isLoading && reviews && reviews.length > 0 && (
                    <div className="container">
                        <h2 className="mt-2 mb-2 ml-2">{t('reviews')}</h2>
                        <Reviews postReview={false} reviews={reviews} reviewsCount={reviewsCount}/>
                    </div>
                )}

            </>
    );
};

export default GeneralReviews;