import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  addShopToDeliverAction,
  resetDeliveryDatetimeAction,
  setDeliveryDatetimeAction,
  resetDeliveryDatetimeByShopAction,
} from '../../store/actions/cartAction';
import CustomDeliveryOptionsTimePicker from '../../components/date/CustomDeliveryOptionsTimePicker';
import CustomDeliveryOptionsTimePicker2 from '../../components/date/CustomDeliveryOptionsTimePicker2';
import moment from 'moment';
import _ from 'lodash';
import { DateTimeWrapper } from '../../components/date/date';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 170,
  },
}));

const DeliveryOptions = ({ option, method, shop }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [time, setTime] = useState(null);
  const [custom, setCustom] = useState(true);
  const [customTimes, setCustomTimes] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const timeSelected = useSelector(
    (state) => state.cartManager.deliveryDatetime,
  );

  // let deliveryDateFormatted = option.default
  //   ? moment(option.default).format('dddd , MMMM DD YYYY')
  //   : null;
  // let deliveryTimeFormatted = option.default
  //   ? moment(option.default).format('LT')
  //   : null;

  useEffect(() => {
    // if (shop) {
    // dispatch(
    //   addShopToDeliverAction(shop, {
    //     store: option.shop,
    // deliveryDate: deliveryDateFormatted,
    // deliveryTime: deliveryTimeFormatted,
    // deliveryDatetime: option.default,
    //   }),
    // );
    // } else {
    // dispatch(setDeliveryDatetimeAction(option.default));
    // }
    if (option.options.length > 2 && option.options[2]) {
      // console.log('log', option.options[2]);
      setCustomTimes(option.options[2].options);
    }
    return () => {};
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue < 2 || newValue === 0) {
      let timeValue = option.options[newValue].times[0].value;
      // console.log('timeValue', timeValue);
      // if (shop) {
      //   dispatch(
      //     addShopToDeliverAction(shop, {
      //       store: option.shop,
      //       deliveryDate: moment(timeValue).format('dddd , MMMM DD YYYY'),
      //       deliveryTime: moment(timeValue).format('LT'),
      //       deliveryDatetime: timeValue,
      //     }),
      //   );
      // } else {
      // dispatch(setDeliveryDatetimeAction(timeValue));
      // }
      // setTime(timeValue);
      if (shop) {
        dispatch(
          resetDeliveryDatetimeByShopAction(shop, {
            store: option.shop,
            deliveryDate: null,
            deliveryTime: null,
            deliveryDatetime: null,
          }),
        );
      } else {
        dispatch(resetDeliveryDatetimeAction());
      }
      setTime('');
      setDisabled(true);
    }
  };

  const handleTimeOptionChange = (event) => {
    let targetValue = event.target.value;
    setTime(targetValue);
    if (option.options) {
      // console.log('check', option.options[value].times.slice(0, 3));
      option.options[value].times.slice(0, 3).map((dayTime, index) => {
        if (index < 2 && event.target.value === dayTime.value) {
          setDisabled(true);
          if (option.options.length > 2) {
            setCustomTimes(option.options[2].options);
          }
        }
        if (index === 2 && event.target.value === dayTime.value) {
          setDisabled(false);
        }
      });
    }
    if (shop) {
      dispatch(
        addShopToDeliverAction(shop, {
          store: option.shop,
          deliveryDate: moment(targetValue).format('dddd , MMMM DD YYYY'),
          deliveryTime: moment(targetValue).format('LT'),
          deliveryDatetime: targetValue,
        }),
      );
    } else {
      dispatch(setDeliveryDatetimeAction(targetValue));
    }
  };

  const filterTodayOptions = (times) => {
    return times.some((timeOptionObj) => timeOptionObj.visible === true)
      ? 'Or choose time'
      : 'Choose Time';
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {option.options &&
            option.options.map((dayOption, index) => (
              <Tab label={dayOption.label} {...a11yProps(index)} />
            ))}
        </Tabs>
      </AppBar>
      {option.options &&
        option.options.map((dayOption, index) => (
          <TabPanel value={value} index={index}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="datetime"
                name="datetime1"
                value={time}
                onChange={handleTimeOptionChange}
              >
                {dayOption.times &&
                  dayOption.times.map((timeOption, index_) => (
                    <>
                      {timeOption.visible && (
                        <FormControlLabel
                          value={timeOption.value}
                          control={<Radio />}
                          label={timeOption.label}
                        />
                      )}
                    </>
                  ))}
                {dayOption.times && dayOption.times.length > 2 && (
                  // <FormControlLabel
                  //   value={dayOption.times[2].value}
                  //   control={<Radio />}
                  //   label={
                  //     <CustomDeliveryOptionsTimePicker
                  //       option={option}
                  //       shop={shop}
                  //       shopId={option.shop}
                  //       disabled={disabled}
                  //       times={dayOption.times}
                  //     />
                  //   }
                  // />
                  <DateTimeWrapper>
                    <select
                      onChange={handleTimeOptionChange}
                      className="dateInput"
                      value={time}
                      type="text"
                    >
                      <option value="">
                        {dayOption.label === 'Today'
                          ? filterTodayOptions(dayOption.times)
                          : 'Choose Time'}
                      </option>
                      {dayOption.times &&
                        dayOption.times.map((timeOption, index_) => (
                          <>
                            {!timeOption.visible && (
                              <option value={timeOption.value}>
                                {timeOption.label}
                              </option>
                            )}
                          </>
                        ))}
                    </select>
                  </DateTimeWrapper>
                )}
                {!dayOption.times && customTimes && (
                  //   <FormControlLabel
                  //     value={dayOption.options[0].times[0].value}
                  //     control={<Radio />}
                  //     label={
                  <CustomDeliveryOptionsTimePicker2
                    onClick={custom}
                    options={customTimes}
                    shop={shop}
                    shopId={option.shop}
                  />
                  //     }
                  //   />
                )}
              </RadioGroup>
            </FormControl>
          </TabPanel>
        ))}
    </div>
  );
};

export default DeliveryOptions;
