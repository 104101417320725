import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Capacitor, Plugins } from '@capacitor/core';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import {
  getCatalogProductByIdAction,
  getProductRelatedAction,
  loadMoreProductsWithSavedPageAction,
  loadProductAction,
  relatedProductsSectionLoadingAction,
  resetRelatedProductsAction,
  scrollDataActionCat,
} from '../../store/actions/catalogAction';
import ProductSlider from '../../components/ui/ProductSlider';
import Reviews from '../../components/ui/Reviews';
import ProductItem from '../../components/ui/ProductItem';
import { scrollToTop } from '../../utils/window';
import {
  resetOptionAction,
  selectVariantAction,
} from '../../store/actions/cartAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getProductReviewsAction,
  getProductReviewsCountAction,
  loadReviewsCountAction,
  resetReviewsAction,
} from '../../store/actions/reviewAction';
import ProductBreadcrumbs from '../../components/products/ProductBreadcrumbs';
import {
  resetSelectedRelatedProductAction,
  setPreviousPathnameAction,
  scrollToLastPositionAction,
  setUpdateLastScrollPosition,
  updateLastScrollPositionAction,
  setPreviousPageIsProductAction,
  setPreviousUrlAction,
  scrollDataActionUi,
} from '../../store/actions/uiAction';
import useUrlQuery from '../../hooks/useUrlQuery';
import ReactPixel from 'react-facebook-pixel';
import {
  getCurettedSectionsAction,
  getFrequentlyBoughtTogetherAction,
  sendProductViewEventAction,
  setFrequentlyBoughtTogetherSectionLoadingAction,
} from '../../store/actions/recommendationAction';
import ProductsSliderSkeleton from '../../components/skeletons/elements/products/ProductsSliderSkeleton';
import ProductDetailsSkeleton from '../../components/skeletons/ProductDetailsSkeleton';
import RecommendedProductSlider from '../../components/ui/RecommendedProductSlider';
const { FirebaseAnalytics } = Plugins;

const Product = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('product');
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const loadedReviewsCount = useSelector(
    (state) => state.reviewManager.loadedReviewsCount,
  );
  const relatedProducts = useSelector(
    (state) => state.catalogManager.relatedProducts,
  );
  const product = useSelector((state) => state.catalogManager.product);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const reviews = useSelector((state) => state.reviewManager.productReviews);
  const scrollPosition = useSelector((state) => state.uiManager.scrollPosition);
  // const previousPageIsProduct = useSelector((state) => state.uiManager.previousPageIsProduct);
  const location = useLocation();
  const frequentlyBoughtTogether = useSelector(
    (state) => state.recommendationManager.frequentlyBoughtTogether,
  );
  const frequentlyBoughtTogetherSectionLoading = useSelector(
    (state) =>
      state.recommendationManager.frequentlyBoughtTogetherSectionLoading,
  );
  const relatedProductsSectionLoading = useSelector(
    (state) => state.catalogManager.relatedProductsSectionLoading,
  );
  const curatedSections = useSelector((state) => state.recommendationManager.curatedSections);

  const reviewsCount = useSelector(
    (state) => state.reviewManager.productReviewsCount,
  );
  const selectedVariant = useSelector(
    (state) => state.cartManager.selectedVariant,
  );
  // const selectedProduct = useSelector((state) => state.cartManager.selectedProduct);
  let { id } = useParams();

  // const analytics = getAnalytics();

  // scrollToTop();
  useEffect(() => {
    scrollToTop();
    // dispatch(setPreviousPageIsProductAction(true));
    dispatch(resetOptionAction());
    dispatch(resetReviewsAction());
    dispatch(loadProductAction());
    dispatch(loadReviewsCountAction());
    dispatch(getProductReviewsAction(id));
    dispatch(getProductReviewsCountAction(id));
    dispatch(getCatalogProductByIdAction(id));
    dispatch(getProductRelatedAction(id));
    dispatch(loadMoreProductsWithSavedPageAction(true));
    dispatch(setFrequentlyBoughtTogetherSectionLoadingAction(true));
    dispatch(relatedProductsSectionLoadingAction(true));
    dispatch(getCurettedSectionsAction());

    let productViewEventBody = {
      userEvent: {
        eventType: 'detail-page-view',
        visitorId: localStorage.getItem('appNumber'),
        productDetails: [
          {
            product: {
              id: id,
            },
          },
        ],
      },
    };
    dispatch(sendProductViewEventAction(productViewEventBody));

    dispatch(
      getFrequentlyBoughtTogetherAction({
        placementId: 'frequently-bought-together',
        userEvent: {
          eventType: 'shopping-cart-page-view',
          visitorId: localStorage.getItem('appNumber'),
          productDetails: [
            {
              product: {
                id: id,
              },
              quantity: {
                items: 1,
              },
            },
          ],
        },
      }),
    );
    // console.log('productsInCart', productsInCart);

    return () => dispatch(resetRelatedProductsAction());
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(updateLastScrollPositionAction(scrollPosition));
    // console.log('scrollPosition in PRODUCT', scrollPosition);
    // dispatch(scrollToLastPositionAction(true));
    // dispatch(setUpdateLastScrollPosition(false));

    dispatch(scrollDataActionUi('save'));
    dispatch(scrollDataActionCat('save'));

    // return () => dispatch(scrollToLastPositionAction(false));
  }, []);
  useEffect(() => {
    if (
      product.variations &&
      product.variations.variants &&
      product.variations.variants.length > 0
    ) {
      dispatch(selectVariantAction(product.variations.variants[0]));
    }

    if (
      product &&
      product.variations &&
      Array.isArray(product.variations) &&
      product.variations.length > 0
    ) {
      dispatch(selectVariantAction(product.variations[0]));
    }

    if (!Capacitor.isNative && product && product._id) {
      let data = {
        product_name: product.name,
        product_category: product.category && product.category.name,
        product_id: product._id,
        value: product.price,
        currency: 'TZS',
        platform: Capacitor.platform,
      };
      // ReactPixel.track('ViewContent', data);

      // logEvent(analytics, 'view_content', {
      //   content_name: product.name,
      //   content_category: product.category && product.category.name,
      //   content_id: product._id,
      //   content_type: 'product',
      //   value: product.price,
      //   currency: 'TZS',
      // });

      FirebaseAnalytics.logEvent({
        name: 'view_item',
        params: {
          currency: 'TZS',
          price: product.price,
          items: [
            {
              item_id: product?.id,
              item_name: product?.name,
              affiliation: product?.shop?.name,
              item_brand: product?.brand?.name,
              item_category: product?.category?.name,
              price: product?.price,
            },
          ],
        },
      });
    }
  }, [product]);

  const description = () => {
    if (product) {
      return product.shop.name && product.description
        ? ' - ' + product.description
        : ' - ' + product.name;
    }
  };

  // map in curatedSections and check the name of 'Frequently Bought Together' is there
  const checkIfFrequentlyBoughtTogetherSectionPresent = (curatedSection) => {
    if (curatedSections && curatedSections.length > 0) {
      for (let i = 0; i < curatedSections.length; i++) {
        if (curatedSections[i].name === `${curatedSection}`) {
          return true;
        }
      }
    }
    return false;
  };

  const Url = location
  const history = useHistory();
  // const history = useHistory();
  // useEffect(() => {
  //   console.log('location action :', history.action);
  //   if (location.action === 'POP' || history.action === 'PUSH') {
  //     console.log('we inside')
  //   return () => {
  //     console.log('we outside')
  //     dispatch(setPreviousUrlAction(Url));
  //   } }
  // }, [dispatch]);
  return (
    <>
      <PageTitle
        title={
          product.name
            ? 'Inalipa - Product - ' + product.name
            : 'Inalipa - Product'
        }
        description={
          product && product.shop && product.shop.name + description()
        }
      />
      <SecondaryNav className="d-none d-sm-block" />
      {success && <p className="alert alert-success">{message}</p>}
      {isLoading && (
        <>
          <ProductDetailsSkeleton />
          <ProductsSliderSkeleton link={true} header={false} />
          <ProductsSliderSkeleton link={true} header={false} />
        </>
        // <div className="container mt-2 text-center">
        //   <CircularProgress />
        // </div>
      )}
      {!isLoading && (
        <>
          <ProductBreadcrumbs product={product} />
          {/* <div className="container"> */}
            {product &&
              product.variations &&
              product.variations.variants &&
              product.variations.variants.length > 0 && (
                <ProductItem
                  product={product}
                  selectedVariant={selectedVariant}
                  reviewsCount={loadedReviewsCount ? reviewsCount : []}
                />
              )}

            {product &&
              product.variations &&
              Array.isArray(product.variations) &&
              product.variations.length === 0 && (
                <ProductItem
                  product={product}
                  selectedVariant={null}
                  reviewsCount={loadedReviewsCount ? reviewsCount : []}
                />
              )}

            {product &&
              product.variations &&
              Array.isArray(product.variations) &&
              product.variations.length > 0 && (
                <ProductItem
                  product={product}
                  selectedVariant={selectedVariant}
                  reviewsCount={loadedReviewsCount ? reviewsCount : []}
                />
              )}

            {product &&
              product.variations &&
              product.variations.variants &&
              product.variations.variants.length === 0 && (
                <ProductItem
                  product={product}
                  selectedVariant={null}
                  reviewsCount={loadedReviewsCount ? reviewsCount : []}
                />
              )}
            {(relatedProductsSectionLoading) && <ProductsSliderSkeleton link={true} header={false} />}
            {!relatedProductsSectionLoading && relatedProducts && relatedProducts.length > 0 && (
              <div className="container-full">
              <RecommendedProductSlider
                title={t('related_products')}
                products={relatedProducts.slice(0, 8)}
                isRelatedProducts={true}
              />
              </div>
            )}
            {checkIfFrequentlyBoughtTogetherSectionPresent('Frequently Bought Together') === true && frequentlyBoughtTogetherSectionLoading && <ProductsSliderSkeleton link={true} header={false} />}
            {checkIfFrequentlyBoughtTogetherSectionPresent('Frequently Bought Together') === true && !frequentlyBoughtTogetherSectionLoading && frequentlyBoughtTogether && frequentlyBoughtTogether.length > 0 && (
              <div className="container-full">
              <RecommendedProductSlider
                title={t('Frequently Bought Together')}
                products={frequentlyBoughtTogether}
                isRelatedProducts={true}
              />
              </div>
            )}

            {product && (
              <Reviews
                reviews={reviews}
                product={product}
                reviewsCount={loadedReviewsCount ? reviewsCount : []}
              />
            )}
          {/* </div> */}
        </>
      )}
    </>
  );
};

export default Product;
