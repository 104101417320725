import React, { useState, useEffect } from 'react';
import AppModal from './components/modals/AppModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import packageJson from '../package.json';
import moment from 'moment';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return momLatestDateTime.isAfter(momCurrentDateTime) ? true : false;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
        // after deleting browser cache, hard reload
        window.location.reload(true);
      }
    };

    return (
      <React.Fragment>
        {/* {isLatestBuildDate ? (
          <Component {...props} />
        ) : (
          <AppModal
            isOpen={true}
            component={<CircularProgress />}
            setIsOpen={setIsOpen}
          />
        )} */}
        {isLatestBuildDate && <Component {...props} />}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
