import React from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import AppRouter from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import withClearCache from './ClearCache';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor, Plugins } from '@capacitor/core';
import { BranchInitEvent } from 'capacitor-branch-deep-links';
import './App.css';
import { deviceInfo } from './services/websiteService';

const {
  Device,
  StatusBar,
  BranchDeepLinks,
  PushNotifications,
  Storage,
  FirebaseAnalytics,
  FirebaseCrashlytics
} = Plugins;

const firebaseConfig = {
  apiKey: 'AIzaSyDasH0S_wptPvUlfLZh_13YBgKjwxs6Jxc',
  authDomain: 'inalipa-74b71.firebaseapp.com',
  databaseURL: 'https://inalipa-74b71.firebaseio.com',
  projectId: 'inalipa-74b71',
  storageBucket: 'inalipa-74b71.appspot.com',
  messagingSenderId: '247489376602',
  appId: '1:247489376602:web:525c6e4c59c38f829e4363',
  measurementId: 'G-WZ31117T44',
};

let appNumber = 'not-set';
// localStorage.setItem('appNumber', appNumber)
//React Google Analytics 4 Implementation for Web
ReactGA.initialize('G-WZ31117T44');

const ga4Tracker = () => {
  ReactGA.ga(async (tracker) => {
    const clientId = await tracker.get('clientId');
    return localStorage.setItem('appNumber', clientId);
  });
}

if (Capacitor.isNative) {
  console.log('set reactGA');
  // ReactGA.set({ checkProtocolTask: null, checkStorageTask: null });
  ga4Tracker();
  const fcm = new FCM();

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermission().then((result) => {
    if (result.granted) {
      // console.log('App granted to receive push notifications');
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register()
        .then(() => {
          // Subscribe to a specific topic
          // you can use `FCMPlugin` or just `fcm`
          fcm
            .subscribeTo({ topic: 'inalipa' })
            .then((r) => console.log(`subscribed to topic inalipa`))
            .catch((err) => console.log(err));

          // Get FCM token instead the APN one returned by Capacitor
          fcm
            .getToken()
            .then((r) => {
              localStorage.setItem('fcmToken', r.token);
              console.log(`Token ${r.token}`);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => alert(JSON.stringify(err)));
    } else {
      // Show some error
    }
  });
} else {
  // Only send page views to google analytics since it is not supported by Capacitor firebase analytics plugin
  // Next comment is deprecated instead use .send()
  // ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });

  // const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  // const options = {
  //   autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: process.env.NODE_ENV === 'development', // enable logs
  // };
  // ReactPixel.init('2511220955812305', advancedMatching, options);

  // Web app's Firebase configuration
  // Initialize Firebase
  FirebaseAnalytics.initializeFirebase(firebaseConfig);

  TagManager.initialize({
    gtmId: 'GTM-N25MDMM',
  });
  // console.log('App Number out: ', appNumber);
  // ReactGA.ga((tracker) => {
  //   appNumber = tracker.get('clientId');
  //   console.log(`App Number GA.1.${appNumber}`);
  //   localStorage.setItem('appNumber', `GA.1.${appNumber}`)
  //   // localStorage.setItem('appNumber', 'GA.1.913086281.1626690835')
  // });
}
// console.log('App Number before: ', appNumber);
ga4Tracker();
// console.log('App Number after 2: ', localStorage.getItem('appNumber'));

// Display content under transparent status bar (Android only)
if (Capacitor.isNative) {
  if (Capacitor.isAndroid) {
    StatusBar.setOverlaysWebView({
      overlay: false,
    });
  }

  let localAppVersion = localStorage.getItem('localAppVersion');
  var pjson = require('./../package.json');

  if (pjson.version !== localAppVersion) {
    Storage.clear();
    localStorage.clear();
  }

  if (!localAppVersion) {
    localStorage.setItem('localAppVersion', pjson.version);
  }

  if (pjson.version !== localAppVersion) {
    window.location.reload(true);
  }

  const isEnabled = async () => {
    const result = await FirebaseCrashlytics.isEnabled();
    return result.enabled;
  };

  isEnabled().then(async (enabled) => {
    if(!enabled){
      await FirebaseCrashlytics.setEnabled({
        enabled: true,
      });
      console.log('enabled firebase crashlytics data collection');
    }

    await FirebaseCrashlytics.sendUnsentReports();
    console.log('sendUnsentReports');
  });
}

BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
  // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
  // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
  console.log(event.referringParams);
});

BranchDeepLinks.addListener('initError', (error) => {
  console.error(error);
});

// window.addEventListener('beforeinstallprompt', (e) => {
//   e.preventDefault(); // Stop automated install prompt.
//   if (navigator.userAgent.indexOf('Chrome') !== -1) {
//     if (navigator.getInstalledRelatedApps) {
//       navigator.getInstalledRelatedApps().then((relatedApps) => {
//         if (relatedApps.length === 0) {
//           e.prompt();
//         }
//       });
//     }
//   }
// });

window.addEventListener('beforeinstallprompt', (e) => {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
  });
});

// BranchDeepLinks.generateShortUrl({analytics, properties}).then(function (res) {
//     console.log('Response: ' + JSON.stringify(res.url));
// }).catch(function (err) {
//     console.log('Error: ' + err, JSON.stringify(err));
// })
Device.getInfo().then((info) => {
  deviceInfo(JSON.stringify(info, null, 2))
    .then((response) => {
      // console.log('response', response);
    })
    .catch((err) => {
      console.log(err);
    });
  if (!localStorage.getItem('appDev')) {
    localStorage.setItem('appDev', JSON.stringify(info));
  }
  if (Capacitor.isNative && !localStorage.getItem('appNumber')) {
    localStorage.setItem('appNumber', JSON.stringify(info.uuid));
  }
});

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;

// function App() {
//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <AppRouter />
//       </PersistGate>
//     </Provider>
//   );
// }

// export default App;
