import React from 'react';

const CircleLeftArrow = ({active}) => {
	return(
<div className="arrow-container" style={{ borderRadius: '50%'}}>		
	<svg  xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"  >
	<path class="arrow-arrow" fill='#FFFFFF'  d="M15,6.7c0.4,0.4,0.4,1,0,1.4L11.1,12l3.9,3.9c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L9,12.7c-0.4-0.4-0.4-1,0-1.4
		l4.6-4.6C14,6.3,14.6,6.3,15,6.7z"/> 
	</svg>
</div>
  );
};

export default CircleLeftArrow;


