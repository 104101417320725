import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import { ProductListWrapper } from '../../components/styles/List';
import ProductTile from '../../components/ui/ProductTile';
import { scrollToTop } from '../../utils/window';
import {
  getCuratedSectionAction,
  setSortByAction,
} from '../../store/actions/catalogAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPreviousPathnameAction } from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';
import {
  scrollToLastPositionAction,
  setPreviousUrlAction,
  setUpdateLastScrollPosition,
  updateScrollPositionAction,
} from '../../store/actions/uiAction';
import { filterOutOfStock } from '../../utils/productFiltering';

const { FirebaseAnalytics } = Plugins;
const RecommendedPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const recommendedProducts = useSelector(
    (state) => state.recommendationManager.recommendedDealsForYou,
  );
  const relatedToYourViews = useSelector(
    (state) => state.recommendationManager.relatedToYourViews,
  );
  const relatedToItemsInYourCart = useSelector(
    (state) => state.recommendationManager.relatedToItemsInYourCart,
  );
  const newArrivals = useSelector(
    (state) => state.recommendationManager.newArrivals,
  );

  const location = useLocation();
  let recommendedSection = [];
  let recommendedSectionName = 'Recommended';
  switch (id) {
    case 'recommended-for-you':
      recommendedSection = filterOutOfStock(recommendedProducts);
      recommendedSectionName = 'Recommended for you';
      break;
    case 'your-recent-views':
      recommendedSection = relatedToYourViews;
      recommendedSectionName = 'Your Recent Views';
      break;
    case 'related-to-items-in-your-cart':
      recommendedSection = filterOutOfStock(relatedToItemsInYourCart);
      recommendedSectionName = 'Related to items in your cart';
      break;
    case 'new-arrivals':
      recommendedSection = newArrivals;
      recommendedSectionName = 'New Arrivals';
      break;
    default:
      recommendedSection = recommendedProducts;
      break;
  }

  const { t } = useTranslation('product');
  let wentBack = false;
  const lastPosition = useSelector(
    (state) => state.uiManager.lastScrollPosition,
  );
  const updateLastScrollPosition = useSelector(
    (state) => state.uiManager.updateLastScrollPosition,
  );
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );

  const Url = location;
  const history = useHistory();
  useEffect(() => {
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    let previousRoutes = {
      parent: { name: 'Home', link: '/' },
      coParent: { name: recommendedSectionName, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'deals',
      },
    });
    return () => {
      dispatch(setPreviousUrlAction(Url));
    };
  }, [location]);

  useEffect(() => {
    // console.log('updateLastScrollPosition:: ', updateLastScrollPosition);
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      if (wentBack) {
        window.scrollTo({
          top: lastPosition,
          behavior: 'smooth',
        });
      }

      // dispatch(setPreviousPageIsProductAction(false));
    }, 300);

    dispatch(setUpdateLastScrollPosition(true));

    return () => {
      dispatch(setSortByAction('featured'));
      window.removeEventListener('scroll', handleScroll);
      // dispatch(updateLastScrollPositionAction(window.scrollY));
      // dispatch(setPreviousPageIsProductAction(false));
      dispatch(scrollToLastPositionAction(false));
    };
  }, []);

  const handleScroll = () => {
    // console.log('position : out ', window.scrollY);

    dispatch(updateScrollPositionAction(window.scrollY));
    // dispatch(updateLastScrollPositionAction(window.scrollY));
  };

  return (
    <>
      <PageTitle title={`Inalipa - ${recommendedSectionName}`} />
      <SecondaryNav className="d-none d-sm-block" />
      <Breadcrumb
        parent={{ link: '/', name: 'Home' }}
        child={{ name: `${recommendedSectionName}` }}
      />
      <div className="container">
        <h2> {recommendedSectionName} </h2>
        <div className="container pt-4">
          {recommendedSection && recommendedSection.length === 0 && (
            <p className="alert alert-info">{t('no_product')}</p>
          )}
          {recommendedSection && recommendedSection.length > 0 && (
            <ProductListWrapper>
              <div className="row">
                {recommendedSection &&
                  recommendedSection.length > 0 &&
                  recommendedSection.map((product, index) => (
                    <div className="column mt-2" key={product._id}>
                      <ProductTile index={index} productListItem={product} />
                    </div>
                  ))}
              </div>
            </ProductListWrapper>
          )}
        </div>
      </div>
    </>
  );
};

export default RecommendedPage;
