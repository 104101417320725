import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUrlQuery from '../../hooks/useUrlQuery';
import { useLocation } from 'react-router-dom';

import {
  resetSelectedRelatedProductAction,
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import Breadcrumb from '../ui/Breadcrumbs';

const ProductBreadcrumbs = ({ product }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  let query = useUrlQuery();

  const previousRoutes = useSelector(
    (state) => state.uiManager.previousPathname,
  );

  useEffect(() => {
    if (
      product &&
      product.shop &&
      product.shop.name &&
      product.shop.subDomain &&
      query.get('op') === '1'
    ) {
      let previousRoutes = {
        parent: { name: 'Home', link: '/' },
        coParent: {
          name:
            product.shop && product.shop.name
              ? product.shop.name === 'Inalipa Store' ||
                product.shop.name === 'Inalipa Megastore'
                ? 'Inalipa Megastore'
                : product.shop.name
              : null,
          link:
            product.shop && product.shop.subDomain
              ? product.shop.name === 'Inalipa Store' ||
                product.shop.name === 'Inalipa Megastore'
                ? '/stores/megastore'
                : '/stores/' + product.shop.subDomain
              : null,
        },
      };
      dispatch(setPreviousPathnameAction(previousRoutes));
    }
    return () => dispatch(resetSelectedRelatedProductAction());
  }, []);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);

  return (
    <Breadcrumb
      parent={previousRoutes.parent ?? null}
      coParent={previousRoutes.coParent ?? null}
      coParent2={previousRoutes.coParent2 ?? null}
      child={{ name: product.name }}
    />
  );
};

export default ProductBreadcrumbs;
