import axios from 'axios';
import { MERCANT_API } from '../constants';
import {inalipaMethodHeaders} from './config';

export const becomeSupplier = async (body) => {
    return await axios
        .post(`${MERCANT_API}/website/leads`, body, inalipaMethodHeaders)
        .then((data) => data);
};

export const contactInalipa = async (body) => {
    return await axios
        .post(`${MERCANT_API}/website/contact`, body, inalipaMethodHeaders)
        .then((data) => data);
};

export const deviceInfo = async (body) => {
    return await axios
        .post(`${MERCANT_API}/customer-sessions/init`, body, inalipaMethodHeaders)
        .then((data) => data);
};