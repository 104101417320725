import {
  CLEAR_ALL_FILTERS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_BRAND_FEATURED_PRODUCTS_SUCCESS,
  GET_BRAND_FILTERS_OPTIONS_SUCCESS,
  GET_BRAND_PRODUCTS_SUCCESS,
  GET_BRAND_SUCCESS,
  GET_BRANDS_SUCCESS,
  GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS,
  GET_CATEGORY_PRODUCTS_SUCCESS,
  GET_DAILY_DEALS_SUCCESS,
  GET_FILTERS_OPTIONS_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_RELATED_PRODUCTS_SUCCESS,
  LOAD_MORE_PRODUCTS,
  LOADING_PRODUCTS_WITH_SAVED_PAGE,
  LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE,
  LOAD_MORE_SEARCH_RESULTS,
  LOADING_PRODUCT,
  PRODUCT_WEB_SHARE_SUCCESS,
  REMOVE_FILTER_TERM,
  SCROLL_DATA_CAT,
  RESET_FILTERS,
  RESET_PAGINATION_ACTION,
  RESET_PRODUCT_SHARING,
  RESET_PRODUCTS,
  RESET_FILTERS_OPTIONS,
  RESET_SAVED_PAGE,
  RESET_PRODUCT,
  RESET_RELATED_PRODUCTS,
  RESET_SELECTED_FILTERS,
  SEARCH_SUCCESS,
  SET_SELECTED_FILTER,
  SET_SELECTED_FILTERS,
  STOP_LOADING_MORE_PRODUCTS,
  UPDATE_SELECTED_FILTERS,
  SET_SORT_BY_OPTION,
  RELATED_PRODUCTS_SECTION_LOADING,
  GET_CURATED_SECTION_SUCCESS,
  REMOVE_CURATED_SECTION,
} from '../constants/ActionTypes';
import {
  getBrand,
  getBrandFeaturedProducts,
  getBrandFilters,
  getBrandProducts,
  getBrands,
  getCategoryFeaturedProducts,
  getCategoryProducts,
  getDailyDeals,
  getFilters,
  getProductById,
  getProducts,
  getRelatedProducts,
  getVertical,
  getVerticalFeaturedProducts,
  getVerticalProducts,
  searchCatalog,
  getCuratedSection,
} from '../../services/catalogService';
import { handleErrorAction } from './utilsAction';
import {
  GET_MEGASTORE_VERTICAL_PRODUCTS_SUCCESS,
  GET_VERTICAL_FEATURED_PRODUCTS_SUCCESS,
  GET_VERTICAL_SUCCESS,
} from '../constants/CatalogActionTypes';
import { APP_URL, AUTOCOMPLETE_LIMIT } from '../../constants';
import { Capacitor, Plugins } from '@capacitor/core';

const { Share } = Plugins;

export const loadingProductsWithLastPageAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: LOADING_PRODUCTS_WITH_SAVED_PAGE,
    });
  };
};

export const resetSavedPageAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SAVED_PAGE,
    });
  };
};

export const scrollDataActionCat = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SCROLL_DATA_CAT,
      payload: data,
    });
  };
};


export const getDailyDealsAction = (data) => {
  return function (dispatch) {
    return getDailyDeals().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_DAILY_DEALS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};


export const getCuratedSectionAction = (id) => {
  return function (dispatch) {
    return getCuratedSection(id).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_CURATED_SECTION_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const removeCuratedSectionAction = () => {
  return function (dispatch) {
    return dispatch({
      type: REMOVE_CURATED_SECTION,
    });
  };
};

export const searchCatalogAction = (searchTerm, page, limit) => {
  return function (dispatch) {
    return searchCatalog(searchTerm, page, limit).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: SEARCH_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const updateCatalogSearchAction = (searchTerm, page, limit) => {
  return function (dispatch) {
    return searchCatalog(searchTerm, page, AUTOCOMPLETE_LIMIT).then(
      (response) => {
        if (response.status === 200) {
          if (
            response.data.data.products &&
            response.data.data.products.length > 0
          ) {
            return dispatch({
              type: LOAD_MORE_SEARCH_RESULTS,
              payload: response.data.data.products,
            });
          }
          if (
            response.data.data.products &&
            response.data.data.products.length === 0
          ) {
            return dispatch({
              type: STOP_LOADING_MORE_PRODUCTS,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getProductsAction = (params) => {
  // console.log('getProductsAction params', params);
  return async function (dispatch) {
    return await getProducts(params).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            if (response.data.data && response.data.data.length > 0) {
              let products = response.data.data;
              //console.log('products  = ', products);
              //products = products.filter((product) => product.shop);
              return dispatch({
                type: GET_ALL_PRODUCTS_SUCCESS,
                payload: products,
              });
            }
            if (response.data.data && response.data.data.length === 0) {
              dispatch({
                type: STOP_LOADING_MORE_PRODUCTS,
              });
            }
            dispatch({
              type: GET_ALL_PRODUCTS_SUCCESS,
              payload: [],
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const loadMoreProductsAction = (params) => {
  // console.log('loadMoreProductsAction params', params);
  return function (dispatch) {
    return getProducts(params).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data && response.data.data.length > 0) {
            return dispatch({
              type: LOAD_MORE_PRODUCTS,
              payload: response.data.data ? response.data.data : [],
            });
          }
          if (response.data.data && response.data.data.length === 0) {
            return dispatch({
              type: STOP_LOADING_MORE_PRODUCTS,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const loadMoreProductsWithSavedPageAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: LOAD_MORE_PRODUCTS_WITH_SAVED_PAGE, 
      payload: data,
    });
  };
};

export const relatedProductsSectionLoadingAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RELATED_PRODUCTS_SECTION_LOADING, 
      payload: data,
    });
  };
};


export const getCatalogProductByIdAction = (productId) => {
  return function (dispatch) {
    return getProductById(productId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_PRODUCT_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getProductRelatedAction = (productId) => {
  return function (dispatch) {
    return getRelatedProducts(productId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_RELATED_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getBrandsAction = () => {
  return function (dispatch) {
    return getBrands().then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_BRANDS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getBrandAction = (brandId) => {
  return function (dispatch) {
    return getBrand(brandId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_BRAND_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getBrandProductsAction = (brandId) => {
  return function (dispatch) {
    return getBrandProducts(brandId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_BRAND_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getBrandFeaturedProductsAction = (brandId) => {
  return function (dispatch) {
    return getBrandFeaturedProducts(brandId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_BRAND_FEATURED_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getCategoryProductsAction = (categoryId) => {
  return function (dispatch) {
    return getCategoryProducts(categoryId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_CATEGORY_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getCategoryFeaturedProductsAction = (categoryId) => {
  return function (dispatch) {
    return getCategoryFeaturedProducts(categoryId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_CATEGORY_FEATURED_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const updateFilterAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_SELECTED_FILTERS,
      payload: data,
    });
  };
};

export const setFilterAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_SELECTED_FILTERS,
      payload: data,
    });
  };
};

export const setSelectedFilterAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_SELECTED_FILTER,
      payload: data,
    });
  };
};

export const resetSelectedFiltersAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SELECTED_FILTERS,
    });
  };
};

export const resetRelatedProductsAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_RELATED_PRODUCTS,
    });
  };
};

export const removeFilterAction = (item, filter) => {
  return function (dispatch) {
    return dispatch({
      type: REMOVE_FILTER_TERM,
      payload: { item, filter },
    });
  };
};

export const resetFiltersAction = (data) => {
  if (data === 'on reset button') {
    return function (dispatch) {
      return dispatch({
        type: RESET_FILTERS,
        payload: data,
      });
    };
  }
  else {return function (dispatch) {
    return dispatch({
      type: RESET_FILTERS,
    });
  };}
};

export const resetPaginationAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_PAGINATION_ACTION,
    });
  };
};

export const clearAllFiltersAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_ALL_FILTERS,
      payload: data,
    });
  };
};

export const setSortByAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_SORT_BY_OPTION,
      payload: data,
    });
  };
};

export const resetProductsAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_PRODUCTS,
    });
  };
};

export const resetFiltersOptionsAction = () => {
  return function (dispatch) {
    return dispatch({
      type: RESET_FILTERS_OPTIONS,
    });
  };
};

export const resetProductAction = () => {
  return function (dispatch) {
    return dispatch({
      type: RESET_PRODUCT,
    });
  };
};

export const loadProductAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: LOADING_PRODUCT,
    });
  };
};

export const getFilterOptionsAction = (data) => {
  // console.log('data here ',data);
  return function (dispatch) {
    return getFilters(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_FILTERS_OPTIONS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getBrandFilterOptionsAction = (data) => {
  return function (dispatch) {
    return getBrandFilters(data).then(
      (response) => {
        // if (response.status === 200) {

        return dispatch({
          type: GET_BRAND_FILTERS_OPTIONS_SUCCESS,
          payload: response.data.data ? response.data.data : [],
        });
        // }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getVerticalAction = (verticalId) => {
  return function (dispatch) {
    return getVertical(verticalId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_VERTICAL_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getVerticalProductsAction = (verticalId) => {
  return function (dispatch) {
    return getVerticalProducts(verticalId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_MEGASTORE_VERTICAL_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getVerticalFeaturedProductsAction = (verticalId) => {
  return function (dispatch) {
    return getVerticalFeaturedProducts(verticalId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            return dispatch({
              type: GET_VERTICAL_FEATURED_PRODUCTS_SUCCESS,
              payload: response.data.data,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const shareProductAction = (product) => (dispatch) => {
  const shareUrl = `${APP_URL}/product/${product.id}?op=1`;
  const title = `${product.name} on Inalipa`;
  const text = `Please see ${product.name} on inalipa by visiting`;
  const shareData = {
    title: title,
    text: text,
    url: shareUrl,
  };
  const message = `${shareUrl}`;
  if (Capacitor.isNative) {
    return Share.share({
      title: title,
      text: text,
      url: shareUrl,
      dialogTitle: `Share product: ${title}`,
    });
  } else {
    if (navigator.share === undefined) {
      return dispatch({
        type: PRODUCT_WEB_SHARE_SUCCESS,
        payload: message,
      });
    } else {
      // Share content
      try {
        navigator.share(shareData);
      } catch (error) {}
    }
  }
};

export const resetProductSharingAction = (data) => (dispatch) => {
  return dispatch({ type: RESET_PRODUCT_SHARING });
};
