import React from 'react';
import Modal from 'react-modal';

const OptionsModal = ({ isOpen, setIsOpen, component }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0 !important',
      maxWidth: '500px',
      width: '80%',
      height: window.innerWidth < 576 ? '80%' : '',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName="bgOverlay"
      closeTimeoutMS={2000}
    >
      {component}
    </Modal>
  );
};

export default OptionsModal;
