import {
  GET_BANNERS_SUCCESS,
  CHANGE_FILTER_STATE,
  TOGGLE_MOBILE_MENU,
  SHOW_MOBILE_BACK_BUTTON,
  CHANGE_SORT_STATE,
  TOGGLE_FLYOUT_MENU,
  SET_PREVIOUS_PATHNAME,
  SET_LOADING_STATE,
  CHANGE_LANGUAGE_SUCCESS,
  UPDATE_SCROLL_POSITION,
  SELECT_PAGE,
  RESET_SELECTED_PAGE,
  SET_SCROLL_TO_LAST_POSITION,
  RE_ORDER,
  CART_ITEMS_RETRIEVED,
  SAVE_ORDER,
  TOGGLE_CALL_MODAL,
  SCROLL_DATA_UI,
  UPDATE_LAST_SCROLL_POSITION,
  SELECT_RELATED_PRODUCT,
  RESET_SELECTED_RELATED_PRODUCT,
  GET_NOTIFICATIONS_SUCCESS,
  RESET_NOTIFICATION,
  SET_ACTIVE_ACCORDION,
  LOCATION_CHANGE,
  RESET_LOCATION,
  SET_UPDATE_LAST_SCROLL_POSITION,
  SET_PREVIOUS_PAGE_IS_PRODUCT,
  SCROLL_TO_LAST_POSITION,
  SET_PREVIOUS_URL,
} from '../constants/ActionTypes';
import { SET_INALIPA_CONTACT_INFORMATION } from '../constants/UiActionTypes';

const initialState = {
  banners: [],
  filterState: false,
  canShowSort: false,
  isMobileMenuOpen: false,
  isFlyoutMenuOpen: false,
  showMobileBackButton: false,
  previousPathname: {},
  previousUrl: {},
  isLoading: false,
  language: 'en',
  selectedPage: null,
  isRelatedProductSelected: false,
  notifications: null,
  loadingNotifications: true,
  activeAccordionPanel: '',
  scrollPosition: 0,
  updateLastScrollPosition : true,
  location: {},
  lastScrollPosition: 0,
  scrollToLastPosition: false,
  previousPageIsProduct: false,
  contactInfo: {},
};

const stateReducer = (
  state = initialState,
  { type, payload, isSharedItemsIncomplete },
) => {
  switch (type) {
    case TOGGLE_CALL_MODAL:
        return {
          ...state,
          isCallModalOpen: payload,
        };
    case UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: payload,
      };
      case SET_PREVIOUS_PAGE_IS_PRODUCT:
      return {
        ...state,
        previousPageIsProduct: payload,
      };
      case SET_SCROLL_TO_LAST_POSITION:
      return {
        ...state,
        scrollToLastPosition: payload,
      };
           case SET_UPDATE_LAST_SCROLL_POSITION:
      return {
        ...state,
        updateLastScrollPosition: payload,
      };
    case UPDATE_LAST_SCROLL_POSITION:
      return {
        ...state,
        lastScrollPosition: payload,
      };
    case SCROLL_DATA_UI:
      if(payload === 'reset') {
        // console.log('reset scroll Ui');
      return {
        ...state,
        lastScrollPosition: 0,
        scrollPosition : 0,
        scrollToLastPosition: false,
      }; }
      if(payload === 'save') {
        // console.log('save scroll Ui');
      return {
        ...state,
        scrollToLastPosition: true,
        updateLastScrollPosition: false,
        // lastScrollPosition: scrollPosition,

      }; }
      if(payload === 'used') {
        // console.log('used scroll Ui');
      return {
        ...state,
        updateLastScrollPosition: true,
        scrollToLastPosition: false,

      }; }
      // case SCROLL_TO_LAST_POSITION:
      // return {
      //   ...state,
      //   scrollToLastPosition: payload,
      // };
    case LOCATION_CHANGE:
      return { ...state};
    case RESET_LOCATION:
      return { ...state, location: {}};
    case GET_BANNERS_SUCCESS:
      return { ...state, banners: payload, isLoading: false };
    case CHANGE_FILTER_STATE:
      return { ...state, filterState: payload };
    case CHANGE_SORT_STATE:
      return { ...state, canShowSort: payload };
    case TOGGLE_MOBILE_MENU:
      return { ...state, isMobileMenuOpen: payload };
    case TOGGLE_FLYOUT_MENU:
      return { ...state, isFlyoutMenuOpen: payload };
    case CART_ITEMS_RETRIEVED:
      return { ...state, isFlyoutMenuOpen: isSharedItemsIncomplete };
    case RE_ORDER:
      return { ...state, isFlyoutMenuOpen: true };
    case SAVE_ORDER:
      return { ...state, isFlyoutMenuOpen: false };
    case SHOW_MOBILE_BACK_BUTTON:
      return { ...state, showMobileBackButton: payload };
    case SET_PREVIOUS_PATHNAME:
      return { ...state, previousPathname: payload };
    case SET_PREVIOUS_URL:
      return { ...state, previousUrl: payload };
    case SET_LOADING_STATE:
      return { ...state, isLoading: true };
    case CHANGE_LANGUAGE_SUCCESS:
      return { ...state, language: payload };
    case SELECT_PAGE:
      return { ...state, selectedPage: payload };
    case SET_ACTIVE_ACCORDION:
      return { ...state, activeAccordionPanel: payload };
    case RESET_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: null,
      };
    case SELECT_RELATED_PRODUCT:
      return {
        ...state,
        isRelatedProductSelected: true,
      };
    case RESET_SELECTED_RELATED_PRODUCT:
      return {
        ...state,
        isRelatedProductSelected: false,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: payload, loadingNotifications: false };

    case RESET_NOTIFICATION:
      return { ...state, notifications: null, loadingNotifications: true };

    case SET_INALIPA_CONTACT_INFORMATION:
      return { ...state, contactInfo: payload };
    default:
      return state;
  }
};

export default stateReducer;
