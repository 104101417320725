import React from "react";
import { HalfCircle, ListImageWrapper } from "../styles/List";
import LazyImage from "./LazyLoadingImage";
const ImageWithLogo = ({ image, logo }) => {
  return (
    <>
      <ListImageWrapper>
        <img src={image} alt="banner" className="img-fluid img-list" />
        <HalfCircle>
          <img src={logo} className="img-fluid"  alt="logo"/>
        </HalfCircle>
      </ListImageWrapper>
    </>
  );
};

export default ImageWithLogo;
