import React, { useState } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Link, useHistory } from 'react-router-dom';
import './VerticalCards.css';
import placeholder from '../../../assets/images/placeholders/item.png';

export function VerticalScrollCard({ link, image, title, shape, color }) {
  const history = useHistory();
  let borderRadius;
  if (shape === 'circle') {
    borderRadius = '50%';
  }
  if (shape === 'square') {
    borderRadius = '20%';
  }
  if (shape === 'box') {
    borderRadius = '10%';
  }

  // const [mouseMoved, setMouseMoved] = useState(false);
  // const handleClick = () => {
  //   if (!mouseMoved) {
  //   to=`${link}`;
  //   }
  // };
  color = color ? color : 'black';
  return (
    <Link
      // onMouseMove={() => setMouseMoved(true)}
      // onMouseDown={() => setMouseMoved(false)}
      // onMouseUp={() => (!mouseMoved ? linkToGo = link : linkToGo = null)}
      to={link}
    >
      <div role="button" tabIndex={0} className={`Vertical-Card ${shape}`}>
        {/* category image */}
        <img
          className="shape"
          // src={image}
          src={image ? image : placeholder}
          alt={`${link}`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: borderRadius,
          }}
        />
        {/* category title */}
        <div
          style={{
            textAlign: 'center',
            fontSize: '13px',
            color: color,
            lineHeight: '13px',
            marginTop: '12px',
          }}
        >
          {title}
        </div>
      </div>
    </Link>
  );
}
