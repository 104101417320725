import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const ProgressBarLine = withStyles((theme) => ({
    root: {
        height: 16,
        borderRadius: 8,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 8,
        backgroundColor: '#00C400',
    },
}))(LinearProgress);

export default ProgressBarLine;