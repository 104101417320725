import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VariantsWrapper } from '../styles/variants';
import { INALIPA_S3_URL } from '../../constants';
import VariantSelector from '../ui/VariantSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddToCartButton from '../cart/AddToCartButton';
import { ActionButtonDangerRound } from '../styles/Form';
import { useTranslation } from 'react-i18next';
import {
  selectProductAction,
  selectVariantAction,
} from '../../store/actions/cartAction';
import useLazyLoadImage from '../../hooks/useLazyLoadImage';
import placeholder from '../../assets/images/placeholders/new_logo.jpg';

const ProductVariants = ({
  product,
  closeModal,
  variations,
  productListItem,
}) => {
  console.log('product + variants', productListItem);
  const dispatch = useDispatch();
  const selectedVariant = useSelector(
    (state) => state.cartManager.selectedVariant,
  );
  const selectedProduct = useSelector(
    (state) => state.cartManager.selectedProduct,
  );
  const [cartProduct, setCartProduct] = useState(null);

  const { t } = useTranslation('product');
  let source = null;
  if (selectedVariant && selectedVariant.picture) {
    source = `${INALIPA_S3_URL}${`products/${selectedVariant.picture}`}`;
  } else {
    source = placeholder;
  }

  useEffect(() => {
    dispatch(selectVariantAction(productListItem.variations.variants[0]));
  }, []);

  useEffect(() => {
    let canTrackInventory = product.track;
    let isVariantOutOfStock =
      canTrackInventory && selectedVariant && selectedVariant.inventory === 0;
    let isOutOfStock = product.isOutOfStock;
    let selectedProductObject = {
      track: canTrackInventory,
      isVariantOutOfStock: isVariantOutOfStock,
      isOutOfStock: isOutOfStock,
    };
    if (selectedVariant) {
      let updatedVariant = {
        ...selectedProduct,
        ...selectedProductObject,
        sku_id: selectedVariant.sku,
        price: parseInt(selectedVariant.price),
        total: parseInt(selectedVariant.price),
        options: { variant: selectedVariant.name },
        isVariantOutOfStock: selectedVariant.inventory === 0,
        stock: selectedVariant.inventory,
        image: selectedVariant.picture
          ? `products/${selectedVariant.picture}`
          : null,
      };
      setCartProduct(updatedVariant);
      dispatch(selectProductAction(updatedVariant));
    }
  }, [selectedVariant]);

  let loadedImage = useLazyLoadImage(source);

  return (
    <>
      {selectedVariant && (
        <VariantsWrapper>
          <div className="variant-product-image-wrapper position-relative">
            <button
              onClick={closeModal}
              className="close-icon position-absolute"
              style={{
                top: 0,
                right: '0px',
                backgroundColor: '#FFF',
                padding: '3px',
                height: '25px',
                cursor: 'pointer',
                zIndex: '20',
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img src={source} alt={product.name} className="img-fluid" />
            {product.shop && product.shopImage && (
              <img
                src={`${INALIPA_S3_URL}shops/${product.shopImage}`}
                alt={product.shop.name}
                className="img-fluid shopLogo"
              />
            )}
          </div>

          <div className="variants-content">
            <h2>{product.name}</h2>
            <h3>
              TZS {selectedVariant && selectedVariant.price.toLocaleString()}
            </h3>
            {cartProduct && cartProduct.track && (
              <h5 className="text-center">
                {selectedVariant && selectedVariant.inventory} left
              </h5>
            )}
            {productListItem &&
              productListItem.variations &&
              productListItem.variations.variants &&
              productListItem.variations.variants.length > 0 &&
              productListItem.variations.options &&
              productListItem.variations.options.map((variantOption, index) => (
                <VariantSelector
                  options={variantOption}
                  variants={productListItem.variations.variants}
                  product={productListItem}
                  index={index + 1}
                />
              ))}

            {cartProduct &&
              selectedProduct &&
              selectedVariant &&
              ((cartProduct.track &&
                !cartProduct.isVariantOutOfStock &&
                selectedVariant.inventory > 0) ||
                (!cartProduct.track && !cartProduct.isOutOfStock)) && (
                <AddToCartButton
                  cartItem={selectedProduct}
                  canTrackInventory={cartProduct.track}
                />
              )}

            {selectedProduct &&
              selectedVariant &&
              ((selectedProduct.track && selectedVariant.inventory === 0) ||
                (!selectedProduct.track && selectedVariant.isOutOfStock)) && (
                <ActionButtonDangerRound>
                  {t('out_of_stock')}
                </ActionButtonDangerRound>
              )}
          </div>
        </VariantsWrapper>
      )}
    </>
  );
};

export default ProductVariants;
