import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {PostReviewWrapper} from "../styles/Review";
import {timeTo12Hrs} from "../../utils/format";

const OpeningHoursModal = ({hours, closeModal}) => {

    return (
        <>
            <PostReviewWrapper>
                <div className="container">
                    <div className="row header">
                        <h3>Opening Hours</h3>
                        <div className="float-right">
                            <button
                                onClick={closeModal}
                                className="close-icon"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <hr/>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            {hours.length > 0 &&
                            <table className="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>DAY</th>
                                    <th>Open From</th>
                                    <th>Closes at</th>
                                </tr>
                                </thead>
                                <tbody>
                                {hours.map((time) => (
                                    <tr>
                                        <td>{time.day.charAt(0).toUpperCase() + time.day.slice(1)}</td>
                                        <td>{time.timeFrom ? timeTo12Hrs(time.timeFrom) : "Closed"}</td>
                                        <td>{time.timeTo ? timeTo12Hrs(time.timeTo) : "Closed"}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>}
                            {hours.length === 0 && <h4>No Opening Hours</h4>}
                        </div>
                    </div>
                </div>
            </PostReviewWrapper>
            </>
    );
}

export default OpeningHoursModal;