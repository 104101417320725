import {
  ADD_DELIVERY_DETAILS,
  UPDATE_LOCATION,
  RESTORE_DEFAULT_CONFIG,
  CHANGE_LOCATION_PERMISSION_STATUS,
  GET_CURRENT_LOCATION,
  LOCATION_OUT_OF_BOUND,
  RESET_LOADING_LOCATION,
} from '../constants/ActionTypes';
import _ from 'lodash';

const initialState = {
  markerPosition: null,
  mapPosition: {
    lat: -6.810787399999999,
    lng: 39.2596277,
  },
  establishment: '',
  address: '',
  zoom: 8,
  city: '',
  area: '',
  region: '',
  isLocationEnabled: false,
  isLoading: false,
  isLocationSaved: false,
  isLocationOutOfBound: false,
};

function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: action.payload,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        establishment: action.payload.establishment,
        address: action.payload.address,
        mapPosition: action.payload.mapPosition,
        markerPosition: action.payload.markerPosition,
        city: action.payload.city,
        area: action.payload.area,
        region: action.payload.region,
        zoom: action.payload.zoom,
        isLoading: false,
        isLocationSaved: true,
        isLocationOutOfBound: false,
      };
    case RESTORE_DEFAULT_CONFIG:
      return { ...state, zoom: 8 };
    case LOCATION_OUT_OF_BOUND:
      return { ...state, isLocationOutOfBound: action.payload };
    case CHANGE_LOCATION_PERMISSION_STATUS:
      return {
        ...state,
        isLocationEnabled: action.payload,
        isLoading: true,
      };
    case GET_CURRENT_LOCATION:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_LOADING_LOCATION:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default LocationReducer;
