import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Slider from 'react-slick';
import ProductTileSkeleton from './ProductTileSkeleton';

function ProductsSliderSkeleton({ link , header}) {
  let settings = {}
  if (!header) {
    settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4.75,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4.75,
            slidesToScroll: 4,
            dots: true,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2.75,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.75,
            slidesToScroll: 2,
            dots: true,
          },
        },
      ],
    };
  }

  if (header) { 
  settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.7,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.75,
          slidesToScroll: 4,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.75,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };
  }

  return (
    <>
    <div className="container-full pl-4"> 
    {header && (
      <div className="row pl-0 pt-1 section-header">
        <div className="col-8 pl-0 text-left">
          <h2 className="pl-0">
            <Skeleton width={180} height={60} />
          </h2>
        </div>
        {link && (
          <div className="pl-0 text-right">
            <h2>
              <SkeletonTheme color="#9dc49d">
                <Skeleton width={100} height={50} />
              </SkeletonTheme>
            </h2>
          </div>
        )}
      </div>
    )}
    {!header && (
      <div className="row pl-0 pt-1 section-header">
        <div className="pl-0 text-left">
          <h2 className="pl-0">
            <Skeleton width={180} height={40} />
          </h2>
        </div>
      </div>
    )}
    
      <Slider {...settings}>
        {Array(6)
          .fill()
          .map((item, index) => (
            <ProductTileSkeleton key={index} />
          ))}
      </Slider>
    </div>
    </>
  );
}

export default ProductsSliderSkeleton;
