import {
  GET_BANNERS_SUCCESS,
  CHANGE_FILTER_STATE,
  TOGGLE_MOBILE_MENU,
  SHOW_MOBILE_BACK_BUTTON,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_SORT_STATE,
  TOGGLE_FLYOUT_MENU,
  SET_PREVIOUS_PATHNAME,
  SET_PREVIOUS_URL,
  SET_LOADING_STATE,
  SELECT_PAGE,
  SCROLL_DATA_UI,
  RESET_SELECTED_PAGE,
  TOGGLE_CALL_MODAL,
  UPDATE_LAST_SCROLL_POSITION,
  SELECT_RELATED_PRODUCT,
  RESET_SELECTED_RELATED_PRODUCT,
  GET_NOTIFICATIONS_SUCCESS,
  RESET_NOTIFICATION,
  SET_ACTIVE_ACCORDION,
  SET_UPDATE_LAST_SCROLL_POSITION,
  LOCATION_CHANGE,
  SCROLL_TO_LAST_POSITION,
  RESET_LOCATION,
  UPDATE_SCROLL_POSITION,
  SET_SCROLL_TO_LAST_POSITION,
  SET_PREVIOUS_PAGE_IS_PRODUCT,
} from '../constants/ActionTypes';
import { getBanners, getDeviceNotifications } from '../../services/uiServices';
import { handleErrorAction } from './utilsAction';
import { SET_INALIPA_CONTACT_INFORMATION } from '../constants/UiActionTypes';

export const getBannersAction = (data) => {
  return function (dispatch) {
    return getBanners({}).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_BANNERS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const changeFilterMenuState = (state) => {
  return function (dispatch) {
    return dispatch({
      type: CHANGE_FILTER_STATE,
      payload: state,
    });
  };
};

export const toggleCallmodalAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: TOGGLE_CALL_MODAL,
      payload: state,
    });
  };
};

// export const scrollToLastPositionAction = (data) => {
//   return function (dispatch) {
//     return dispatch({
//       type: SCROLL_TO_LAST_POSITION,
//       payload: data,
//     });
//   };
// };

export const   updateLastScrollPositionAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_LAST_SCROLL_POSITION,
      payload: data,
    });
  };
};

export const setPreviousPageIsProductAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_PREVIOUS_PAGE_IS_PRODUCT,
      payload: data,
    });
  };
};

export const updateScrollPositionAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_SCROLL_POSITION,
      payload: data,
    });
  };
};

export const scrollDataActionUi = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SCROLL_DATA_UI,
      payload: data,
    });
  };
};

export const scrollToLastPositionAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_SCROLL_TO_LAST_POSITION,
      payload: data,
    });
  };
};

export const setUpdateLastScrollPosition = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_UPDATE_LAST_SCROLL_POSITION,
      payload: data,
    });
  };
};

export const changeSortMenuState = (state) => {
  return function (dispatch) {
    return dispatch({
      type: CHANGE_SORT_STATE,
      payload: state,
    });
  };
};

export const toggleMobileMenuAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: TOGGLE_MOBILE_MENU,
      payload: state,
    });
  };
};

export const toggleFlyoutMenuAction = (state, itemsInCart) => {
  return function (dispatch) {
    return dispatch({
      type: TOGGLE_FLYOUT_MENU,
      payload: state,
      itemsInCart,
    });
  };
};

export const handlerBackButtonAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: SHOW_MOBILE_BACK_BUTTON,
      payload: state,
    });
  };
};

export const changeLanguageAction = (language) => {
  return function (dispatch) {
    return dispatch({
      type: CHANGE_LANGUAGE_SUCCESS,
      payload: language,
    });
  };
};

export const setPreviousPathnameAction = (pathname) => {
  return function (dispatch) {
    return dispatch({
      type: SET_PREVIOUS_PATHNAME,
      payload: pathname,
    });
  };
};

export const setPreviousUrlAction = (pathname) => {
  return function (dispatch) {
    return dispatch({
      type: SET_PREVIOUS_URL,
      payload: pathname,
    });
  };
};
export const setLoadingState = () => {
  return function (dispatch) {
    return dispatch({
      type: SET_LOADING_STATE,
    });
  };
};

export const selectPageAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SELECT_PAGE,
      payload: data,
    });
  };
};

export const setActiveAccordionAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_ACTIVE_ACCORDION,
      payload: data,
    });
  };
};

export const resetSelectedPageAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SELECTED_PAGE,
    });
  };
};

export const selectedRelatedProductAction = () => {
  return function (dispatch) {
    return dispatch({
      type: SELECT_RELATED_PRODUCT,
    });
  };
};

export const resetSelectedRelatedProductAction = () => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SELECTED_RELATED_PRODUCT,
    });
  };
};

export const setInalipaContactInformationAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: SET_INALIPA_CONTACT_INFORMATION,
      payload: data,
    });
  };
};

export const getDeviceNotificationsAction = (data) => {
  return function (dispatch) {
    return getDeviceNotifications(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const resetDeviceNotificationsAction = () => (dispatch) => {
  return dispatch({ type: RESET_NOTIFICATION });
};

export const locationChangeAction = (data) => {
  return {
    type: LOCATION_CHANGE,
    payload: data,
  };
};

export const resetLocationAction = (location) => {
  return {
    type: RESET_LOCATION,
    payload: location,
    // store.dispatch(push(''));
  };
};
