import React, { useState , useEffect} from 'react';
import { TrackOrderResultsWrapper } from '../styles/Order/Order';
import check from '../../assets/images/check.png';
import { numberWithCommas } from '../../utils/format';
import AppModal from '../modals/PostReviewModal';
import WriteReview from '../modals/WriteReview';
import FileDispute from '../modals/FileDisputeModal';
import { useTranslation } from 'react-i18next';
import { Capacitor, Plugins } from '@capacitor/core';
// import Collapse from '@mui/material/Collapse';
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon';
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';

const { Browser } = Plugins;
const TrackOrderResults = ({ order }) => {
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const { t } = useTranslation('menu');

  const openReceiptInAppBrowser = (receiptLink) => {
    return Browser.open({ url: receiptLink });
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    var x = document.getElementById("tracking-map");
    if (!open) {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  
  // function refreshIframe() {
  //   var iframe = document.getElementById("tracking-map");
  //   var elmnt = iframe.getElementsByClassName("fa-repeat")[0];
  //   elmnt.click();
  // }

// //  refresh iframe every 4 seconds
//   useEffect(() => {
//     if (order.tracking_number) {
//       const timer = setInterval(() => {
//         const iframe = document.getElementById('tracking-map');
//         iframe.src = iframe.src;
//         console.log('refreshing iframe');
//         document.frames['tracking-map'].location.reload(); 
//       }, 4000);
//       return () => clearInterval(timer);
//     }
//     // const interval = setInterval(() => {
//     //   document.frames['tracking-map'].location.reload(); 
//     // }, 300);
//     // return () => clearInterval(interval);
//   }, []);


  return (
    <>
      <TrackOrderResultsWrapper>
        <div className="row">
          <div className="col-md">
            <div className="bg-white p-md m-b-md">
              {/*<h2>Delivery Method: <strong>{order.shippingAddress.deliveryMethod.name === "partial"}</strong></h2>*/}
              <div className="m-b-md mt-3">&nbsp;</div>

              {order.trackingUrl &&  
                               ( order.transactions &&
                                order.transactions.length > 0 &&
                                order.transactions[0].metadata &&
                                order.transactions[0].metadata.dispatchment &&
                                order.transactions[0].metadata.dispatchment
                                  .status &&
                                order.transactions[0].metadata.dispatchment.status === 'dispatched') &&
                                !(order.transactions &&
                                order.transactions.length > 0 &&
                                order.transactions[0].metadata &&
                                order.transactions[0].metadata.fulFillment &&
                                order.transactions[0].metadata.fulFillment
                                  .status &&
                                order.transactions[0].metadata.fulFillment
                                  .status === 'fulfilled')&& 
                                  (
                                   
                                    <>
            
                    <div className="col-md-12 pr-0 pl-0 mt-4 pb-2" >
                      <div className="btn btn-primary pt-0 pb-0" onClick={handleClick} style={{width:'100%'}}>
                          {open ? 'Hide Driver Location' : 'Reveal Driver Location'} 
                          {open ? <ArrowUpIcon/>:  <ArrowDownIcon/>} 
                        </div>
                    </div>
                    {/* // show an iframe with the tracking url */}
                    <div className="col-md-12 mt-1" id={'tracking-map'} style={{display:'none'}}>
                      <iframe
                        title={order.trackingNumber}
                        name={'tracking-map'}
                        src={order.trackingUrl}
                        width="100%"
                        height="500"
                        frameBorder="0"
                        style={{ border: 'none' }}
                        allowTransparency="true"
                        allow="encrypted-media"
                      ></iframe>
                    </div>
                    </>
                                  )}

              {order.shippingAddress.deliveryMethod.name === 'delivery' && (
                <>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <th>
                          {order.status === 'refunded' && (
                            <>
                              <button className="btn btn-sm btn-danger">
                                Refunded
                              </button>
                              &nbsp;
                            </>
                          )}
                          Delivery
                        </th>
                        <td>
                          {
                            order.shippingAddress.deliveryMethod.params
                              .deliveryDate
                          }
                          .{' '}
                          {
                            order.shippingAddress.deliveryMethod.params
                              .deliveryTime
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="order-timeline m-b-lg">
                    {/*<hr className="divider no-margin" />*/}
                    <div className="row" style={{ margin: '0 -15px' }}>
                      <div className="col-4 timeline-step">
                        <div className="row">
                          <div className="col-12">
                            <span
                              className={
                                (order.status && order.status === 'complete') ||
                                'dispatched' ||
                                'fulfilled'
                                  ? 'check active'
                                  : 'check'
                              }
                            >
                              <img src={check} alt="check" />
                            </span>
                          </div>
                          <div className="col-12">
                            <label className="state">Processed</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-4 timeline-step text-center">
                        <div className="row">
                          <div className="col-12">
                            <span
                              className={
                                order.transactions &&
                                order.transactions.length > 0 &&
                                order.transactions[0].metadata &&
                                order.transactions[0].metadata.dispatchment &&
                                order.transactions[0].metadata.dispatchment
                                  .status &&
                                order.transactions[0].metadata.dispatchment
                                  .status === 'dispatched'
                                  ? 'check active'
                                  : 'check'
                              }
                            >
                              <img src={check} alt="check" />
                            </span>
                          </div>
                          <div className="col-12">
                            <label className="state">Dispatched</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 timeline-step text-right">
                        <div className="row">
                          <div className="col-12">
                            <span
                              className={
                                order.transactions &&
                                order.transactions.length > 0 &&
                                order.transactions[0].metadata &&
                                order.transactions[0].metadata.fulFillment &&
                                order.transactions[0].metadata.fulFillment
                                  .status &&
                                order.transactions[0].metadata.fulFillment
                                  .status === 'fulfilled'
                                  ? 'check active'
                                  : 'check'
                              }
                            >
                              <img src={check} alt="check" />
                            </span>
                          </div>
                          <div className="col-12">
                            <label className="state">Delivered</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table" style={{ marginBottom: '0' }}>
                      <thead>
                        <tr>
                          <td className="text-left">
                            <strong>Description</strong>
                          </td>
                          <td>
                            <strong>Qty</strong>
                          </td>
                          <td className="text-right">
                            <strong>Price</strong>
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {order.items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{numberWithCommas(item.quantity)}</td>
                            <td className="text-right">
                              {numberWithCommas(item.price)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className="text-right">
                            <strong>
                              {order.amount.currency}{' '}
                              {numberWithCommas(order.amount.total)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {order.shippingAddress.deliveryMethod.name === 'partial' && (
                <>
                  {order.transactions.length > 0 &&
                    order.transactions.map((transaction) => (
                      <>
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <th>Delivery</th>
                              {order.shippingAddress.deliveryMethod.params.shops.map(
                                (shop) => (
                                  <>
                                    {shop.store === transaction.shop && (
                                      <td>
                                        {shop.deliveryDate}. {shop.deliveryTime}
                                      </td>
                                    )}
                                  </>
                                ),
                              )}
                            </tr>
                          </tbody>
                        </table>

                        <div className="order-timeline m-b-lg">
                          {/*<hr className="divider no-margin" />*/}
                          <div className="row" style={{ margin: '0 -15px' }}>
                            <div className="col-4 timeline-step">
                              <div className="row">
                                <div className="col-12">
                                  <span
                                    className={
                                      (order.status &&
                                        order.status === 'complete') ||
                                      'dispatched' ||
                                      'fulfilled'
                                        ? 'check active'
                                        : 'check'
                                    }
                                  >
                                    <img src={check} alt="check" />
                                  </span>
                                </div>
                                <div className="col-12">
                                  <label className="state">Processed</label>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 timeline-step text-center">
                              <div className="row">
                                <div className="col-12">
                                  <span
                                    className={
                                      transaction.metadata.dispatchment &&
                                      transaction.metadata.dispatchment
                                        .status &&
                                      transaction.metadata.dispatchment
                                        .status === 'dispatched'
                                        ? 'check active'
                                        : 'check'
                                    }
                                  >
                                    <img src={check} alt="check" />
                                  </span>
                                </div>
                                <div className="col-12">
                                  <label className="state">Dispatched</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 timeline-step text-right">
                              <div className="row">
                                <div className="col-12">
                                  <span
                                    className={
                                      transaction.metadata.fulFillment &&
                                      transaction.metadata.fulFillment.status &&
                                      transaction.metadata.fulFillment
                                        .status === 'fulfilled'
                                        ? 'check active'
                                        : 'check'
                                    }
                                  >
                                    <img src={check} alt="check" />
                                  </span>
                                </div>
                                <div className="col-12">
                                  <label className="state">Delivered</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table
                          className="table"
                          style={{ marginBottom: '30px' }}
                        >
                          <thead>
                            <tr>
                              <td className="text-left">
                                <strong>Description</strong>
                              </td>
                              <td>
                                <strong>Qty</strong>
                              </td>
                              <td className="text-right">
                                <strong>Price</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.items.length > 0 &&
                              transaction.items.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.name}</td>
                                  <td>{numberWithCommas(item.quantity)}</td>
                                  <td className="text-right">
                                    {numberWithCommas(item.price)}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan="3" className="text-right">
                                <strong>
                                  {transaction.amount.currency}{' '}
                                  {numberWithCommas(transaction.amount.total)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ))}
                </>
              )}

              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th>Order #</th>
                    <td>{order.trackingNumber}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>
                      {order.amount.currency}{' '}
                      {numberWithCommas(order.amount.total)}
                    </td>
                  </tr>
                  <tr>
                    <th>Recipient</th>
                    <td>{order.shippingAddress.recipient_name}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{order.shippingAddress.address}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-md-12 pr-0 pl-0">
                  {order.receipt_link && (
                    <>
                      {!Capacitor.isNative && (
                        <a
                          className="btn btn-primary"
                          href={order.receipt_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Receipt
                        </a>
                      )}
                      {Capacitor.isNative && (
                        <a
                          className="btn btn-primary"
                          onClick={() =>
                            openReceiptInAppBrowser(order.receipt_link)
                          }
                        >
                          Receipt
                        </a>
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12 pr-0 pl-0 mt-4">
                  How was your experience with Inalipa ?{' '}
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      document.body.style.overflow = 'hidden';
                      setReviewModalIsOpen(true);
                    }}
                  >
                    Rate us
                  </button>
                </div>
                <div className="col-md-12 mt-2 pr-0 pl-0">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      document.body.style.overflow = 'hidden';
                      setDisputeModalIsOpen(true);
                    }}
                  >
                    File a dispute
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TrackOrderResultsWrapper>
      <AppModal
        isOpen={reviewModalIsOpen}
        component={
          <WriteReview
            order={order}
            closeModal={() => {
              document.body.style.overflow = 'unset';
              setReviewModalIsOpen(false);
            }}
          />
        }
        setIsOpen={setReviewModalIsOpen}
      />
      <AppModal
        isOpen={disputeModalIsOpen}
        component={
          <FileDispute
            order={order}
            closeModal={() => {
              document.body.style.overflow = 'unset';
              setDisputeModalIsOpen(false);
            }}
          />
        }
        setIsOpen={setDisputeModalIsOpen}
      />
    </>
  );
};

export default TrackOrderResults;
