import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { scrollToTop } from '../../utils/window';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import { FormInputWrapper } from '../../components/styles/Form';
import { becomeSupplier } from '../../services/websiteService';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
const { FirebaseAnalytics, FacebookAnalytics } = Plugins;

const validate = (values) => {
  const errors = {};

  if (!values.hasBizLicense) {
    errors.hasBizLicense =
      'Please selected whether or not you have a business license';
  }
  if (!values.vatRegistered) {
    errors.vatRegistered = 'This field is required';
  }
  if (!values.bizName) {
    errors.bizName = 'This field is required';
  }
  if (!values.firstName) {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName) {
    errors.lastName = 'This field is required';
  }
  if (!values.phone) {
    errors.phone = 'This field is required';
  }
  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.supplierType) {
    errors.supplierType = 'This field is required';
  }
  if (!values.category) {
    errors.category = 'This field is required';
  }
  if (!values.brands) {
    errors.brands = 'This field is required';
  }
  if (!values.hasSellRights) {
    errors.hasSellRights = 'This field is required';
  }
  if (!values.hasPhysicalStore) {
    errors.hasPhysicalStore = 'This field is required';
  }
  if (!values.sellingOnline) {
    errors.sellingOnline = 'This field is required';
  }
  if (values.sellingOnline === 'yes' && !values.onlinePlatform) {
    errors.onlinePlatform = 'This field is required';
  }
  if (!values.hasWebsite) {
    errors.hasWebsite = 'This field is required';
  }
  if (values.hasWebsite === 'yes' && !values.website) {
    errors.website = 'This field is required';
  }
  if (!values.hasSocialMediaPage) {
    errors.hasSocialMediaPage = 'This field is required';
  }
  if (values.hasSocialMediaPage === 'yes' && !values.socialMediaPage) {
    errors.socialMediaPage = 'This field is required';
  }
  if (!values.acceptTerms) {
    errors.acceptTerms = 'This field is required';
  }
  return errors;
};

const BecomeSupplier = () => {
  const categories = useSelector((state) => state.categoryManager.categories);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('footer');
  useEffect(() => {
    scrollToTop();
    dispatch(getCategoriesAction());
  }, []);
  const [isSubmitting, setSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      hasBizLicense: '',
      vatRegistered: '',
      bizName: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      supplierType: '',
      supplierTypeComments: '',
      category: '',
      comments: '',
      brands: '',
      hasSellRights: '',
      hasPhysicalStore: '',
      sellingOnline: '',
      onlinePlatform: '',
      hasWebsite: '',
      website: '',
      hasSocialMediaPage: '',
      socialMediaPage: '',
      acceptTerms: '',
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSubmitting(true);
      becomeSupplier(JSON.stringify(values, null, 2))
        .then((data) => {
          setSubmitting(false);
          // window.dataLayer.push({
          //   event: 'supplier_reg',
          //   eventProps: {
          //     category: 'supplier_reg',
          //     action: 'success',
          //     label: 'supplier_reg',
          //     value: 2001,
          //   },
          // });

          // FacebookAnalytics.logEvent({
          //   event: 'supplier_reg',
          //   params: {
          //     category: 'supplier_reg',
          //     action: 'success',
          //     label: 'supplier_reg',
          //     value: 2001,
          //   },
          // });

          FirebaseAnalytics.logEvent({
            name: 'supplier_reg',
            params: {
              action: 'success',
              platform: Capacitor.platform,
            },
          });

          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Successfully submitted',
            showConfirmButton: false,
            timer: 2000,
          });
          history.push('/');
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    },
  });
  return (
    <>
      <PageTitle title="Inalipa - Become a Supplier" />
      <SecondaryNav className="d-none d-md-block" />
      <div className="container mb-5">
        <h2 className="mt-2 mb-2 ml-2">{t('become_supplier')}</h2>
        <hr className="m-0" />
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mb-4">
              <h2 className="h3 mb-3 subtitle-text-2">
                Please kindly fill in this form to tell us more about your
                business.
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 mb-4">
            <div className="row mt-2">
              <div className="col-md-12">
                <FormInputWrapper>
                  <form
                    className="generic-form"
                    action="#"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="name">
                            Do you have a business license ?
                          </label>
                          <div className="form-group">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="hasBizLicense"
                                id="hasBizLicenseYes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="yes"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hasBizLicenseYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="hasBizLicense"
                                id="hasBizLicenseNo"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="no"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hasBizLicenseNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {formik.errors.hasBizLicense ? (
                            <div className="error">
                              {formik.errors.hasBizLicense}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="name">Are you VAT registered ?</label>
                          <div className="form-group">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="vatRegistered"
                                id="vatRegisteredYes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="yes"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="vatRegisteredYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="vatRegistered"
                                id="vatRegisteredNo"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="no"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="vatRegisteredNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {formik.errors.vatRegistered ? (
                            <div className="error">
                              {formik.errors.vatRegistered}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="name">
                            What is your business name ?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bizName"
                            name="bizName"
                            placeholder="Enter your business name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bizName}
                          />
                          {formik.errors.bizName ? (
                            <div className="error">{formik.errors.bizName}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <h2>Business Owner Info</h2>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="Enter First Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                          />
                          {formik.errors.firstName ? (
                            <div className="error">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="Enter Last Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                          />
                          {formik.errors.lastName ? (
                            <div className="error">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <input type="hidden" name="names" id="names" />
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <input
                            type="tel"
                            name="phone"
                            className="form-control"
                            id="phone"
                            placeholder="Enter Phone Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                          />
                          {formik.errors.phone ? (
                            <div className="error">{formik.errors.phone}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter Email Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>Are you a</label>
                        </div>

                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="supplierType"
                              id="manufacturer"
                              value="manufacturer"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="manufacturer"
                            >
                              Manufacturer
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="supplierType"
                              id="official_distributor"
                              value="official_distributor"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="official_distributor"
                            >
                              Official Distributor
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="supplierType"
                              id="retailer"
                              value="retailer"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="retailer"
                            >
                              Retailer
                            </label>
                          </div>
                          {formik.errors.supplierType ? (
                            <div className="error">
                              {formik.errors.supplierType}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="supplierTypeComments"
                            id="supplierTypeComments"
                            rows="3"
                            placeholder="Type a comment"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.supplierTypeComments}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="verticalCategory">
                            What is your product vertical ?
                          </label>
                          <select
                            className="form-control"
                            id="verticalCategory"
                            name="category"
                            aria-invalid="true"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Product Vertical</option>
                            {categories &&
                              categories.length > 0 &&
                              categories.map((category) => (
                                <option value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                          </select>
                          {formik.errors.category ? (
                            <div className="error">
                              {formik.errors.category}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="comments">Any Comment</label>
                          <input
                            type="text"
                            className="form-control"
                            name="comments"
                            id="comments"
                            placeholder="Type something"
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="brands">
                            What brands do you sell?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="brands"
                            id="brands"
                            placeholder="Type something"
                            aria-invalid="true"
                            value={formik.values.brands}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.brands ? (
                            <div className="error">{formik.errors.brands}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>
                            Do you exclusively own the rights to the brands you
                            sell ?
                          </label>
                        </div>

                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasSellRights"
                              id="hasSellRightsyes"
                              value="yes"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasSellRightsyes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasSellRights"
                              id="hasSellRightsno"
                              value="no"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasSellRightsno"
                            >
                              No
                            </label>
                          </div>
                          {formik.errors.hasSellRights ? (
                            <div className="error">
                              {formik.errors.hasSellRights}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>
                            Do you have a physical retail footprint?
                          </label>
                        </div>

                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasPhysicalStore"
                              id="hasPhysicalStoreyes"
                              value="yes"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasPhysicalStoreyes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasPhysicalStore"
                              id="hasPhysicalStoreno"
                              value="no"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasPhysicalStoreno"
                            >
                              No
                            </label>
                          </div>
                          {formik.errors.hasPhysicalStore ? (
                            <div className="error">
                              {formik.errors.hasPhysicalStore}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>
                            Are you already selling products online on an
                            e-commerce platform?
                          </label>
                        </div>

                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="sellingOnline"
                              id="sellingOnlineyes"
                              value="yes"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="sellingOnlineyes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="sellingOnline"
                              id="sellingOnlineno"
                              value="no"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="sellingOnlineno"
                            >
                              No
                            </label>
                          </div>
                          {formik.errors.sellingOnline ? (
                            <div className="error">
                              {formik.errors.sellingOnline}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="onlinePlatform">Platform name</label>
                          <input
                            type="text"
                            name="onlinePlatform"
                            className="form-control"
                            id="onlinePlatform"
                            placeholder="Type something"
                            value={formik.values.onlinePlatform}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.onlinePlatform ? (
                            <div className="error">
                              {formik.errors.onlinePlatform}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>Do you have a website?</label>
                        </div>
                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasWebsite"
                              id="hasWebsiteyes"
                              value="yes"
                              aria-invalid="false"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasWebsiteyes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasWebsite"
                              id="hasWebsiteno"
                              value="no"
                              aria-invalid="false"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasWebsiteno"
                            >
                              No
                            </label>
                          </div>
                          {formik.errors.hasWebsite ? (
                            <div className="error">
                              {formik.errors.hasWebsite}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="website">Website link</label>
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            id="website"
                            placeholder="E.g. http://example.com"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.website}
                          />
                          {formik.errors.website ? (
                            <div className="error">{formik.errors.website}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label>Do you have a social media page?</label>
                        </div>
                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasSocialMediaPage"
                              id="hasSocialMediaPageyes"
                              value="yes"
                              aria-invalid="false"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasSocialMediaPageyes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasSocialMediaPage"
                              id="hasSocialMediaPageno"
                              value="no"
                              aria-invalid="false"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="hasSocialMediaPageno"
                            >
                              No
                            </label>
                          </div>
                          {formik.errors.hasSocialMediaPage ? (
                            <div className="error">
                              {formik.errors.hasSocialMediaPage}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="socialMediaPage">
                            Social media link
                          </label>
                          <input
                            type="text"
                            name="socialMediaPage"
                            className="form-control"
                            id="socialMediaPage"
                            placeholder="E.g. http://example.com"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.socialMediaPage}
                          />
                          {formik.errors.socialMediaPage ? (
                            <div className="error">
                              {formik.errors.socialMediaPage}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="yes"
                            name="acceptTerms"
                            id="acceptTerms"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="acceptTerms"
                          >
                            By submitting this form, I accept Inalipa's{' '}
                            <Link
                              to="privacy-policy"
                              style={{ color: '#00C400' }}
                            >
                              Privacy Policy
                            </Link>
                          </label>
                          {formik.errors.acceptTerms ? (
                            <div className="error">
                              {formik.errors.acceptTerms}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md">
                        {!isSubmitting && (
                          <button
                            className="btn-submit"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        )}
                        {isSubmitting && (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md text-center">
                        {Object.keys(formik.errors).length > 0 ? (
                          <div className="error">
                            Error! Please review inputs above and submit again.
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </FormInputWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeSupplier;
