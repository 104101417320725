import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addShopToDeliverAction } from '../../store/actions/cartAction';
import DatePicker from 'react-datepicker';
import CustomDatePicker from './CustomDatePicker';
import CustomTimePicker from './CustomTimePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DELIVERY_WAITING_TIME } from '../../constants';
import _ from 'lodash';
import { setMinutes, setHours, isToday } from 'date-fns';

function DateTimePicker({ shop, shopId, openingHours, preOrder, shopName }) {
  // console.log('Deliver when available DateTimePicker shop = ', shop, shopName);
  const NowHour = parseInt(moment().format('H'));
  const NowMinute = parseInt(moment().format('m'));
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState(
    new moment().add(DELIVERY_WAITING_TIME, 'h').toDate(),
  );
  const [maxOvernightHour, setMaxOvernightHour] = useState(null);
  const [isShopClosed, setIsShopClosedStatus] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new moment().add(9, 'days').toDate());
  const [minTime, setMinTime] = useState({
    hour: parseInt(NowHour) + parseInt(DELIVERY_WAITING_TIME),
    min: NowMinute,
  });
  const [maxTime, setMaxTime] = useState({
    hour: 19,
    min: 0,
  });
  const [excludeTimes, setExcludeTimes] = useState([]);
  const inalipaMegastore = useSelector(
    (state) => state.shopManager.inalipaMegastore,
  );
  let inalipaOpensAt = inalipaMegastore.settings.opening_hours[0].timeFrom.split(
    ':',
  )[0];
  let inalipaclosesAt = inalipaMegastore.settings.opening_hours[0].timeTo.split(
    ':',
  )[0];

  const dispatch = useDispatch();

  useEffect(() => {
    let deliveryDateFormatted = moment(deliveryDate).format(
      'dddd , MMMM DD YYYY',
    );
    let deliveryTimeFormatted = moment(deliveryTime).format('LT');

    checkDeliveryTime(deliveryDate);

    dispatch(
      addShopToDeliverAction(shop, {
        store: shopId,
        deliveryDate: deliveryDateFormatted,
        deliveryTime: deliveryTimeFormatted,
        shopName: shopName,
      }),
    );
  }, []);

  useEffect(() => {
    // console.log(
    //   'useEffect deliveryTime + deliveryDate changed',
    //   deliveryTime,
    //   deliveryDate,
    // );
    updateDeliveryTime();
  }, [deliveryTime, deliveryDate]);

  const handleDeliveryDateChange = (date) => {
    setDeliveryDate(date);
    checkDeliveryTime(date);
  };

  const checkDeliveryTime = (selectedDate) => {
    // console.log(
    //   'Deliver When Available checkDeliveryTime + preOrder ',
    //   selectedDate,
    //   preOrder,
    // );
    let today = moment().format('ddd');
    let tomorrow = moment().add(1, 'days');
    let tomorrowLowerCase = moment().add(1, 'days').format('ddd').toLowerCase();
    let currentDay = today.toLowerCase();

    let TodaysOpeningHour = _.filter(openingHours, ['day', currentDay])[0];
    let TomorrowOpeningHour = _.filter(openingHours, [
      'day',
      tomorrowLowerCase,
    ])[0];
    let isOvernightStore = _.includes(TodaysOpeningHour, true);
    let _isTomorrow = moment(selectedDate).isSame(tomorrow, 'day');
    let isAfterTomorrow = moment(selectedDate).isAfter(tomorrow, 'day');
    let _midNight = -1;

    let isShopClosedToday =
      TodaysOpeningHour && TodaysOpeningHour.timeFrom === '' ? true : false;

    let isShopClosedTomorrow =
      TodaysOpeningHour && TomorrowOpeningHour.timeFrom === '' ? true : false;

    let openHour = parseInt(TodaysOpeningHour.timeFrom.split(':')[0]);
    let closeHour = parseInt(TodaysOpeningHour.timeTo.split(':')[0]);
    let tomorrowOpenHour = parseInt(TomorrowOpeningHour.timeFrom.split(':')[0]);
    let tomorrowCloseHour = parseInt(TomorrowOpeningHour.timeTo.split(':')[0]);
    inalipaclosesAt = parseInt(inalipaclosesAt);
    inalipaOpensAt = parseInt(inalipaOpensAt);
    let _excludeTimes = [];

    openHour = openHour + parseInt(DELIVERY_WAITING_TIME);

    if (isToday(selectedDate)) {
      let TodayMinDeliveryTime = parseInt(NowHour);

      if (NowHour < openHour) {
        setMinTime({
          hour: openHour,
          min: 0,
        });
        setDeliveryTime(
          new moment()
            .set({
              hour: parseInt(openHour),
              minute: 0,
            })
            .toDate(),
        );
      } else {
        setMinTime({
          hour: TodayMinDeliveryTime,
          min: NowMinute,
        });
        setDeliveryTime(
          new moment()
            .set({
              hour: parseInt(NowHour) + parseInt(DELIVERY_WAITING_TIME),
              minute: NowMinute,
            })
            .toDate(),
        );
      }

      setMaxTime({ hour: 23, min: 55 });

      // If delivery time is an hour before midnight , set delivery date to the next day
      if (NowHour === 23) {
        setDeliveryDate(new moment().add(1, 'days').toDate());
      }
    }

    if (
      !isOvernightStore &&
      NowHour > closeHour &&
      NowMinute > 0 &&
      isToday(deliveryDate)
    ) {
      let midNight = -1;
      let storeOpenHour = openHour;
      if (isShopClosedTomorrow) {
        setMinDate(new moment(selectedDate).add(2, 'days').toDate());
        setDeliveryDate(new moment().add(2, 'days').toDate());
        setMinTime(storeOpenHour);
        setMaxTime({ hour: 23, min: 55 });
        while (_midNight < storeOpenHour) {
          _midNight = _midNight + 1;
          _excludeTimes.push(setHours(setMinutes(new Date(), 0), _midNight));
        }
        setExcludeTimes(_excludeTimes);
        setDeliveryTime(
          new moment().set({ hour: storeOpenHour, minute: 0 }).toDate(),
        );
      } else {
        setMinDate(new moment(selectedDate).add(1, 'days').toDate());
        setDeliveryDate(new moment().add(1, 'days').toDate());
      }
      setMaxTime({ hour: closeHour });
      setMinTime({ hour: storeOpenHour });
      setDeliveryTime(
        new moment().set({ hour: storeOpenHour, minute: 0 }).toDate(),
      );
      while (midNight < storeOpenHour) {
        midNight = midNight + 1;
        _excludeTimes.push(setHours(setMinutes(new Date(), 0), midNight));
      }
      setExcludeTimes(_excludeTimes);
    }

    if (isShopClosedToday && isToday(deliveryDate)) {
      if (isShopClosedTomorrow) {
        setMinDate(new moment().add(2, 'days').toDate());
        setDeliveryDate(new moment().add(2, 'days').toDate());
      } else {
        setMinDate(new moment().add(1, 'days').toDate());
        setDeliveryDate(new moment().add(1, 'days').toDate());
      }

      setMinTime(tomorrowOpenHour + 1);
      setMaxTime({ hour: 23, min: 55 });
      setDeliveryTime(
        new moment().set({ hour: tomorrowOpenHour + 1, minute: 0 }).toDate(),
      );

      while (_midNight < tomorrowOpenHour) {
        _midNight = _midNight + 1;
        _excludeTimes.push(setHours(setMinutes(new Date(), 0), _midNight));
      }
      setExcludeTimes(_excludeTimes);
    }

    if (preOrder > 0) {
      let preOrderDate = new moment().add(preOrder, 'days').toDate();
      let maxPreOrder = preOrder;
      setMinDate(new moment().add(maxPreOrder, 'days').toDate());
      setDeliveryDate(new moment().add(maxPreOrder, 'days').toDate());
      if (moment(selectedDate).isAfter(preOrderDate)) {
        setMinTime(inalipaOpensAt);
      } else {
        if (NowHour > 17) {
          let timeHours = 0;
          _excludeTimes = [];
          setDeliveryTime(new moment().set({ hour: 17, minute: 0 }).toDate());
          while (timeHours < 24) {
            timeHours = timeHours + 1;
            _excludeTimes.push(setHours(setMinutes(new Date(), 0), timeHours));
          }
          setExcludeTimes(_excludeTimes);
        } else {
          if (NowHour > closeHour) {
            setDeliveryTime(
              new moment().set({ hour: NowHour, minute: NowMinute }).toDate(),
            );
            setMinTime({
              hour: parseInt(NowHour),
              min: NowMinute,
            });
          } else {
            setDeliveryTime(
              new moment().set({ hour: openHour, minute: 0 }).toDate(),
            );
            setMinTime({
              hour: parseInt(openHour),
              min: 0,
            });
          }
        }
      }
    }

    if (_isTomorrow) {
      // console.log('_isTomorrow ', tomorrowOpenHour, isOvernightStore);
      _excludeTimes = [];
      if (tomorrowOpenHour && isOvernightStore) {
        while (tomorrowCloseHour < tomorrowOpenHour) {
          tomorrowCloseHour = tomorrowCloseHour + 1;
          _excludeTimes.push(
            setHours(setMinutes(new Date(), 0), tomorrowCloseHour),
          );
        }
        setExcludeTimes(_excludeTimes);
        setMinTime(tomorrowOpenHour);
      } else {
        setMinTime(openHour + 1);
        if (isShopClosedToday) {
          while (_midNight < tomorrowOpenHour) {
            _midNight = _midNight + 1;
            _excludeTimes.push(setHours(setMinutes(new Date(), 0), _midNight));
          }
          setExcludeTimes(_excludeTimes);
        } else {
          while (inalipaclosesAt < tomorrowOpenHour) {
            inalipaclosesAt = inalipaclosesAt + 1;
            _excludeTimes.push(
              setHours(setMinutes(new Date(), 0), inalipaclosesAt),
            );
          }
          setExcludeTimes(_excludeTimes);
        }
      }
      setDeliveryTime(
        new moment().set({ hour: tomorrowOpenHour + 1, minute: 0 }).toDate(),
      );
    }

    if (isAfterTomorrow) {
      _excludeTimes = [];
      while (inalipaclosesAt < inalipaOpensAt) {
        inalipaclosesAt = inalipaclosesAt + 1;
        _excludeTimes.push(
          setHours(setMinutes(new Date(), 0), inalipaclosesAt),
        );
      }

      setExcludeTimes(_excludeTimes);
      setMinTime(inalipaOpensAt);
      setDeliveryTime(
        new moment().set({ hour: inalipaOpensAt + 1, minute: 0 }).toDate(),
      );
    }
    if (isOvernightStore) {
      setMaxTime({ hour: 23, min: 55 });
    }

    if (!isToday(deliveryDate) && !isShopClosedToday) {
      setDeliveryDate(selectedDate);
    }
  };

  const updateDeliveryTime = () => {
    let deliveryDateFormatted = moment(deliveryDate).format(
      'dddd , MMMM DD YYYY',
    );
    let deliveryTimeFormatted = moment(deliveryTime).format('LT');

    // console.log(
    //   'DIspatch addShopToDeliverAction with deliveryDate +  deliveryTime',
    //   deliveryDateFormatted,
    //   deliveryTimeFormatted,
    // );
    return dispatch(
      addShopToDeliverAction(shop, {
        store: shopId,
        deliveryDate: deliveryDateFormatted,
        deliveryTime: deliveryTimeFormatted,
      }),
    );
  };

  return (
    <div>
      <div className="dateTimeSelector">
        <div className="dateTimeSelector_option">
          <h4>Date</h4>
          <DatePicker
            selected={deliveryDate}
            onChange={(date) => handleDeliveryDateChange(date)}
            customInput={<CustomDatePicker />}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <div className="dateTimeSelector_option">
          <h4>Time</h4>

          <DatePicker
            selected={deliveryTime}
            onChange={(time) => setDeliveryTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="h:mm aa"
            minTime={setHours(
              setMinutes(new Date(), minTime.min),
              minTime.hour,
            )}
            maxTime={setHours(
              setMinutes(new Date(), maxTime.min),
              maxTime.hour,
            )}
            excludeTimes={excludeTimes}
            customInput={<CustomTimePicker />}
          />
        </div>
      </div>
    </div>
  );
}

export default DateTimePicker;
