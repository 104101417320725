import styled from 'styled-components';

export const CategoryWrapper = styled.div`
  a {
    text-decoration: none;
    color: #000000;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 25%;
    max-width: 25%;
  }

  @media (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 50px;

    .column {
      flex: 100%;
      max-width: 50%;
    }
  }
`;

export const CenteredTextImageWrapper = styled.div`
  .cat {
    position: relative;
    text-align: center;
    // color: #f2f2f2;
    // background: #f2f2f2;
    overflow: hidden;
    border-radius: 5px;
    // border-color: #000;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .cat-page {
    position: relative;
    text-align: center;
    // color: #f2f2f2;
    // background: #f2f2f2;
    overflow: hidden;
    // border-radius: 20px;
    // border-color: #000;
    // border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .cat > img {
    object-fit: cover;
    // opacity: 0.6;
    border-radius: 5px;
    height: 180px;
  }

  p {
    position: absolute;
    font-weight: bolder;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    line-height: 24px;
  }
  @media (min-width: 767px) {
    p {
      font-size: 20px;
    }
  }
`;

export const SearchResultsWrapper = styled.div`
  h3 {
    color: #423f3f;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  .product-preview-store .store-icon-container img {
    max-width: 40px;
    padding: 5px;
    border-radius: 50%;
    border: solid 1px #ececec;
    background: #fff;
  }
  .product-preview-store .store-icon-container {
    position: absolute;
    right: 30%;
    bottom: 11%;
  }
  p.preview-product-price {
    color: #00c400;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 17px;
  }

  .result-tile {
    margin: 0px 0 3em 0;
  }
`;
