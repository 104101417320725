import React from "react";
import {  useLocation } from "react-router-dom";
import BottomNav from "../../components/ui/BottomNav";
import MobileFooter from "../../components/ui/MobileFooter";
import { FooterWrapper} from "../../components/styles/Layout";
import PaymentsFooter from "../ui/PaymentsFooter";

const Footer = () => {
    const location = useLocation();

    const checkCurrentRoute = (pathname) => {
        switch (pathname) {
            case "/":
            case "/Home":
            case "/stores":
            case "/categories":
            case "/brands":
            case "/products":
                return true;
            default:
                return false;
        }
    };

  return (
    <FooterWrapper>
        {checkCurrentRoute(location.pathname) && (
            <BottomNav />
        )}
      <div className="desktop-footer">
          <PaymentsFooter />
          <MobileFooter/>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
