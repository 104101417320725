import styled from 'styled-components';

export const SliderWrapper = styled.div`
  position: relative;

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: red;
  }
  img {
    width: 100%;
    height: auto;
  }
  h2 {
    font-weight: 500;
    margin-bottom: 0px;
    padding-top: 5px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  .caption {
    position: absolute;
    bottom: 40%;
    // left: 8%;
  }
  a {
    margin-top: 5px;
    font-weight: bold;
    background: none;
    color: #00c400;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: right;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    color: #423f3f;
    margin: 0px;
    max-width: 150px;
    line-height: 12px;
  }
  .slick-dots {
    bottom: 0 !important;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    h2 {
      letter-spacing: 2px;
      font-weight: 900;
      font-size: 38px;
      line-height: 50px;

      margin-bottom: 15px;
    }
    p {
      font-size: 22px;
      margin-bottom: 10px;
      max-width: 300px;
    }
    a {
      font-size: 18px;
    }
    button {
      font-size: 16px;
    }
    .caption {
      bottom: 40%;
    }

    @media (max-width: 575px) {
      .caption {
        bottom: 40%;
        // left: 20px;
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }

  .item-image-wrapper {
    position: relative;
  }
  .item-image-wrapper img {
    width: 142px;
    max-width: 100%;
    height: auto;
    max-height: 200px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    object-position: bottom;
  }
  img.shopLogo {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: auto;
    object-fit: cover;
    // z-index: 10;
  }
  h3 {
    color: #383D42;
    font-size: 14px;
    line-height: 18px;
    // font-weight: bold;
    text-align: center;
    padding-bottom: 0px;
    padding-top: 14px;
    min-height: 50px;
  }
  h4 {
    text-align: center;
  }
  strike {
    color: #757575;
    // font-size: 12px;
    // font-weight: bold;
  }
  .product-image {
    position: relative;
  }
  img.product-logo-sticker {
    width: 18px;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
  .item-image-wrapper .pre-order {
    position: absolute;
    top: 75%;
    left: 0%;
    border: 1px #f15b2a solid;
    border-radius: 20px;
    background-color: white;
    color: #f15b2a;
    font-size: 10px;
    text-align: center;
    padding: 5px 10px;
    margin: 5px 10px;
    cursor: none;

    @media (min-width: 768px) {
      top: 75%;
      left: 0%;
      border-radius: 30px;
      padding: 6px 8px;
      font-size: 12px;
    }
  }

  .item-image-wrapper > .productImage__Skeleton {
    width: 142px;
    max-width: 100%;
    height: auto;
    max-height: 200px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    object-position: bottom;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    img.product-logo-sticker {
      width: 40px;
    }
    // .active-price {
    //     font-size: 15px !important;
    // }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    h3 {
      font-size: 15px;
      padding: 5px 5px;
      min-height: 56px;
      line-height: 24px;
    }
    .item-image-wrapper {
      max-height: 180px;
    }
    .item-image-wrapper img {
      width: 180px;
    }
    img.shopLogo {
      width: 50px;
    }
    .active-price {
      font-size: 18px !important;
    }
    .item-image-wrapper > .productImage__Skeleton {
      max-height: 180px;
    }
  }
`;

export const QuantityTogglerWrapper = styled.div`
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100px;
  border: none;
  color: #000;

  input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 50px;
  }

  .button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    padding: 0 !important;
    position: relative;
    -webkit-transition-duration: 0.12s;
    transition-duration: 0.12s;
    -webkit-transition-property: background-color, opacity, border-color, color,
      -webkit-box-shadow;
    transition-property: background-color, opacity, border-color, color,
      -webkit-box-shadow;
    transition-property: background-color, opacity, border-color, box-shadow,
      color;
    transition-property: background-color, opacity, border-color, box-shadow,
      color, -webkit-box-shadow;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .MuiButtonGroup-root {
    display: flex;
    justify-content: center;
  }
  .MuiButton-label {
    color: #00c400;
  }
  .mui-counter .MuiButton-label {
    color: #000;
  }
`;
