import '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
import {
  INITIATE_PAYMENT_SUCCESS,
  ADD_DELIVERY_INFO_SUCCESS,
  ACTION_FAIL,
  ACTION_SUCCESS,
  PAYMENT_COMPLETE,
  PAYMENT_CANCELLED,
  DISCOUNT_SUCCESS,
  DISCOUNT_FAIL,
  CLEAR_DISCOUNT,
  INITIATE_CARD_PAYMENT,
  CARD_PAYMENT_SUCCESS,
  NO_PAYMENT_RECEIVED,
  RESET_PAYMENT_OPTIONS,
  TOGGLE_ORDER_SUMMARY_ACCORDION,
  UPDATE_SHIPPING_ADDRESS_SUCCESS,
  CARD_PAYMENT_FAIL,
  INITIATE_PAYMENT_PROCESS_SUCCESS,
  REFETCH_DELIVERY_OPTIONS_SUCCESS,
  CLEAR_REFETCH__DELIVERY_OPTIONS,
  SET_TRACKING_NUMBER,
  RESET_TRACKING_NUMBER,
  PAYMENT_ACTION_FAIL,
  PAYMENT_ACTION_SUCCESS,
  PAYMENT_RESET_STATE,
} from '../constants/ActionTypes';
import {
  initializeTransaction,
  processPayment,
  checkPaymentStatus,
  checkDiscount,
  verifyCardPayment,
  updateShippingDetails,
  refetchDeliveryOptions,
} from '../../services/PaymentService';
import { handleErrorAction } from './utilsAction';
import { CHECK_INTERVAL_TIME, MAX_CHECKS } from '../../constants';
import ReactPixel from 'react-facebook-pixel';
import { store } from '../../store/index';
import {
  generateItemsListForFirebaseAnalytics,
  generateItemsListForCloudRetail,
  generateItemsObjectForRelatedToCart,
} from '../../utils/analytics';
import { sendPurchaseCompleteEvent } from '../../services/RecommendationService';
import { SEND_PURCHASE_COMPLETE_EVENT } from '../constants/RecommendationActionTypes';
const { FirebaseAnalytics, FacebookAnalytics, Modals } = Plugins;

export const initiateTransactionAction = (cartId) => {
  // console.log('initiateTransactionAction cartId = ', cartId);
  return function (dispatch) {
    return initializeTransaction(cartId).then(
      (response) => {
        if (response.status === 200) {
          // console.log('response', response);
          return dispatch({
            type: INITIATE_PAYMENT_SUCCESS,
            payload: response.data.data ? response.data.data : null,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const updateShippingDetailsAction = (data) => {
  return function (dispatch) {
    return updateShippingDetails(data).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: UPDATE_SHIPPING_ADDRESS_SUCCESS,
            payload: response.data,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const processPaymentAction = (data, cartId, total) => {
  return function (dispatch) {
    return processPayment(data).then(
      (response) => {
        // console.log(response);
        if (response.status === 200) {
          if (response.data.status === 0) {
            return dispatch({
              type: PAYMENT_ACTION_FAIL,
              payload: response.data.message,
            });
          }
          if (response.data.status === 1) {
            dispatch({
              type: SET_TRACKING_NUMBER,
              payload: response.data.data.trackingNumber,
            });
            if (data.payment.gateway === 'card') {
              dispatch({
                type: INITIATE_CARD_PAYMENT,
                payload: response.data.data.paymentUrl,
              });
            }

            handlePaymentStatusCheck(
              { transactionId: data.transactionId },
              dispatch,
              data.payment.gateway,
              cartId,
              total,
            );
            dispatch({
              type: RESET_PAYMENT_OPTIONS,
            });

            dispatch({
              type: PAYMENT_ACTION_SUCCESS,
              payload: response.data.message,
            });
          }
        }
      },
      (error) => dispatch(handlePaymentErrorAction(error)),
    );
  };
};

var checkTimer;
var counter = 0;

const handlePaymentStatusCheck = (
  data,
  dispatch,
  paymentMethod,
  cartId,
  total,
) => {
  checkTimer = setInterval(() => {
    counter = counter + 1;

    checkPaymentStatusAction(data, dispatch, paymentMethod, cartId, total);
  }, CHECK_INTERVAL_TIME * 1000);

  //setInterval(checkPaymentStatusAction(data), CHECK_INTERVAL_TIME * 1000);
};

export const checkPaymentStatusAction = async (
  data,
  dispatch,
  paymentMethod,
  cartId,
  total,
) => {
  let res = await checkPaymentStatus(data);

  let status = res.data.data.status;

  if (res.status === 200) {
    //clearInterval(checkTimer);
  }

  if (counter >= MAX_CHECKS) {
    clearInterval(checkTimer);
    counter = 0;
    return dispatch({
      type: NO_PAYMENT_RECEIVED,
      payload:
        localStorage.getItem('language') === 'sw'
          ? 'Malipo yanachukua muda mrefu. Tafadhali jaribu tena'
          : 'The request seems to be taking too long. Please try again',
    });
  }

  if (status === 'complete' || status === 'megastore-split') {
    // window.dataLayer.push({
    //   event: 'purchase_success',
    //   eventProps: {
    //     category: 'user_purchase',
    //     action: 'success',
    //     label: 'purchase_success',
    //     value: total,
    //   },
    // });

    let params = {
      value: total,
      currency: 'TZS',
    };

    if (Capacitor.isNative) {
      // FacebookAnalytics.logPurchase(params);
    } else {
      // ReactPixel.track('Purchase', params);
    }

    // FirebaseAnalytics.logEvent({
    //   name: 'purchase_success',
    //   params: {
    //     action: 'success',
    //     currency: 'TZS',
    //     value: total,
    //     platform: Capacitor.platform,
    //   },
    // });
    let storeCartItems = store.getState().cartManager.cartItems;

    // let cartItems = generateItemsListForFirebaseAnalytics(storeCartItems);

    // let formattedCartItems = generateItemsListForCloudRetail(storeCartItems);
    //
    // window.dataLayer.push({
    //   cloud_retail: {
    //     eventType: 'purchase-complete',
    //     productDetails: formattedCartItems,
    //     purchaseTransaction: {
    //       revenue: parseFloat(total).toFixed(1),
    //       currencyCode: 'TZS',
    //     },
    //   },
    // });

    let cartItems = generateItemsListForFirebaseAnalytics(
      store.getState().cartManager.cartItems,
    );

    let productsInCartDetails = generateItemsObjectForRelatedToCart(
      store.getState().cartManager.cartItems,
    );
    let purchaseCompleteEventBody = {
      userEvent: {
        eventType: 'purchase-complete',
        visitorId: localStorage.getItem('appNumber'),
        productDetails: productsInCartDetails,
        purchaseTransaction: {
          currencyCode: 'TZS',
          revenue: parseFloat(total).toFixed(1),
        },
      },
    };

    // console.log('purchaseCompleteEventBody:', purchaseCompleteEventBody);

    await sendPurchaseCompleteEvent(purchaseCompleteEventBody).then(
      (response) => {
        if (response.status === 1) {
          // console.log('sendUserEventAction', response);
          return dispatch({
            type: SEND_PURCHASE_COMPLETE_EVENT,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );

    FirebaseAnalytics.logEvent({
      name: 'purchase',
      params: {
        currency: 'TZS',
        value: total,
        items: cartItems,
      },
    });

    clearInterval(checkTimer);
    return dispatch({
      type: PAYMENT_COMPLETE,
      payload:
        paymentMethod === 'cash'
          ? localStorage.getItem('language') === 'sw'
            ? 'Oda imekamilika'
            : 'Order Placed'
          : localStorage.getItem('language') === 'sw'
          ? 'Malipo yamekamilika'
          : 'Payment Completed',
    });
  } else if (status === 'cancelled') {
    clearInterval(checkTimer);
    return dispatch({
      type: PAYMENT_CANCELLED,
      payload:
        localStorage.getItem('language') === 'sw'
          ? 'Malipo yamesitishwa'
          : 'Payment Cancelled',
    });
  }
};

export const addDeliveryInfoAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: ADD_DELIVERY_INFO_SUCCESS,
      payload: data,
    });
  };
};

export const checkDiscountAction = (data) => {
  return function (dispatch) {
    return checkDiscount(data).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.status === 1) {
            return dispatch({
              type: DISCOUNT_SUCCESS,
              payload: response.data.data,
            });
          }
          if (response.data.status === 0) {
            // console.log('DISCOUNT_FAIL', response.data.message);
            return dispatch({
              type: DISCOUNT_FAIL,
              payload: response.data.message,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const clearDiscountsAction = () => {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_DISCOUNT,
    });
  };
};

export const resetPaymentOptionsAction = () => {
  return function (dispatch) {
    return dispatch({
      type: RESET_PAYMENT_OPTIONS,
    });
  };
};

export const verifyCardPaymentAction = (data, transactionId) => {
  return function (dispatch) {
    return verifyCardPayment(data, transactionId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.status === 1) {
            return dispatch({
              type: CARD_PAYMENT_SUCCESS,
              payload: response.data.data,
            });
          }
          if (response.data.status === 0) {
            // console.log('DISCOUNT_FAIL', response.data.message);
            return dispatch({
              type: CARD_PAYMENT_FAIL,
              payload: response.data.message,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const toggleOrderSummaryAccordion = (value) => {
  return function (dispatch) {
    return dispatch({
      type: TOGGLE_ORDER_SUMMARY_ACCORDION,
      payload: value,
    });
  };
};

export const initiateTransactionProcessAction = (value) => {
  return function (dispatch) {
    return dispatch({
      type: INITIATE_PAYMENT_PROCESS_SUCCESS,
      payload: value,
    });
  };
};

export const refetchDeliveryOptionsActions = (data) => {
  // console.log('refetchDeliveryOptionsData', data);
  return function (dispatch) {
    return refetchDeliveryOptions(data).then(
      (response) => {
        if (response.status === 200) {
          // console.log('refetchDeliveryOptions', response);
          return dispatch({
            type: REFETCH_DELIVERY_OPTIONS_SUCCESS,
            payload: response.data.data ? response.data.data : null,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const clearRefetchDeliveryOptionsAction = () => {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_REFETCH__DELIVERY_OPTIONS,
    });
  };
};

export const resetTrackingNumberAction = () => {
  return function (dispatch) {
    return dispatch({
      type: RESET_TRACKING_NUMBER,
    });
  };
};

export const handlePaymentErrorAction = (error) => {
  console.log(error);
  return {
    type: PAYMENT_ACTION_FAIL,
    payload:
      error && error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        ? error.message
        : "Something wen't wrong , please try again",
  };
};

export const resetPaymentStateAction = () => {
  return function (dispatch) {
    dispatch({ type: PAYMENT_RESET_STATE });
  };
};
