import '@capacitor-community/firebase-analytics';
import { Capacitor, Plugins } from '@capacitor/core';
import {
  SELECT_VARIANT,
  ADD_TO_CART,
  CART_CREATED,
  ADD_SHOP_DELIVERY_ITEM,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_TOTAL,
  ADD_DELIVERY_DETAILS,
  REMOVE_ITEM,
  CHANGE_MODAL_STATUS,
  UPDATE_LOCATION,
  RESTORE_DEFAULT_CONFIG,
  SELECT_SKU,
  CLEAR_CART,
  CLEAR_ALERTS,
  CHANGE_LOCATION_PERMISSION_STATUS,
  LOGOUT,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  CART_WEB_SHARE_SUCCESS,
  UPDATE_CART,
  SAVE_ORDER,
  GET_PAST_ORDERS,
  RE_ORDER,
  CART_ITEMS_RETRIEVED,
  RESET_CART_RETRIEVAL_STATUS,
  RESET_CART_SHARING,
  SELECT_OPTION,
  RESET_VARIANTS_OPTION,
  SELECT_PRODUCT,
  GO_TO_CHECKOUT,
  ACTION_SUCCESS,
  ACTION_FAIL,
  RESET_PAST_ORDERS,
  SET_DELIVERY_DATETIME_SUCCESS,
  RESET_DELIVERY_DATETIME,
  RESET_DELIVERY_DATETIME_BY_SHOP,
} from '../constants/ActionTypes';
import { handleErrorAction } from './utilsAction';
import { APP_URL } from '../../constants';
import { updateCart, createCart } from '../../services/catalogService';
import {
  getCartItems,
  getPastCartItems,
  getPastOrders,
} from '../../services/cartService';
import placeholder from '../../assets/images/placeholders/item.png';
import _ from 'lodash';
import { initiateTransactionAction } from './PaymentAction';
import ReactPixel from 'react-facebook-pixel';
const { Device, FirebaseAnalytics, FacebookAnalytics, Share } = Plugins;

export const addToCartAction = (data) => (dispatch) => {
  // const analytics = getAnalytics();
  window.dataLayer.push({
    cloud_retail: {
      eventType: 'add-to-cart',
      productDetails: [
        {
          product: {
            id: data?.id,
          },
          quantity: data?.quantity,
        },
      ],
    },
  });

  FirebaseAnalytics.logEvent({
    name: 'add_to_cart',
    params: {
      currency: 'TZS',
      value: data.price,
      items: [
        {
          item_id: data?.id,
          item_name: data?.name,
          affiliation: data?.shopName,
          item_brand: data?.brand,
          item_category: data?.category,
          quantity: data?.quantity,
        },
      ],
      // platform: Capacitor.platform,
    },
  });

  let params = {
    content_type: 'cart_product',
    name: data.name,
    value: data.price,
    currency: 'TZS',
  };

  if (Capacitor.isNative) {
    // FacebookAnalytics.logAddToCart(params);
  } else {
    // ReactPixel.track('AddToCart', params);
    // logEvent(analytics, 'view_content', params);
  }

  return dispatch({ type: ADD_TO_CART, payload: data });
};

export const saveCompletedOrderAction = (cartId) => (dispatch) => {
  let createCartPayload = {
    items: [],
    status: 'created',
  };
  dispatch(createCartAction(createCartPayload));
  return dispatch({ type: SAVE_ORDER, payload: cartId });
};

export const reOrderAction = (data) => (dispatch) => {
  return dispatch({ type: RE_ORDER, payload: data });
};

export const shareCartAction = (cartId) => (dispatch) => {
  const shareUrl = `${APP_URL}/share-cart/${cartId}`;
  const title = 'Inalipa cart share';
  const text = 'Please see the cart on inalipa by visiting';
  const shareData = {
    title: title,
    text: text,
    url: shareUrl,
  };
  if (Capacitor.isNative) {
    return Share.share({
      title: title,
      text: text,
      url: shareUrl,
      dialogTitle: `Share cart: ${title}`,
    });
  } else {
    if (navigator.share === undefined) {
      let createCartPayload = {
        items: [],
        status: 'created',
      };
      const message = `${shareUrl}`;
      setTimeout(() => createCartAction(createCartPayload), 10000);
      return dispatch({
        type: CART_WEB_SHARE_SUCCESS,
        payload: message,
      });
    } else {
      // Share content
      try {
        navigator.share(shareData);
      } catch (error) {}
    }
  }
};

export const createCartAction = (data) => {
  let combinedObj = _.clone(data);
  let platform = {};
  if (Capacitor.isNative) {
    let fcmToken = localStorage.getItem('fcmToken');
    platform['name'] = Capacitor.platform;
    platform['device_token'] = fcmToken;
  } else {
    platform['name'] = 'web';
  }
  combinedObj['platform'] = platform;
  // console.log('combinedObj', combinedObj);
  return function (dispatch) {
    return createCart(combinedObj).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.status === 1) {
            let cartId = response.data.data._id;
            return dispatch({
              type: CART_CREATED,
              payload: cartId,
              items: response.data.data.items,
            });
          }

          if (response.data.status === 0) {
            return dispatch({
              type: ACTION_FAIL,
              payload: response.data.message,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const createCartAndTransactionAction = (cartItens) => {
  let createCartPayload = {
    items: cartItens,
    status: 'created',
  };

  return function (dispatch) {
    return createCart(createCartPayload).then(
      (response) => {
        if (response.status === 200) {
          let cartId = response.data.data._id;
          dispatch({
            type: CART_CREATED,
            payload: cartId,
            items: response.data.data.items,
          });
          dispatch(initiateTransactionAction(cartId));
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getCartItemsAction = (cartId) => {
  return function (dispatch) {
    return getCartItems(cartId).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.status === 1) {
            let isSharedItemsIncomplete = false;
            let products = response.data.data;

            let cartItems = products.map((product) => {
              let selectedProductPrice =
                product.options &&
                product.options.variant &&
                product.options.variant.price
                  ? product.options.variant.price
                  : product.unitPrice;

              let selectedVariant = null;
              let oldPrice = null;
              let canTrackInventory = false;
              let isOutOfStock = product.product.outOfStock;

              if (
                !product.variations &&
                product.pricePromotion &&
                product.pricePromotion.prices &&
                product.pricePromotion.prices.retail &&
                product.pricePromotion.prices.retail.old
              ) {
                oldPrice = product.pricePromotion.prices.retail.old;
              }
              if (
                product.product.variations &&
                product.product.variations.length === 0 &&
                product.product.pricePromotion &&
                product.product.pricePromotion.prices &&
                product.product.pricePromotion.prices.retail &&
                product.product.pricePromotion.prices.retail.old
              ) {
                oldPrice = product.product.pricePromotion.prices.retail.old;
              }
              if (
                product.product.variations &&
                product.product.variations.variants &&
                product.product.variations.variants.length === 0 &&
                product.product.pricePromotion &&
                product.product.pricePromotion.prices &&
                product.product.pricePromotion.prices.retail &&
                product.product.pricePromotion.prices.retail.old
              ) {
                oldPrice = product.product.pricePromotion.prices.retail.old;
              }
              if (
                product.product.variations &&
                product.product.variations.variants &&
                product.product.variations.variants.length > 0
              ) {
                let variantPrices = product.product.variations.variants.map(
                  (variant) => {
                    return { name: variant.name, price: variant.price };
                  },
                );
                let minProduct = _.minBy(variantPrices, 'price');
                let variants = product.product.variations.variants;
                selectedVariant = _.find(variants, ['name', minProduct.name]);

                if (
                  product.product.pricePromotion &&
                  product.product.pricePromotion.variants &&
                  product.product.pricePromotion.variants.length > 0
                ) {
                  let promotionPrices = product.product.pricePromotion.variants.map(
                    (variant) => {
                      return {
                        name: variant.name,
                        old: variant.retail.old,
                        new: variant.retail.new,
                      };
                    },
                  );
                  let minPromotionProduct = _.minBy(promotionPrices, 'new');
                  if (minPromotionProduct.new === minProduct.price) {
                    oldPrice = minPromotionProduct.old;
                  }
                }

                //dispatch(selectVariantAction(selectedVariant));
              }
              if (
                product.product.options &&
                product.product.options.inventory &&
                product.product.options.inventory.policy &&
                product.product.options.inventory.policy === 'inalipa'
              ) {
                canTrackInventory = true;
                isOutOfStock = !(
                  product.product.options &&
                  product.product.options.inventory &&
                  product.product.options.inventory.quantity &&
                  product.product.options.inventory.quantity > 0
                );
              }
              const item = {
                name: product.product.name,
                quantity: product.quantity,
                qty: product.quantity,
                id: product.product._id,
                price: product.unitPrice,
                total: product.total,
                image:
                  product.options &&
                  product.options.variant &&
                  product.options.variant.picture
                    ? `products/${product.options.variant.picture}`
                    : product.product.images
                    ? `products/${product.product.images.main}`
                    : placeholder,
                shop: product.product.shop && product.product.shop.subDomain,
                shopId: product.product.shop && product.product.shop._id,
                shopName: product.product.shop && product.product.shop.name,
                shopImage:
                  product.product.shop &&
                  product.product.shop.settings &&
                  product.product.shop.settings.logo &&
                  product.product.shop.settings.logo,
                preOrder:
                  product.product.options &&
                  product.product.options.preOrder &&
                  product.product.options.preOrder &&
                  parseInt(product.product.options.preOrder) > 0
                    ? parseInt(product.product.options.preOrder)
                    : 0,
                shopOpeningHours:
                  product?.product?.shop?.settings?.opening_hours,
                vat:
                  product.product.options && product.product.options.vatExempted
                    ? 0
                    : parseFloat(
                        selectedProductPrice - selectedProductPrice / 1.18,
                      ),
                options: {
                  variant:
                    product.options &&
                    product.options.variant &&
                    product.options.variant.name
                      ? product.options.variant.name
                      : '',
                },
                stock:
                  product.options &&
                  _.isObject(product.options.variant) &&
                  product.options.variant.inventory
                    ? product.options.variant.inventory
                    : product.product.options &&
                      product.product.options.inventory &&
                      product.product.options.inventory.quantity >= 0
                    ? product.product.options.inventory.quantity
                    : null,
                sku_id:
                  product.options &&
                  product.options.variant &&
                  product.options.variant._id
                    ? product.options.variant._id
                    : product.product._id,
                track: canTrackInventory,
                isOutOfStock: isOutOfStock,
                isSharedCartIncomplete: false,
              };
              return item;
            });

            let newItems = cartItems.map((cartItem) => {
              if (cartItem.quantity > cartItem.stock && cartItem.track) {
                isSharedItemsIncomplete = true;
                cartItem['isSharedCartIncomplete'] = true;
                cartItem['oldQuantity'] = cartItem.quantity;
                cartItem['quantity'] = cartItem.stock;
                cartItem['qty'] = cartItem.stock;
                cartItem['vat'] =
                  parseFloat(cartItem.price - cartItem.price / 1.18).toFixed(
                    2,
                  ) * cartItem.stock;
                cartItem['total'] = cartItem.price * cartItem.stock;
              }
              return cartItem;
            });

            let incompleteItemsArr = _.filter(newItems, {
              isSharedCartIncomplete: true,
            });

            let incompleteItems = _.filter(newItems, {
              isSharedCartIncomplete: true,
            });
            incompleteItems = incompleteItemsArr.map(
              (item) =>
                ` ${item.quantity}  ${item.name} remain in stock from ${item.oldQuantity} shared`,
            );

            let incompleteItemsCheckoutPage = incompleteItemsArr.map(
              (item) => `${item.quantity}  ${item.name}  `,
            );

            let message = `The shared cart has been modified because only ${incompleteItems.join(
              ',',
            )}`;

            let checkoutMessage = `Sorry your cart has been modified to ${incompleteItemsCheckoutPage.join(
              ',',
            )} because someone just completed a purchase`;

            cartItems = _.filter(cartItems, function (item) {
              return item.quantity > 0;
            });

            // let modifiedCartItems = _.filter(cartItems, function (o) {
            //   return o.qty === 0;
            // });
            return dispatch({
              type: CART_ITEMS_RETRIEVED,
              payload: cartItems,
              isSharedItemsIncomplete,
              message,
              checkoutMessage,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getPastCartItemsAction = (body) => {
  return function (dispatch) {
    return getPastOrders(body).then(
      (response) => {
        if (response.status === 200) {
          let orders = response.data.data;
          if (orders) {
            let cartOrderItems = orders.map((order) => {
              let cartItems = order.items.map((product) => {
                let selectedProductPrice =
                  product.options &&
                  product.options.variant &&
                  product.options.variant.price
                    ? product.options.variant.price
                    : product.unitPrice;
                let canTrackInventory = false;
                let isOutOfStock = product.product.outOfStock;

                if (
                  product.product.options &&
                  product.product.options.inventory &&
                  product.product.options.inventory.policy &&
                  product.product.options.inventory.policy === 'inalipa'
                ) {
                  canTrackInventory = true;
                  isOutOfStock = !(
                    product.product.options &&
                    product.product.options.inventory &&
                    product.product.options.inventory.quantity &&
                    product.product.options.inventory.quantity > 0
                  );
                }

                const item = {
                  name: product.product.name,
                  quantity: product.quantity,
                  qty: product.quantity,
                  id: product.product._id,
                  price: product.unitPrice,
                  total: product.total,
                  image:
                    product.options &&
                    product.options.variant &&
                    product.options.variant.picture
                      ? `products/${product.options.variant.picture}`
                      : product.product.images
                      ? `products/${product.product.images.main}`
                      : placeholder,
                  shop: product.product.shop && product.product.shop.subDomain,
                  shopId: product.product.shop && product.product.shop._id,
                  shopName: product.product.shop && product.product.shop.name,
                  shopImage:
                    product.product.shop &&
                    product.product.shop.settings &&
                    product.product.shop.settings.logo &&
                    product.product.shop.settings.logo,
                  preOrder:
                    product.product.options &&
                    product.product.options.preOrder &&
                    product.product.options.preOrder &&
                    parseInt(product.product.options.preOrder) > 0
                      ? parseInt(product.product.options.preOrder)
                      : 0,
                  shopOpeningHours:
                    product.product.shop &&
                    product?.product?.shop?.settings?.opening_hours,
                  vat:
                    product.product.options &&
                    product.product.options.vatExempted
                      ? 0
                      : parseFloat(
                          selectedProductPrice - selectedProductPrice / 1.18,
                        ),
                  options: {
                    variant:
                      product.options &&
                      product.options.variant &&
                      product.options.variant.name
                        ? product.options.variant.name
                        : '',
                  },
                  stock:
                    product.options && _.isObject(product.options.variant)
                      ? product.options.variant.inventory
                      : product.product.options &&
                        product.product.options.inventory &&
                        product.product.options.inventory.quantity
                      ? product.product.options.inventory.quantity
                      : null,
                  sku_id:
                    product.options &&
                    product.options.variant &&
                    product.options.variant._id
                      ? product.options.variant._id
                      : product.product._id,
                  track: canTrackInventory,
                  isOutOfStock: isOutOfStock,
                };
                return item;
              });

              return { cartId: order.id, cartItems, canReorder: true };
            });

            cartOrderItems = cartOrderItems.map((cartOrder) => {
              cartOrder.cartItems.map((cartItem) => {
                if (cartItem.quantity > cartItem.stock && cartItem.track) {
                  cartOrder['canReorder'] = false;
                  cartOrder['outOfStock'] = cartItem;
                }
                if (!cartItem.track && cartItem.isOutOfStock) {
                  cartOrder['canReorder'] = false;
                  cartOrder['outOfStock'] = cartItem;
                }
              });
              return cartOrder;
            });

            return dispatch({ type: GET_PAST_ORDERS, payload: cartOrderItems });

            // let cartItems = products.map((product) => {
            //   const item = {
            //     name: product.product.name,
            //     quantity: product.quantity,
            //     qty: product.quantity,
            //     id: product.product._id,
            //     price: product.unitPrice,
            //     total: product.total,
            //     image: product.product.images
            //       ? `products/${product.product.images.main}`
            //       : placeholder,
            //     shop: product.product.shop && product.product.shop.subDomain,
            //     shopId: product.product.shop && product.product.shop._id,
            //     preOrder:
            //       product.product.options &&
            //       product.product.options.preOrder &&
            //       product.product.options.preOrder !== ''
            //         ? parseInt(product.product.options.preOrder)
            //         : 0,
            //     shopOpeningHours:
            //       product.product.shop &&
            //       product.product.shop.settings.opening_hours,
            //     vat:
            //       product.product.options && product.product.options.vatExempted
            //         ? 0
            //         : parseFloat(
            //             product.unitPrice - product.unitPrice / 1.18,
            //           ).toFixed(2) * product.quantity,
            //   };
            //   return item;
            // });
            //
            // return dispatch({
            //   type: CART_ITEMS_RETRIEVED,
            //   payload: cartItems,
            // });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const resetPastOrdersAction = () => (dispatch) => {
  return dispatch({ type: RESET_PAST_ORDERS });
};

export const updateCartAction = (data, cartId) => {
  return function (dispatch) {
    return updateCart(data, cartId).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: UPDATE_CART,
            payload: response?.data?.data?.items,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const changeQuantityAction = (product, quantity) => (dispatch) => {
  return dispatch({ type: CHANGE_PRODUCT_QUANTITY, product, quantity });
};

export const goToCheckoutAction = () => (dispatch) => {
  return dispatch({ type: GO_TO_CHECKOUT });
};

export const incrementQuantityAction = (data) => (dispatch) => {
  return dispatch({ type: INCREMENT_QUANTITY, payload: data });
};

export const resetCartRetrievalStatusAction = () => (dispatch) => {
  return dispatch({ type: RESET_CART_RETRIEVAL_STATUS });
};

export const addShopToDeliverAction = (shop, deliveryInfo) => (dispatch) => {
  return dispatch({
    type: ADD_SHOP_DELIVERY_ITEM,
    payload: { shop, deliveryInfo },
  });
};

export const decrementQuantityAction = (data) => (dispatch) => {
  return dispatch({ type: DECREMENT_QUANTITY, payload: data });
};

export const changeTotalAction = (total) => (dispatch) => {
  return dispatch({ type: CHANGE_TOTAL, payload: total });
};

export const addDeliveryDetailsAction = (data) => (dispatch) => {
  return dispatch({ type: ADD_DELIVERY_DETAILS, payload: data });
};

export const removeItemAction = (data) => (dispatch) => {
  // window.dataLayer.push({
  //   event: 'remove_from_cart',
  //   eventProps: {
  //     category: 'remove_from_cart',
  //     action: 'success',
  //     label: 'remove_from_cart',
  //     value: data.price * data.quantity,
  //   },
  // });

  if (Capacitor.isNative) {
    // FacebookAnalytics.logEvent({
    //   event: 'remove_from_cart',
    //   params: {
    //     category: 'remove_from_cart',
    //     action: 'success',
    //     label: 'remove_from_cart',
    //     value: data.price * data.quantity,
    //     currency: 'TZS',
    //   },
    // });
  } else {
    let params = {
      content_type: 'cart_product',
      name: data.name,
      value: data.price * data.quantity,
      currency: 'TZS',
    };
    // ReactPixel.trackCustom('Remove From Cart', params);
  }

  FirebaseAnalytics.logEvent({
    name: 'remove_from_cart',
    params: {
      currency: 'TZS',
      value: data.price * data.quantity,
      items: [
        {
          item_id: data?.id,
          item_name: data?.name,
          affiliation: data?.shopName,
          item_brand: data?.brand,
          item_category: data?.category,
          quantity: data?.quantity,
        },
      ],
      // platform: Capacitor.platform,
    },
  });

  return dispatch({ type: REMOVE_ITEM, payload: data });
};

export const toggleModalAction = (data) => (dispatch) => {
  return dispatch({ type: CHANGE_MODAL_STATUS, payload: data });
};

export const updateLocationAction = (data) => (dispatch) => {
  return dispatch({ type: UPDATE_LOCATION, payload: data });
};

export const restoreDefaultMapConfigAction = () => (dispatch) => {
  return dispatch({ type: RESTORE_DEFAULT_CONFIG });
};

export const selectSkuAction = (sku) => (dispatch) => {
  return dispatch({ type: SELECT_SKU, payload: sku });
};

export const clearCartAction = () => (dispatch) => {
  return dispatch({ type: CLEAR_CART });
};

export const clearAlertsAction = () => (dispatch) => {
  return dispatch({ type: CLEAR_ALERTS });
};

export const changeLocationPermissionStatusAction = (data) => (dispatch) => {
  return dispatch({ type: CHANGE_LOCATION_PERMISSION_STATUS, payload: data });
};

export const selectVariantAction = (data) => (dispatch) => {
  return dispatch({ type: SELECT_VARIANT, payload: data });
};
export const selectProductAction = (data) => (dispatch) => {
  return dispatch({ type: SELECT_PRODUCT, payload: data });
};
export const selectOptionAction = (data) => (dispatch) => {
  return dispatch({ type: SELECT_OPTION, payload: data });
};

export const resetOptionAction = () => (dispatch) => {
  return dispatch({ type: RESET_VARIANTS_OPTION });
};

export const logoutAction = () => (dispatch) => {
  localStorage.clear();
  localStorage.clear();
  dispatch({ type: LOGOUT });
};

export const resetCartSharingAction = (data) => (dispatch) => {
  return dispatch({ type: RESET_CART_SHARING });
};

export const setDeliveryDatetimeAction = (data) => (dispatch) => {
  return dispatch({ type: SET_DELIVERY_DATETIME_SUCCESS, payload: data });
};

export const resetDeliveryDatetimeAction = () => (dispatch) => {
  return dispatch({ type: RESET_DELIVERY_DATETIME });
};

export const resetDeliveryDatetimeByShopAction = (shop, deliveryInfo) => (
  dispatch,
) => {
  return dispatch({
    type: RESET_DELIVERY_DATETIME_BY_SHOP,
    payload: { shop, deliveryInfo },
  });
};
