import styled from 'styled-components';

export const BannerWrapper = styled.div`
  min-height: 126px;
  ul.slick-dots {
    bottom: 40px !important;
  }
  // .banner__Skeleton {
  //   height: 250px;
  // }

  // for mobile
  @media (max-width: 575.98px) {
    .banner__Skeleton {
      height: 330px !important;
    }
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    // bottom: 115px !important;
    min-height: 347px;

    .banner__Skeleton {
      height: 460px;
    }
  }
  .slick-slider {
    line-height: 0;
  }
`;
