import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import ProductsComponent from '../../components/products/Products';
import {
  getBrandAction,
  getProductsAction,
  loadProductAction,
  setFilterAction,
  getBrandFilterOptionsAction,
} from '../../store/actions/catalogAction';
import { scrollToTop } from '../../utils/window';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumb from '../../components/ui/Breadcrumbs';
import {
  resetSelectedPageAction,
  selectPageAction,
  setPreviousPathnameAction,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { Plugins } from '@capacitor/core';

const { FirebaseAnalytics } = Plugins;

const Brand = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let { id } = useParams();
  const isLoading = useSelector((state) => state.catalogManager.isLoading);
  const brand = useSelector((state) => state.catalogManager.brand);
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const products = useSelector((state) => state.catalogManager.products);

  const filterOptions = useSelector(
    (state) => state.catalogManager.filterOptions,
  );
  const sortBy = useSelector((state) => state.catalogManager.sortBy);
  useEffect(() => {
    // dispatch(loadProductAction());
    dispatch(getBrandAction(id));
    dispatch(selectPageAction({ name: 'Brand', filter: [id] }));
    return () => dispatch(resetSelectedPageAction());
    // dispatch(setPreviousPathnameAction(location.pathname));
  }, [dispatch, id]);

  useEffect(() => {
    let selectedBrand = {
      key: 'Brand',
      item: { value: brand._id, label: brand.name },
    };
    dispatch(setFilterAction(selectedBrand));
    // dispatch(getProductsAction({ Brand: [id], sortBy: sortBy }));
    if (scrollToLastPosition === false || products.length < 1) {
      dispatch(getProductsAction({ Brand: [id], sortBy: sortBy }));
      dispatch(loadProductAction());
    }
    dispatch(getBrandFilterOptionsAction({ Brand: id }));

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'brand',
        item_name: brand.name,
        item_id: brand._id,
      },
    });
  }, [brand._id, brand.name, dispatch, id]);

  useEffect(() => {
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    let previousRoutes = {
      parent: { name: 'All Brands', link: '/brands' },
      coParent: { name: brand.name, link: location.pathname },
    };
    dispatch(setPreviousPathnameAction(previousRoutes));
  }, [brand, dispatch, location.pathname]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <>
      <PageTitle
        title={brand.name ? 'Inalipa - ' + brand.name : 'Inalipa - Brand'}
      />
      <SecondaryNav className="d-none d-sm-block" />

      <Breadcrumb
        parent={{ link: '/brands', name: 'All Brands' }}
        child={{ name: brand.name }}
      />
      <div className="container pt-4">
        {/*<h2 className="mt-2 mb-2 ml-2">{brand.name}</h2>*/}
        <ProductsComponent />
      </div>
    </>
  );
};

export default Brand;
