import axios from 'axios';
import { MERCANT_API, API_KEY } from '../constants';

const contentTypeApplicationJson = {
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Inalipa-Shop-API-KEY': API_KEY,
  },
};

export const initializeTransaction = async (cartId) => {
  const result = await axios
    .get(`${MERCANT_API}/payments/initialize-cart/${cartId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const processPayment = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/payments/start/${data.transactionId}`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const checkPaymentStatus = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/payments/status/${data.transactionId}`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const checkDiscount = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/payments/check-discount-code/${data.transactionId}`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const verifyCardPayment = async (data, transactionId) => {
  const result = await axios
    .post(
      `${MERCANT_API}/payments/card/verify/${transactionId}`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const updateShippingDetails = async (data) => {
  const result = await axios
    .put(
      `${MERCANT_API}/payments/shipping-address/${data.transactionId}`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const refetchDeliveryOptions = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/payments/delivery-options/${data.transactionId}`,
      data.location ? JSON.stringify(data.location) : null,
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};
