import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavBarWrapper } from '../../../../components/styles/Navigation';
import SearchBoxSkeleton from '../search/SearchBoxSkeleton';
import Logo from '../../../../assets/images/logo.png';

function NavBarSkeleton() {
  return (
    <NavBarWrapper>
      <div className="border-bottom">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light  rounded  main-nav mt-1">
            <div className="brand-container">
              <div className="navbar-brand text-info font-weight-bolder" to="/">
                <img src={Logo} alt="Logo" className="img-fluid" />
              </div>
            </div>
            <div className="header_search">
              <SearchBoxSkeleton />
            </div>
            <div className="inalipa-nav-right">
              <div className="d-none d-sm-block inalipa-nav-link ">
                <div className="row">
                  <Skeleton width={125} height={18} />
                </div>
              </div>
              <div className="mr-1">
                <Skeleton width={24} height={24} />
              </div>
              <div>
                <SkeletonTheme color="#9dc49d">
                  <Skeleton circle={true} width={20} height={20} />
                </SkeletonTheme>
              </div>
              <div className="nav-toggler d-sm-block d-md-none">
                <Skeleton width={22} height={18} />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </NavBarWrapper>
  );
}

export default NavBarSkeleton;
