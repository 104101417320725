import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import Logo from '../../assets/images/logo.png';
import Autocomplete from '../ui/Autocomplete';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Basket from '../ui/Basket';
import { NavBarWrapper } from '../styles/Navigation';
import {
  scrollToLastPositionAction,
  toggleMobileMenuAction,
} from '../../store/actions/uiAction';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ArrowBack from '@material-ui/icons/ArrowBack';
import FlyoutMobileMenu from '../../components/menu/FlyoutMobileMenu';
import { useTranslation } from 'react-i18next';
import AppInstallBanner from '../ui/AppInstallBanner';
import NotificationsIcon from '@material-ui/icons/NotificationsNone';
import { whichBrowser } from '../../utils/browser';
import {
  loadProductAction,
  resetFiltersAction,
  resetFiltersOptionsAction,
  resetProductsAction,
  resetSelectedFiltersAction,
  setSortByAction,
} from '../../store/actions/catalogAction';

const TopNav = () => {
  const menuIsOpen = useSelector((state) => state.uiManager.isMobileMenuOpen);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('menu');

  const toggleFilterMenu = () => {
    dispatch(toggleMobileMenuAction(!menuIsOpen));
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [menuIsOpen]);

  useEffect(() => {
    console.log(location.pathname);
    const currentPath = location.pathname;
    const paths = [
      'product',
      'products',
      'category',
      'brand',
      'stores',
      'search',
      'shop',
      'megastore',
      'curated-page',
      'recommended-page',
      'daily-deals',
    ];

    if (!paths.some((v) => currentPath.includes(v))) {
      console.log('i DONT HAVE got a match');
      dispatch(resetFiltersAction());
      dispatch(resetSelectedFiltersAction());
      dispatch(scrollToLastPositionAction(false));
      dispatch(resetProductsAction());
      dispatch(resetFiltersOptionsAction());
      dispatch(setSortByAction('featured'));
      dispatch(loadProductAction());
      console.log('we reset the filters');
    }

    // if (
    //   !location.pathname.includes(
    //     'product' ||
    //       'products' ||
    //       'category' ||
    //       'brand' ||
    //       'stores' ||
    //       'search' ||
    //       'shop' ||
    //       'megastore' ||
    //       'curated-page' ||
    //       'recommended-page' ||
    //       'daily-deals',
    //   )
    // ) {
    //   dispatch(resetFiltersAction());
    //   dispatch(resetSelectedFiltersAction());
    //   dispatch(scrollToLastPositionAction(false));
    //   dispatch(resetProductsAction());
    //   dispatch(resetFiltersOptionsAction());
    //   dispatch(setSortByAction('featured'));
    //   dispatch(loadProductAction());
    //   console.log('we reset the filters');
    // } else {
    //   console.log('we DO NOT reset the filters, filters options and products');
    // }
  }, [location.pathname]);

  const checkCurrentRoute = (pathname) => {
    switch (pathname) {
      case '/':
      case '/Home':
      case '/stores':
      case '/categories':
      case '/brands':
      case '/products':
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <NavBarWrapper>
        <div
          className={window.innerWidth < 576 && `fixed-header`}
          id="fixed-header"
        >
          {!Capacitor.isNative &&
            whichBrowser() !== 'Safari' &&
            location.pathname === '/' && <AppInstallBanner />}
          <div className="border-bottom">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light  rounded  main-nav mt-1">
                <div className="brand-container">
                  {checkCurrentRoute(location.pathname) && (
                    <div
                      className="back-button"
                      onClick={history.goBack}
                      style={{ cursor: 'pointer' }}
                    >
                      <ArrowBack />
                    </div>
                  )}
                  {!checkCurrentRoute(location.pathname) && (
                    <div
                      className="nav-toggler  d-sm-block d-md-none"
                      onClick={toggleFilterMenu}
                    >
                      <DehazeIcon />
                    </div>
                  )}
                  <Link
                    className="navbar-brand text-info font-weight-bolder"
                    to="/"
                  >
                    <img src={Logo} alt="Logo" className="img-fluid" />
                  </Link>
                </div>
                {/* <SearchBox /> */}
                <div className="header_search">
                  <Autocomplete />
                </div>
                <div className="inalipa-nav-right">
                  <Link
                    to="/need-support"
                    className="d-none d-sm-block inalipa-nav-link "
                  >
                    {t('contact_inalipa')}
                  </Link>
                  {Capacitor.isNative && (
                    <Link to="/notifications">
                      <NotificationsIcon
                        style={{
                          color: '#000000',
                          margin: '2px 14px 0',
                        }}
                      />
                    </Link>
                  )}
                  <Basket />
                  {/* <div
                    className="nav-toggler  d-sm-block d-md-none"
                    onClick={toggleFilterMenu}
                  >
                    <DehazeIcon opacity={0.5} />
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
        <FlyoutMobileMenu />
      </NavBarWrapper>
    </>
  );
};

export default TopNav;
