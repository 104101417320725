import {
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_REVIEWS_COUNT,
  GET_REVIEWS,
  GET_REVIEWS_COUNT,
  LOAD_MORE_PRODUCT_REVIEWS,
  LOAD_MORE_REVIEWS,
  LOADING_REVIEWS,
  LOADING_REVIEWS_COUNT,
  RESET_REVIEWS_LOADING,
  STOP_LOADING_MORE_REVIEWS,
} from '../constants/ReviewsActionTypes';

const initialState = {
  reviews: [],
  productReviews: [],
  reviewsCount: [],
  productReviewsCount: {},
  isLoading: false,
  loadedReviewsCount: true,
  page: 1,
  hasMore: false,
};

const reviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REVIEWS:
      return { ...state, reviews: payload, isLoading: false, hasMore: true };
    case GET_PRODUCT_REVIEWS:
      return { ...state, productReviews: payload, hasMore: true };
    case GET_REVIEWS_COUNT:
      return { ...state, reviewsCount: payload };
    case GET_PRODUCT_REVIEWS_COUNT:
      return {
        ...state,
        productReviewsCount: payload,
        loadedReviewsCount: true,
      };
    case LOADING_REVIEWS:
      return { ...state, isLoading: true };
    case LOADING_REVIEWS_COUNT:
      return { ...state, loadedReviewsCount: false };
    case LOAD_MORE_REVIEWS:
      return {
        ...state,
        reviews: [...state.reviews, ...payload],
        hasMore: true,
        page: state.page + 1,
      };
    case LOAD_MORE_PRODUCT_REVIEWS:
      return {
        ...state,
        productReviews: [...state.productReviews, ...payload],
        hasMore: true,
        page: state.page + 1,
      };
    case STOP_LOADING_MORE_REVIEWS: {
      return {
        ...state,
        hasMore: false,
      };
    }
    case RESET_REVIEWS_LOADING:
      return {
        ...state,
        page: 1,
        reviews: [],
        productReviews: [],
        productReviewsCount: {},
      };
    default:
      return state;
  }
};

export default reviewReducer;
