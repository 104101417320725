import React, { useEffect } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

const { FirebaseCrashlytics } = Plugins;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const { t } = useTranslation('errors');

    const message = {
        message: error.message,
    }

  useEffect(() => {
      const effect = async () => {
          if (Capacitor.isNative) {
              const addLogMessage = async () => {
                  try {
                      await FirebaseCrashlytics.crash(message);
                      await FirebaseCrashlytics.addLogMessage(message);
                  } catch (e) {
                      console.log('firebase error ::', e.message);
                  }
              };

              await addLogMessage();
              console.log('send log message to Firebase Crashlytics');
          }
      }

      effect().then(() => {
          console.log('run effect');
      });
  }, [error, message]);

  const handleReset = () => {
    // ?? this next line is why the fallbackRender is useful
    // resetComponentState()
    // ?? though you could accomplish this with a combination of the FallbackCallback and onReset props as well.
    resetErrorBoundary();
  };

  return (
    <div className="errorContainer" role="alert">
      <h2 style={{ fontWeight: 'bold' }}>Oops.</h2>
      <p>{t('something_wrong')}</p>
      <div>
        {process.env.NODE_ENV === 'development' && <p>{error.message}</p>}
      </div>
      <button
        style={{ fontWeight: 'bold' }}
        className="button-pill"
        onClick={() => handleReset()}
      >
        {t('try_again')}
      </button>
    </div>
  );
};

export default ErrorFallback;
