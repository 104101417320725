import {
  GET_FEATURED_SHOPS_SUCCESS,
  GET_INALIPA_MEGASTORE_SUCCESS,
  GET_SHOP_BY_SUBDOMAIN_SUCCESS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOPS_SUCCESS,
  SHOP_NOT_FOUND,
  LOADING_SHOP_PRODUCTS,
  RESET_SHOP_PRODUCTS,
  RESET_SHOP_NOT_FOUND,
} from '../constants/ActionTypes';
import {
  getFeaturedShops,
  getInalipaMegaStore,
  getShopBySubDomain,
  getShopProducts,
  getShops,
} from '../../services/shopService';
import { handleErrorAction } from './utilsAction';

export const getFeaturedShopsAction = (data) => {
  return function (dispatch) {
    return getFeaturedShops().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_FEATURED_SHOPS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getShopsAction = () => {
  return function (dispatch) {
    return getShops().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_SHOPS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getShopProductsAction = (shopName) => {
  return function (dispatch) {
    return getShopProducts(shopName).then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_SHOP_PRODUCTS_SUCCESS,
            payload: response.data.data ? response.data.data : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getShopBySubDomainAction = (shopSubDomain) => {
  return function (dispatch) {
    return getShopBySubDomain(shopSubDomain).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.data) {
            // console.log(
            //   'getShopBySubDomainAction  shopSubDomain + response.data.data  = ',
            //   shopSubDomain,
            //   response.data.data,
            // );

            return dispatch({
              type: GET_SHOP_BY_SUBDOMAIN_SUCCESS,
              payload: response.data.data ? response.data.data : [],
            });
          } else {
            return dispatch({
              type: SHOP_NOT_FOUND,
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const getInalipaMegaStoreAction = () => {
  return function (dispatch) {
    return getInalipaMegaStore().then(
      (response) => {
        if (response.status === 200) {
          return dispatch({
            type: GET_INALIPA_MEGASTORE_SUCCESS,
            payload: response.data.data ? response.data.data : {},
          });
        }
      },
      (error) => dispatch(handleErrorAction(error)),
    );
  };
};

export const resetShopProductsAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SHOP_PRODUCTS,
    });
  };
};

export const resetShopFoundStatusAction = (state) => {
  return function (dispatch) {
    return dispatch({
      type: RESET_SHOP_NOT_FOUND,
    });
  };
};

export const loadShopProductsAction = (data) => {
  return function (dispatch) {
    return dispatch({
      type: LOADING_SHOP_PRODUCTS,
    });
  };
};
