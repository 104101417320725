import axios from "axios";
import { MERCANT_API } from "../constants";
import {inalipaMethodHeaders} from "./config";

export const trackOrder = async (body) => {
    return await axios
        .post(`${MERCANT_API}/orders/track`, body, inalipaMethodHeaders)
        .then((data) => data);
};

export const fileDispute = async (body) => {
    return await axios
        .post(`${MERCANT_API}/orders/dispute`, body, inalipaMethodHeaders)
        .then((data) => data);
};