import React from 'react';
import { NavBarMobileFooterWrapper } from '../styles/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../../assets/images/logo-white.png';
import { useTranslation } from 'react-i18next';
import { toggleMobileMenuAction } from '../../store/actions/uiAction';
import { useDispatch } from 'react-redux';
import { useCheckVersion } from '../../utils/versioning';

const SideBarMobileFooter = () => {
  let now = moment();
  useCheckVersion();
  const { t } = useTranslation('footer');
  const dispatch = useDispatch();
  const closeSidebar = () => {
    dispatch(toggleMobileMenuAction(false));
  };


  
  return (
    <NavBarMobileFooterWrapper>
      <div className="nav-bar-mobile">
      {/* <img src={Logo} alt="Logo" className="img-fluid footer-logo" /> */}
      <div className="nav-bar-mobile-section-container">
        <div className="nav-bar-mobile-section-header">{t('about')}</div>
        {/* <ul className="list-inline mx-auto justify-content-center"> */}
          <div className="about-list-item">
            <Link to="/about-inalipa" onClick={closeSidebar}>
              {t('about')}
            </Link>
          </div>
          <div className="about-list-item">
            <Link to="/privacy-policy" onClick={closeSidebar}>
              {t('privacy')}
            </Link>
          </div>
          <div className="about-list-item">
            <Link to="/terms-and-conditions" onClick={closeSidebar}>
              {t('terms_and_conditions')}
            </Link>
          </div>
        {/* </ul> */}
      
      
      </div>
      <div className="horizontal-line" />
      <div className="nav-bar-mobile-section-container">
      <div className="nav-bar-mobile-section-header"> {t('follow_us')}</div>
      {/*<div className="d-flex justify-content-center">*/}
      {/* <div className="footer text-left pb-2 logos my-3 d-flex d-flex-column justify-content-center align-items-left"> */}
        {/* <div className="py-2"> */}
        <a
            href="https://www.facebook.com/inalipatz/"
            rel="noopener noreferrer"
            target="__blank"
            onClick={closeSidebar}
          >
            <span className="fa-stack"  >
              {/* <FontAwesomeIcon
                icon={faCircle}
                className=" fa-stack-2x fa-svg-round"
              /> */}
              <FontAwesomeIcon
                icon={faFacebook}
                className="fa-stack-1x fa-inverse fa-logo"
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
          <a
            href="https://www.instagram.com/inalipa_tz/"
            target="__blank"
            rel="noopener noreferrer"
            className="mr-1"
            onClick={closeSidebar}
          >
            <span className="fa-stack"  >
              {/* <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x fa-svg-round"
              /> */}
              <FontAwesomeIcon
                icon={faInstagram}
                className="fa-stack-1x fa-inverse fa-logo "
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
         
          <a
            href="https://twitter.com/Inalipa_tz"
            target="__blank"
            rel="noopener noreferrer"
            onClick={closeSidebar}
          >
            <span className="fa-stack"  > 
              {/* <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x fa-svg-round"
              /> */}
              <FontAwesomeIcon
                icon={faTwitter}
                className=" fa-stack-1x fa-inverse fa-logo"
                style={{ color: '#3c3f42' }}
              />
            </span>
          </a>
          <div className='inalipa-btn-container'>
          <Link
            to="/become-supplier"
            onClick={closeSidebar}
            style={{ color: '#fff' }}
          >
            {' '}
            {t('become_supplier')}
          </Link>
           </div>
        </div>
      
      {/*</div>*/}
      <div style={ {paddingTop : '30px'}}>
        <span className="tradeMark">  
          &copy; {now.year()}. {t('trademark')}
        </span>
      </div>
 <div> 
      <span className="appVersion">
        App version {localStorage.getItem('appVersion')}
      </span>
      </div>
    {/* </div> */}
    </div>
    </NavBarMobileFooterWrapper>
  );
};

export default SideBarMobileFooter;
