import React, { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import 'yup-phone';
import { FormInputWrapper } from '../styles/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PostReviewWrapper } from '../styles/Review';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fileDispute } from '../../services/orderService';
import { isValidPhoneNumber } from 'tanzanian-phone-validator';

const FileDispute = ({ order, closeModal }) => {
  const fileDisputeValidationSchema = Yup.object().shape({
    fullnames: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    phone: Yup.string()
      .matches(/^(?:\+?255|0)[-.\s]?(6[1256789]|7[1345678])\d{7}$/, 'Please enter a valid Tanzanian phone number')
      .required('Must enter a Phone number'),

    type: Yup.string().required('Required'),
    comments: Yup.string().min(5, 'Too Short!').required('Required'),
  });

  let serverError = false;
  const [isSubmitting, setSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      trackingNumber: order.trackingNumber,
      fullnames: '',
      phone: '',
      // email: '',
      type: '',
      comments: '',
    },
    // validate: validateDispute,
    validationSchema: fileDisputeValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSubmitting(true);
      let body = JSON.stringify(values, null, 2);
      let submit = fileDispute(body);
      submit
        .then((data) => {
          setSubmitting(false);
          closeModal();
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: data.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          serverError = true;
        });
    },
  });

  return (
    <>
      <PostReviewWrapper>
        <div className="row header">
          <h3>Order dispute</h3>
          <div className="float-right">
            <button onClick={closeModal} className="close-icon">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <hr />
        </div>
        {serverError && (
          <div className="alert alert-danger">
            You have an error on your previous request.
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <p>
              If you have got a complain about your order, please fill in below
              form.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormInputWrapper>
              <form
                className="generic-form"
                action="#"
                onSubmit={formik.handleSubmit}
              >
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label htmlFor="fullnames">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullnames"
                        name="fullnames"
                        placeholder="Enter full name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullnames}
                      />
                      {formik.errors.fullnames ? (
                        <div className="error">{formik.errors.fullnames}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label htmlFor="review">Phone number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Enter your phone number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone ? (
                        <div className="error">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/*               <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="email">Email Address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter your email address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                                            </div>
                                        </div>
                                    </div>*/}
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label htmlFor="type">Dispute type</label>
                      <select
                        className="form-control"
                        id="type"
                        name="type"
                        aria-invalid="true"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Dispute Type</option>
                        <option value="wrong_order">
                          I haven't received what I ordered
                        </option>
                        <option value="wrong_quantity">
                          I have received items of wrong quantity
                        </option>
                        <option value="damaged_items">
                          I have received damaged items
                        </option>
                        <option value="expired_items">
                          I have received an expired item
                        </option>
                        <option value="poor_quality_items">
                          I have received poor quality items
                        </option>
                        <option value="other">Other</option>
                      </select>
                      {formik.errors.type ? (
                        <div className="error">{formik.errors.type}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label htmlFor="name">Explain further</label>
                      <textarea
                        className="form-control"
                        id="comments"
                        name="comments"
                        placeholder="Enter your explanation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.comments}
                      />
                      {formik.errors.comments ? (
                        <div className="error">{formik.errors.comments}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md">
                    {!isSubmitting && (
                      <button
                        className="btn-submit"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                    {isSubmitting && (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormInputWrapper>
          </div>
        </div>
      </PostReviewWrapper>
    </>
  );
};

export default FileDispute;
