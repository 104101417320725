const convertToObject = (reviewsCount) => {
    const sums = {};
    for (let i = 0; i < reviewsCount.length; i++) {
        let obj = reviewsCount[i];
        sums[obj._id] = sums[obj._id] === undefined ? 0 : sums[obj._id];
        sums[obj._id] += parseInt(obj.count);
    }
    return sums;
};

const total =  ( obj ) => {
    let total = 0;
    for( let el in obj ) {
        if( obj.hasOwnProperty( el ) ) {
            total += parseFloat( obj[el] );
        }
    }
    return total;
}

const averageRating = (rating) => {
    const total = rating.reduce((prev, current) => {
        return prev + current;
    }, 0);

    if (total === 0) {
        return 0;
    }

    let sum = 0;
    let k = 1;
    rating.forEach((item) => {
        sum += item * k;
        k++;
    });
    const r = sum / total;
    return Number(r.toFixed(1));
};

export const getTotalNumberOfRatings = (reviewsCount) => {
   return total(convertToObject(reviewsCount));
};

export const getRatingAverage = (ratingCount, totalRatingsCount) => {
    return (ratingCount * 100) / totalRatingsCount;
}

export const getTotalAverageRating = (reviewsCount) => {
    return averageRating(Object.values(convertToObject(reviewsCount)));
};
