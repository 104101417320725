import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import HomeSkeleton from './components/skeletons/HomeSkeleton';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/errors/ErrorFallback';
const rootElement = document.getElementById('root');
ReactDOM.hydrate(
  <Suspense fallback={<HomeSkeleton />}>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      <App />
    </ErrorBoundary>
  </Suspense>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => console.log('Service worker registered'),
  onUpdate: (registration) => {
    // store.dispatch({ type: SW_UPDATE, payload: registration }),
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          // localStorage.clear(); //you do this and you also clear all the other stuff?
          //or you just clear the appVersion or specific items?
          localStorage.removeItem('appVersion');
          window.location.reload();
        }
      });
    }
  },
});
