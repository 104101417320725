import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop } from '../../utils/window';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import {
  getDeviceNotificationsAction,
  resetDeviceNotificationsAction,
} from '../../store/actions/uiAction';
import { CircularProgress } from '@material-ui/core';

const NotificationList = () => {
  const notifications = useSelector((state) => state.uiManager.notifications);
  const isLoading = useSelector(
    (state) => state.uiManager.loadingNotifications,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    scrollToTop();
    if (localStorage.getItem('fcmToken')) {
      dispatch(getDeviceNotificationsAction(localStorage.getItem('fcmToken')));
    }
    return () => {
      dispatch(resetDeviceNotificationsAction());
    };
  }, []);

  const generateLinkPath = (link) => {
    const slug = link.split('.co.tz').pop();
    return slug;
  };

  return (
    <>
      <PageTitle title="Inalipa - Notication Message" />
      <SecondaryNav className="d-none d-sm-block" />
      {isLoading && !notifications && (
        <div className="container mt-3 text-center">
          <CircularProgress />
        </div>
      )}
      {notifications && notifications.length === 0 && (
        <p className="alert alert-info mt-2">You have no notification.</p>
      )}

      {notifications && notifications.length > 0 && (
        <>
          {notifications.map((notification) => (
            <div className="row mt-3 mb-2">
              <div className="col-md-12">
                <div className="card shadow">
                  <div className="card-body">
                    {notification.metadata && notification.metadata.link && (
                      <Link
                        to={`${generateLinkPath(notification.metadata.link)}`}
                        style={{ color: '#000000', textDecoration: 'none' }}
                      >
                        <div className="row">
                          <div className="col-xs-8 col-sm-7 col-md-8">
                            <h6>{notification.title}</h6>
                            <p>{notification.message}</p>
                          </div>
                          <div className="col-xs-1 col-sm-2 col-md-1 mr-3"></div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default NotificationList;
