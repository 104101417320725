import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  createCartAction,
  getPastCartItemsAction,
  reOrderAction,
  resetPastOrdersAction,
} from '../../store/actions/cartAction';
import { INALIPA_S3_URL } from '../../constants';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { useTranslation } from 'react-i18next';
import './PastOrders.css';
import CurrencyFormat from 'react-currency-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Device } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import ScrollButton from '../../components/ui/scrollButton';

const { FirebaseAnalytics } = Plugins;

function PastOrders() {
  const pastCartIds = useSelector((state) => state.cartManager.pastCartIds);
  const pastOrders = useSelector((state) => state.cartManager.pastOrders);
  const isLoading = useSelector((state) => state.cartManager.loadingPastOrders);
  const dispatch = useDispatch();
  const { t } = useTranslation('menu');

  const handleReOrderAction = (items) => {
    let deviceUuid = JSON.parse(localStorage.getItem('appDev')).uuid ?? null;
    if (!deviceUuid) {
      Device.getInfo()
        .then((device) => {
          deviceUuid = device.uuid;
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    let createCartPayload = {
      items: items,
      status: 'created',
      uuid: deviceUuid,
    };
    //dispatch(updateCartAction({ items: cartItems }, cartId));
    dispatch(createCartAction(createCartPayload));
    dispatch(reOrderAction(items));
  };

  useEffect(() => {
    // dispatch(
    //   getPastCartItemsAction(
    //     pastCartIds.map((order) => order.cartID).join(','),
    //   ),
    // );
    if (localStorage.getItem('appDev')) {
      dispatch(
        getPastCartItemsAction({
          uuid: JSON.parse(localStorage.getItem('appDev')).uuid,
        }),
      );
    }

    FirebaseAnalytics.logEvent({
      name: 'select_content',
      params: {
        content_type: 'past_orders',
      },
    });

    return () => {
      dispatch(resetPastOrdersAction());
    };
  }, []);

  return (
    <>
      <PageTitle title="Inalipa - Past Orders" />
      <SecondaryNav className="d-none d-sm-block" />
      <div className="container mt-2 mb-5">
        <h2 className="mt-2 mb-2 ml-2">{t('past_orders')}</h2>
        <ScrollButton />

        {isLoading && Object.keys(pastOrders).length === 0 && (
          <div className="container mt-3 text-center">
            <CircularProgress />
          </div>
        )}

        {pastOrders && pastOrders.length === 0 && (
          <p className="alert alert-info">
            No past orders yet . Keep shopping.
          </p>
        )}

        {pastOrders && pastOrders.length > 0 && (
          <>
            {pastOrders.map((order) => (
              <div>
                <div className="pastOrders_row">
                  <div className="pastOrders_row_items">
                    {order.cartItems &&
                      order.cartItems.map((product) => (
                        <div className="pastOrders_row_item">
                          <div className="pastOrders_image">
                            <img
                              src={`${INALIPA_S3_URL}${product.image}`}
                              alt={product.name}
                              className="img-fluid cart-image"
                            ></img>
                          </div>
                          <div className="pastOrders_desc">
                            <h2>
                              {product.quantity}{' '}
                              {product.options &&
                              product.options.variant &&
                              product.options.variant.name
                                ? product.options.variant.name
                                : ''}{' '}
                              {product.name}
                            </h2>
                            <h4>
                              TZS{' '}
                              <CurrencyFormat
                                value={product.total}
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                            </h4>
                            {product.stock &&
                              product.stock >= 0 &&
                              product.track && (
                                <p
                                  className={
                                    product.stock > 10 ? 'sm-info' : 'err'
                                  }
                                >
                                  {`${product.stock}`} left
                                </p>
                              )}

                            {!product.stock && product.track && (
                              <p className="err">Out of stock</p>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="pastOrders_action">
                    <div className="pastOrders_action_btn">
                      <button
                        type="button"
                        className={
                          order.canReorder
                            ? 'btn btn-primary'
                            : 'btn btn-primary inalipa-btn-disabled'
                        }
                        onClick={() => handleReOrderAction(order.cartItems)}
                        disabled={order.canReorder ? false : true}
                      >
                        Re-order
                        {/* {order.cartItems &&
                      order.cartItems.map((item) => {
                        if (item.stock < item.qualtity) {
                          return false;
                        }
                      })} */}
                      </button>
                      {!order.canReorder && (
                        <p className="err">
                          Cannot re-order {order.outOfStock.stock}{' '}
                          {order.outOfStock.name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default PastOrders;
