import React from 'react';
import { INALIPA_S3_URL } from '../../constants';
import { Card } from '../styles/Card';
import { MegaStoreImageWrapper } from '../styles/List';

const ShopBanner = ({ banner }) => {
  return (
    <div className="row">
      <div className="col-md-12 pl-0 pr-0">
        <Card>
          <MegaStoreImageWrapper>
            <img
              src={`${INALIPA_S3_URL}shops/${banner}`}
              className="img-fluid img-list"
              height="120"
              width="120"
              alt="banner"
            />
          </MegaStoreImageWrapper>
        </Card>
      </div>
    </div>
  );
};

export default ShopBanner;
