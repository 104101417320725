import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SecondaryNavWrapper } from '../../../../components/styles/Navigation';

function SecondaryNavSkeleton() {
  return (
    <SecondaryNavWrapper>
      <div className="container d-none d-sm-block pl-0">
        <nav className="navbar navbar-expand-sm pb-0 pl-0">
          <ul className="navbar-nav pl-0">
            {Array(4)
              .fill()
              .map((item, index) => (
                <li className="nav-item" key={index}>
                  <div className="row mt-1 mr-3 mb-3">
                    <Skeleton width={60} height={20} />
                  </div>
                </li>
              ))}
            <li className="nav-item mr-3">
              <span>|</span>
            </li>
            {Array(3)
              .fill()
              .map((item, index) => (
                <li className="nav-item" key={index}>
                  <div className="row mt-1 mr-3 mb-3">
                    <Skeleton width={60} height={20} />
                  </div>
                </li>
              ))}
            <li className="nav-item mr-3">
              <span>|</span>
            </li>
            <li className="nav-item">
              <div className="row mt-1 mb-3">
                <Skeleton width={60} height={20} />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </SecondaryNavWrapper>
  );
}

export default SecondaryNavSkeleton;
