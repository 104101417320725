import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import {
  resetProductSharingAction,
  shareProductAction,
} from '../../store/actions/catalogAction';
import { ActionButtonDangerRound } from '../styles/Form';
import { ProductWrapper } from '../styles/product';
import productPlaceholder from '../../assets/images/placeholders/item.png';
import AddToCartButton from '../cart/AddToCartButton';
import VariantSelector from './VariantSelector';
import RatingStars from '../ui/Reviews/RatingStars';
import { INALIPA_S3_URL } from '../../constants';
import { numberWithCommas, roundOffNumber } from '../../utils/format';
import { useTranslation } from 'react-i18next';
import placeholder from '../../assets/images/placeholders/item.png';
import ShareIcon from '@material-ui/icons/Share';
import { selectProductAction } from '../../store/actions/cartAction';
import useLazyLoadImage from '../../hooks/useLazyLoadImage';
import backgroundImage from '../../assets/images/placeholders/new_logo.jpg';
import MutlipeImages from '../product/MutlipeImages';

const ProductItem = ({ product, selectedVariant, reviewsCount }) => {
  const [cartProduct, setCartProduct] = useState(null);
  const selectedProduct = useSelector(
    (state) => state.cartManager.selectedProduct,
  );
  const [isProductCopied, setIsProductCopied] = useState(false);
  const [isProductCopiedFail, setIsProductCopiedFail] = useState(false);

  const dispatch = useDispatch();
  const isProductShared = useSelector(
    (state) => state.catalogManager.isProductShared,
  );
  const message = useSelector((state) => state.catalogManager.message);
  // const selectedProduct = useSelector(
  //   (state) => state.cartManager.selectedProduct,
  // );
  const { t } = useTranslation('product');

  useEffect(() => {
    let canTrackInventory = false;
    let isVariantOutOfStockInventory = true;
    let isOutOfStock = product.outOfStock;
    if (
      product.options &&
      product.options.inventory &&
      product.options.inventory.policy &&
      product.options.inventory.policy === 'inalipa'
    ) {
      canTrackInventory = true;
      isOutOfStock = !(
        product.options &&
        product.options.inventory &&
        product.options.inventory.quantity &&
        product.options.inventory.quantity > 0
      );
      if (selectedVariant && selectedVariant.inventory > 0) {
        isVariantOutOfStockInventory = false;
      }
    }
    let selectedProductPrice =
      product.pricePromotion && product.pricePromotion.prices.retail.new
        ? product.pricePromotion.prices.retail.new
        : product.price;
    const selectedProductItem = {
      name: product.name,
      quantity: 1,
      qty: 1,
      id: product._id,
      price: parseInt(selectedProductPrice),
      total: parseInt(selectedProductPrice),
      image: product.images ? `products/${product.images.main}` : placeholder,
      shop: product.shop && product.shop.subDomain,
      shopId: product.shop && product.shop._id,
      shopName: product.shop && product.shop.name,
      shopImage: product.shop && product.shop.image,
      preOrder:
        product.options &&
        product.options.preOrder &&
        product.options.preOrder !== ''
          ? parseInt(product.options.preOrder)
          : 0,
      shopOpeningHours: product?.shop?.settings?.opening_hours,
      vat:
        product.options && product.options.vatExempted
          ? 0
          : parseFloat(
              selectedProductPrice - selectedProductPrice / 1.18,
            ).toFixed(2),
      options: selectedVariant
        ? {
            variant: selectedVariant.name,
          }
        : '',
      stock: selectedVariant
        ? selectedVariant.inventory
        : product.options &&
          product.options.inventory &&
          product.options.inventory.quantity
        ? product.options.inventory.quantity
        : null,
      sku_id: product._id,
      track: canTrackInventory,
      isVariantOutOfStockInventory: isVariantOutOfStockInventory,
      isOutOfStock: isOutOfStock,
      brand: product?.brand?.name,
      category: product?.category?.name,
    };
    setCartProduct(selectedProductItem);
    dispatch(selectProductAction(selectedProductItem));
    // console.log(JSON.stringify(selectedProductItem));
    return () => dispatch(resetProductSharingAction());
  }, []);
  // }, [dispatch, product, selectedVariant]);

  useEffect(() => {
    if (cartProduct) {
      let updatedVariant = {
        ...selectedProduct,
        sku_id: selectedVariant.sku,
        options: { variant: selectedVariant.name },
        isVariantOutOfStockInventory: selectedVariant.inventory === 0,
        stock: selectedVariant.inventory,
        image: selectedVariant.picture
          ? `products/${selectedVariant.picture}`
          : null,
      };
      setCartProduct(updatedVariant);
      dispatch(selectProductAction(updatedVariant));
    }
  }, [selectedVariant]);
  // }, [cartProduct, dispatch, selectedProduct, selectedVariant]);

  const shareProduct = () => {
    dispatch(
      shareProductAction({
        id: cartProduct.id,
        image: `${INALIPA_S3_URL}${cartProduct.image}`,
        name: cartProduct.name,
      }),
    );
  };

  // if (product.pricePromotion && product.pricePromotion.variants && product.pricePromotion.variants.length > 0) {
  // const variantPromoPrice = (variant) =>
  // product.pricePromotion.variants.map((variantPromo) =>
  //   variantPromo.name === variant.name ?
  // 'TZS ' + roundOffNumber(variant.retail.old, 0);
  // : ' ',
  // );
  // }

  let source = null;
  if (selectedVariant && selectedVariant.picture) {
    source = `${INALIPA_S3_URL}${`products/${selectedVariant.picture}`}`;
  } else if (cartProduct && cartProduct.image && product.images.main) {
    source = `${INALIPA_S3_URL}${cartProduct.image}`;
  } else {
    source = backgroundImage;
  }
  const loaded = useLazyLoadImage(source);

  const copyProductLink = () => {
    navigator.clipboard.writeText(message).then(
      function () {
        setIsProductCopied(true);
      },
      function () {
        setIsProductCopiedFail(false);
      },
    );
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = backgroundImage;
  };

  function displayPackagingUnitName(data) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].serviceId === 'b2c') {
            return data[i].packagingUnit.name;
        }
    }
    return '';
}
  return (
    <ProductWrapper>
      <div className="row mt-3 pl-2">
        <div className=" col-md-6 text-center">
          <div className="product-image-wrapper">
            {((product &&
              product.variations &&
              product.variations.variants &&
              product.variations.variants.length === 0) ||
              (product &&
                product.variations &&
                product.variations.length === 0) ||
              (product && !product.variations)) && (
              <>
                {product && product.images && !product.images.others && (
                  <img
                    src={`${INALIPA_S3_URL}products/${product.images.main}`}
                    alt={product.name}
                    className="img-fluid"
                    product={true}
                    onError={addDefaultSrc}
                  />
                )}
                {product &&
                  product.images &&
                  product.images.others &&
                  product.images.others.length > 0 && (
                    <MutlipeImages
                      name={product.name}
                      mainImage={product.images.main}
                      otherImages={product.images.others}
                    />
                  )}
              </>
            )}
            {product &&
              product.variations &&
              product.variations.variants &&
              product.variations.variants.length > 0 && (
                <>
                  {selectedVariant &&
                    (!selectedVariant.otherPictures ||
                      (selectedVariant.otherPictures &&
                        selectedVariant.otherPictures.length === 0)) && (
                      <img
                        src={`${INALIPA_S3_URL}products/${
                          selectedVariant && selectedVariant.picture
                        }`}
                        alt={product.name}
                        className="img-fluid"
                        product={true}
                        onError={addDefaultSrc}
                      />
                    )}
                  {selectedVariant &&
                    selectedVariant.otherPictures &&
                    selectedVariant.otherPictures.length > 0 && (
                      <MutlipeImages
                        name={selectedVariant.name}
                        mainImage={selectedVariant.picture}
                        otherImages={selectedVariant.otherPictures}
                      />
                    )}
                </>
              )}
            {product.options &&
              product.options.preOrder &&
              product.options.preOrder > 0 && (
                <button className="pre-order">Pre Order</button>
              )}
          </div>
        </div>
        <div className="col-md-6 pl-0 pr-0 text-left">
          <div className="row">
            <div className="col-3 pl-0 pr-0 text-left">
              <img
                className="img-fluid"
                width="40"
                height="40"
                src={
                  product &&
                  product.shop &&
                  product.shop.settings &&
                  product.shop.settings.logo
                    ? `${INALIPA_S3_URL}shops/${product.shop.settings.logo}`
                    : productPlaceholder
                }
                alt="product-store"
              />
            </div>
            <div className="col-9 pl-0 pr-0 text-right">
              <div className="row mt-2">
                <div className="col-8 text-right pl-0">
                  <RatingStars average={reviewsCount.average} />
                </div>
                <div className="col-4 text-left pr-0 pl-0">
                  <button onClick={shareProduct} className="btn-default">
                    <ShareIcon /> Share
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {isProductShared && document.queryCommandSupported('copy') && (
              <>
                <div className="col-md-12 pr-0 pl-0">
                  <button onClick={copyProductLink} className="btn btn-copy">
                    Click to copy link
                  </button>
                </div>
                {isProductCopied && <p className="alert-copy">Copied!</p>}
              </>
            )}
            {product && product.name && (
              <div className="col-md-12 pr-0 pl-0">
                <h2 style={{ fontSize: '18px' }}>{product.name}</h2>
              </div>
            )}
            <div className="col-md-12 mb-5">
              <div className="row text-left">
                <div className="col-md-12 px-0">
                  {selectedVariant && <p>{selectedVariant.name}</p>}
                  {product.packaging && <p>{displayPackagingUnitName(product.packaging)}</p>}
                  {cartProduct && cartProduct.track && (
                    <p>
                      {selectedVariant
                        ? selectedVariant.inventory
                        : product.options.inventory.quantity}{' '}
                      left
                    </p>
                  )}
                  <div className="row">
                    {!selectedVariant &&
                      product.pricePromotion &&
                      product.pricePromotion.prices.retail.old && (
                        <p className="pr-2">
                          <strike class="discounted-price">
                            TZS{' '}
                            {numberWithCommas(
                              roundOffNumber(
                                product.pricePromotion.prices.retail.old,
                                0,
                              ),
                            )}
                          </strike>
                        </p>
                      )}
                    {selectedVariant &&
                      product.pricePromotion &&
                      product.pricePromotion.variants &&
                      product.pricePromotion.variants.length > 0 &&
                      product.pricePromotion.variants.map(
                        (variant) =>
                          variant.name === selectedVariant.name && (
                            <p className="pr-2">
                              <strike class="discounted-price">
                                {numberWithCommas(
                                  'TZS ' +
                                    roundOffNumber(variant.retail.old, 0),
                                )}
                              </strike>
                            </p>
                          ),
                      )}
                    <p
                      style={{
                        color: '#f15b2a',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      TZS{' '}
                      {numberWithCommas(
                        roundOffNumber(
                          selectedVariant
                            ? selectedVariant.price
                            : product.price,
                          0,
                        ),
                      )}
                    </p>
                  </div>
                </div>
                {product.variations.variants &&
                  product.variations.variants.length > 0 &&
                  product.variations.options &&
                  product.variations.options.map((variantOption, index) => (
                    <VariantSelector
                      options={variantOption}
                      variants={product.variations.variants}
                      product={product}
                      index={index}
                    />
                  ))}
                {cartProduct &&
                  (cartProduct.track
                    ? cartProduct.track && !cartProduct.isOutOfStock
                    : !cartProduct.isOutOfStock) &&
                  product.variations &&
                  product.variations.length === 0 && (
                    <AddToCartButton
                      cartItem={cartProduct}
                      canTrackInventory={cartProduct.track}
                    />
                  )}
                {cartProduct &&
                  (cartProduct.track
                    ? cartProduct.track && !cartProduct.isOutOfStock
                    : !cartProduct.isOutOfStock) &&
                  product.variations.variants &&
                  product.variations.variants.length === 0 && (
                    <AddToCartButton
                      cartItem={cartProduct}
                      canTrackInventory={cartProduct.track}
                    />
                  )}
                {cartProduct &&
                  (cartProduct.track
                    ? cartProduct.track &&
                      !cartProduct.isOutOfStock &&
                      !cartProduct.isVariantOutOfStockInventory
                    : !cartProduct.isOutOfStock) &&
                  product.variations.variants &&
                  product.variations.variants.length > 0 &&
                  selectedVariant && (
                    <AddToCartButton
                      cartItem={selectedProduct}
                      canTrackInventory={cartProduct.track}
                      hasVariant={true}
                    />
                  )}
                {/* {cartProduct &&
                  (cartProduct.track
                    ? cartProduct.track &&
                      !cartProduct.isOutOfStock &&
                      cartProduct.isVariantOutOfStockInventory
                    : cartProduct.isOutOfStock) &&
                  product.variations.variants &&
                  product.variations.variants.length > 0 &&
                  selectedVariant && (
                    <ActionButtonDangerRound>
                      {t('out_of_stock')}
                    </ActionButtonDangerRound>
                  )} */}
                {cartProduct &&
                  (cartProduct.track
                    ? cartProduct.track && cartProduct.isOutOfStock
                    : cartProduct.isOutOfStock) &&
                  !selectedVariant && (
                    <ActionButtonDangerRound>
                      {t('out_of_stock')}
                    </ActionButtonDangerRound>
                  )}

                {selectedProduct &&
                  selectedProduct.track &&
                  selectedVariant &&
                  selectedVariant.inventory === 0 && (
                    <ActionButtonDangerRound>
                      {t('out_of_stock')}
                    </ActionButtonDangerRound>
                  )}

                {selectedProduct &&
                  !selectedProduct.track &&
                  selectedVariant &&
                  selectedVariant.isOutOfStock && (
                    <ActionButtonDangerRound>
                      {t('out_of_stock')}
                    </ActionButtonDangerRound>
                  )}
              </div>
            </div>
          </div>
          {product && product.description ? (
            <div className="row">
              <hr className="mt-3 mb-3" />
              <div className="col-md-12 pl-0">
                <h5>Product Description</h5>
                <p>{ReactHtmlParser(product.description)}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </ProductWrapper>
  );
};

export default ProductItem;
