import axios from 'axios';
import {
  MERCANT_API,
  API_KEY,
  INALIPA_MEGASTORE_ID,
  SEARCH_LIMIT,
  PRODUCTS_LIMIT,
  AUTOCOMPLETE_LIMIT,
} from '../constants';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;

const contentTypeApplicationJson = {
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-Inalipa-Shop-API-KEY': API_KEY,
  },
};

export const getDailyDeals = async () => {
  const result = await axios
    .get(`${MERCANT_API}/website/products/deals`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const getCuratedSection = async (id) => {
  const result = await axios
    .get(`${MERCANT_API}/curated-products/section/${id}?active=true`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
      },
    })
    .then((data) => data);
  return result;
};

export const searchCatalog = async (searchTerm, page, limit) => {
  const result = await axios
    .get(
      `${MERCANT_API}/website/search?limit=${limit}&q=${searchTerm}&page=${page}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
        },
      },
    )
    .then((data) => data);
  return result;
};

let cancelToken;
export const searchAutocomplete = async (searchTerm, page) => {
  if (cancelToken) {
    cancelToken.cancel('Operation cancelled, new request initiated ');
  }

  cancelToken = axios.CancelToken.source();
  let result;

  try {
    result = await axios.get(
      `${MERCANT_API}/website/search/autocomplete-slim?limit=${AUTOCOMPLETE_LIMIT}&q=${searchTerm}&page=${page}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
        },
        cancelToken: cancelToken.token,
      },
    );
    //.then((data) => data);
  } catch (error) {
    console.log(error);
  }

  return result;
};

export const shareCart = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/shopping-carts`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const createCart = async (data) => {
  const result = await axios
    .post(
      `${MERCANT_API}/shopping-carts`,
      JSON.stringify(data),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const updateCart = async (data, cartId) => {
  let deviceUuid = JSON.parse(localStorage.getItem('appDev')).uuid ?? null;
  if (!deviceUuid) {
    Device.getInfo()
      .then((device) => {
        deviceUuid = device.uuid;
      })
      .catch((err) => {
        console.log('err', err);
      });
  }
  let payload = {
    items: data.items,
    uuid: deviceUuid,
  };
  const result = await axios
    .put(
      `${MERCANT_API}/shopping-carts/${cartId}`,
      JSON.stringify(payload),
      contentTypeApplicationJson,
    )
    .then((data) => data);
  return result;
};

export const getProducts = async (params) => {
  let sortBy = params.sortBy
    ? params.sortBy === 'featured'
      ? ''
      : `sortBy=${params.sortBy}&`
    : ``;
  let pagination = `limit=${PRODUCTS_LIMIT}&page=${
    params.page ? params.page : 0
  }`;
  let brandsFilter =
    params.Brand && params.Brand.length > 0
      ? `brands=${params.Brand.map((brand) =>
          brand.value ? brand.value : brand,
        ).join(',')}&`
      : '';

  let shopFilter =
    params.Shop && params.Shop.length > 0
      ? `shop=${params.Shop.map((shop) =>
          shop.value ? shop.value : shop,
        ).join(',')}&`
      : '';

  let subDomainFilter =
    params.subDomain && params.subDomain.length > 0
      ? `subDomain=${params.subDomain
          .map((domain) => (domain.value ? domain.value : domain))
          .join(',')}&`
      : '';

  let categoryFilter =
    params.Category && params.Category.length > 0
      ? `categories=${params.Category.map((cat) => cat.value).join(',')}&`
      : '';

  let subCategoryFilter =
    params.SubCategory && params.SubCategory.length > 0
      ? `subCategories=${params.SubCategory.map((cat) => cat.value).join(',')}&`
      : '';

  let verticalFilter =
    params.Vertical && params.Vertical.length > 0
      ? `vertical=${params.Vertical.map((vertical) =>
          vertical.value ? vertical.value : vertical,
        ).join(',')}&`
      : '';
  return await axios
    .get(
      `${MERCANT_API}/website/products?${subDomainFilter}${brandsFilter}${categoryFilter}${subCategoryFilter}${verticalFilter}${shopFilter}${sortBy}${pagination}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => data);
};

export const getProductById = async (productId) => {
  return await axios
    .get(`${MERCANT_API}/products/${productId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getRelatedProducts = async (productId) => {
  return await axios
    .get(`${MERCANT_API}/products/${productId}/related`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getBrands = async () => {
  return await axios
    .get(`${MERCANT_API}/product-brands`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getBrand = async (brandId) => {
  return await axios
    .get(`${MERCANT_API}/product-brands/${brandId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getBrandProducts = async (brandId) => {
  return await axios
    .get(`${MERCANT_API}/website/products?brand=${brandId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getBrandFeaturedProducts = async (brandId) => {
  return await axios
    .get(`${MERCANT_API}/website/products?brand=${brandId}&featured=true`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getCategoryProducts = async (categoryId) => {
  return await axios
    .get(`${MERCANT_API}/website/products?category=${categoryId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getCategoryFeaturedProducts = async (categoryId) => {
  return await axios
    .get(
      `${MERCANT_API}/website/products?category=${categoryId}&featured=true`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => data);
};

export const getVerticalProducts = async (verticalId) => {
  return await axios
    .get(
      `${MERCANT_API}/website/products?shop=${INALIPA_MEGASTORE_ID}&vertical=${verticalId}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => data);
};

export const getVertical = async (verticalId) => {
  return await axios
    .get(`${MERCANT_API}/shop-categories/${verticalId}`, {
      headers: {
        'x-inalipa-shop-api-key': API_KEY,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data);
};

export const getVerticalFeaturedProducts = async (verticalId) => {
  return await axios
    .get(
      `${MERCANT_API}/website/products?shopId=${INALIPA_MEGASTORE_ID}&vertical=${verticalId}&featured=true`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((data) => data);
};

export const getFilters = async (data) => {
  let shopNew = data.subDomain
    ? `store=${data.subDomain.map((v) => (v.id ? v.id : v)).join(',')}`
    : '';
  let vertical = data.Vertical
    ? `vertical=${data.Vertical.map((v) => (v.value ? v.value : v)).join(',')}`
    : '';
  let Shop = data.Shop
    ? `store=${data.Shop.map((v) => (v.value ? v.value : v)).join(',')}`
    : '';

  let category = data.Category
    ? `categories=${data.Category.map((v) => (v.value ? v.value : v)).join(
        ',',
      )}`
    : '';

  let subCategory = data.SubCategory
    ? `subCategories=${data.SubCategory.map((v) =>
        v.value ? v.value : v,
      ).join(',')}`
    : '';

  let Brand = data.Brand
    ? `brand=${data.Brand.map((v) => (v.value ? v.value : v)).join(',')}`
    : '';

  let brand = data.brand ? `brand=${data.brand}` : '';
  let verticalAnd = vertical !== '' ? '&' : '';
  let brandAnd = brand !== '' ? '&' : '';
  let BrandAnd = Brand !== '' ? '&' : '';
  let categoryAnd = category !== '' ? '&' : '';
  let subCategoryAnd = subCategory !== '' ? '&' : '';
  let shopNewAnd = shopNew !== '' ? '&' : '';
  let shopAnd = Shop !== '' ? '&' : '';

  if (shopNew !== '') {
    vertical = '';
    verticalAnd = '';
  }
  const result = await axios
    .get(
      `${MERCANT_API}/product-filters?${vertical}${verticalAnd}${Shop}${shopAnd}${shopNew}${shopNewAnd}${category}${categoryAnd}${subCategory}${subCategoryAnd}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
        },
      },
    )
    .then((data) => data);
  return result;
};

export const getBrandFilters = async (data) => {
  // console.log('data', data);
  let categories = data.Category
    ? `categories=${data.Category.map((v) => (v.value ? v.value : v)).join(
        ',',
      )}`
    : '';

  let brand = data.Brand
    ? Array.isArray(data.Brand)
      ? `brand=${data.Brand[0].value}`
      : `brand=${data.Brand}`
    : '';

  let brandAnd = brand !== '' ? '&' : '';
  let categoryAnd = categories !== '' ? '&' : '';

  const result = await axios
    .get(
      `${MERCANT_API}/brand-filters?${brand}${brandAnd}${categories}${categoryAnd}`,
      {
        headers: {
          'x-inalipa-shop-api-key': API_KEY,
        },
      },
    )
    .then((data) => data);
  return result;
};
