import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FilterWrapperSkeletonWrapper } from '../../../styles/Filter';


function FiltersSectionSkeleton() {

    return (
        <><FilterWrapperSkeletonWrapper>
            <SkeletonTheme color="#e5e5e5"> 
            <div className={'filter-form-skeleton-container'}> 
                    <Skeleton className={'filter-form-skeleton-box'} height={46}/>
            </div>
            <div className={'filter-form-skeleton-container'}>
                    <Skeleton className={'filter-form-skeleton-box'} height={46}/>
            </div>
            </SkeletonTheme>
        </FilterWrapperSkeletonWrapper>
        </>
    );
}

export default FiltersSectionSkeleton;

