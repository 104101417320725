import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAction } from '../../store/actions/categoryAction';
import { INALIPA_S3_URL } from '../../constants';
import {
  CategoryWrapper,
  CenteredTextImageWrapper,
} from '../../components/styles/general';
import PageTitle from '../../components/utils/PageTitle';
import SecondaryNav from '../../components/Navigation/SecondaryNav';
import { Link, useLocation } from 'react-router-dom';
import placeholder from '../../assets/images/placeholders/item.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import {
  resetFiltersAction,
  scrollDataActionCat,
} from '../../store/actions/catalogAction';
import {
  scrollDataActionUi,
  setPreviousUrlAction,
} from '../../store/actions/uiAction';
import { scrollToTop } from '../../utils/window';
import ScrollButton from '../../components/ui/scrollButton';

const Departments = () => {
  const categories = useSelector((state) => state.categoryManager.categories);
  const dispatch = useDispatch();
  const { t } = useTranslation('product');
  const scrollToLastPosition = useSelector(
    (state) => state.uiManager.scrollToLastPosition,
  );
  const location = useLocation();
  useEffect(() => {
    if (scrollToLastPosition === false) {
      scrollToTop();
    }
    dispatch(getCategoriesAction());
    dispatch(resetFiltersAction());
    dispatch(scrollDataActionUi('reset'));
    dispatch(scrollDataActionCat('reset'));
  }, [location]);

  // const Url = location
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPreviousUrlAction(Url));
  //   }
  // }, [location]);
  return (
    <>
      <PageTitle title="Inalipa - Categories" />
      <SecondaryNav className="d-none d-sm-block" />
      <div className="container mb-5">
        <ScrollButton />
        <h3 className="mt-2 mb-2 ml-2">{t('all_categories')}</h3>
        <CategoryWrapper>
          <div className="row">
            {!categories && (
              <div className="container mt-2 text-center">
                <CircularProgress />
              </div>
            )}
            {categories &&
              categories.length > 0 &&
              categories.map((category) => (
                <div className="column col-md-3 col-sm-6 mt-3 pr-2 pl-2">
                  <Link to={`/category/${category._id}`}>
                    <CenteredTextImageWrapper>
                      <div className="cat-page">
                        <img
                          src={
                            category.image
                              ? `${INALIPA_S3_URL}${category.image}`
                              : placeholder
                          }
                          className="img-thumb-same-height"
                          alt={category.name}
                        />
                      </div>
                    </CenteredTextImageWrapper>
                    <p className="text-center mt-2">{category.name}</p>
                    {/* <p>{category.name}</p> */}
                  </Link>
                </div>
              ))}
          </div>
        </CategoryWrapper>
      </div>
    </>
  );
};

export default Departments;
