// Scroll to the top of the page
export function scrollToTop() {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (error) {
    window.scrollTo(0, 0);
  }
}

// get current scroll position
export const getWindowPosition = () => {
  // console.log(window.scrollY);
  return window.scrollY;
};
